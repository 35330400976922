<template>
    <div class="realNameLinkError">
        <!-- 导航栏 -->
        <div class="header">
            <h3>实名补录</h3>
        </div>
        <!-- 内容 -->
        <div class="main">
            <div class="hit">
                <img src="../../assets/images/sb.png" alt="">
                <p>实名失败，请退出此页面重新进行实名</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "auditCabcel",
    components: {},
    methods: {}
};
</script>

<style lang="scss" scoped>
.realNameLinkError {
    height: 100%;

    .header {
        height: px2rem(60);
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            font-size: px2rem(38);
        }
    }

    .main {
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;

        .hit {
            height: 40%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            img {
                width: px2rem(147);
                height: px2rem(118);
                margin: px2rem(50) 0 px2rem(40);
            }

            p {
                font-size: px2rem(32);
                font-weight: 700;
            }
        }
    }
}
</style>