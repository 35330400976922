<template>
    <div class="WxQuery">
        <div class="wxquery_title">
            <img src="../../assets/images/pay/wx1.png" alt="" />
            <p>请确认微信支付是否已完成</p>
        </div>
        <div class="wxquery_btn">
            <button class="finish" @click="Payfinish">
                支付成功，继续开卡流程
            </button>
            <button class="again" @click="matter">
                支付遇到问题，重新支付
            </button>
        </div>
    </div>
</template>

<script>
import {
    QueryOpenCardLocation,
    QueryOrderByOutTradeNo,
    AddOpenCardLocation,
} from "../../common/newapi/api.js"
import { Toast, Dialog } from "vant"
import Lanmao from "../../components/lanmao/index"
import preventBack from "vue-prevent-browser-back" //组件内单独引入
export default {
    components: {},
    mixins: [preventBack], //注入  阻止返回上一页
    data() {
        return {}
    },

    created() { },
    // 组件方法
    methods: {
        // 支付查询
        async Payfinish() {
            Lanmao.loading.show("支付查询验证中")
            //支付后查询开卡记录
            var QueryOpenCardLocationData = await QueryOpenCardLocation({
                uimId: localStorage.getItem("uimId"),
            })

            if (QueryOpenCardLocationData.code != 1) {
                Lanmao.loading.hide()
                Dialog.alert({
                    message: "开卡时间过长数据缺失,请重新输入手机号",
                }).then(() => {
                    this.$router.push("/OpenCard")
                })
            } else {
                // 微信支付查询
                try {
                    let WxQueryData = await QueryOrderByOutTradeNo(
                        QueryOpenCardLocationData.data.outtradeno //商户流水号
                    )
                    if (WxQueryData.code == 1) {
                        Lanmao.loading.hide()
                        // 支付查询后增加缓存记录
                        this.payqueryaddredis(QueryOpenCardLocationData.data)
                    } else {
                        Lanmao.loading.hide()
                        Toast({
                            position: "bottom",
                            message: WxQueryData.msg,
                        })
                        this.$router.push("/OpenPay")
                    }
                } catch (err) {
                    Toast({
                        position: "bottom",
                        message: "微信支付异常,请重新开卡",
                    })
                    Lanmao.loading.hide()
                }
            }
        },

        //支付查询后增加缓存记录
        async payqueryaddredis(data) {
            // 2、支付后增加缓存记录
            let AddOpenCardOne = await AddOpenCardLocation({
                serviceid: data.serviceid, //手机号
                applyEvent: "1", //业务类型
                systemId: data.systemId, //套餐业务类型
                acceptPerson: data.acceptPerson, //工号
                cityCode: "360", //受理人所在地市
                resInstId: data.resInstId, //号码的实例ID
                serviceKind: data.serviceKind, //运营商类型
                uimId: data.uimId, //卡号
                uimResInstId: data.uimResInstId, //卡号的实例ID
                belongCode: "360", //号码归属地
                dealerId: data.dealerId, //代理商编号
                recordNumber: data.recordNumber, //订单号
                openCardLocation: "2", //步骤条进度
                prodOfferId: data.prodOfferId, //套餐ID
                ifCheckCard: data.ifCheckCard, //是否添加小卡版
                sumFee: data.sumFee, //充值金额
                outTradeNo: data.outtradeno, //商户流水号
                rechargeFlag: "finish", //充值标记 start进入支付 finish支付成功
                dealerMainId: data.dealerMainId, //顶级渠道
            })
            if (AddOpenCardOne.code != 1) {
                Dialog.alert({
                    message: "开卡时间过长数据缺失,请重新输入手机号",
                }).then(() => {
                    this.$router.push("/OpenCard")
                })
            } else {
                localStorage.setItem("payquery", "1")
                this.$router.push("/OpenCard")
            }
        },
        // 支付遇到问题,重新支付
        matter() {
            this.$router.push("/OpenPay")
        },
    },

    computed: {},

    mounted() { },
}
</script>

<style scoped lang='scss'>
.WxQuery {
    width: 100%;
    height: 100%;

    .wxquery_title {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 16%;
            height: 18%;
            margin-bottom: px2rem(60);
        }

        p {
            font-size: px2rem(40);
        }
    }

    .wxquery_btn {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-size: px2rem(30);

        .finish {
            width: 80%;
            height: px2rem(100);
            border-radius: px2rem(30);
            color: #4881c4;
            background: #e1e3f3;
        }

        .again {
            width: 80%;
            height: px2rem(100);
            color: #615b5b;
            border-radius: px2rem(30);
        }
    }
}
</style>