<template>
    <div class="activity">
        <!-- 导航栏 -->
        <topHead><h3 slot="header">特惠活动</h3></topHead>
        <!-- 内容 -->
        <div class="main">
            <ul class="main_list">
                <li v-for="(item,index) in list" :key="index"  @click="goDetails(item.activityId)" >
                    <div class="img">
                        <img :src="item.cover" alt="" />
                    </div>
                    <div class="title">{{item.name}}</div>
                    <p>{{item.desc}}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import topHead from "../../components/topHead.vue"
import API from "../../common/api/api.js"
export default {
    name: "activity",
    data () {
      return {
        list:[]  
      }
    },
    components: {
        topHead,
    },
    mounted(){
      this.getActivity();
    },
    methods: {
        goDetails(id) {
            this.$router.push({ path: "activityDetails" , query:{id:id}})
        },
        getActivity(){
          let that = this;
          API.getJson("GET_ACTIVITY_LIST", {}).then(res => {
              that.list = res.list
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
}
.main_list {
    margin-top: px2rem(20);
}
.main_list li {
    margin-bottom: px2rem(30);
}
.main_list .img,
.main_list .img img {
    width: 100%;
    height: px2rem(290);
    border-radius: px2rem(20);
}
.main_list .title {
    font-size: px2rem(30);
    font-weight: bold;
    color: #333;
    margin: px2rem(25) 0 px2rem(10);
    white-space: nowrap;
    /*内容超宽后禁止换行显示*/
    overflow: hidden;
    /*超出部分隐藏*/
    text-overflow: ellipsis;
}
.main_list p {
    font-size: px2rem(22);
    color: #999999;
    line-height: px2rem(46);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
</style>