<template>
    <div class="setMeal">
        <!-- 导航栏 -->
        <topHead>
            <h3 slot="header">套餐余量</h3>
        </topHead>
        <!-- 内容 -->
        <div class="main">
            <!-- 头部 -->
            <div class="balance_top">
                <!-- <span @click="show = true">{{ packageName }}</span> -->
                <p>您好，您当前的【 {{ packageName }} 】使用情况为</p>
            </div>

            <!-- <van-overlay :show="show" @click="show = false">
                <div class="wrapper">
                    <div class="block">
                        <div class="x">X</div>
                        <p v-html="setDatalist"></p>
                    </div>
                </div>
            </van-overlay> -->

            <!-- 新改列表开始 -->
            <van-tabs v-model="active">
                <van-tab title="流量">
                    <div class="flow">
                        <div class="alternative">
                            <div class="Within" v-if="flowSetList.length >= 1">
                                <div class="Withins">
                                    <div class="tab_title">本月套餐内使用:</div>

                                    <div class="Withins_com">
                                        <van-progress pivot-text="已使用" color="#F07541" :percentage="flowSetList[0].percentage
                                            " stroke-width="20" :show-pivot="true" />
                                        <div class="Withins_com_use dis">
                                            <p>
                                                已使用{{ flowSetList[0].used }}
                                            </p>
                                            <p>
                                                剩余{{ flowSetList[0].surplus }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="Outer" v-if="flag == 'yincang'">
                                <div class="tab_title">本月套餐外使用:</div>
                                <div class="dis">
                                    <p>当月使用:</p>
                                    <p>{{ setOuter.flowcount }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- 套餐资费 -->
                        <div class="kxsetMeal" v-if="flag == 'Daypack'">
                            <div class="firstSet">
                                <div class="setMealOuter">
                                    <div class="setMealOuter_DayPack">
                                        <div class="setMealOuter_title">
                                            <div class="flow_title">流量日包:</div>
                                            <p class="ask" @click="askShow = true"> ? </p>
                                            <van-popup v-model="askShow">
                                                <ul>
                                                    <li>资费说明：</li>
                                                    <li>1、您订购的套餐为{{ packageName }};</li>
                                                    <li> 2、当月使用流量套外自动续订流量日包1元/500MB累计使用200G后不在订购日包，超出费用均按0.25元/MB收取。</li>
                                                </ul>
                                            </van-popup>

                                        </div>
                                        <div class="setMealOuter_con">
                                            <div class="DayPack_use dis">
                                                <p>
                                                    当月自动续订日包累计
                                                    {{
                                                        flowList[0].autoDayPackageNum
                                                    }}
                                                    个
                                                </p>
                                            </div>
                                            <div class="setMealOuter_con_progressBar">
                                                <div class="progressBar_title">
                                                    <p>当前:
                                                        {{
                                                            flowList[0]
                                                                .packageName
                                                        }}
                                                    </p>
                                                </div>
                                                <van-progress pivot-text="已使用" color="#F07541" :percentage="flowList[0].percentage
                                                        " stroke-width="20" :show-pivot="true" />
                                                <div class="progressBar_use dis">
                                                    <p>
                                                        已使用 {{ flowList[0].autoDayPackageNum > 0 ?
                                                            Math.round(Number(500 - (flowList[0].total.slice(0,
                                                                -2).replace(/[^\d\.]/g, '') - flowList[0].used.slice(0,
                                                                    -2).replace(/[^\d\.]/g, ''))) *
                                                                100) / 100 + "MB" : flowList[0].used }} </p>
                                                    <p>
                                                        剩余
                                                        {{
                                                            flowList[0].surplus
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="setMealOuter_footer dis" v-if="Outside != ''">
                                            <p>
                                                <span>本月套餐外使用：</span>
                                            </p>
                                            <p>
                                                当月使用 {{ OutsideData >= 1024 ? OutsideData / 1024 + 'GB' : OutsideData + 'MB'
                                                }}
                                            </p>
                                        </div>

                                        <div class="setMealOuter_footer dis" v-else>
                                            <p>
                                                <span>本月套餐外使用:</span>
                                            </p>

                                            <p>
                                                当月使用{{ setOuter.flowcount }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="语音">
                    <div class="Tabs_voice">
                        <div class="voice_con">
                            <div class="Tabs_voice_SetMealWithin" v-if="voice != ''">
                                <div class="tab_title">本月套餐内使用</div>
                                <div>
                                    <div class="Tabs_voice_order_con" v-for="(item, index) in voice" :key="index">
                                        <div class="Tabs_voice_order_con_tit">
                                            <p>{{ item.title }}</p>
                                        </div>
                                        <div class="voice_progress">
                                            <van-progress pivot-text="已使用" color="#F07541" :percentage="item.percentage"
                                                stroke-width="20" :show-pivot="true" />
                                        </div>
                                        <div class="dis">
                                            <p>
                                                已用：<span>{{
                                                    item.used
                                                }}</span>
                                            </p>
                                            <p>
                                                剩余：<span>{{
                                                    item.surplus
                                                }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="Tabs_voice_SetMealOuters">
                                <div class="tab_title">本月套餐外使用</div>
                                <div class="dis">
                                    <p>当月使用</p>
                                    <p>{{ setOuter.minutecount }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="短信">
                    <div class="Tabs_note">
                        <div class="Tabs_note_SetMealWithin" v-if="note != ''">
                            <div class="tab_title">本月套餐内使用</div>
                            <div class="Tabs_note_SetMealWithin_not">
                                <P>短信共 {{ note[0].surplus }}</P>
                                <p>已发送 {{ note[0].used }}</p>
                            </div>
                        </div>

                        <div class="Tabs_note_SetMealOuters">
                            <div class="tab_title">本月套餐外使用</div>
                            <div class="dis">
                                <p>短信 0.1 元/条</p>
                                <p>当月已发送 {{ setOuter.smscount }}</p>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
            <!-- 新改列表结束 -->

            <div class="explain">
                <div class="explain_title">说明</div>
                <p v-html="explain"></p>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../common/api/api"
import topHead from "../../components/topHead.vue"
import Lanmao from "../../components/lanmao/index"
export default {
    name: "setMeal",
    data() {
        return {
            packageName: "",
            explain: "",
            list: [],
            // 新改列表变量
            active: 0,
            voice: [], //语音
            flow: [], //流量
            flowList: [], //获取的日包数据
            flowSetList: [], //获取套内的数据
            Outside: [],//日包累计30G套外
            OutsideData: "",//日包累计30G套外数据
            note: [], //短信
            noteSent: "", //短信已发送
            noteSurplus: "", //短信剩余
            setOuter: [], //套餐外
            flag: "",
            type: "one",
            askShow: false,
            // setId: "",
            setDatalist: "",
            bill: "",//账单上网及数据通信费
        }
    },

    created() {
        this.queryBill()
        // this.setMeal()
    },
    mounted() { },
    methods: {
        queryBill() {
            Lanmao.loading.show("加载中...")
            var token = localStorage.getItem('token');
            API.getJson("QUERY_BILL_DETAILS", {
                token: token
            }).then(res => {
                let costData
                res.chart.map(item => {
                    if (item.name == "上网及数据通信费") {
                        costData = item.value.slice(0, -3)
                    }
                })
                if (costData == undefined) {
                    costData = 0
                }
                this.bill = costData
                this.setMeal()
            }).catch(err => {
                Lanmao.loading.hide()
            })
        },

        setMeal() {
            let token = localStorage.getItem("token")
            API.getJson("QUERY_SET_MEAL", {
                token: token,
            })
                .then(res => {
                    Lanmao.loading.hide()
                    this.packageName = res.packageName
                    this.explain = res.explain
                    this.list = res.list

                    this.list.map(item => {
                        if (item.title == "语音通话") {
                            if (item.voice.length != "") {
                                this.voice = item.voice
                            }
                        } else if (item.title == "上网流量") {
                            this.flow = item.list
                            // 等于 1元500M 就是日包
                            // 等于 0元1M不计费 、 1M计费（单价流量包）不展示
                            // 不等于 0元1M不计费 、 1M计费、1元500M 就是月包

                            this.flow.forEach((item, index) => {
                                // 如果 packageName 是 1元/500M自动续订日包 那就是有日包
                                if (item.packageName.indexOf("1元/500M自动续订日包") != -1) {
                                    this.flowList.push(item)
                                } else if (item.packageName == "累计200G套外") {
                                    this.Outside.push(item)
                                } else if (// 如果 packageName 不是这些名称，数据就是套内数据
                                    item.packageName !=
                                    "1元/500M自动续订日包" &&
                                    item.packageName != "0元1M不计费按天" &&
                                    item.packageName != "1MB" &&
                                    item.packageName != "累计200G套外"
                                ) {
                                    this.flowSetList.push(item)  //套内数据
                                }
                            })
                            if (this.Outside.length > 0) {
                                // boss数据为套外使用量不符合累计30G套外的量改为前端计算：
                                // 累计30G套外数据 = (上网及通讯费 - 1元/500M自动续订日包的数量) / 0.25
                                this.OutsideData = (this.bill - this.flowList[0].autoDayPackageNum) / 0.25
                            }
                            try {
                                if (this.flowList[0].packageName == "1元/500M自动续订日包" || this.flowList[0].title == "15元600M流量月包") {
                                    this.flag = "Daypack"
                                }
                            } catch {
                                this.flag = "yincang"
                            }
                        }

                        else if (item.title == "短彩信") {
                            if (item.sms != '') {
                                this.note = item.sms
                            }
                        }
                        else if (item.title == "套外") {
                            this.setOuter = item.outer[0]
                        }
                    })
                })
                .catch(err => {
                    Lanmao.loading.hide()
                })
        },

        // tab 切换
        ActiveHandle(index) {
            this.active = index
        },
    },
    components: {
        topHead,
    },
    computed: {},
}
</script>

<style lang="scss" scoped>
// 新改列表开始
.main {
    padding: 0 px2rem(20);

    .dis {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    p {
        font-size: px2rem(24);
    }

    .noData {
        width: 100%;
        height: px2rem(120);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tab_title {
        margin-top: px2rem(20);
        font-size: px2rem(28);
        font-weight: 600;
    }

    // 头部
    .balance_top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: px2rem(90);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #336189;
        border-radius: px2rem(18);
        color: #fff;
        font-size: px2rem(26);
        padding: 0 px2rem(20);
        box-sizing: border-box;
        margin-top: px2rem(20);
        font-weight: bold;
        box-shadow: 0 0 px2rem(20) rgba($color: #000000, $alpha: 0.2);
        margin-bottom: px2rem(36);

        span {
            color: #86beec;
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        box-sizing: border-box;

        .block {
            width: 82%;
            height: 60%;
            background-color: #fff;
            padding: 0 px2rem(20);

            .x {
                width: 100%;
                height: px2rem(60);
                display: flex;
                justify-content: flex-end;
                align-items: center;
                font-size: px2rem(32);
                padding-right: px2rem(20);
            }

            p {
                color: #000000;
                line-height: px2rem(60);
            }
        }
    }

    // 流量样式
    .flow {
        width: 100%;

        .alternative {
            width: 100%;

            .Within {
                .Withins {
                    .Withins_tit {
                        margin: px2rem(50) 0;

                        p {
                            font-size: px2rem(26);
                            font-weight: 600;
                        }
                    }

                    .Withins_com {
                        font-size: px2rem(24);

                        .van-progress {
                            margin: px2rem(16) 0;
                        }
                    }
                }
            }

            .Outer {
                width: 100%;
                height: px2rem(130);
                margin-top: px2rem(50);
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }

        .kxsetMeal {
            width: 100%;

            .dis {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .firstSet {
                width: 100%;

                .setMealOuter {
                    width: 100%;

                    .setMealOuter_DayPack {
                        .setMealOuter_title {
                            height: px2rem(60);
                            font-size: px2rem(28);
                            font-weight: 600;
                            margin: px2rem(30) 0;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;


                            .flow_title {
                                font-size: px2rem(28);
                                font-weight: 600;
                            }

                            .ask {
                                width: px2rem(30);
                                height: px2rem(30);
                                margin-left: px2rem(30);
                                border: px2rem(1) solid blue;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: px2rem(24);


                            }

                            .van-popup {
                                width: 86%;
                                height: 28%;
                                line-height: px2rem(60);
                                font-weight: 500;
                                padding: px2rem(20);
                            }
                        }

                        .setMealOuter_con {
                            font-size: px2rem(24);

                            .DayPack_use {
                                margin: px2rem(40) 0;
                                padding: 0, px2rem(23);
                            }

                            .setMealOuter_con_progressBar {
                                .progressBar_title {
                                    color: #f07541;
                                }
                            }

                            .van-progress {
                                margin: px2rem(10) 0;
                            }
                        }

                        .setMealOuter_footer {
                            height: px2rem(50);
                            font-size: px2rem(24);
                            margin: px2rem(80) 0;
                            border-bottom: px2rem(1) solid #ccc;

                            span {
                                font-weight: 600;
                                font-size: px2rem(26);
                            }
                        }
                    }
                }
            }
        }
    }

    // 语音样式
    .Tabs_voice {
        width: 100%;

        .voice_con {
            width: 100%;

            // 套内样式
            .Tabs_voice_SetMealWithin {
                width: 100%;
                margin-top: px2rem(20);

                .Tabs_voice_order_con {
                    width: 100%;
                    margin-top: px2rem(40);

                    .Tabs_voice_order_con_tit {
                        color: #f07541;
                    }

                    .voice_progress {
                        margin: px2rem(10) 0;
                    }
                }
            }

            // 套外样式
            .Tabs_voice_SetMealOuters {
                width: 100%;
                height: px2rem(130);
                margin-top: px2rem(50);
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }

    // 短信样式 
    .Tabs_note {
        width: 100%;

        // 套内样式
        .Tabs_note_SetMealWithin {
            width: 100%;
            margin-top: px2rem(20);

            .Tabs_note_SetMealWithin_not {
                margin-top: px2rem(40);
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        // 套外样式
        .Tabs_note_SetMealOuters {
            width: 100%;
            height: px2rem(130);
            margin-top: px2rem(50);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
    }

    // 底部
    .explain {
        width: 100%;
        border-radius: px2rem(18);
        background-color: #e5eaed;
        padding: px2rem(28);
        box-sizing: border-box;
        margin: px2rem(30) 0;

        .explain_title {
            font-weight: bold;
            margin-bottom: px2rem(15);
            font-size: px2rem(26);
            color: #336289;
        }

        p {
            font-size: px2rem(22);
            color: #333;
            line-height: px2rem(44);
        }
    }
}

// 新改列表结束
</style>

<style>
.van-tabs__content {
    height: 70% !important;
}

.van-progress__pivot {
    font-size: 0.26rem;
}

.custom-image .van-empty__image {
    width: 120px;
    height: 120px;
}
</style>
