<template>
    <div class="personal">
        <!-- 导航栏 -->
        <topHead>
            <span slot="head"></span>

            <img slot="head" @click="backBefor()" src="../../assets/images/fhb.png" alt="" />

            <h3 slot="header">个人信息</h3>
        </topHead>
        <!-- 背景 -->
        <div class="back"></div>
        <!-- 骨架屏幕 -->
        <div class="skeleton-accountbind-box skeleton--animate" v-if="member.length == 0">
            <div class="skeleton-top-box">
                <div class="skeleton-face">
                    <div></div>
                </div>
                <div class="skeleton-info">
                    <div class="skeleton-name">
                        <div></div>
                    </div>
                    <div class="skeleton-phone"></div>
                </div>
                <div class="skeleton-change"></div>
            </div>
            <ul class="skeleton-content-box">
                <li>
                    <div class="skeleton-row"></div>
                    <div class="skeleton-row-long" style="margin-right: 0"></div>
                </li>
                <li>
                    <div class="skeleton-row"></div>
                    <div class="skeleton-row" style="margin-right: 0"></div>
                </li>
                <li>
                    <div class="skeleton-row"></div>
                    <div class="skeleton-row-right-3" style="margin-right: 0"></div>
                </li>
                <li>
                    <div class="skeleton-row"></div>
                    <div class="skeleton-row" style="margin-right: 0"></div>
                </li>
                <li>
                    <div class="skeleton-row-half"></div>
                    <div class="skeleton-row-half" style="margin-right: 0"></div>
                </li>
                <li>
                    <div class="skeleton-row"></div>
                    <div class="skeleton-row-half" style="margin-right: 0"></div>
                </li>
                <li>
                    <div class="skeleton-row"></div>
                    <div class="skeleton-row" style="margin-right: 0"></div>
                </li>
            </ul>
        </div>
        <!-- 以登录 -->
        <div class="main" v-else-if="member.mobilePhoneInfo && member.mobilePhoneInfo.mobilePhone">
            <div class="main_top">
                <div class="face">
                    <img src="../../assets/images/face1.png" alt="" />
                </div>
                <div class="info">
                    <div class="name">
                        <div class="title fl">{{ member.memberInfo.memberName }}</div>
                        <div class="Star fl">
                            <img src="../../assets/images/xjyh.png" alt="" />
                            <p>{{ member.mobilePhoneInfo.level }}星用户</p>
                        </div>
                    </div>
                    <div class="phone">
                        {{ member.mobilePhoneInfo.mobilePhone }}
                    </div>
                </div>
                <div class="change" @click="goPassword">更改服务密码</div>
            </div>
            <ul class="main_bottom">
                <li>
                    <span></span>
                    <p>号码状态</p>
                    <em>{{ member.mobilePhoneInfo.mobileStatus }}</em>
                </li>
                <li>
                    <span></span>
                    <p>入网时间</p>
                    <em>{{ member.mobilePhoneInfo.time }}</em>
                </li>
                <li @click="goMealDetails(member.mobilePhoneInfo.packageId)">
                    <span></span>
                    <p>当前套餐</p>
                    <em>{{ member.mobilePhoneInfo.packageName }}</em>
                    <img src="../../assets/images/yjt.png" alt="" />
                </li>
                <li>
                    <span></span>
                    <p>网龄</p>
                    <em>{{ member.mobilePhoneInfo.internetAge }}月</em>
                </li>
                <li>
                    <span></span>
                    <p>当前积分</p>
                    <em>{{ member.mobilePhoneInfo.pointInfo.currentPoints }}分</em>
                </li>
                <li>
                    <span></span>
                    <p>绑定状态</p>
                    <em>{{ member.memberInfo.bindStatus }}</em>
                </li>
                <li>
                    <span></span>
                    <p>号码归属地</p>
                    <em>{{ member.mobilePhoneInfo.attribution }}</em>
                </li>
            </ul>
            <van-button round type="info" to="relieve">解除绑定</van-button>
        </div>
        <!-- 未登录 -->
        <div class="main" v-else-if="!member.mobilePhoneInfo || !member.mobilePhoneInfo.mobilePhone">
            <div class="main_top">
                <div class="face">
                    <img src="../../assets/images/face.png" alt="" />
                </div>
                <div class="info">
                    <div class="name">
                        <div class="login" @click="loginHeadler">
                            登录
                        </div>
                    </div>
                    <div class="phone">
                        登录后可查看更多信息
                    </div>
                </div>
                <div class="change" @click="goPassword">更改服务密码</div>
            </div>
            <ul class="main_bottom">
                <li>
                    <span></span>
                    <p>号码状态</p>
                </li>
                <li>
                    <span></span>
                    <p>入网时间</p>
                </li>
                <li>
                    <span></span>
                    <p>当前套餐</p>
                </li>
                <li>
                    <span></span>
                    <p>网龄</p>
                </li>
                <li>
                    <span></span>
                    <p>当前积分</p>
                </li>
                <li>
                    <span></span>
                    <p>绑定状态</p>
                </li>
                <li>
                    <span></span>
                    <p>号码归属地</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant"
import { mapState } from "vuex"
import topHead from "../../components/topHead.vue"
export default {
    name: "personal",
    data() {
        return {
            skeleton: 0
        }
    },
    components: {
        topHead,
    },
    computed: {
        ...mapState(["member"]),
    },
    mounted() { },
    created() {
        this.$store.dispatch("getMember")
    },
    methods: {
        backBefor() {
            this.$router.push("/PersonalInformation")
        },
        goMealDetails(id) {
            if (this.$store.state.member != "") {
                this.$router.push({ name: "mealDetails", query: { id: id } })
            } else {
                Toast({
                    message: "请登录",
                    position: "bottom",
                })
            }
        },
        goPassword() {
            this.$router.push({ name: "setPassword" })
        },
        loginHeadler() {
            this.$router.replace({ name: "AccountBind" })
        },
    },
}
</script>

<style lang="scss" scoped>
//骨架
.skeleton-accountbind-box {
    position: absolute;
    left: 0;
    top: px2rem(50);
    padding: 0 px2rem(30);
    z-index: 15;
    width: 100%;
    box-sizing: border-box;
}

.skeleton-top-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: px2rem(70);
    padding: px2rem(20) 0 px2rem(68);
    box-sizing: border-box;
    border-radius: px2rem(20) px2rem(20) 0 0;
}

.skeleton-face div {
    width: px2rem(100);
    height: px2rem(100);
    border-radius: 50%;
    background-color: #f6f6f6;
    opacity: 0.5;
}

.skeleton-info {
    margin-right: auto;
    margin-top: px2rem(20);
    margin-left: px2rem(20);
}

.skeleton-info .skeleton-name {
    width: px2rem(100);
    height: px2rem(24);
    background-color: #f6f6f6;
    margin-bottom: px2rem(15);
    margin-right: px2rem(30);
    opacity: 0.5;
}

.skeleton-info .skeleton-phone {
    width: px2rem(100);
    height: px2rem(24);
    background-color: #eee;
    opacity: 0.5;
}

.skeleton-change {
    width: px2rem(190);
    height: px2rem(60);
    background-color: #eee;
    border-radius: px2rem(28);
    margin-top: px2rem(20);
    opacity: 0.5;
}

.skeleton-content-box {
    width: 100%;
    background-color: #fff;
    padding: px2rem(20) px2rem(40);
    background-color: #f6f6f6;
    box-sizing: border-box;
    border-radius: 0 0 px2rem(20) px2rem(20);
}

.skeleton-content-box li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: px2rem(21);
    padding-bottom: px2rem(21);
    line-height: px2rem(66);
}

.skeleton-content-box li p {
    margin-right: auto;
    font-size: px2rem(28);
    color: #333;
}

.skeleton-row {
    width: px2rem(120);
    height: px2rem(24);

    background-color: #eeeeee;
}

.skeleton-row-half {
    width: px2rem(60);
    height: px2rem(24);

    background-color: #eeeeee;
}

.skeleton-row-long {
    width: px2rem(144);
    height: px2rem(24);
    background-color: #eeeeee;
}

.skeleton-row-right-3 {
    width: px2rem(132);
    height: px2rem(24);

    background-color: #eeeeee;
}

.skeleton--animate {
    -webkit-animation: skeleton-blink 1.2s ease-in-out infinite;
    animation: skeleton-blink 1.2s ease-in-out infinite;
}

@-webkit-keyframes skeleton-blink {
    50% {
        opacity: 0.6;
    }
}

@keyframes skeleton-blink {
    50% {
        opacity: 0.6;
    }
}

.main {
    position: absolute;
    left: 0;
    top: px2rem(88);
    padding: 0 px2rem(30);
    z-index: 5;
    width: 100%;
    box-sizing: border-box;
}

.topHead h3 {
    color: #fff;
}

.topHead {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
}

.topHead img {
    width: px2rem(32);
    height: px2rem(27);
}

.back {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: px2rem(400);
    background: url(../../assets/images/grzxbj.png) no-repeat center center;
    background-size: cover;
    z-index: 4;
}

.main_top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: px2rem(52);
    color: #fff;
}

.main_top .face,
.main_top .face img {
    width: px2rem(100);
    height: px2rem(100);
    border-radius: 50%;
}

.main_top .change {
    width: px2rem(190);
    height: px2rem(60);
    line-height: px2rem(60);
    background-color: #234f73;
    color: #6f8fa8;
    border-radius: px2rem(28);
    font-size: px2rem(24);
    text-align: center;
    margin-top: px2rem(20);
}

.info {
    margin-right: auto;
    margin-top: px2rem(15);
    margin-left: px2rem(20);
}

.info .name {
    display: flex;
}

.info .name .title {
    width: auto;
    max-width: px2rem(200);
    font-size: px2rem(34);
    font-weight: bold;
    margin-bottom: px2rem(10);
    margin-right: px2rem(15);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info .name .Star {
    display: flex;
    position: relative;
    width: px2rem(124);
    height: px2rem(35);
}

.info .name .Star img {
    position: relative;
    width: 100%;
    height: 100%;
}

.info .name .Star p {
    position: absolute;
    right: px2rem(15);
    top: 0;
    font-size: px2rem(20);
    height: px2rem(35);
    line-height: px2rem(35);
}

.info .phone {
    font-size: px2rem(28);
}

.main_bottom {
    width: 100%;
    height: px2rem(502);
    background-color: #fff;
    border-radius: px2rem(20);
    margin-top: px2rem(70);
    box-shadow: 0 0 px2rem(20) rgba($color: #000000, $alpha: 0.2);
    padding: 0 px2rem(40);
    box-sizing: border-box;
    padding-top: px2rem(20);
}

.main_bottom li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: px2rem(66);
}

.main_bottom li span {
    width: px2rem(14);
    height: px2rem(14);
    border-radius: 50%;
    background-color: #336289;
    margin-right: px2rem(17);
}

.main_bottom li p {
    margin-right: auto;
    font-size: px2rem(26);
    color: #333;
}

.main_bottom li em {
    font-size: px2rem(26);
    color: #999999;
}

.main_bottom li:nth-child(1) em {
    color: #ff7505;
}

.main_bottom li:nth-child(3) em {
    color: #4182ef;
}

.van-button {
    display: flex;
    justify-content: center;
    text-align: center;
    width: px2rem(550);
    height: px2rem(80);
    line-height: px2rem(80);
    color: #999999;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #d6d6d6;
    margin: px2rem(78) auto 0;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
    border: none;
}

.login {
    width: px2rem(124);
    height: px2rem(42);
    line-height: px2rem(42);
    text-align: center;
    font-size: px2rem(26);
    background-color: #85b9de;
    border-radius: px2rem(20);
    margin-bottom: px2rem(8);
}</style>