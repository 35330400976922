export default {
    "LOGIN": "/api/wechat/login", //登录接口
    "RECHARGE_PRODUCT": "/api/wechat/product", //获取充值产品列表
    // "CHECK_PHONE": "/api/wechat/checkPhone",//已废弃--检测是否是蓝猫移动手机号
    "ADD_ORDERS": "/api/wechat/addOrders", //话费充值下单接口
    "PAY": "/api/wechat/pay", //发起支付
    "OPEN_FLOW": "/api/wechat/openFlow", //开通流量
    "SEND_SMS_CODE": "/api/wechat/sendSmsCode", //发送验证码
    "BIND_PHONE": "/api/wechat/bindPhone", //绑定手机号
    "PERSONAL_DATA": "/api/wechat/memberInfo", //个人资料
    "RELIEVE_BINDING": "/api/wechat/unbind", //解除绑定
    "GET_MEAL": "/api/wechat/package", //获取套餐详情
    // "servicePassword_YANZ": "/api/wechat/servicePasswordEditTest", //服务密码修改验证
    "setService_Password": "/api/wechat/servicePasswordEdit", //服务密码修改
    //营业厅 查询类
    "QUERY_PHONE": "/api/wechat/queryPhone", //查询名下卡号    
    "RECHARGE_RECORD": "/api/wechat/rechargeRecord", //充值记录
    "ORDER_BUSINESS": "/api/wechat/querySubscribeBusiness", //查询已订购业务
    "QUERY_HISTORY_BILL": "/api/wechat/historyBill", //查询历史账单
    "QUERY_BALANCE": "/api/wechat/queryBalance", //查询余额
    "QUERY_BILL_DETAILS": "/api/wechat/queryBill", //查询账单详情
    "QUERY_SET_MEAL": "/api/wechat/queryPackageBalance", //查询套餐余量
    "QUERY_CARD_NUMBER": "/api/wechat/queryCardNumber", //查名下号码
    //营业厅 办理类PACKAGE_ORDER
    "RESULT_QUERY": "/api/wechat/checkResultQuery", //校验结果查询
    "PACKAGE_LIST": "/api/wechat/packageList", //漏电提醒 来电显示 套餐列表
    "PACKAGE_ORDER": "/api/wechat/packageOrder", //漏电提醒 来电显示 套餐内容
    "CLOSE_ACCONT": "/api/wechat/accountOff", //销户申请
    "ACCOUNT_BANK": "/api/wechat/accountBank", //获取销户表单 银行列表
    "ACCOUNT_FORM": "/api/wechat/accountForm", //提交销户表单
    "REAL_NAME": "/api/wechat/realName", //实名认证
    "INVOICE_LIST": "/api/wechat/invoiceList", //开发票 获取发票列表
    "INVOICE_DETAIL": "/api/wechat/invoiceDetail", //发票详情
    "SUBMIT_INVOICE": "/api/wechat/submitInvoice", //提交发票信息
    "NEW_CHECKCODE": "/api/wechat/checkcode", //新 发票校验
    "NEW_INVOICE_LIST": "/api/wechat/invoiceList", //新 获取发票列表
    "NEW_SEND_INVOICE": "/api/wechat/sendInvoice", //新 申请发票
    "NEW_QUERY_USER": "/api/wechat/queryuser", //新 获取用户发票地址列表
    "NEW_ADD_INVOICEUSER": "/api/wechat/addinvoiceuser", //新 用户发票地址新增和修改
    "NEW_DEL_INVOICEUSER": "/api/wechat/delinvoiceuser", //新 用户发票地址删除
    "NEW_QUERY_TITLE": "/api/wechat/queryTitle", //新 搜索公司信息
    "NEW_MONTHS_INFO": "/api/wechat/monthsInfo", //新 校验发票是否为多开
    "NEW_RELOAD_EMAIL": "/api/wechat/reloadEmail", //新 重发邮箱
    "FACE_AUTH_ACCOUNT_BIND": "/api/wechat/faceAuthAccountBind", //人脸验证-账号绑定
    "CHANGE_CARD": "/api/wechat/changeCard", //补卡申请 
    "CHANGE_CARD_FORM": "/api/wechat/changeCardForm", //提交补卡申请表单

    "PACKAGE_CHANGE": "/api/wechat/change", //套餐变更
    "PACKAGE_DETAILS": "/api/wechat/getUserprod", //套餐详情
    "verificationCode": "/api/wechat/verificationCode", //code认证

    "GET_SMSBYPHONE": "/api/wechat/getSmsByPhone",//是否订购查询接口
    "SMSREGISTER": "/api/wechat/smsRegister",//短信意愿订购或退订
    "QUERY_SMSREGISTER": "/api/wechat/querySmsRegister",//查询行业类别及用户登记信息
    "CHANGESMS": "/api/wechat/changeSms",//手机号短信登记
    "INSERTUSERAPPEAL": "/api/wechat/insertUserAppeal",//客户服务

    "TRANS_ACCT_S": "/api/wechat/transAcctS", //过户发起
    "TRANS_ACCT_S_FORM": "/api/wechat/transAcctSForm", //过户发起申请表单
    "TRANS_ACCT_T": "/api/wechat/transAcctT", //过户接收 
    "TRANS_ACCT_T_FORM": "/api/wechat/transAcctTForm", //过户接收申请表单

    //校验活体认证
    // "CHECKVIDEO": "/api/wechat/checkVideo", //视频是否上传
    "CPDEIMG": "/api/wechat/getBase64CodeIMG", //获取小程序验证码
    "CHECKVIDEO": "/api/wechat/checkVideo", //校验是否已上传视频实名认证

    //其他
    "COMPLAINT": "/api/wechat/Report", //举报投诉
    "COOPERATION": "/api/wechat/business", //商务合作
    "STORE": "/api/wechat/shop", //附近门店

    //积分兑换
    "BANNER": "/api/wechat/banner", //积分兑换--轮播图
    "POINTS_EXCHANGE_LIST": '/api/wechat/pointsExchangeList', //积分兑换--兑换列表
    "POINTS_EXCHANGE": "/api/wechat/pointsExchange", //兑换接口
    "SIGN": "/api/wechat/sign", //签到接口
    "SIGN_STATUS": "/api/wechat/signStatus", //获取签到状态
    "POINTS_ORDERS": "/api/wechat/pointsOrders", //获取积分兑换订单
    "POINTS_DETAIL": "/api/wechat/pointsDetail", //积分明细
    "POINTS_RULE": "/api/wechat/pointsRule", //积分规则

    //活动商城
    "GET_ACTIVITY_LIST": "/api/wechat/activityList", //活动列表
    "GET_ACTIVITY_DETAIL": '/api/wechat/activityDetail', //活动详情


    "ORDER_QUERY": '/api/wechat/queryOrder', //订单查询
    "ORDER_DETAIL": '/api/wechat/orderDetail', //订单详情

    // 吐槽
    "get_banner": '/api/wechat/getBanner', //轮播图
    "sub_content": '/api/wechat/subContent', //提交评论
    "comment_list": '/api/wechat/commentList', //获取列表
    "myLike": '/api/wechat/myLike', //点赞
    "sub_comment": '/api/wechat/subComment', //回复
    "reply": '/api/wechat/more', //回复 
    "replyDetails": '/api/wechat/replyDetails', //文章详情


    // 修改更改服务密码
    "servicePasswordEditTest": '/api/wechat/servicePasswordEditTest', //忘记服务密码

    "wx_url": "/api/wechat/checknickName", //获取微信属性


    // 开卡接口
    "GetSeesion": "/api/gzh/getSeesion", //获取token
    "GoLogin": "/api/gzh/login", //登录
    "ClipData": "/api/gzh/checkworknoinfo", //工号登录信息查询
    "SimOrServiceIdQuery": "/api/gzh/simOrServiceIdQuery", //号卡查询接口
    "QueryOpenCardLocation": "/api/gzh/queryOpenCardLocation", //查询开卡记录
    "AddOpenCardLocation": "/api/gzh/addOpenCardLocation", //新增开卡记录
    "CheckPhoneValid": "/api/gzh/checkPhoneValid", //号卡有效性验证
    "ChanelCheck": "/api/gzh/chanelCheck", //渠道校验
    "AppCommonCheck": "/api/gzh/appCommonCheck", //激活期和冻结
    "CheckofferName": "/api/gzh/checkoffername", //未激活号码套餐展示
    "GetUrl": "/api/gzh/getUrl", //获取实名认证的链接
    "GetSimpleOrCardNum": "/api/gzh/getSimpleOrCardNum", //获取验证信息
    "DoCheckIdentityCodeByWorkNo": "/api/gzh/doCheckIdentityCodeByWorkNo", //一证五户校验
    "SignatureUpload": "/api/gzh/signatureUpload", //上传签名
    "QuerySign": "/api/gzh/querySign", //提交加密
    "Dosubmit": "/api/gzh/dosubmit", //开成卡提交
    "AdelOpenCardLocation": "/api/gzh/delOpenCardLocation", //删除开卡记录
    "GetKey": "/api/gzh/getKey", //获取key
    "AliPay": "/api/gzh/aliPay", //支付
    "QueryAliPay": "/api/gzh/queryAliPay", //支付订单查询
}