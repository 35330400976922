<template>
    <div class="RejectNumber">
        <!-- 导航栏 -->
        <topHead>
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">拒收号码防护</h3>
        </topHead>

        <div class="main">
            <div class="hint">
                <span>提示：添加后，名单中的号码发送的短信将会被拦截</span>
            </div>

            <div class="industry_table" v-if="enRoute != ''">
                <h3>拒收进度</h3>
                <table>
                    <tr>
                        <th>操作类型</th>
                        <th>拒收类别</th>
                        <th>进度</th>
                        <th>详情</th>
                    </tr>
                    <tr v-for="(item, index) in enRoute" :key="index">
                        <td v-if="item.pickType == 'add'">登记</td>
                        <td v-if="item.pickType == 'delete'">取消登记</td>
                        <td>手机号</td>
                        <td style="color: red;">处理中</td>
                        <td style="color: blue;" @click="detail(item, index)">详情</td>
                    </tr>
                </table>

                <van-overlay class="overlay_table" :show="tableShow" @click="tableShow = false">
                    <div class="phone_det">
                        <p>拒收手机号详情 <span v-if="mark == 'add'">(登记)</span><span v-if="mark == 'delete'">(取消登记)</span></p>
                        <ul>
                            <li v-for="item, index in detailData" :key="index">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </van-overlay>
            </div>

            <div class="con">
                <div class="phone_add">
                    <p class="add_title">号码添加</p>
                    <div class="add_con">
                        <input type="text" placeholder="请输入号码" v-model="inputValue"
                            onkeyup="value=value.replace(/[^\d]/g,'')">
                        <button @click="verify">增加号码</button>
                    </div>



                    <div class="inputbox_tex_con" v-if="addShow">
                        <textarea @click.stop name="" id="" cols="30" rows="1" style="resize: none;"
                            v-model="addTextValue" readonly placeholder="新增号码"
                            onkeyup="this.value = this.value.replace(/[^\r\n0-9]/g,'');"></textarea>
                    </div>

                    <div class="hint_list">
                        <ul>
                            <li v-if="serviceKind == '7'">手机号码:11位手机号码，如165****1234；</li>
                            <li>端口号码:全位长端口号码，如1069********；</li>
                            <li>点击增加号码/删除按钮，可进行多个增加/删除操作；</li>
                        </ul>
                    </div>

                    <div class="inputbox_tex_con" v-if="delShow">
                        <textarea @click.stop name="" id="" cols="30" rows="1" style="resize: none;"
                            v-model="delTextValue" readonly placeholder="删除号码"
                            onkeyup="this.value = this.value.replace(/[^\r\n0-9]/g,'');"></textarea>
                    </div>


                </div>

                <div class="phone_list" v-if="success != ''">
                    <table>
                        <tr>
                            <th>id</th>
                            <th>拒收手机号</th>
                            <th>进度</th>
                            <th>操作</th>
                        </tr>
                        <tr v-for="(item, index) in currentItems" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item }}</td>
                            <td style="color: green; ">已拒收</td>
                            <td>
                                <p class="del" @click="del(item)">删除</p>
                            </td>
                        </tr>
                    </table>
                    <van-pagination v-model="currentPage" :total-items="success.length" :items-per-page="itemsPerPage"
                        force-ellipses @change="onPageChange" />
                </div>

                <div class="btn">
                    <van-button round type="info" @click="submitHint()">提交</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import topHead from "../../../components/topHead.vue"
import API from "../../../common/api/api"
import { Toast, Dialog } from 'vant'
export default {
    components: { topHead },
    data() {
        return {
            phone: "",
            serviceKind: "",
            addShow: false,
            delShow: false,
            inputValue: "",
            addTextValue: "",   // 增加展示数据
            delTextValue: "",   // 删除展示数据
            addPhone: [],   //新增拒收手机号
            delPhone: [],   //删除拒收手机号
            success: [],    // 拒收成功手机号渲染数据
            scheduleType: "", // 进度模态框类型
            enRoute: [],    // 进行中数据区分
            tableShow: false,   // 详情模态框展示隐藏
            mark: "",
            detailData: [], //拒收进度详情
            currentPage: 1, // 当前页码
            itemsPerPage: 5, // 每页显示条数
        }
    },

    created() {
        this.query()
    },
    // 组件方法
    methods: {
        // 分页回调
        onPageChange(page) {
            this.currentPage = page;
        },

        // 查询行业类别及用户登记信息
        query() {
            // 刚进页面调用接口获取已拒收的行业进行渲染
            this.phone = localStorage.getItem("phone")
            API.getJson("QUERY_SMSREGISTER", { serviceid: this.phone }).then(res => {
                if (res.data) {
                    if (res.data.code == -100) {
                        this.$router.push("/WillRegister")
                        Toast({
                            message: res.data.msg,
                            position: "bottom",
                            duration: 1000,
                        })
                    }
                } else {
                    this.serviceKind = res.serviceKind  //获取移动联通区分
                    res.NumProcessing.forEach(item => {
                        item.value = "sj"
                        this.enRoute.push(item)
                    })
                    res.success.registerNums.split(",").forEach(item => {
                        this.success.push(item)
                    })
                }
            })
        },

        // 新增拒收手机号
        verify() {
            if (this.inputValue != "") {
                if (this.serviceKind == "7") {
                    // 移动
                    this.duplicateCheck()
                } else {
                    const regex = /^1[3-9]\d{9}$/;
                    if (this.inputValue.length != "11") {
                        this.duplicateCheck()
                    } else {
                        if (!(regex.test(this.inputValue))) {
                            let NotOne = this.inputValue.split(0, 1)
                            if (NotOne != "1") {
                                this.duplicateCheck()
                            } else {
                                Toast({
                                    message: "您的号码不可拒收11位的手机号码",
                                    position: "bottom",
                                })
                            }
                        } else {
                            Toast({
                                message: "您的号码不可拒收11位的手机号码",
                                position: "bottom",
                            })
                        }
                    }
                }
            } else {
                Toast({
                    message: "手机号不能为空",
                    position: "bottom",
                    duration: 800,
                })
            }

        },

        // 增加拒收号码重复号码校验
        duplicateCheck() {
            this.addShow = true
            if (this.addPhone.length > 0) {
                if (this.addPhone.find(item => item == this.inputValue)) {
                    Toast({
                        message: "已增加此号码，请重新添加号码！",
                        position: "bottom",
                        duration: 1000,
                    })
                } else {
                    // 判断第二次添加时成功拒收数组中是否已存在
                    if (this.success.find(item => item == this.inputValue)) {
                        Toast({
                            message: "添加的号码已拒收！！！",
                            position: "bottom",
                            duration: 1000,
                        })
                    } else {
                        // 单次最多添加10个号码
                        if (this.addPhone.length >= 10) {
                            Toast({
                                message: "单次最多拒收10个号码！！",
                                position: "bottom",
                                duration: 1000,
                            })
                        } else {
                            this.addPhone.push(this.inputValue)
                        }
                    }
                }
            } else {
                // 判断第一次添加时成功拒收数组中是否已存在
                if (this.success.find(item => item == this.inputValue)) {
                    this.addShow = false
                    Toast({
                        message: "添加的号码已拒收！！！",
                        position: "bottom",
                        duration: 1000,
                    })
                } else {
                    this.addPhone.push(this.inputValue)
                }
            }
            this.inputValue = ""
        },

        // 拒收手机号删除
        del(val) {
            this.delShow = true
            if (this.delPhone.length > 0) {
                if (this.delPhone.find(item => item == val)) {
                    Toast({
                        message: "已增加此号码，请重新添加号码！",
                        position: "bottom",
                        duration: 1000,
                    })
                } else {
                    if (this.delPhone.length >= 10) {
                        Toast({
                            message: "单次最多删除10个号码！！",
                            position: "bottom",
                            duration: 1000,
                        })
                    } else {
                        this.delPhone.push(val)
                    }
                }
            } else {
                this.delPhone.push(val)
            }

        },

        // 提交提示
        submitHint() {
            if (this.addTextValue != "" && this.delTextValue == "") {
                Dialog.confirm({
                    title: "提示",
                    message: "您输入的号码短信将会被拦截！！",
                    confirmButtonText: "确认",
                })
                    .then(() => {
                        this.submit()
                    }).catch(() => {
                        // on cancel
                    })
            } else if (this.delTextValue != "" && this.addTextValue == "") {
                Dialog.confirm({
                    title: "提示",
                    message: "您删除的号码短信将不会被拦截！！",
                    confirmButtonText: "确认",
                })
                    .then(() => {
                        this.submit()
                    }).catch(() => {
                        // on cancel
                    })
            } else if (this.delTextValue == "" && this.addTextValue == "") {
                Toast({
                    message: "号码不能为空！！",
                    position: "bottom",
                    duration: 1000,
                })
            } else {
                Dialog.confirm({
                    title: "提示",
                    message: "您输入的号码短信将会被拦截；删除的号码将不会被拦截！！",
                    confirmButtonText: "确认",
                })
                    .then(() => {
                        this.submit()
                    }).catch(() => {
                        // on cancel
                    })
            }
        },
        // 提交
        submit() {
            API.getJson('CHANGESMS', {
                addNum: this.addTextValue,
                delNum: this.delTextValue,
                serviceid: this.phone,
            }).then(res => {
                if (res.code == "1") {
                    localStorage.removeItem("phone")
                    this.$router.push("/BusinessHandle")
                    Toast({
                        message: "提交成功，请稍后进行查询！",
                        position: "bottom",
                        duration: 1000,
                    })
                }
            })
        },

        // 详情
        detail(item) {
            this.scheduleType = item.value
            this.mark = item.pickType
            this.tableShow = true
            this.detailData = item.registerNums.split(",")
        },


        // 返回
        backBefor() {
            this.$router.push("/SelectFunction")
        },
    },

    computed: {
        // 当前页面显示的数据
        currentItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.success.slice(start, end);
        },
    },

    watch: {
        addPhone: {
            deep: true,
            handler(newVal, oldVal) {
                this.addTextValue = newVal.join()
            }
        },

        delPhone: {
            deep: true,
            handler(newVal, oldVal) {
                this.delTextValue = newVal.join()
            }
        }
    },

    mounted() { },
}
</script>

<style scoped lang='scss'>
.RejectNumber {
    width: 100%;
    height: 100%;
    background: #f8f8f8;

    .topHead {
        position: sticky;
        /* 新属性sticky */
        top: 0;
        background: #fff;
    }

    .main {
        width: 100%;
        height: 94%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: px2rem(20);

        .hint {
            width: 100%;
            height: 5%;
            font-size: px2rem(26);
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fddce0;
            color: #a7515c;
        }

        .industry_table {
            width: 89%;
            height: 18% !important;
            display: flex;
            flex-direction: column;
            height: px2rem(320);
            font-size: px2rem(28);
            padding: 0 px2rem(20);
            background: #fff;
            border-radius: px2rem(20);
            margin-top: px2rem(20);


            h3 {
                margin: px2rem(20) 0;
            }



            table {
                width: 100%;
                border: px2rem(1) solid;
                border: 0;
                border-collapse: collapse;
                border-style: solid;



                tr:hover {
                    background-color: #F0FFFF;
                }

                th,
                td {
                    width: 25%;
                    height: px2rem(50);
                    text-align: center;
                    line-height: normal;
                    border: px2rem(1) solid;
                    border-style: solid;


                    p {
                        width: px2rem(200);
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .overlay_table {
                display: flex;
                justify-content: center;
                align-items: center;


                .phone_det {
                    width: 80%;
                    background: #ffffff;
                    padding: px2rem(20);
                    border-radius: px2rem(20);

                    p {
                        width: 100%;
                        height: px2rem(40);
                        display: flex;
                        align-items: center;
                        font-weight: 600;

                        span {
                            color: red;
                        }
                    }

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;

                        li {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: px2rem(20) px2rem(20);
                            margin-top: px2rem(20);
                            margin-left: px2rem(40);
                            text-align: center;
                            border: px2rem(1) solid #aaa;
                            border-radius: px2rem(20);
                        }
                    }
                }
            }
        }

        .con {
            width: 89%;
            // height: 80%;
            padding: px2rem(20);
            background: #fff;
            border-radius: px2rem(20);
            margin-top: px2rem(20);

            .phone_add {
                width: 100%;

                .add_title {
                    font-size: px2rem(34);
                }

                .add_con {
                    width: 100%;
                    height: px2rem(60);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: px2rem(26);
                    margin: px2rem(20) 0;

                    ::placeholder {
                        color: #aaa;
                    }


                    input {
                        width: 80%;
                        height: 100%;
                        border: px2rem(1) solid #dcdfe6;
                        border-top-left-radius: px2rem(10);
                        border-bottom-left-radius: px2rem(10);
                        padding-left: px2rem(20);
                    }

                    button {
                        width: 20%;
                        height: 100%;
                        border: px2rem(1) solid #dcdfe6;
                        border-top-right-radius: px2rem(10);
                        border-bottom-right-radius: px2rem(10);
                        background: #336289;
                        color: #fff;
                    }
                }

                .inputbox_tex_con {
                    font-size: px2rem(28);
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin: px2rem(20) 0;

                    textarea {
                        width: 100%;
                        height: px2rem(110);
                        border: 1px solid;
                        padding: px2rem(14);
                        border-radius: px2rem(20);
                    }
                }

                .hint_list {
                    font-size: px2rem(24);
                    margin: px2rem(40) 0;

                    ul {
                        padding: 0 px2rem(30);
                        list-style-type: disc;

                        ::marker {
                            color: #336289;
                        }

                        li {
                            margin: px2rem(12) 0;
                            color: red;
                        }
                    }
                }
            }

            .phone_list {
                width: 100%;
                margin-top: px2rem(10);
                font-size: px2rem(26);

                table {
                    width: 100%;
                    border: 0;
                    border-collapse: collapse;
                    border-style: solid;


                    tr:hover {
                        background-color: #F0FFFF;
                    }

                    th,
                    td {
                        width: 25%;
                        height: px2rem(50);
                        text-align: center;
                        line-height: normal;
                        border: px2rem(1) solid #ebebeb;
                        border-style: solid;
                        color: #909399;
                        font-weight: 500;

                    }

                    tr,
                    td {
                        padding: px2rem(10);
                        font-size: px2rem(24);
                    }

                    .del {
                        // font-size: px2rem(20);
                        color: red;
                        border-radius: px2rem(10);
                        padding: px2rem(6);
                    }
                }
            }

            .btn {
                width: 100%;
                height: px2rem(120);
                margin: px2rem(50) 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .van-button {
                    display: flex;
                    justify-content: center;
                    width: px2rem(550);
                    height: px2rem(80);
                    color: #fff;
                    font-weight: bold;
                    font-size: px2rem(28);
                    background-color: #336289;
                }
            }
        }
    }
}
</style>

<style>
.van-pagination {
    font-size: 0.6rem;
}

.van-pagination li {
    height: 1.2rem;
}

.van-pagination__page {
    width: 0.62rem;
}

.van-pagination__item--active {
    color: #6699FF !important;
    background-color: transparent;
}

.van-pagination__page {
    color: #aaa;
}
</style>