<template>
    <div class="ChoosePackage">
        <topHead class="topHead">
            <h3 slot="header">变更套餐</h3>
        </topHead>
        <!-- 导航栏 -->
        <!-- 套餐展示 -->
        <div class="mains">
            <van-tabs v-model="flowTabActive">
                <van-tab title="蓝猫流量套餐">
                    <ul class="SetMeal">
                        <li class="SetMealLi" v-for="(item, index) in dayFlow" :key="index"
                            :class="{ SetMeals: dayFlow_li == index }" @click="flowClick(index, item.productId, 'dayFlow')">
                            <div class="SetMealLeft">
                                <div class="radio">
                                    <p></p>
                                </div>
                            </div>
                            <div class="SetMealRight">
                                <div class="SetMealTitle">
                                    <span>{{ item.name }}</span>
                                    <p>
                                        <em>{{ item.amount }}</em>元/月
                                    </p>
                                </div>
                                <div class="particulars">
                                    <p @click="
                                        GoPackageDetails(item.productId)
                                        ">
                                        详情
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </van-tab>
                <van-tab title="蓝猫王卡套餐" v-if="wk_switch">
                    <ul class="SetMeal">
                        <li class="SetMealLi" v-for="(item, index) in dayFlows" :key="index"
                            :class="{ SetMeals: dayFlows_li == index }" @click="
                                flowClick(index, item.productId, 'dayFlows')
                                ">
                            <div class="SetMealLeft">
                                <div class="radio">
                                    <p></p>
                                </div>
                            </div>
                            <div class="SetMealRight">
                                <div class="SetMealTitle">
                                    <span>{{ item.name }}</span>
                                    <p>
                                        <em>{{ item.amount }}</em>元/月
                                    </p>
                                </div>
                                <div class="particulars">
                                    <p @click="
                                        GoPackageDetails(item.productId)
                                        ">
                                        详情
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </van-tab>
            </van-tabs>

            <!-- 底部确认按钮 -->
            <div class="footer">
                <span>套餐费用: <em>{{ currents }} </em>元/月</span>
                <van-button @click="flowRecharge">确认变更</van-button>
            </div>
        </div>
        <!-- 提示弹出框 -->
        <Popup :show="show" confirmText="确认变更" @cancel="onCancel()" @confirm="openFlow()">
            <div slot="conter" class="conter">
                <p class="conter_phone">
                    <span>更改套餐手机号:</span><em>{{ mobilePhone }}</em>
                </p>

                <p class="conter_money">
                    <span>将要修改为:</span><em>{{ SetMeal }}</em>
                </p>
                <p class="conter_flow">
                    <span>套餐价格:</span><em> {{ currents }} </em>
                </p>
            </div>
            <div class="bottom" slot="bottom">
                套餐一经变更不可恢复原套餐，首月变更次月生效（靓号低消和协议期不变）
            </div>
        </Popup>

        <!-- 加载中 -->
        <load :load="load" v-if="load">
            <p slot="text" class="loadText">结果查询中...</p>
        </load>
    </div>
</template>

<script>
import API from "../../../common/api/api"
import { mapState } from "vuex"
import { Toast } from "vant"
import load from "../../../components/load.vue"
import topHead from "../../../components/topHead.vue"
import Popup from "../../../components/Popup.vue"
export default {
    name: "ChoosePackage",
    components: {},
    data() {
        return {
            detail: true,
            mobilePhone: "",
            code: "",
            dayFlow_li: null,
            dayFlows_li: null,
            show: false,
            flowTabActive: 0,
            SetMeal: "",
            currents: "",
            currentss: {},
            newofferId: "",
            dayFlow: [],
            mobilePhone: "",
            packageId: "",
            packageName: "",
            newofferName: "",
            //加载中
            load: false,
            dayFlows: [],
            wk_switch: null
        }
    },
    watch: {
        flowTabActive(newval) {
            if (newval == 1) {
                this.dayFlow_li = null
            } else {
                this.dayFlows_li = null
            }
            this.productId = null
        },
    },

    components: {
        Popup,
        load,
        topHead,
    },

    computed: {
        ...mapState(["member", "verifyCode", "isNone"]),
    },

    created() {
        this.uesr()
        this.ChoosePackage()
    },

    // 组件方法
    methods: {
        ChoosePackage() {
            API.getJson("RECHARGE_PRODUCT", {}).then(res => {
                res.userprod.list.map((item, index) => {
                    if (res.serviceKind == 7) {
                        // 移动号码
                        this.wk_switch = true
                        if (item.Calldisplay == 1) {
                            this.dayFlow.push(item)
                        } else if (item.Calldisplay == 2) {
                            this.dayFlows.push(item)
                        }
                    } else {
                        // 联通号码
                        if (item.serviceKind == 8) {
                            this.wk_switch = false
                            this.dayFlow.push(item)
                        }
                    }

                })
                this.mobilePhone = res.mobilePhone
                this.detail = true
            })
        },

        // 选中套餐弹出提示框
        flowRecharge() {
            if ((this.SetMeal == "", this.currents == "")) {
                Toast({
                    position: "bottom",
                    message: "请选择流量套餐",
                })
            } else {
                this.show = true
            }
        },

        //选择套餐
        flowClick(index, id, type) {
            this.newofferId = id
            this[type + "_li"] = index
            this.SetMeal = this[type][index].name
            this.currents = this[type][index].amount
        },
        GoPackageDetails(id) {
            this.$router.push({
                path: "/PackageDetails",
                query: { id: id },
            })
        },

        //popup框取消回调
        onCancel() {
            this.show = false
        },

        uesr() {
            API.getJson("PERSONAL_DATA", {}).then(res => {
                this.packageId = res.mobilePhoneInfo.packageId
                this.packageName = res.mobilePhoneInfo.packageName
            })
        },

        //变更套餐
        openFlow() {
            this.show = false
            this.load = true
            let token = localStorage.getItem("token")
            API.getJson("PACKAGE_CHANGE", {
                token: token,
                packageId: this.packageId,
                newofferId: this.newofferId,
                packageName: this.packageName,
                newofferName: this.SetMeal,
            }).then(res => {
                this.load = false
                this.show = false
                if (res.rspCode == "0000") {
                    this.$router.push("/ChangeSucces")
                } else if (!res.rspCode == "000") {
                    Toast({
                        position: "bottom",
                        message: res.rspDesc,
                    })
                }
            })
        },
    },
}
</script> 

<style scoped lang='scss' >
.ChoosePackage {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .topHead {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        background: #fff;
        z-index: 99;
    }

    .mains {
        padding: px2rem(30) 0;
        box-sizing: border-box;

        .van-tabs {
            width: 100%;
            height: 80%;
            margin-top: px2rem(90);
            overflow: scroll;
        }

        .SetMeal {
            width: 100%;
            height: px2rem(880);

            .SetMealLi {
                width: 100%;
                height: px2rem(200);
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: px2rem(26);
                border: px2rem(1) solid #c5e0f8;
                background: #ffffff;
                margin: px2rem(10) 0;
                border-radius: px2rem(10);

                .SetMealLeft {
                    width: 20%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .radio {
                        width: px2rem(40);
                        height: px2rem(40);
                        border-radius: 50%;
                        border: px2rem(1) solid #999999;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        p {
                            width: px2rem(20);
                            height: px2rem(20);
                            border-radius: 50%;
                            // background: #999999;
                        }
                    }
                }

                .SetMealRight {
                    width: 80%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 px2rem(20);
                    padding-right: px2rem(40);

                    .SetMealTitle {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span {
                            font-size: px2rem(26);
                        }

                        em {
                            color: #ff802c;
                            font-size: px2rem(30);
                        }
                    }

                    .particulars {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-top: px2rem(20);
                        padding-right: px2rem(20);

                        span {
                            font-size: px2rem(22);
                        }

                        p {
                            width: px2rem(120);
                            height: px2rem(50);
                            text-align: center;
                            line-height: px2rem(50);
                            border-radius: px2rem(10);
                            border: px2rem(1) solid #336289;
                            color: #336289;
                        }
                    }
                }
            }
        }

        .footer {
            width: 100%;
            height: px2rem(120);
            line-height: px2rem(120);
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 100;
            background: #fff;
            padding-left: px2rem(20);
            border-top: px2rem(1) solid #959cf0;

            span {
                font-size: px2rem(30);
                margin-left: px2rem(30);
                color: #cccccc;

                em {
                    color: #ff802c;
                    font-size: px2rem(40);
                }
            }

            button {
                position: absolute;
                right: px2rem(50);
                top: px2rem(24);
                width: px2rem(250);
                height: px2rem(80);
                color: #fff;
                font-weight: bold;
                font-size: px2rem(40) !important;
                background: #336289;
                border-radius: 6%;
                margin-left: px2rem(70);
                background-color: #336289;
                box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
            }
        }
    }

    .conter {
        p {
            display: flex;
            justify-content: space-between;
            padding: 0 px2rem(66);
            line-height: px2rem(56);

            span {
                width: 50%;
                font-size: px2rem(26);
                color: #909198;
            }

            em {
                width: 50%;
                text-align: left;
                font-size: px2rem(28);
                color: #333;
                font-weight: 600;
            }
        }

        .conter_money em {
            color: #ff7505;
            font-size: px2rem(26);
        }

        .conter_flow em {
            color: #336289;
        }
    }

    .bottom {
        width: 100%;
        height: px2rem(70);
        // line-height: px2rem(80);
        font-size: px2rem(26);
        font-weight: 800;
        color: #909198;
        border-top: 1px solid #909198;
        text-align: center;
    }

    .SetMeals {
        width: 100%;
        color: #336289;
        background: #c5e0f8 !important;

        .radio {
            border: px2rem(1) solid #336289 !important;

            p {
                background: #336289 !important;
            }
        }
    }
}
</style>
