<template>
    <div class="cancel">
        <!-- 导航栏 -->
         <topHead>
            <img
                slot="head"
                src="../../../assets/images/fh.png"
                alt=""
                @click="backBefor()"
            />
            <h3 slot="header">过户接收</h3>
        </topHead>
        <!-- 内容 -->
        <div class="main">
            <div class="ipt">
                <p>过户单号</p>
                <input
                    type="text"
                    maxlength="30"
                    v-model="idCard"
                    placeholder="请输入过户单号(字母不区分大小写)"
                />
            </div>
            <div class="ipt">
                <p>过户验证码</p>
                <input
                    type="text"
                    maxlength="6"
                    v-model="name"
                    placeholder="请输入过户验证码(字母不区分大小写)"
                />
            </div>
            <van-button round type="info" @click="cancelHeadler">下一步</van-button
            >
        </div>
        <!-- 加载中 -->
        <load :load="load">
            <p slot="text" class="loadText">结果查询中...</p>
        </load>
        <!-- 失败 -->
        <fail :fail="fail" @headler="failHeadler()"></fail>
    </div>
</template>

<script>
import API from "../../../common/api/api"
import topHead from "../../../components/topHead.vue"
import load from "../../../components/load.vue"
import fail from "../../../components/fail.vue"
import { Toast } from 'vant'
import FaceAuth from "../../../common/FaceAuth"
import Lanmao from "../../../components/lanmao/index"
export default {
    name: "transAcctT",
    data() {
        return {
            //上传内容
            idCard: "",
            name: "",
            //类型
            type: "FACE_AUTH",
            //加载中
            load: false,
            //失败
            fail: false,
            
            paths: "",
        }
    },
    
    methods: {
        // 查询校验结果
        //上传信息
        cancelHeadler() {
            if (this.idCard == "") {
                Toast({
                    message: "请填写过户单号",
                    position: "bottom",
                })
                return
            } 
            if (this.name == "") {
                Toast({
                    message: "请填写过户验证码",
                    position: "bottom",
                })
                return
            } 

            let options = {
                type: "TRANS_ACCT_T",
                idCard:this.idCard,
                name:this.name
            }
            FaceAuth.start(options)
        },
        //子传父
        failHeadler(){
            this.fail = false
        },
         backBefor() {
            this.$router.push("/BusinessHandle")
        },
    },
    components: {
        topHead,
        load,
        fail,
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
}
.ipt {
    position: relative;
    width: 100%;
    height: px2rem(100);
    background-color: #f6f6f6;
    border-radius: px2rem(18);
    margin-top: px2rem(30);
    font-size: px2rem(26);
    padding: 0 px2rem(30);
    box-sizing: border-box;
}
.ipt input {
    width: 100%;
    height: px2rem(100);
    text-align: right;
}
.ipt p {
    position: absolute;
    left: px2rem(30);
    top: 0;
    height: px2rem(100);
    line-height: px2rem(100);
}
.van-button {
    display: flex;
    justify-content: center;
    text-align: center;
    width: px2rem(550);
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin: px2rem(90) auto 0;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
}
</style>