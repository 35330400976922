<template>
    <div class="willregisterorder">
        <!-- 导航栏 -->
        <topHead>
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">短信免打扰服务</h3>
        </topHead>

        <div class="min">
            <div class="explain">
                <div class="con">
                    <p>短信免打扰服务是保护用户免受打扰短信的一种防护业务。</p>
                    <ul>
                        <li>短信免打扰服务业务操作流程：</li>
                        <li>第一步：请先订购，并收到短信订购成功的通知；</li>
                        <li>第二步：在短信免打扰服务业务界面，选择拒收行业短信意愿登记;</li>
                        <li>注:如果您在使用短信防护时遇到任何问题或有反馈建议，可拨打10020，<span class="service_tel"
                                @click="GoService">或点击这里</span>，联系在线客服申诉。</li>

                    </ul>
                </div>
            </div>

            <div class="bottom">
                <div class="check">
                    <p class="checked" :class="{ check_class: check_show }" @click="checked"></p>
                    <p class="checked_con">已阅读并同意签署 <span @click="$router.push('/UserAgreement')">《用户服务协议》</span></p>
                </div>
                <div class="btn">
                    <van-button round type="info" v-if="isShow == '0'" @click="order">订购</van-button>
                    <van-button round type="info" v-if="isShow == '1'" @click="GoRegister">去登记</van-button>
                    <van-button round type="info" v-if="isShow == '1'" @click="withdraw">退订</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../../common/api/api"
import topHead from "../../../components/topHead.vue"
import { Toast, Dialog } from 'vant'
export default {
    components: {
        topHead,
    },
    data() {
        return {
            mobilePhone: "",
            isShow: null,
            dialog_tit: "",
            dialog_con: "",
            check_show: false,
        }
    },

    created() {
        this.Get_SmsByPhone()//是否订购查询接口
    },
    // 组件方法
    methods: {
        // 订购
        order() {
            if (this.check_show) {
                Dialog.confirm({
                    title: "风险提示",
                    message: "订购成功后，本服务将根据用户短信意愿登记对相关内容进行拦截，可能会影响部分短信的正常接收。",
                    messageAlign: "left",
                })
                    .then(() => {
                        API.getJson('SMSREGISTER', {
                            serviceid: this.mobilePhone,
                            regflag: "01"
                        }).then(res => {
                            if (res.code == "1") {
                                Toast({
                                    message: "订购成功，请稍后进行查询!",
                                    position: "bottom",
                                    duration: 1000,
                                })
                                this.$router.push("/BusinessHandle")
                            }
                        })
                    }).catch(() => {
                        // on cancel
                    })
            } else {
                Toast({
                    message: "请先勾选用户服务协议",
                    position: "bottom",
                    duration: 1000,
                })
            }
        },

        // 退订
        withdraw() {
            Dialog.confirm({
                title: "退订",
                message: "您确定要退订此功能!",
            })
                .then(() => {
                    API.getJson('SMSREGISTER', {
                        serviceid: this.mobilePhone,
                        regflag: "02"
                    }).then(res => {
                        if (res.code == "1") {
                            Toast({
                                message: "退订成功，请稍后进行查询!",
                                position: "bottom",
                                duration: 1000,
                            })
                            this.$router.push("/BusinessHandle")
                        }
                    })
                }).catch(() => {
                    // on cancel
                })
        },
        // 是否订购查询功能
        Get_SmsByPhone() {
            API.getJson("RECHARGE_PRODUCT", {}).then(res => {
                if (res.mobilePhone == "") {
                    Dialog.confirm({
                        title: "提示",
                        message: "您还未绑定手机号，无法订购意愿登记服务",
                        confirmButtonText: "去绑定",
                    })
                        .then(() => {
                            this.$router.push({ path: "/AccountBind" })
                        })
                        .catch(() => {
                            // on cancel
                        })
                } else {
                    this.mobilePhone = res.mobilePhone
                    //是否订购查询接口
                    API.getJson("GET_SMSBYPHONE", { serviceid: this.mobilePhone }).then(res => {
                        if (res.code == 1) {
                            // 号码已订购，展示"去登记"按钮
                            this.isShow = "1"
                        } else {
                            // 号码未订购，展示"订购"按钮
                            this.isShow = "0"
                        }
                    })
                }
            })
        },
        // 短信意愿订购返回
        backBefor() {
            this.$router.push("/BusinessHandle")
        },

        // 去登记
        GoRegister() {
            if (this.check_show) {
                localStorage.setItem('phone', this.mobilePhone);
                this.$router.push({ "name": "SelectFunction" })
            } else {
                Toast({
                    message: "请先勾选用户服务协议",
                    position: "bottom",
                    duration: 1000,
                })
            }
        },

        // 跳转客服
        GoService() {
            var token = localStorage.getItem("token")
            API.getJson("wx_url", {
                token: token,
            }).then(res => {
                this.nickType = res.list[0].nickType
                this.clientId = res.list[0].nickId
                this.otherParams = `{"nickName":"${res.list[0].nickName}"}`
                let sit = `https://webchat.7moor.com/wapchat.html?accessId=b8e25cb0-5ca3-11ec-99c8-3739e01ad41c&fromUrl=&urlTitle="${this.nickType}"&language=ZHCN&clientId=${this.clientId}&otherParams=${this.otherParams}`
                window.location.href = sit
            })
        },

        checked() {
            this.check_show = !this.check_show
        }
    },

    computed: {

    },

    watch: {},

    mounted() { },
}
</script>

<style scoped lang='scss'>
.willregisterorder {
    height: 100%;

    .topHead {
        position: sticky;
        /* 新属性sticky */
        top: 0;
        background: #fff;
    }

    .min {
        height: 75%;

        .explain {
            font-size: px2rem(26);
            padding: 0 px2rem(20);
            height: 80%;

            .con {
                height: 60%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-top: px2rem(36);

                p {
                    height: px2rem(40);
                    font-size: px2rem(26);
                    display: flex;
                    align-items: center;
                }

                ul {
                    height: 70%;

                    li {
                        height: 25%;
                        line-height: px2rem(60);
                        padding-left: px2rem(30);

                        .service_tel {
                            color: #3399FF;
                        }
                    }

                    :nth-child(1) {
                        padding-left: px2rem(10);
                    }
                }
            }
        }

        .bottom {
            height: 30%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-end;
            padding: 0 px2rem(20);

            .check {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: px2rem(10);

                .checked {
                    width: px2rem(36);
                    height: px2rem(36);
                    background: #cdcdcd;
                    border-radius: px2rem(8);
                    margin: 0 px2rem(10);
                }

                .check_class {
                    background: url(../../../assets/images/harassDefend/business/xz.png) no-repeat center center;
                    background-size: cover;
                }

                .checked_con {
                    font-size: px2rem(26);
                    display: flex;
                    justify-content: space-around;

                    span {
                        color: #3399FF;
                    }
                }


            }

            .btn {
                width: 100%;
                height: 70%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .van-button {
                    display: flex;
                    justify-content: center;
                    width: px2rem(550);
                    height: px2rem(80);
                    line-height: px2rem(80);
                    color: #fff;
                    font-weight: bold;
                    font-size: px2rem(28);
                    background-color: #336289;
                    margin: auto;
                    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
                }
            }
        }
    }
}
</style>
<style>
.van-dialog {
    width: 86% !important;
}

.van-dialog__message--has-title {
    color: #000;
    padding: 0.6rem 0.6rem;
}
</style>