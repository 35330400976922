<template>
    <div class="mealDetails">
        <!-- 导航栏 -->
        <topHead>
            <img
                slot="head"
                @click="backBefor()"
                src="../../assets/images/fhb.png"
                alt=""
            />
            <h3 slot="header">套餐详情</h3>
        </topHead>
        <!-- 背景 -->
        <div class="back"></div>
        <!-- 内容 -->
        <div class="main">
            <div class="main_top" v-if="member.mobilePhoneInfo && member.mobilePhoneInfo.mobilePhone">
                <div class="face">
                    <img src="../../assets/images/face1.png" alt="" />
                </div>
                <div class="info">
                    <div class="name">
                        <div class="title fl">{{ member.memberInfo.memberName }}</div>
                        <div class="Star fl" >
                            <img src="../../assets/images/xjyh.png" alt="" />
                            <p>{{ member.mobilePhoneInfo.level }}星用户</p>
                        </div>
                    </div>
                    <div class="phone">
                        {{ member.mobilePhoneInfo.mobilePhone }}
                    </div>
                </div>
                <div class="change" @click="goPassword">更改服务密码</div>
            </div>
        </div>
        <div class="foot">
            <ul class="details_list">
                <li>
                    <span></span>
                    <p>当前套餐</p>
                    <em>{{list.packageName}}</em>
                </li>
                <li v-for="(item,index) in list.list" :key="index">
                    <span></span>
                    <p>{{item.title}}</p>
                    <em>{{item.desc}}</em>
                </li>
            </ul>
            <div class="explain">
                <div class="explain_title">业务规则：</div>
                <p v-html="list.packageExplain"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import API from "../../common/api/api"
import topHead from "../../components/topHead.vue"
export default {
    name: "mealDetails",
    data() {
        return {
            packageId:this.$route.query.id,
            list:[]
        }
    },
    components: {
        topHead,
    },
    created() {
        this.$store.dispatch("getMember")
    },
    mounted() {
      this.getMeal();
    },
    computed: {
        ...mapState(["member"]),
    },
    methods: {
        backBefor() {
            this.$router.back()
        },
        goPassword() {
            this.$router.push({ name: "setPassword" })
        },
        // 获取当前套餐
        getMeal() {
            API.getJson("GET_MEAL", {
                packageId:this.packageId,
            }).then(res => {
                this.list = res;
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    position: absolute;
    left: 0;
    top: px2rem(88);
    padding: 0 px2rem(30);
    z-index: 5;
    width: 100%;
    box-sizing: border-box;
}
.topHead h3 {
    color: #fff;
}
.topHead {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
}
.topHead img {
    width: px2rem(32);
    height: px2rem(27);
}
.back {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: px2rem(400);
    background: url(../../assets/images/grzxbj.png) no-repeat center center;
    background-size: cover;
    z-index: 4;
}
.main_top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: px2rem(52);
    color: #fff;
}
.main_top .face,
.main_top .face img {
    width: px2rem(100);
    height: px2rem(100);
    border-radius: 50%;
}
.main_top .change {
    width: px2rem(190);
    height: px2rem(60);
    line-height: px2rem(60);
    background-color: #234f73;
    color: #6f8fa8;
    border-radius: px2rem(28);
    font-size: px2rem(24);
    text-align: center;
    margin-top: px2rem(20);
}

.info {
    margin-right: auto;
    margin-top: px2rem(15);
    margin-left: px2rem(20);
}
.info .name{
    display: flex;
}
.info .name .title {
    width:auto;
    max-width: px2rem(200);
    font-size: px2rem(34);
    font-weight: bold;
    margin-bottom: px2rem(10);
    margin-right:px2rem(15);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.info .name .Star {
    display: flex;
    position: relative;
    width: px2rem(124);
    height: px2rem(35);
}
.info .name .Star img {
    position: relative;
    width: 100%;
    height: 100%;
}
.info .name .Star p {
    position: absolute;
    right: px2rem(15);
    top: 0;
    font-size: px2rem(20);
    height: px2rem(35);
    line-height: px2rem(35);
}
.info .phone {
    font-size: px2rem(28);
}
.foot {
    position: absolute;
    left: 0;
    top: px2rem(250);
    z-index: 6;
    width: 100%;
}
.details_list {
    width: 100%;
    background-color: #fff;
    margin-top: px2rem(70);
    padding: 0 px2rem(40);
    box-sizing: border-box;
    padding-top: px2rem(20);
}
.details_list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: px2rem(66);
}
.details_list li span {
    width: px2rem(14);
    height: px2rem(14);
    border-radius: 50%;
    background-color: #336289;
    margin-right: px2rem(17);
}
.details_list li p {
    margin-right: auto;
    font-size: px2rem(26);
    color: #333;
}
.details_list li em {
    font-size: px2rem(26);
    color: #999999;
}
.details_list li:nth-child(1) em {
    color: #4182ef;
}
.explain {
    position: absolute;
    width: px2rem(690);
    margin-left: px2rem(30);
    border-radius: px2rem(18);
    background-color: #e5eaed;
    padding: px2rem(28);
    box-sizing: border-box;
    margin-top: px2rem(30);
}
.explain_title {
    font-weight: bold;
    margin-bottom: px2rem(15);
    font-size: px2rem(26);
    color: #336289;
}
.explain p {
    font-size: px2rem(22);
    color: #333;
    line-height: px2rem(44);
}
</style>