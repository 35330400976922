<template>
    <div class="orderDetail">
        <topHead><h3 slot="header">订单详情</h3></topHead>
        <!-- 导航栏 -->
        <div class="main">

        </div>
    </div>
</template>
<script>
import { mapState } from "vuex"
import API from "../../../common/api/api"
import topHead from "../../../components/topHead.vue"
import { Dialog, Toast } from "vant"
export default {
    name: "orderDetail",
    data() {
        return {

        }
    },
    watch: {},
    created() {},
    components: {
        topHead,
    },
    computed: {},
    mounted() {
      console.log(this.$route.query.res)
    },
    methods: {}
}
</script>
<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
    box-sizing: border-box;
}
</style>
