<template>
    <div class="relieve">
        <!-- 导航栏 -->
        <topHead><h3 slot="header">更改服务密码</h3></topHead>
        <!-- 内容 -->
        <div class="main">
            <div class="ipt">
                <p>手机号</p>
                <input
                    type="text"
                    maxlength="11"
                    placeholder="请填写您的手机号"
                    v-model="mobilePhone"
                />
            </div>
            <div class="ipt">
                <p>验证码</p>
                <input
                    type="text"
                    maxlength="8"
                    v-model="code"
                    placeholder="请输入验证码"
                />
                <button class="tab4_btn" :disabled="isNone" @click="codeBtn">
                    {{ verifyCode }}
                </button>
            </div>
            <div class="ipt">
                <p>原密码</p>
                <input
                    type="text"
                    maxlength="20"
                    v-model="rawServicePass"
                    placeholder="请输入原密码"
                />
            </div>
            <div class="unableCode" @click="goNot">忘记原密码？</div>
            <van-button round type="info" @click="goNewPassword"
                >下一步</van-button
            >
        </div>
        <!-- 加载中 -->
        <load :load="load">
            <p slot="text" class="loadText">短信发送中...</p>
        </load>
    </div>
</template>

<script>
import { mapState } from "vuex"
import API from "../../common/api/api"
import load from "../../components/load.vue"
import topHead from "../../components/topHead.vue"
import Popup from "../../components/Popup.vue"
import { Toast } from "vant"
export default {
    name: "relieve",
    data() {
        return {
            show: false,
            isShow: false,
            mobilePhone: "",
            rawServicePass: "",
            code: "",
        }
    },
    components: {
        topHead,
        Popup,
        load,
    },
    computed: {
        ...mapState(["verifyCode", "isNone", "load"]),
    },
    methods: {
        //提交信息
        goNewPassword() {
            let phone = /^[1][3,4,5,6,7,8][0-9]{9}$/
            let token = localStorage.getItem("token")
            if (!phone.test(this.mobilePhone)) {
                Toast({
                    message: "请输入正确的手机号",
                    position: "bottom",
                })
            } else if (this.code == "") {
                Toast({
                    message: "请填写验证码",
                    position: "bottom",
                })
            } else if (this.rawServicePass == "") {
                Toast({
                    message: "请填写原密码",
                    position: "bottom",
                })
            } else {
                API.getJson("servicePasswordEditTest", {
                    rawServicePass: this.rawServicePass,
                    mobilePhone: this.mobilePhone,
                    code: this.code,
                    token: token,
                }).then(res => {
                    if (res.code == 1) {
                        this.$router.replace({ path: "newPassword" })
                    } else if (res.data.code == -100) {
                        Toast({
                            message: res.data.msg,
                            position: "bottom",
                        })
                    }
                })
            }
        },
        goNot() {
            this.$router.replace({ path: "notPassword" })
        },
        //获取验证码
        codeBtn() {
            let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (this.mobilePhone == "") {
                Toast({
                    message: "请输入手机号",
                    position: "bottom",
                })
            } else if (!phone.test(this.mobilePhone)) {
                Toast({
                    message: "请输入正确的手机号",
                    position: "bottom",
                })
            } else {
                this.$store.commit("obtainCode", {
                    mobilePhone: this.mobilePhone,
                    type: "05",
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
}
//账号绑定
.tab4 {
    padding: 0 px2rem(30);
    box-sizing: border-box;
}
.ipt {
    position: relative;
    width: 100%;
    height: px2rem(100);
    background-color: #f6f6f6;
    border-radius: px2rem(18);
    margin-top: px2rem(40);
    font-size: px2rem(26);
    padding: 0 px2rem(30);
    box-sizing: border-box;
}
.ipt:nth-child(1) input,
.ipt:nth-child(3) input {
    text-align: right;
}
.ipt input {
    width: 100%;
    height: px2rem(100);
    box-sizing: border-box;
}
.ipt:nth-child(2) input {
    padding: 0 px2rem(120);
}
.ipt p {
    position: absolute;
    left: px2rem(30);
    top: 0;
    height: px2rem(100);
    line-height: px2rem(100);
}
.unableCode {
    font-size: px2rem(26);
    color: #999;
    text-align: right;
    margin-top: px2rem(30);
}
.tab4_btn {
    position: absolute;
    right: px2rem(30);
    top: px2rem(28);
    padding: 0 px2rem(20);
    height: px2rem(44);
    line-height: px2rem(44);
    color: #fff;
    font-size: px2rem(24);
    background-color: #336289;
    text-align: center;
    border-radius: px2rem(20);
}
.van-button {
    display: flex;
    justify-content: center;
    width: px2rem(540);
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin: px2rem(100) auto 0;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
}

.conter {
    display: flex;
    justify-content: center;
}
.conter img {
    width: px2rem(147);
    height: px2rem(118);
}
.bottom {
    font-size: px2rem(30);
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-top: px2rem(50);
}
.pay {
    width: px2rem(210);
    height: px2rem(70);
    line-height: px2rem(70);
}
</style>