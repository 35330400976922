<template>
    <div class="relieve">
        <!-- 导航栏 -->
        <topHead><h3 slot="header">更改服务密码</h3></topHead>
        <!-- 内容 -->
        <div class="main">
            <div class="ipt">
                <p>姓名</p>
                <input
                    type="text"
                    maxlength="11"
                    placeholder="请输入姓名"
                    v-model="name"
                />
            </div>
            <div class="ipt">
                <p>身份证</p>
                <input
                    type="text"
                    maxlength="18"
                    placeholder="请输入身份证号"
                    v-model="idCard"
                />
            </div>
            <div class="ipt">
                <p>手机号</p>
                <input
                    type="text"
                    maxlength="11"
                    placeholder="请填写您的手机号"
                    v-model="mobilePhone"
                />
            </div>
            <!-- <div class="ipt">
                <p>验证码</p>
                <input type="text" maxlength="6" placeholder="请输入验证码"  v-model="code"/>
                <button class="tab4_btn" :disabled="isNone" @click="codeBtn">
                    {{ verifyCode }}
                </button>
            </div> -->
            <van-button round type="info" @click="newPassword">确认</van-button>
        </div>
        <!-- 加载中 -->
        <!-- <load :load="load">
            <p slot="text" class="loadText">短信发送中...</p>
        </load> -->
    </div>
</template>

<script>
import { mapState } from "vuex"
import API from "../../common/api/api"
import load from "../../components/load.vue"
import topHead from "../../components/topHead.vue"
import Popup from "../../components/Popup.vue"
import FaceAuth from "../../common/FaceAuth.js"
import { Toast } from "vant"
export default {
    name: "relieve",
    data() {
        return {
            show: false,
            isShow: false,
            mobilePhone: "",
            code: "",
            name: "",
            idCard: "",
        }
    },
    components: {
        topHead,
        Popup,
        load,
    },
    computed: {
        ...mapState(["verifyCode", "isNone", "load"]),
    },
    methods: {
        //提交信息
        newPassword() {

            let options = {
                type: "SET_PASSWORD",
                idCard:this.idCard,
                name:this.name,
                mobilePhone: this.mobilePhone
            }
            FaceAuth.start(options)


            // let token = localStorage.getItem("token")
            // let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            // let idCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
            // let that = this
            // if (that.name == "") {
            //     Toast({
            //         message: "请填写姓名",
            //         position: "bottom",
            //     })
            // } else if (that.idCard == "") {
            //     Toast({
            //         message: "请填写身份证",
            //         position: "bottom",
            //     })
            // }else if(that.mobilePhone == ''){
            //     Toast({
            //         message:'请填写手机号',
            //         position:'bottom'
            //     })
            // }else if(!phone.test(that.mobilePhone)){
            //     Toast({
            //         message:'请输入正确的手机号',
            //         position:'bottom'
            //     })
            // } else if (!idCard.test(that.idCard)) {
            //     Toast({
            //         message: "请输入正确的身份证号",
            //         position: "bottom",
            //     })
            // } else {
            //     API.getJson("servicePasswordEditTest", {
            //         name: that.name,
            //         idCard: that.idCard,
            //         mobilePhone: that.mobilePhone,
            //         // code: that.code,
            //         token: token,
            //     }).then(res => {
            //         console.log(res)
            //         // that.$router.replace({ path: "newPassword" })
            //     })
            // }
        },
        //获取验证码
        // codeBtn() {
        //     let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        //     if (this.mobilePhone == "") {
        //         Toast({
        //             message: "请输入手机号",
        //             position: "bottom",
        //         })
        //     } else if (!phone.test(this.mobilePhone)) {
        //         Toast({
        //             message: "请输入正确的手机号",
        //             position: "bottom",
        //         })
        //     } else {
        //         this.$store.commit("obtainCode", {
        //             mobilePhone: this.mobilePhone,
        //             type: "03",
        //         })
        //     }
        // },
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
}
//账号绑定
.tab4 {
    padding: 0 px2rem(30);
    box-sizing: border-box;
}
.ipt {
    position: relative;
    width: 100%;
    height: px2rem(100);
    background-color: #f6f6f6;
    border-radius: px2rem(18);
    margin-top: px2rem(40);
    font-size: px2rem(26);
    padding: 0 px2rem(30);
    box-sizing: border-box;
}
.ipt input {
    width: 100%;
    height: px2rem(100);
    box-sizing: border-box;
    text-align: right;
}
.ipt:nth-child(4) input {
    text-align: center;
}
.ipt p {
    position: absolute;
    left: px2rem(30);
    top: 0;
    height: px2rem(100);
    line-height: px2rem(100);
}
.unableCode {
    font-size: px2rem(26);
    color: #999;
    text-align: right;
    margin-top: px2rem(30);
}
.tab4_btn {
    position: absolute;
    right: px2rem(30);
    top: px2rem(28);
    padding: 0 px2rem(20);
    height: px2rem(44);
    line-height: px2rem(44);
    color: #fff;
    font-size: px2rem(24);
    background-color: #336289;
    text-align: center;
    border-radius: px2rem(20);
}
.van-button {
    display: flex;
    justify-content: center;
    width: px2rem(540);
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin: px2rem(100) auto 0;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
}
</style>