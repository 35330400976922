import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
// import VConsole from "vconsole"
// var vConsole = new VConsole();
import vueEsign from "vue-esign"

// 引入hotcss
import "./lib/hotcss/hotcss.js"
//全局引用echarts图表
import echarts from "echarts"
//请求接口
import API from "./common/api/api"
import CONFIG from "./common/config"
//引入vant
import {
    Sidebar,
    SidebarItem,
    TreeSelect,
    Tab,
    Tabs,
    Field,
    Button,
    Overlay,
    Uploader,
    Toast,
    RadioGroup,
    Radio,
    CountDown,
    Progress,
    Area,
    Checkbox,
    Popup,
    Empty,
    Swipe,
    SwipeItem,
    Tabbar,
    TabbarItem,
    DropdownMenu,
    DropdownItem,
    Dialog,
    Loading,
    Divider,
    SubmitBar,
    Step,
    Steps,
    Cell,
    Icon,
    Popover,
    Pagination,
} from "vant"
Vue.use(Icon)
Vue.use(Cell)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Loading)
Vue.use(Divider)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(TreeSelect)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Field)
Vue.use(Button)
Vue.use(Overlay)
Vue.use(Uploader)
Vue.use(Toast)
Toast.setDefaultOptions({ duration: 5000 })
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(CountDown)
Vue.use(Progress)
Vue.use(Checkbox)
Vue.use(Area)
Vue.use(Popup)
Vue.use(Empty)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dialog)
Vue.use(SubmitBar)
Vue.use(vueEsign)
Vue.use(Popover)
Vue.use(Pagination)

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
//注册一个全局守卫
router.beforeEach(async (to, from, next) => {
    if (
        to.path == "/login" ||
        to.path == "/OpenCardMessage" ||
        to.path == "/OpenCard" ||
        to.path == "/OpenPay" ||
        to.path == "/WxQuery" ||
        to.path == "/DxWithoutCodeLogin24af2192e12d490cbdb899729fcfc010" ||
        to.path == "/YhWithoutCodeLogin7f00f1f345874528af99d77ed877748b" ||
        to.path == "/YyWithoutCodeLogin1dd955d4f93d4743a972ee639f94f843" ||
        to.path == "/ZnWithoutCodeLogin284e92113c5e4391a689f4807cd9b0b6" ||
        to.path == "/ZyWithoutCodeLogina7f66fd4a61a4f18b76ead9c8a82177a" ||
        to.path == "/SwWithoutCodeLogin3e86ae1e87084e5ca4028a42c6191b71" ||
        to.path == "/phWithoutCodeLogin3ae42313a1324d9ea16e3daf081ff6e1" ||
        to.path == "/realNameLink" ||
        to.path == "/realNameLinkForm" ||
        to.path == "/realNameLinkSuccess" ||
        to.path == "/realNameLinkError"
    ) {
        // 获取到的标识
        var ua = window.navigator.userAgent.toLowerCase()
        var useragent = navigator.userAgent.toLowerCase()
        // console.log("获取的标识", useragent.indexOf("micromessenger"))
        if (ua.match(/android/i) == "android") {
            next()
        } else if (ua.match(/iphone os/i) == "iphone os") {
            next()
        } else if (ua.match(/MicroMessenger/i) == "micromessenger") {
            next()
        } else if (useragent.indexOf("micromessenger") === -1) {
            // 判断获取到的标识，微信标识为164；浏览器的标识为-1（360浏览器/谷歌浏览器）
            // 提示用户在微信内置浏览器访问本页面后，（强行关闭当前页面）
            alert("请在微信客户端访问本页面")
            let APPID = CONFIG.APPID
            let pageUrl = window.location.protocol + "//" + window.location.host
            let wechatAuth2URL =
                "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                APPID +
                "&redirect_uri=" +
                encodeURIComponent(pageUrl) +
                "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
            window.location.href = wechatAuth2URL
        }
        // else {
        //     next()
        // }
    } else {
        let token = localStorage.getItem("token")
        //用户未登录进行登录
        if (!token) {
            if (to.query.code && to.query.code != "" && to.query.code != null) {
                //code存在请求 请求接口code换取token
                try {
                    let code = await API.getJson("LOGIN", {
                        code: to.query.code,
                    }).then(res => {
                        if (res.token) {
                            localStorage.setItem("token", res.token)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
                next()
            } else {
                // 测试使用
                // localStorage.setItem('token', 'f6021d7eaee74564b07ba5bd4846a4a3'); //1054eb44722c4674958b41e1d2151f0b
                // code不存在 跳转到微信侧换取code
                let APPID = CONFIG.APPID
                let pageUrl =
                    window.location.protocol + "//" + window.location.host
                let wechatAuth2URL =
                    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                    APPID +
                    "&redirect_uri=" +
                    pageUrl +
                    "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
                window.location.href = wechatAuth2URL

                next()
            }
        } else {
            next()
        }
    }
})
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app")
