<template>
    <div class="addsite">
        <topHead class="topHead">
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">公司信息</h3>
        </topHead>
        <div class="header_search">
            <div class="search_con">
                <input type="text" placeholder="请输入搜索公司关键词" v-model="keyword"
                    onkeyup="this.value=this.value.replace(/[ ]/g,'')">
                <button @click="seach">搜索</button>
            </div>
        </div>

        <div class="content">
            <ul class="dataList">
                <li v-for="item, index in messageList" :key="index">
                    <div class="li_left" @click="selectMessage(item)">
                        <p><span>公司信息：</span> {{ item.name }} </p>
                        <p><span>纳税人识别号：</span> {{ item.tin }} </p>
                        <p><span>公司地址：</span> {{ item.address }} </p>
                        <p><span>公司电话：</span> {{ item.tel }} </p>
                        <p><span>开户行：</span> {{ item.bankName }} </p>
                        <p><span>账号：</span> {{ item.bankAccount }} </p>
                    </div>
                    <div class="li_right" v-if="item.id">
                        <div class="ImgOperate">
                            <p> <img @click="edit(item)" src="../../../assets/images/xg.png" alt=""></p>
                            <p> <img @click="del(item.id)" src="../../../assets/images/sc.png" alt=""></p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="bottom">
            <button class="addBtn" @click="add_show = true">新增公司信息</button>
            <!-- 添加 -->
            <van-popup v-model="add_show" position="bottom" :style="{ height: '50%' }">

                <van-field v-model="addList.firmName" label-width="3rem" label="公司名称:" placeholder="请输入公司名称 (必填)" />


                <van-field v-model="addList.taxNumber" label-width="4.2rem" label="纳税人识别号:" placeholder="请输入纳税人识别号 (必填)"
                    maxlength="20" @click="taxNumber_hint = true" @blur="taxNumber_hint = false"
                    onkeyup="this.value=this.value.toUpperCase()" />
                <div class="tit" v-if="taxNumber_hint">
                    <p>注：纳税人识别号填写完成后请确认填写是否正确</p>
                </div>
                <van-field v-model="addList.address" label-width="3rem" label="公司地址:" placeholder="请输入公司地址 (选填)" />
                <van-field v-model="addList.tel" label-width="3rem" label="公司电话:" placeholder="请输入公司电话 (选填)" />
                <van-field v-model="addList.bankName" label-width="3rem" label="开户行:" placeholder="请输入开户行 (选填)" />
                <van-field v-model="addList.bankAccount" label-width="3rem" label="账号:"
                    placeholder="请输入账号 (选填)" />


                <div class="btn">
                    <van-button @click="add">添加</van-button>
                </div>
            </van-popup>

            <!-- 编辑 -->
            <van-popup v-model="edit_show" position="bottom" :style="{ height: '50%' }">

                <van-field v-model="editList.firmName" label-width="3rem" label="公司名称" placeholder="请输入公司名称 (必填)" />
                <van-field v-model="editList.taxNumber" label-width="4.2rem" label="纳税人识别号" placeholder="请输入纳税人识别号 (必填)"
                    maxlength="20" />
                <van-field v-model="editList.address" label-width="3rem" label="公司地址:" placeholder="请输入公司地址 (选填)" />
                <van-field v-model="editList.tel" label-width="3rem" label="公司电话:" placeholder="请输入公司电话 (选填)" />
                <van-field v-model="editList.bankName" label-width="3rem" label="开户行:" placeholder="请输入开户行 (选填)" />
                <van-field v-model="editList.bankAccount"  label-width="3rem" label="账号:"
                    placeholder="请输入账号 (选填)" />
                <div class="btn">
                    <van-button @click="editData">修改</van-button>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
import API from "../../../common/api/api"
import topHead from "../../../components/topHead.vue"
import { Dialog, Toast } from "vant"
import Lanmao from "../../../components/lanmao/index"
export default {
    components: {
        topHead,
    },
    data() {
        return {
            add_show: false,
            edit_show: false,
            addList: {
                firmName: "",
                taxNumber: "",
                address: "",//地址
                tel: "",//电话
                bankName: "",//开户行
                bankAccount: "",//账号
            },
            editList: {
                firmName: "",
                taxNumber: "",
                address: "",//地址
                tel: "",//电话
                bankName: "",//开户行
                bankAccount: "",//账号
            },
            keyword: "",
            taxNumber_hint: false,
            messageList: [],
            searchList: [],
            edit_id: ""
        }
    },

    created() {
        this.userList()
    },
    // 组件方法
    methods: {
        //获取公司信息
        userList() {
            API.getJson("NEW_QUERY_USER", {
                userId: localStorage.getItem("userId"),
            }).then(res => {
                this.messageList = res
            })
        },

        // 搜索
        seach() {
            if (this.keyword.length >= 3) {
                Lanmao.loading.show("查询中")
                API.getJson("NEW_QUERY_TITLE", {
                    name: this.keyword,
                }).then(res => {
                    Lanmao.loading.hide()
                    if (res == "") {
                        Toast({
                            message: "暂无公司信息，请新增公司信息",
                            position: "bottom",
                            duration: 900,
                        })
                        this.add_show = true
                    } else {
                        this.messageList = res
                    }

                }).catch(err => {
                    Lanmao.loading.hide()
                })
            } else if (this.keyword.length == 0) {
                this.userList()
            } else {
                Toast({
                    message: "企业名称格式不正确(最少3位,最大100位);",
                    position: "bottom",
                    duration: 900,
                })
            }


        },

        // 添加
        add() {
            if (this.addList.firmName == '') {
                Toast({
                    message: "请填写公司信息",
                    position: "bottom",
                })
            } else if (this.addList.taxNumber == '') {
                Toast({
                    message: "请填写纳税人识别号",
                    position: "bottom",
                })
            } else {
                API.getJson("NEW_ADD_INVOICEUSER", {
                    name: this.addList.firmName,
                    tin: this.addList.taxNumber,
                    address: this.addList.address,
                    tel: this.addList.tel,
                    bankName: this.addList.bankName,
                    bankAccount: this.addList.bankAccount,
                    userId: localStorage.getItem("userId"),
                    id: ""
                }).then(res => {
                    if (res == true) {
                        this.addList.firmName = ""
                        this.addList.taxNumber = ""
                        this.add_show = false
                        this.userList()
                    }
                })
            }

        },

        // 编辑
        edit(item) {
            this.edit_show = true
            this.editList.firmName = item.name
            this.editList.taxNumber = item.tin
            this.editList.address = item.address
            this.editList.tel = item.tel
            this.editList.bankName = item.bankName
            this.editList.bankAccount = item.bankAccount
            this.edit_id = item.id

        },
        editData() {
            API.getJson("NEW_ADD_INVOICEUSER", {
                name: this.editList.firmName,
                tin: this.editList.taxNumber,
                address: this.editList.address,
                tel: this.editList.tel,
                bankName: this.editList.bankName,
                bankAccount: this.editList.bankAccount,
                userId: localStorage.getItem("userId"),
                id: this.edit_id
            }).then(res => {
                if (res == true) {
                    this.edit_show = false
                    this.keyword = ""
                    this.userList()
                }
            })
        },

        // 删除
        del(id) {
            Dialog.confirm({
                message: '确定删除该条信息',
            }).then(() => {
                API.getJson("NEW_DEL_INVOICEUSER", {
                    id: id,
                }).then(res => {
                    if (res == true) {
                        this.userList()
                    }
                })
            })
        },

        // 返回
        backBefor() {
            this.$router.push("/submitInvoice");
        },

        // 选择的公司信息
        selectMessage(v) {
            localStorage.setItem("selectFlag", "query")
            this.$router.push({
                "name": "submitInvoice",
                params: { Message: v }
            })
        },
    },

    computed: {
        // customerMobile() {
        //     return this.keyword
        // },
    },

    watch: {
        // keyword: function (val, oldval) {
        //     if (val.length >= 3) {
        //     }
        // },
    },

    mounted() { },
}
</script> 

<style scoped lang='scss' >
.addsite {
    width: 100%;
    height: 120vh;
    background-color: #f5f5f5;
    position: relative;


    .topHead {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
        background: #fff;
    }

    .header_search {
        width: 100%;
        height: px2rem(120);
        position: fixed;
        margin-top: px2rem(88);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: px2rem(26);
        background-color: #fff;

        .search_con {
            width: 86%;
            height: 60%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            input {
                width: 80%;
                height: 100%;
                padding-left: px2rem(20);
                border-top-left-radius: px2rem(30);
                border-bottom-left-radius: px2rem(30);
                border: px2rem(1) solid #aaa;
            }

            button {
                width: 20%;
                height: 100%;
                background-color: #1d6184;
                color: #fff;
                border-top-right-radius: px2rem(30);
                border-bottom-right-radius: px2rem(30);
                box-sizing: content-box;
            }
        }

    }

    .content {
        width: 100%;
        height: 76%;
        padding: px2rem(20);
        box-sizing: border-box;
        position: fixed;
        top: px2rem(180);
        overflow: auto;


        .dataList {
            width: 100%;
            height: 100%;

            li {
                width: 100%;
                height: px2rem(320);
                display: flex;
                justify-content: space-between;
                background-color: #fff;
                padding: px2rem(10) px2rem(20);
                border-radius: px2rem(10);
                margin-top: px2rem(30);

                .li_left {
                    width: 75%;
                    height: 100%;
                    font-size: px2rem(24);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    p {
                        line-height: px2rem(36);
                    }
                }

                .li_right {
                    width: 25%;
                    height: 100%;

                    .ImgOperate {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;

                        p {
                            width: px2rem(60);
                            height: px2rem(60);

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom {
        width: 100%;
        height: px2rem(100);
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;

        .addBtn {
            width: 86%;
            height: 80%;
            font-size: px2rem(28);
            border-radius: px2rem(20);
            background-color: #1d6184;
            color: #fff;
        }

        .tit {
            font-size: px2rem(24);

            p {
                margin-left: px2rem(30);
                color: red;
            }
        }

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: px2rem(30) 0;

            .van-button {
                display: flex;
                justify-content: center;
                text-align: center;
                width: 90%;
                height: px2rem(80);
                line-height: px2rem(80);
                color: #fff;
                font-weight: bold;
                font-size: px2rem(28);
                background-color: #336289;
                box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
                border-radius: px2rem(10);
            }
        }

        .van-field {
            margin-top: px2rem(30);
        }
    }
}
</style>