<template>
    <div class="login">
        <div class="logins" v-if="Judge == 'android'">
            <div class="login_header">
                <img src="@/assets/images/login/login2.png" alt="" />
            </div>
            <div class="login_boxs">
                <div class="login_box">
                    <div class="login_box_tit">账号登录</div>
                    <div class="login_box_con">
                        <div class="inp">
                            <i>
                                <img src="@/assets/images/login/login4.png" alt="" />
                            </i>
                            <input v-model="UserName" type="text" placeholder="请输入账号" />
                        </div>
                        <div class="inp">
                            <i>
                                <img src="@/assets/images/login/login3.png" alt="" />
                            </i>
                            <input v-model="PassWord" type="password" placeholder="请输入密码" />
                        </div>
                        <div class="inp code">
                            <i>
                                <img src="@/assets/images/login/login3.png" alt="" />
                            </i>
                            <input v-model="LoginCode" type="text" placeholder="请输入验证码" />
                            <p @click="getCode">
                                <img :src="'data:image/jpeg;base64,' + imgCode" alt="" />
                            </p>
                        </div>
                    </div>
                </div>
                <div class="btn">
                    <van-button type="primary" @click="LoginBtn" size="large">登录</van-button>
                </div>
            </div>
        </div>
        <div class="login_tu" v-if="Judge == 'micromessenger'">
            <p>点击右上角在浏览器中打开</p>
        </div>
        <div class="login_tus" v-if="Judge == 'unknown'">
            <p>该系统暂不支持开卡</p>
        </div>
    </div>
</template>

<script>
import preventBack from "vue-prevent-browser-back"
import { Toast } from "vant"
import { GetSeesion, Login, CaptchaImage } from "../../common/newapi/api.js"
export default {
    name: "login",
    components: {},
    mixins: [preventBack], //注入  阻止返回上一页
    data() {
        return {
            // 生产
            UserName: "",
            PassWord: "",
            // 测试
            // UserName: "BJBJ000004_1", //lishifeng  CSDY
            // PassWord: "Lanmao!@#1qaz",

            LoginCode: "", //文本框图形
            randomStrs: "", //随机数
            imgCode: "", //图形验证码
            token: "",
            Judge: null,
            types: "1", //0是不需要图形验证码，1是需要图形验证码
        }
    },
    created() {
        this.ForceRefresh()
        this.judge()
        // this.getCode()
    },

    // 组件方法
    methods: {
        judge() {
            let ua = window.navigator.userAgent.toLowerCase()

            // 判断用户手机是否为安卓手机
            if (
                ua.match(/android/i) == "android" ||
                ua.match(/iphone os/i) == "iphone os"
            ) {
                // 用户手机为安卓手机在判断是否在微信浏览器中打开
                if (ua.match(/MicroMessenger/i) == "micromessenger") {
                    // 微信浏览器中打开
                    this.Judge = "micromessenger"
                } else {
                    this.Judge = "android"
                    this.getCode()
                }
            } else {
                alert("该系统暂不支持开卡")
                this.Judge = "unknown"
            }
        },

        // 获取code
        async getCode() {
            let times = Date.now()
            this.randomStr = Math.ceil(Math.random() * 10)
            this.randomStrs = `${this.randomStr}${times}`
            let ImageData = await CaptchaImage({
                randomStr: this.randomStr,
                times: times,
            })
            const base64Image = btoa(
                new Uint8Array(ImageData).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ""
                )
            )
            this.imgCode = base64Image
        },

        LoginBtn() {
            if (
                this.UserName == "" &&
                this.PassWord == "" &&
                this.LoginCode == ""
            ) {
                Toast({
                    position: "bottom",
                    message: "账号不能为空",
                })
            } else if (this.PassWord == "") {
                Toast({
                    position: "bottom",
                    message: "密码不能为空",
                })
            } else if (this.LoginCode == "") {
                Toast({
                    position: "bottom",
                    message: "验证码不能为空",
                })
            } else {
                this.gologin()
            }
        },

        async gologin() {
            // 获取token
            let TokenData = await GetSeesion({
                workNo: this.UserName,
                Type: this.types,
            })

            if (TokenData.code != 1) {
                Toast({
                    position: "bottom",
                    message: TokenData.msg,
                })
            } else {
                // 将 工号 跟 tokens 存储在本地
                localStorage.setItem("tokens", TokenData.data.thirdSession)
                localStorage.setItem("workNo", TokenData.data.workNo)

                // 开成开登录
                let LoginData = await Login({
                    workNo: this.UserName,
                    passWord: this.PassWord,
                    code: this.LoginCode,
                    randomStr: this.randomStrs,
                    type: this.types,
                })

                if (LoginData.code == 1) {
                    if (LoginData.data.flag == "0000") {
                        Toast({
                            position: "bottom",
                            message: LoginData.data.message,
                        })
                        this.$router.push("/OpenCardMessage")
                    } else {
                        Toast({
                            position: "bottom",
                            message: LoginData.data.message,
                        })
                    }
                } else if (LoginData.code == -100) {
                    Toast({
                        message: LoginData.msg,
                        position: "bottom",
                    })
                    this.LoginCode = ""
                    this.getCode()
                } else {
                    Toast({
                        message: LoginData.msg,
                        position: "bottom",
                    })
                }
            }
        },

        // 强制刷新页面
        ForceRefresh() {
            if (location.href.indexOf("#reloaded") == -1) {
                location.href = location.href + "#reloaded"
                location.reload()
            }
        },
    },

    computed: {},

    mounted() { },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.path === "/login") {
                if (location.href.indexOf("#reloaded") == -1) {
                    location.href = location.href + "#reloaded"
                    location.reload()
                }
            }
        })
    },
}
</script>

<style scoped lang="scss">
.login {
    width: 100%;
    height: 100%;

    .logins {
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background: url(../../assets/images/login/login1.png) no-repeat;
        background-size: 100% 100%;

        .login_header {
            width: 100%;
            height: px2rem(340);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 60%;
                height: 40%;
            }
        }

        .login_boxs {
            width: 100%;
            height: px2rem(520);
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            margin-top: px2rem(50);

            .login_box {
                width: 86%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                background: #ffffff;
                border-top-left-radius: px2rem(20);
                border-top-right-radius: px2rem(20);
                border-bottom-right-radius: px2rem(20);
                border-bottom-left-radius: px2rem(20);
                box-shadow: 0 0 5px 1px #ccc;

                .login_box_tit {
                    font-size: px2rem(36);
                    color: #00008b;
                }

                .inp {
                    width: px2rem(530);
                    height: px2rem(90);
                    margin-top: px2rem(20);
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    font-size: px2rem(32);
                    border-bottom: px2rem(1) solid #f0f0f2;

                    i {
                        width: 8%;
                        height: 76%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 80%;
                            height: 60%;
                        }
                    }

                    input {
                        width: 82%;
                        height: 70%;
                        color: #000000;
                    }
                }

                .code {
                    width: px2rem(600);
                    height: px2rem(110);
                    border: none;

                    input {
                        width: 55%;
                        color: #000000;
                    }

                    i {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        img {
                            width: 70%;
                            height: 50%;
                        }
                    }

                    p {
                        width: 30%;
                        height: 80%;
                        background: #4683c6;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .btn {
            width: 100%;
            height: px2rem(70);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: px2rem(30);

            .van-button {
                width: 70%;
                height: 100%;
                font-size: px2rem(32);
                border-radius: px2rem(30);
                background: #4683c6;
            }
        }
    }

    .login_tu {
        width: 100%;
        height: 100%;
        background: url(../../assets/images/login/login5.png) no-repeat;
        background-size: 100% 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        p {
            margin-bottom: px2rem(300);
            color: #1497ef;
        }
    }

    .login_tus {
        width: 100%;
        height: 100%;
        background: url(../../assets/images/login/login6.png) no-repeat;
        background-size: 100% 70%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        p {
            margin-bottom: px2rem(300);
            color: #1497ef;
        }
    }
}
</style>
