<template>
    <div class="DxWithoutCodeLogin">
        <WithoutCodeLogin
            :UserNames="UserName"
            :PassWords="PassWord"
        ></WithoutCodeLogin>
    </div>
</template>

<script>
import WithoutCodeLogin from "../../components/openCard/WithoutCodeLoginPublic.vue"
export default {
    components: { WithoutCodeLogin },
    data() {
        return {
            // 武汉泰成江润通讯有限公司
            UserName: "WHTCZNTX", //测试工号 SHSHZY003  正式：WHTCZNTX
            PassWord: "Tc147258!", //测试密码  744810   正式密码：Hlsd2021!
        }
    },

    created() {},
    // 组件方法
    methods: {},

    computed: {},

    mounted() {},
}
</script> 

<style scoped lang='scss' >
</style>