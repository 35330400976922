<template>
    <div class="NoteRegister">
        <topHead>
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">短信意愿登记</h3>
        </topHead>

        <div class="min">

            <div class="industry_table" v-if="enRoute != ''">
                <h3>拒收进度</h3>
                <table>
                    <tr>
                        <th>操作类型</th>
                        <th>拒收类别</th>
                        <th>进度</th>
                        <th>详情</th>
                    </tr>
                    <tr v-for="(item, index) in enRoute" :key="index">
                        <td v-if="item.pickType == 'add'">登记</td>
                        <td v-if="item.pickType == 'delete'">取消登记</td>
                        <td v-if="item.value == 'hy'">行业</td>
                        <td v-if="item.value == 'sj'">手机号</td>
                        <td style="color: red;">处理中</td>
                        <td style="color: blue;" @click="detail(item, index)">详情</td>
                    </tr>
                </table>

                <van-overlay class="overlay_table" :show="tableShow" @click="tableShow = false">
                    <div class="phone_det">
                        <p v-if="scheduleType == 'sj'">拒收手机号详情 <span v-if="mark == 'add'">(登记)</span><span
                                v-if="mark == 'delete'">(取消登记)</span> </p>
                        <p v-if="scheduleType == 'hy'">拒收行业详情 <span v-if="mark == 'add'">(登记)</span><span
                                v-if="mark == 'delete'">(取消登记)</span></p>
                        <ul>
                            <li v-for="item, index in detailData" :key="index">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </van-overlay>
            </div>
            <!-- 拒收行业部分 -->
            <div class="select">
                <div class="fold" @click="fold">
                    <h3>拒收行业</h3>
                    <img v-if="checkbox_show" src="../../../assets/images/sjt.png" alt="">
                    <img v-else src="../../../assets/images/xjt.png" alt="">
                </div>

                <ul v-show="checkbox_show">
                    <li v-for="(item, index) in industry" :key="index" @change="checkedClick(item)">
                        <p class="checkbox">
                            <input type="checkbox" v-model="item.checkbox_type" :disabled="item.isDisabled">
                        </p>
                        <p class="select_title">{{ item.name }}</p>
                    </li>
                </ul>
            </div>
            <!-- 拒收手机号部分 -->
            <div class="phone_content">
                <h3>拒收号码</h3>
                <div class="inputbox">
                    <div class="inputbox_con">
                        <input type="text" v-model="inputValue" placeholder="请输入拒收手机号" />
                        <button @click="verify()">增加</button>
                    </div>
                    <div class="inputbox_tex_con" v-if="addShow">
                        <textarea @click.stop name="" id="" cols="30" rows="1" style="resize: none;"
                            v-model="addTextValue" readonly placeholder="新增号码"
                            onkeyup="this.value = this.value.replace(/[^\r\n0-9]/g,'');"></textarea>
                    </div>
                    <div class="inputbox_tex_con" v-if="delShow">
                        <textarea @click.stop name="" id="" cols="30" rows="1" style="resize: none;"
                            v-model="delTextValue" dreadonly placeholder="删除号码"
                            onkeyup="this.value = this.value.replace(/[^\r\n0-9]/g,'');"></textarea>
                    </div>
                </div>
                <div class="phone_table" v-if="success != ''">
                    <table>
                        <tr>
                            <th>id</th>
                            <th>拒收手机号</th>
                            <th>进度</th>
                            <th>操作</th>
                        </tr>
                        <tr v-for="(item, index) in success" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item }}</td>
                            <td style="color: green;">已拒收</td>
                            <td><button class="del" @click="del(item)">删除</button></td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="btn">
                <van-button round type="info" @click="submit()">提交</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../../common/api/api"
import topHead from "../../../components/topHead.vue"
import { Toast } from 'vant'
export default {
    components: {
        topHead
    },
    data() {
        return {
            checkbox_show: true,    // 折叠展示隐藏
            tableShow: false,   // 详情模态框展示隐藏
            addTextValue: "",   // 增加展示数据
            delTextValue: "",   // 删除展示数据
            inputValue: "",
            scheduleType: "", // 进度模态框类型
            serviceKind: "",  // 运营商号码区分 7：移动 8：联通
            mark: "",
            addShow: false,
            delShow: false,
            phone: this.$route.params.phone,
            enRoute: [],    // 进行中数据区分
            industry: [],   // 拒收行业数据
            success: [],    // 拒收成功手机号渲染数据
            phone_table: [],   // 已拒收成功数据
            detailData: [], //拒收进度详情
            addPhone: [],   //新增拒收手机号
            delPhone: [],   //删除拒收手机号

        }
    },

    created() {
        this.query()
    },
    // 组件方法
    methods: {
        // 查询行业类别及用户登记信息
        query() {
            API.getJson("QUERY_SMSREGISTER", { serviceid: this.phone }).then(res => {
                if (res.data) {
                    if (res.data.code == -100) {
                        this.$router.push("/BusinessHandle")
                        Toast({
                            message: "接口参数缺失！",
                            position: "bottom",
                            duration: 1000,
                        })
                    }
                } else {
                    this.industry = res.list
                    this.serviceKind = res.serviceKind
                    let val = res.success.registerType.split(",").map(item => item.trim())
                    this.enRoute_phone = res.NumProcessing
                    res.NumProcessing.forEach(item => {
                        item.value = "sj"
                        this.enRoute.push(item)
                    })
                    res.TypeProcessing.forEach(item => {
                        item.value = "hy"
                        this.enRoute.push(item)
                    })
                    res.success.registerNums.split(",").forEach(item => {
                        this.success.push(item)
                    })
                    this.judge(this.industry, val)
                }
            })
        },

        // 拒收行业已选中数据渲染
        judge(arr1, arr2) {
            arr1.forEach(function (item) {
                item.checkbox_type = false  //给每个值添加复选框状态
                item.isDisabled = false
                if (arr2.some(function (element) {
                    return element == item.name;
                })) {
                    item.checkbox_type = true;
                }
                if (item.checkbox_type) {
                    item.sign = "default"//给每个默认选中的值添加默认选中的标记"default"
                } else {
                    item.sign = ""//给每个值添加选中未选中标记
                }
            });
        },

        // 新增拒收手机号
        verify() {
            if (this.inputValue != "") {
                if (this.serviceKind == "7") {
                    // 移动
                    this.duplicateCheck()
                } else {
                    const regex = /^1[3-9]\d{9}$/;
                    if (this.inputValue.length != "11") {
                        this.duplicateCheck()
                    } else {
                        if (!(regex.test(this.inputValue))) {
                            console.log('手机号', this.inputValue)
                            let NotOne = this.inputValue.split(0, 1)
                            if (NotOne != "1") {
                                this.duplicateCheck()
                            } else {
                                Toast({
                                    message: "您的号码不可拒收11位的手机号码",
                                    position: "bottom",
                                })
                            }
                        } else {
                            Toast({
                                message: "您的号码不可拒收11位的手机号码",
                                position: "bottom",
                            })
                        }
                    }
                }
            } else {
                Toast({
                    message: "手机号不能为空",
                    position: "bottom",
                    duration: 800,
                })
            }

        },

        // 增加拒收号码重复号码校验
        duplicateCheck() {
            this.addShow = true
            if (this.addPhone.length > 0) {
                if (this.addPhone.find(item => item == this.inputValue)) {
                    Toast({
                        message: "已增加此号码，请重新添加号码！",
                        position: "bottom",
                        duration: 800,
                    })
                } else {
                    this.addPhone.push(this.inputValue)
                }
            } else {
                this.addPhone.push(this.inputValue)
            }
            this.inputValue = ""
        },

        // 拒收手机号删除
        del(val) {
            this.delShow = true
            if (this.delPhone.length > 0) {
                this.delPhone.forEach(item => {
                    if (this.delPhone.find(item => item == val)) {
                        Toast({
                            message: "已增加此号码，请重新添加号码！",
                            position: "bottom",
                            duration: 800,
                        })
                    } else {
                        this.delPhone.push(val)
                    }
                })
            } else {
                this.delPhone.push(val)
            }
        },

        // 提交
        submit() {
            let add = []
            let del = []
            this.industry.forEach(item => {
                if (item.check == "checked") {
                    add.push(item.code)
                } else if (item.check == "cancel") {
                    del.push(item.code)
                }
            })
            let addType = add.join()
            let delType = del.join()

            API.getJson('CHANGESMS', {
                addType: addType,
                addNum: this.addTextValue,
                delType: delType,
                delNum: this.delTextValue,
                serviceid: this.phone,
            }).then(res => {
                if (res.code == "1") {
                    this.$router.push("/BusinessHandle")
                    Toast({
                        message: "提交成功，请稍后进行查询！",
                        position: "bottom",
                        duration: 1000,
                    })
                    // window.location.reload(); // 刷新页面
                }
            })

        },

        // 点击复选框改变状态
        checkedClick(a) {
            if (a.sign == "default") {
                // 默认选中的
                if (a.checkbox_type == false) {
                    a.check = "cancel"
                } else {
                    a.check = "nochange"
                }
            } else {
                if (a.checkbox_type) {
                    a.check = "checked"
                } else {
                    a.check = "nochange"
                }
            }

        },

        // 折叠
        fold() {
            this.checkbox_show = !this.checkbox_show
        },

        // 短信意愿订购返回
        backBefor() {
            this.$router.push("/BusinessHandle")
        },
        // 详情
        detail(item) {
            this.scheduleType = item.value
            this.mark = item.pickType
            this.tableShow = true
            if (item.value == "sj") {
                this.detailData = item.registerNums.split(",")
            } else {
                this.detailData = item.RegisterType.split(",")
            }
        },
    },

    computed: {},

    watch: {
        addPhone: {
            deep: true,
            handler(newVal, oldVal) {
                this.addTextValue = newVal.join()
            }
        },

        delPhone: {
            deep: true,
            handler(newVal, oldVal) {
                this.delTextValue = newVal.join()
            }
        }
    },

    mounted() { },
}
</script>

<style scoped lang='scss'>
.NoteRegister {
    height: 100%;
    padding-top: px2rem(88);
    background: #f8f8f8;

    .topHead {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        background: #fff;
        z-index: 99;
    }

    .min {
        height: 100%;
        background: #ffffff;

        .industry_table {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: px2rem(320);
            font-size: px2rem(28);
            padding: 0 px2rem(20);



            table {
                width: 100%;
                border: px2rem(1) solid;
                border: 0;
                border-collapse: collapse;
                border-style: solid;



                tr:hover {
                    background-color: #F0FFFF;
                }

                th,
                td {
                    width: 25%;
                    height: px2rem(50);
                    text-align: center;
                    line-height: normal;
                    border: px2rem(1) solid;
                    border-style: solid;


                    p {
                        width: px2rem(200);
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .overlay_table {
                display: flex;
                justify-content: center;
                align-items: center;


                .phone_det {
                    width: 80%;
                    background: #ffffff;
                    padding: px2rem(20);
                    border-radius: px2rem(20);

                    p {
                        width: 100%;
                        height: px2rem(40);
                        display: flex;
                        align-items: center;
                        font-weight: 600;

                        span {
                            color: red;
                        }
                    }

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;

                        li {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: px2rem(20) px2rem(20);
                            margin-top: px2rem(20);
                            margin-left: px2rem(40);
                            text-align: center;
                            border: px2rem(1) solid #aaa;
                            border-radius: px2rem(20);
                        }
                    }
                }
            }
        }

        h3 {
            font-size: px2rem(32);
            padding-left: px2rem(20);
        }

        .select {
            margin-top: px2rem(30);

            .fold {
                height: px2rem(80);
                display: flex;
                justify-content: flex-start;
                align-items: center;

                img {
                    width: px2rem(32);
                    height: px2rem(32);
                    margin-left: px2rem(30);
                }
            }

            ul {
                margin-top: px2rem(18);

                li {
                    height: px2rem(60);
                    border-bottom: px2rem(1) solid #e5e5e5;
                    display: flex;
                    justify-content: space-around;

                    .checkbox {
                        width: 18%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        input {
                            width: px2rem(30);
                            height: px2rem(30);
                        }


                    }

                    .select_title {
                        width: 82%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font-size: px2rem(28);
                    }
                }
            }
        }

        .phone_content {
            width: 100%;
            background: #ffffff;
            margin-top: px2rem(20);

            .inputbox {
                width: 100%;
                font-size: px2rem(28);
                padding: px2rem(0) px2rem(22);
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                .inputbox_con {
                    width: 100%;
                    padding: 0 px2rem(20);
                    display: flex;
                    align-items: center;
                    margin: px2rem(20) 0;

                    input {
                        width: 80%;
                        height: px2rem(50);
                        border: 1px solid;
                        padding-left: px2rem(22);
                    }

                    button {
                        width: 20%;
                        height: px2rem(50);
                        border: 1px solid;
                        color: #fff;
                        font-size: px2rem(28);
                        background-color: #336289;
                    }
                }



                .inputbox_tex_con {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin: px2rem(10) 0;

                    textarea {
                        width: 100%;
                        height: px2rem(110);
                        border: 1px solid;
                        padding: px2rem(6);
                    }
                }




                // .overlay_inp {
                //     width: 100%;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;


                //     .textArea {
                //         width: 80%;
                //         height: 50%;
                //         display: flex;
                //         flex-direction: column;
                //         align-items: center;
                //         justify-content: space-between;
                //         background: #ffffff;

                //         button {
                //             display: flex;
                //             justify-content: center;
                //             align-items: center;
                //             width: 100%;
                //             height: 16%;
                //             color: #fff;
                //             font-size: px2rem(28);
                //             background-color: #336289;
                //         }



                //         textarea {
                //             width: 100%;
                //             height: 84%;
                //             border: px2rem(1) solid;
                //             font-size: px2rem(28);
                //             padding: px2rem(20);
                //         }
                //     }
                // }
            }

            .phone_table {
                width: 100%;
                margin-top: px2rem(10);
                font-size: px2rem(28);
                padding: 0 px2rem(20);

                table {
                    width: 100%;
                    border: px2rem(1) solid;
                    border: 0;
                    border-collapse: collapse;
                    border-style: solid;


                    tr:hover {
                        background-color: #F0FFFF;
                    }

                    th,
                    td {
                        width: 25%;
                        height: px2rem(50);
                        text-align: center;
                        line-height: normal;
                        border: px2rem(1) solid;
                        border-style: solid;
                    }

                    .del {
                        width: px2rem(60);
                        height: px2rem(36);
                        background: red;
                        font-size: px2rem(24);
                        color: #ffffff;
                        border-radius: px2rem(10);
                    }
                }
            }

        }

        .btn {
            width: 100%;
            height: px2rem(120);
            margin: px2rem(30) 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .van-button {
                display: flex;
                justify-content: center;
                width: px2rem(550);
                height: px2rem(80);
                color: #fff;
                font-weight: bold;
                font-size: px2rem(28);
                background-color: #336289;
            }
        }
    }
}
</style>