import request from './request'

// 获取图形验证码
export function CaptchaImage(data) {
    return request({
        url: `/api/gzh/captchaImage?randomStr=${data.randomStr}${data.times}`,
        method: 'GET',
        responseType: 'arraybuffer' // 指定响应类型为arraybuffer
    })
}


// 获取token
export function GetSeesion(data) {
    return request({
        url: '/api/gzh/getSeesion',
        method: 'POST',
        data: {
            workNo: data.workNo,
            Type: data.Type
        }

    })
}
// 开卡登录
export function Login(data) {
    return request({
        url: '/api/gzh/login',
        method: 'POST',
        data: data
    })
}


// 工号登录信息查询
export function Checkworknoinfo(data) {
    return request({
        url: '/api/gzh/checkworknoinfo',
        method: 'POST',
        data: data
    })
}

// 号卡查询接口
export function SimOrServiceIdQuery(data) {
    return request({
        url: '/api/gzh/simOrServiceIdQuery',
        method: 'POST',
        data: data
    })
}

// 查询开卡记录
export function QueryOpenCardLocation(data) {
    console.log("查询开卡记录的数据", data)
    return request({
        url: '/api/gzh/queryOpenCardLocation',
        method: 'POST',
        data: data
    })
}

//卡号后七位校验
export function CheckIccid(data) {
    return request({
        url: '/api/gzh/checkIccid',
        method: 'POST',
        data: data
    })
}

//号卡有效性验证
export function CheckPhoneValid(data) {
    return request({
        url: '/api/gzh/checkPhoneValid',
        method: 'POST',
        data: data
    })
}

//渠道校验
export function ChanelCheck(data) {
    return request({
        url: '/api/gzh/chanelCheck',
        method: 'POST',
        data: data
    })
}

//激活期和冻结
export function AppCommonCheck(data) {
    return request({
        url: '/api/gzh/appCommonCheck',
        method: 'POST',
        data: data
    })
}

//未激活号码套餐展示
export function Checkoffername(data) {
    return request({
        url: '/api/gzh/checkoffername',
        method: 'POST',
        data: data
    })
}


//新增开卡记录
export function AddOpenCardLocation(data) {
    console.log("新增开卡记录的数据", data)
    return request({
        url: '/api/gzh/addOpenCardLocation',
        method: 'POST',
        data: data
    })
}


//上传合拍接口
export function SignatureUpload(data) {
    return request({
        url: '/api/gzh/signatureUpload',
        method: 'POST',
        data: data
    })
}

//上传签名
export function UploadCard(data) {
    return request({
        url: '/api/gzh/uploadCard',
        method: 'POST',
        data: data
    })
}


// 获取实名认证链接
export function GetUrl() {
    return request({
        url: '/api/gzh/getUrl',
        method: 'GET',
    })
}

// 获取实名认证验证信息
export function GetSimpleOrCardNum(data) {
    return request({
        url: '/api/gzh/getSimpleOrCardNum',
        method: 'POST',
        data: data
    })
}
// 一证五户校验
export function DoCheckIdentityCodeByWorkNo(data) {
    return request({
        url: '/api/gzh/doCheckIdentityCodeByWorkNo',
        method: 'POST',
        data: data
    })
}

// 提交加密
export function QuerySign(data) {
    return request({
        url: '/api/gzh/querySign',
        method: 'POST',
        data: data
    })
}

// 开成卡提交
export function Dosubmits(data) {
    console.log("提交的数据", data)
    return request({
        url: '/api/gzh/dosubmit',
        method: 'POST',
        data: data
    })
}

//  获取号码支付方式
export function PayKind(data) {
    return request({
        url: `api/gzh/getPayKind?phoneNumber=${data.phoneNumber}`,
        method: 'GET',
    })
}

//  资金池支付
export function FundPoolRecharge(data) {
    return request({
        url: 'api/gzh/fundPoolRecharge',
        method: 'POST',
        data: data
    })
}

//  获取微信支付链接
export function WxPay(data) {
    return request({
        url: 'api/gzh/wxPay',
        method: 'POST',
        data: data
    })
}


// 微信支付查询
export function QueryOrderByOutTradeNo(data) {
    return request({
        url: `/api/gzh/queryOrderByOutTradeNo?outTradeNo=${data}`,
        method: 'GET',
    })
}

//  获取支付宝支付链接
export function AliPay(data) {
    return request({
        url: '/api/gzh/aliPay',
        method: 'POST',
        data: data
    })
}

// 支付订单查询
export function QueryAliPay(data) {
    return request({
        url: '/api/gzh/queryAliPay',
        method: 'POST',
        data: data
    })
}