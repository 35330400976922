<template>
    <div class="YyWithoutCodeLogin">
        <WithoutCodeLogin
            :UserNames="UserName"
            :PassWords="PassWord"
        ></WithoutCodeLogin>
    </div>
</template>

<script>
import WithoutCodeLogin from "../../components/openCard/WithoutCodeLoginPublic.vue"
export default {
    components: { WithoutCodeLogin },
    data() {
        return {
            // 蓝猫企信通
            UserName: "LMQXTZYMD", //测试工号 SHSHZY003  正式：LMQXTZYMD
            PassWord: "Hlsd2022!", //测试密码  744810   正式密码：Hlsd2021!
        }
    },

    created() {},
    // 组件方法
    methods: {},

    computed: {},

    mounted() {},
}
</script> 

<style scoped lang='scss' >
</style>