<template>
    <div class="UserAgreement">
        <div class="u-p-20">
            <!-- 导航栏 -->
            <topHead>
                <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
                <h3 slot="header">用户服务协议</h3>
            </topHead>
            <div class="con">
                <div>
                    &ensp;&ensp;&ensp;&ensp;北京互联时代移动通信有限公司根据工信部针对非应邀商业电子信息防护能力提升的要求，结合基础运营商能力，推出“短信免打扰”业务。“短信免打扰”是通过利用技术手段依据用户登记意愿提供相应短信屏蔽防护服务，为被叫用户提供个性化短信接收意愿登记服务。“短信免打扰”是尊重用户意愿，引导营销短信规范发送的一项重要措施。
                </div>
                <div><br></div>
                <div>本业务向用户提供意愿登记服务，记录用户的手机号码，选择接收意愿，并提供修改和撤销登记的方式。可按短信类别、单号码接收意愿，分类为用户提供短信屏蔽等防护服务。</div>
                <div><br></div>
                <div>
                    本业务系北京互联时代移动通信有限公司(以下简称“蓝猫移动”或“乙方”)所有并负责运营。在登录、使用本业务之前，您应当仔细阅读、充分理解《用户服务协议》(以下简称“本协议”)的全部内容，特别是本协议中以加粗字体显示的内容，您应当重点阅读。如果您不同意本协议或其中任何条款，请您立即停止登录、使用本业务。您开始登录、使用本业务包括微信公众号、客户端及网页形式后，即表明您已同意本协议内容，本协议即构成对用户和互联时代(合称“双方”)有约束力的法律文件。
                </div>
                <div><br></div>
                <div>重要提示：</div>
                <div><br></div>
                <div>1 、甲方应具有完全的民事行为能力。无民事行为能力或限制民事行为能力的甲方入网时应经过其监护人的同意并签名确认。</div>
                <div><br></div>
                <div>
                    2 、在签署本协议之前，请甲方或其监护人、代理人仔细阅读本协议各条款，如有疑问请及时咨询。一旦签署本协议，即意味甲方或其监护人、代理人接受并认可协议内容，愿意遵守执行。甲方所需的业务、办理手续以及资费标准请参见乙方的相关业务说明。
                </div>
                <div><br></div>
                <div>第一条 定义</div>
                <div><br></div>
                <div>1.1本业务:是指由北京市互联时代移动通信有限公司运营中心所有并负责运营的“短信免打扰。</div>
                <div><br></div>
                <div>
                    1.2所有权以及相关的权利在本协议中是指:第一，所有权；第二，知识产权，包括但不限于著作权、专利权、商标权、商业秘密；第三，除上述权利之外的对物、信息及其载体和表现形式的使用、利用、传播、复制、发行、编辑、修改、处分权利。
                </div>
                <div><br></div>
                <div>1.3用户(甲方):是指接受本业务服务的自然人、法人或其他组织。用户包括使用蓝猫移动手机号码和使用其他手机号码登录的用户(以下简称“蓝猫移动”用户);以及未登录使用本业务的用户。</div>
                <div><br></div>
                <div>1.4访问互联网:当您使用微信公众号、手机客户端、网页业务形态开通业务、进行设置、查看记录时；需手机连接移动通信网络或WLAN网络。</div>
                <div><br></div>
                <div>第二条 服务内容</div>
                <div><br></div>
                <div>2.1服务内容及范围</div>
                <div><br></div>
                <div>(1)短信免打扰服务当前面向蓝猫移动用户开放注册。短信拒接业务是依据用户登记意愿，利用技术手段分类为用户提供短信屏蔽等防侵扰服务。</div>
                <div><br></div>
                <div>
                    (2)用户可以进行短信拦截设置，决定开启或关闭金融推销、零售业推销、电信业务宣传、网络游戏推广、教育培训推销、房产中介推销、股票证券推销、医疗卫生推销、猎头招聘推广、旅游推广以及其他营销类别拦截，同时可针对单个号码拦截。
                </div>
                <div><br></div>
                <div>
                    2.2本业务有权根据情况变化调整服务的具体内容，本业务服务内容进行调整的，本业务无需另行通知用户，调整后的内容一经发布即自动生效。如用户不同意上述调整，应当立即停止使用服务；如用户继续使用服务的，视为用户同意调整后的内容并同意遵守。
                </div>
                <div><br></div>
                <div>2.3短信免打扰服务使用条款</div>
                <div><br></div>
                <div>(1)用户使用短信免打扰可选择订购相应增值服务，蓝猫移动不会对用户登记行为收取任何费用。</div>
                <div><br></div>
                <div>(2)如果用户停止使用本服务，短信免打扰可以从服务器上永久地删除用户数据。本服务停止、终止后，短信免打扰没有义务向用户返还任何数据。</div>
                <div><br></div>
                <div>(3)用户在使用本服务的微信公众号用户服务界面时，将使用网络接入服务，用户须自行支付网络通信费用，如手机上网产生的流量费用将视您的流量资费套餐标准，由运营商收取。</div>
                <div><br></div>
                <div>2.4用户使用本业务提供的具体服务时，应当满足相应的前提条件、具备相应的服务使用权限。用户不满足使用服务的前提条件，不具备服务使用权限的，无法使用相应服务。</div>
                <div><br></div>
                <div>第三条 用户及用户账号管理</div>
                <div><br></div>
                <div>
                    3.1用户确认，用户实际使用本业务服务时，应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。如用户不具备前述主体资格，则用户及用户的监护人应当依照法律规定承担因此而导致的一切后果，蓝猫移动有权暂停或终止提供服务。
                </div>
                <div><br></div>
                <div>3.2用户应当按照本业务要求使用手机号码绑定蓝猫移动微信公众号登录并使用本业务。</div>
                <div><br></div>
                <div>3.3用户通过蓝猫移动微信公众号订购并使用本业务的行为，均视为用户本人行为，用户应当承担由此导致的相关后果和责任。</div>
                <div><br></div>
                <div>第四条 用户的权利和义务</div>
                <div><br></div>
                <div>
                    4.1用户使用本协议项下服务，应当遵守《中华人民共和国网络安全法》、《中华人民共和国电信条例》及其他有关法律、法规或相关规定、以及本协议的约定，不得存在任何违法违规行为，不得侵犯蓝猫移动以及任何第三方的合法权益。
                </div>
                <div><br></div>
                <div>4.3用户承诺使用服务应符合国家法律法规的规定，且行为符合下列要求：</div>
                <div><br></div>
                <div>(1)不干扰本业务的正常运转，不进行任何破坏或试图破坏网络安全的行为，不进行任何改变或试图改变本业务系统配置或破坏系统安全的行为，不得侵入本公众号及国家计算机信息系统。</div>
                <div><br></div>
                <div>(2)不对本业务展示或提供的。由蓝猫移动所有的任何数据或信息作商业性利用，包括在未经蓝猫移动事先书面同意的情况下，以复制、传播任何方式使用本公众号展示或提供的、由蓝猫移动所有的资料、信息、数据。
                </div>
                <div><br></div>
                <div>(3)不模仿、修改、翻译、改编、出借、出售、转许可、传播或转让本业务提供的服务，也不得逆向工程、反汇编、反编译、分解拆卸或试图以其他方式发现本业务提供的服务的源代码。</div>
                <div><br></div>
                <div>(4)不发送、上传和储存带有病毒的、蠕虫的、木马和其他有害内容的计算机代码、文件、脚本和程序。</div>
                <div><br></div>
                <div>(5)遵守所有使用网络服务的网络协议、规定、程序。</div>
                <div><br></div>
                <div>(6)不从事其他违反法律法规规定或双方约定的行为。</div>
                <div><br></div>
                <div>
                    4.4用户(甲方)不得利用乙方通信资源、电信服务等制作、复制、发布、传播含有以下内容的信息或从事下列违法违规、违背公序良俗的活动，否则乙方有权暂停或限制向甲方提供服务直至终止。由此产生的后果，乙方不承担责任。
                </div>
                <div><br></div>
                <div>(1)反对宪法所确定的基本原则，破坏国家宗教政策，宣扬邪教和封建迷信的。</div>
                <div><br></div>
                <div>(2)危害国家安全、荣誉和利益，泄露国家秘密，颠覆国家破坏国家统一的。</div>
                <div><br></div>
                <div>(3)宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的。</div>
                <div><br></div>
                <div>(4)散布虚假信息、诈骗信息、谣言，扰乱经济秩序和社会破坏社会稳定秩序的。</div>
                <div><br></div>
                <div>(5)散布涉及实施诈骗，制作或销售违禁物品、管制物品淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的。</div>
                <div><br></div>
                <div>(6)侮辱或诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的。</div>
                <div><br></div>
                <div>(7)发送违法信息、拨打诈骗电话，未经接收方同意或请求、或接收方明确表示拒绝的情形下发送骚扰信息或商业性信息拨打骚扰话音或商业性话音的。</div>
                <div><br></div>
                <div>(8)其他利用乙方通信资源、电信服务等制作、复制、发布、传播违法犯罪信息或进行违法活动的行为。</div>
                <div><br></div>
                <div>
                    4.5若用户存在违反法律规定或本协议约定的任一情形，本业务有权按相关规定暂停或终止提供服务，保存有关记录，并向政府主管部门报告，由此引起的一切后果和责任由用户负责。同时蓝猫移动有权终止本协议，并不承担任何责任。
                </div>
                <div><br></div>
                <div>4.6用户违反本协议使用网络服务导致蓝猫移动或第三人损失的，应当承担相应法律责任、并赔偿相应的损失。</div>
                <div><br></div>
                <div>第五条 (乙方)本业务的权利和义务</div>
                <div><br></div>
                <div>5.1任何本业务所发布的信息及/或其载体，及/或其表现形式及/或服务，除以下情况之外，其所有权及相关的权利均独占性地属于蓝猫移动及/或关联公司所有:</div>
                <div><br></div>
                <div>(1)依据相关法律规定该信息及/或载体，及/或服务，不享有所有权以及与此相关的权利的;</div>
                <div><br></div>
                <div>(2)依据蓝猫移动和其他主体之间的协议、合同、章程，由蓝猫移动和其他主体共享，或其他主体单独享有或蓝猫移动转让的;</div>
                <div><br></div>
                <div>(3)依据蓝猫移动具有法律效力的正式声明而放弃的。</div>
                <div><br></div>
                <div>
                    5.2非经蓝猫移动及/或授权其使用的第三方权利人书面同意，用户不得擅自使用本业务内容，且无权擅自复制、修改这些内容，或创造与内容有关的派生产品。任何对本公众号享有的基于该信息及/或其载体，及/或其表现形式，及/或服务的权利的侵犯，均属于对蓝猫移动权利的侵犯。
                </div>
                <div><br></div>
                <div>第六条 用户个人信息保护</div>
                <div><br></div>
                <div>
                    6.1乙方非常重视用户个人信息的保护，乙方通过本业务向用户提供服务时，将按照相关法律法规的规定收集、存储、使用、共享、转移、公开披露、保护和管理用户信息和隐私。本业务的隐私政策具体见《用户隐私政策》，用户同意仔细阅读并充分理解和接受该隐私政策，并同意该隐私政策作为本协议的重要组成部分。
                </div>
                <div><br></div>
                <div>6.2乙方严格按照《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《中华人民共和国反电信网络诈骗法》《电信和互联网用户个人信息保护规定》(工业和信息化部令第 24
                    号)等法律法规的相关要求，在业务活动(包括但不限于业务受理系统登记、通过网络接收、读取并记录、身份验证、客户服务、安全防范、诈骗监测、邀请甲方参与有关乙方产品和服务的调查)中处理甲方信息。为提供更为精准优质的服务，乙方可以依法对包含甲方在内的整体用户数据进行分析并加以利用。乙方承诺对甲方信息依法负有保密义务，不得泄露、纂改或毁损，不得非法出售或非法向他人提供，并采取符合业界标准、合理可行的安全防护措施保护甲方的个人信息，防止甲方的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。
                </div>
                <div><br></div>
                <div>6.3为遵守相关法律法规或者行政机关、监管机构等有权部门依法提出的要求以及基于诉讼/仲裁需要，乙方有权披露甲方的信息。</div>
                <div><br></div>
                <div>第七条 违约责任</div>
                <div><br></div>
                <div>7.1双方应共同遵守本协议约定，如一方违约给对方造成损失的，违约方应赔偿守约方的损失。</div>
                <div><br></div>
                <div>7.2除双方另有约定外，用户违反本协议中用户应履行的各项义务、承诺、保证的任意内容，蓝猫移动均有权就其违约情节，尤其是对蓝猫移动造成的损失，随时采取以下措施：</div>
                <div><br></div>
                <div>(1)要求用户立即更换、修改相应内容；</div>
                <div><br></div>
                <div>(2)限制、中止用户使用相应服务；</div>
                <div><br></div>
                <div>(3)终止用户使用全部服务，终止本协议；</div>
                <div><br></div>
                <div>(4)依法追究用户的法律责任。</div>
                <div><br></div>
                <div>第八条 服务的停止</div>
                <div><br></div>
                <div>8.1在以下情形下，本业务有权停止对用户提供服务，且无需通知用户或取得用户同意:</div>
                <div><br></div>
                <div>(1)当该用户所对应的手机号码停机或销号后;</div>
                <div><br></div>
                <div>(2)用户违反本协议规定使用服务;</div>
                <div><br></div>
                <div>(3)用户注销了自己的账号。</div>
                <div><br></div>
                <div>8.2用户不同意本协议的内容及蓝猫移动对本协议的后续修改应当停止使用本业务的服务。用户如对本业务的服务不满意，可以停止使用本业务的服务，或通知本业务停止对该用户的服务。</div>
                <div><br></div>
                <div>8.3用户服务停止后，本业务没有义务向该用户或第三方传送任何未处理的信息或未完成的服务，亦无需对该用户或第三方负责。</div>
                <div><br></div>
                <div>第九条 协议范围及协议变更</div>
                <div><br></div>
                <div>9.1服务协议的修改</div>
                <div><br></div>
                <div>
                    根据国家法律法规变化及网络运营需要，蓝猫移动有权对本协议条款不时地进行修改，修改后的协议一旦被使用在蓝猫移动上即表示生效，并代替原来的协议。用户可随时登陆查阅最新协议；用户有义务不时关注并阅读最新版的协议及蓝猫移动公告。如用户不同意更新后的协议，可以且应立即停止接受蓝猫移动依据本协议提供的服务；如用户继续使用蓝猫移动提供的服务，即视为同意更新后的协议。蓝猫移动建议您在使用服务之前阅读本协议及相关公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
                </div>
                <div><br></div>
                <div>第十条 法律适用与争议解决</div>
                <div><br></div>
                <div>
                    10.1本协议之效力、解释、变更、履行与争议解决均适用中华人民共和国(为本协议之目的，不含香港、澳门、台湾地区)法律法规。本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方均具有约束力。
                </div>
                <div><br></div>
                <div>10.2本协议签订地为中华人民共和国北京市朝阳区</div>
                <div><br></div>
                <div>10.3因双方就本协议的签订、履行或解释发生争议，双方应持平等、友好、争取合理解决问题的态度协商解决；如协商未成，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</div>
                <div><br></div>
                <div>10.4有关乙方提供本协议项下服务适用的个人信息处理规则，见《中国电信个人信息保护政策》。《中国电信个人信息保护政策》与本协议条款冲突的部分以《中国电信个人信息保护政策》为准。</div>
                <div><br></div>
                <div>本版本发布日期：2024年06月13日</div>
                <div><br></div>
            </div>
        </div>
    </div>
</template>

<script>
import topHead from "../../../components/topHead.vue"
export default {
    components: { topHead },
    data() {
        return {}
    },

    created() { },
    // 组件方法
    methods: {
        backBefor() {
            this.$router.push("/WillRegister")
        }
    },

    computed: {},

    watch: {},

    mounted() { },
}
</script>

<style scoped lang='scss'>
.UserAgreement {
    font-size: px2rem(26);

    .con {
        padding: px2rem(20);
        line-height: px2rem(40);
    }

    .topHead {
        position: sticky;
        /* 新属性sticky */
        top: 0;
        background: #fff;
    }
}
</style>