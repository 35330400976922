import request from './request'
import env from './env'

// 获取手机号
export function GetPhoneById(data) {
    return request({
        url: '/api/wechat/getPhoneById',
        method: 'POST',
        data: {
            id: data.id,

        }
    })
}

// 新过户发起
export function NewTransAcctT(data) {
    return request({
        url: '/api/wechat/newTransAcctT',
        method: 'POST',
        data: {
            serviceId: data.phone,
            returnUrl: `${env.prod.baseUrl}${data.url}`
            // returnUrl: `http://localhost:8080/realNameLinkForm`
        }
    })
}

// 检测结果
export function NewGetImgPath(data) {
    return request({
        url: '/api/wechat/newGetImgPath',
        method: 'POST',
        data: {
            biz_no: data.biz_no,
        }
    })
}

// 提交
export function NewTransAcctTForm(data) {
    return request({
        url: '/api/wechat/newTransAcctTForm',
        method: 'POST',
        data: {
            biz_no: data.biz_no,
            sparePhone: data.sparePhone,
            id: data.id
        }
    })
}