<template>
    <div class="record">
        <!-- 导航栏 -->
        <topHead><h3 slot="header">充值记录</h3></topHead>
        <!-- 内容 -->
        <div class="main">
            <ul class="record_list">
                <li v-for="(item,index) in list" :key="index">
                    <div class="record_left">
                        <div class="record_time">{{item.date}}</div>
                        <div class="record_type">
                            {{item.mobilePhone}}<span>{{item.title}}</span>
                        </div>
                    </div>
                    <div class="record_right">
                        <div class="record_state fail" v-if="item.status == 0">未支付</div>
                        <div class="record_state middle" v-if="item.status == 1">充值中</div>
                        <div class="record_state" v-if="item.status == 2">充值成功</div>
                        <div class="record_state fail" v-if="item.status == 3">充值失败退款中</div>
                        <div class="record_state fail" v-if="item.status == 4">充值失败已退款</div>
                        <div class="record_money">￥{{item.amount}}</div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="notData" v-if="list==''">
            <van-empty description="暂无充值记录" />
        </div>
    </div>
</template>

<script>
import API from "../../common/api/api"
import topHead from "../../components/topHead.vue"
export default {
    name: "record",
    data() {
        return {
          list:[]
        }
    },
    mounted() {
      this.record()
    },
    methods: {
        record() {
            let token = localStorage.getItem("token")
            API.getJson("RECHARGE_RECORD", {
                token: token,
            }).then(res => {
                this.list = res.list
            })
        },
    },
    components: {
        topHead,
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: px2rem(20) px2rem(30) 0;
}
.record_list li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: px2rem(148);
    background-color: #f5f5f5;
    border-radius: px2rem(20);
    margin-bottom: px2rem(30);
    padding: px2rem(33) px2rem(35) 0;
    box-sizing: border-box;
}
.record_time {
    font-size: px2rem(24);
    color: #666666;
    margin-bottom: px2rem(30);
}
.record_type {
    font-size: px2rem(26);
    color: #333333;
}
.record_type span {
    margin-left: px2rem(20);
}
.record_right {
    text-align: right;
}
.record_state {
    font-size: px2rem(24);
    color: #d04040;
    font-weight: bold;
    margin-bottom: px2rem(30);
}
.record_money {
    font-size: px2rem(26);
    color: #ff7505;
}
.fail {
    color: #666666;
}
.middle {
    color: #336289;
}
</style>