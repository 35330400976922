import Vue from 'vue'
import VueRouter from 'vue-router'


//营业厅
import Hall from '../views/Hall.vue'
// 查询类
import queryCard from '../views/query/queryCard.vue'
import card from '../views/query/card.vue'
import record from '../views/query/record.vue'
import setMeal from '../views/query/setMeal.vue'
import orderbusine from '../views/query/orderbusine.vue'
import balance from '../views/query/balance.vue'
import historyBill from '../views/query/historyBill.vue'
import bill from '../views/query/bill.vue'
import billDetails from '../views/query/billDetails.vue'
// 开发票
import billing from '../views/handle/Invoice/billing.vue'
import Invoice from '../views/handle/Invoice/Invoice.vue'
import InvoiceSuccess from '../views/handle/Invoice/InvoiceSuccess.vue'
import state from '../views/handle/Invoice/state.vue'
// 开发票新增
import invoiceVerify from '../views/handle/Invoice/invoiceVerify.vue'
import invoiceList from '../views/handle/Invoice/invoiceList.vue'
import submitInvoice from '../views/handle/Invoice/submitInvoice.vue'
import invoiceDetailPage from '../views/handle/Invoice/invoiceDetailPage.vue'
import addsite from '../views/handle/Invoice/addsite.vue'

//实名认证
import realName from '../views/handle/realName/realName.vue'
import realResult from '../views/handle/realName/realResult.vue'
import mp from "../views/Test/mp.vue"
//-------------------------已废弃-------------------------
//来电显示 
// import caller from '../views/handle/Caller/caller.vue'
// import openCaller from '../views/handle/Caller/openCaller.vue'
// import openSuccess from '../views/handle/Caller/openSuccess.vue'
//漏电提醒
// import remind from '../views/handle/remind/remind.vue'
import openRemindSuccess from '../views/handle/remind/openSuccess.vue'
import quitRemindSuccess from '../views/handle/remind/quitRemindSuccess.vue'
// import openRemind from '../views/handle/remind/openRemind.vue'
//---------------------------------------------------------

// 销户申请
import cancel from '../views/handle/cancel/cancel.vue'
import auditCabcel from '../views/handle/cancel/auditCabcel.vue'
import auditFail from '../views/handle/cancel/auditFail.vue'
import cancelForm from '../views/handle/cancel/cancelForm.vue'

//更改套餐
import Choosepackage from '../views/handle/ChoosePackage/ChoosePackage.vue'
import PackageDetails from '../views/handle/ChoosePackage/PackageDetails.vue'
import ChangeSucces from '../views/handle/ChoosePackage/ChangeSucces.vue'
import verification from '../views/handle/ChoosePackage/verification.vue'

//意愿登记
import WillRegister from '../views/handle/willregister/willregisterorder.vue'
import NoteRegister from '../views/handle/willregister/noteregister.vue'
import SelectFunction from '../views/handle/willregister/SelectFunction.vue'
import RejectionBusiness from '../views/handle/willregister/RejectionBusiness.vue'
import RejectNumber from '../views/handle/willregister/RejectNumber.vue'
import CustomerService from '../views/handle/willregister/CustomerService.vue'
import UserAgreement from '../views/handle/willregister/UserAgreement.vue'

// 过户发起
import transAcctS from '../views/handle/transAcctS/transAcctS.vue'
import transAcctSauditCabcel from '../views/handle/transAcctS/transAcctSauditCabcel.vue'
import transAcctSauditFail from '../views/handle/transAcctS/transAcctSauditFail.vue'
import transAcctSForm from '../views/handle/transAcctS/transAcctSForm.vue'
// 过户接收
import transAcctT from '../views/handle/transAcctT/transAcctT.vue'
import transAcctTauditCabcel from '../views/handle/transAcctT/transAcctTauditCabcel.vue'
import transAcctTauditFail from '../views/handle/transAcctT/transAcctTauditFail.vue'
import transAcctTForm from '../views/handle/transAcctT/transAcctTForm.vue'

// 实名补录 
import realNameLink from '../views/realNameLink/realNameLink.vue'
import realNameLinkForm from '../views/realNameLink/realNameLinkForm.vue'
import realNameLinkSuccess from '../views/realNameLink/realNameLinkSuccess.vue'
import realNameLinkError from '../views/realNameLink/realNameLinkError.vue'


// // 订购呼转
// import callDivert from '../views/handle/calldivert/calldivert.vue'
// import calldivertOrder from '../views/handle/calldivert/calldivertOrder.vue'

// // 订购权益
// import Appreciation from '../views/handle/Appreciation/Appreciation.vue'
// import AppreciationOrder from '../views/handle/Appreciation/AppreciationOrder.vue'
// import AppreciationDetailPage from '../views/handle/Appreciation/AppreciationDetailPage.vue'

//订单查询
import orderQuery from '../views/handle/orderQuery/index.vue';
import orderDetail from '../views/handle/orderQuery/detail.vue';

// 补卡申请
import changeCard from '../views/handle/changeCard/changeCard.vue'
import changeCardauditCabcel from '../views/handle/changeCard/changeCardauditCabcel.vue'
import changeCardauditFail from '../views/handle/changeCard/changeCardauditFail.vue'
import changeCardForm from '../views/handle/changeCard/changeCardForm.vue'

// 账号绑定
import noCode from '../views/account/noCode.vue'
import success from '../views/account/success.vue'
import fail from '../views/account/fail.vue'
// 支付
import flowPayFali from '../views/pay/flowPayFali.vue'
import payFail from '../views/pay/payFail.vue'
import paySuccess from '../views/pay/paySuccess.vue'
//会员
// 其他
import other from '../views/memberOther/other.vue'
import cooperate from '../views/memberOther/cooperate.vue'
import complain from '../views/memberOther/complain.vue'
import complainSuccess from '../views/memberOther/complainSuccess.vue'
import store from '../views/memberOther/store.vue'
//个人信息
import personal from '../views/memberPersonal/personal.vue'
import relieve from '../views/memberPersonal/relieve.vue'
import relieveNot from '../views/memberPersonal/relieveNot.vue'
import mealDetails from '../views/memberPersonal/mealDetails.vue'
import setPassword from '../views/memberPersonal/setPassword.vue'
import setSuccess from '../views/memberPersonal/setSuccess.vue'
import setFail from '../views/memberPersonal/setFail.vue'
import notPassword from '../views/memberPersonal/notPassword.vue'
import newPassword from '../views/memberPersonal/newPassword.vue'
//积分兑换
import integral from '../views/memberIntegral/integral.vue'
import exchangeSuccess from '../views/memberIntegral/exchangeSuccess.vue'
import exchangeFail from '../views/memberIntegral/exchangeFail.vue'
import myOrder from '../views/memberIntegral/myOrder.vue'
import integralDetails from '../views/memberIntegral/integralDetails.vue'

//活动
import activity from '../views/activity/activity.vue'
import activityDetails from '../views/activity/activityDetails.vue'



import Transfer from '../views/Test/Transfer.vue'
//来电显示、漏电提醒套餐列表页
import PackageOpen from '../views/handle/package/open.vue';
//来电显示、漏电提醒套餐列表页
import PackageOpenSuccess from '../views/handle/package/success.vue';

//吐槽有理
import proposal from '../views/proposal/index.vue';
import message from '../views/proposal/message.vue';
import proposalDetail from '../views/proposal/detail.vue';

// 账号绑定AccountBind
import AccountBind from '../views/HallChildPage/AccountBind.vue';

// 公众号开卡登录
import login from '../views/login/login.vue';
import OpenCard from '../views/login/OpenCard.vue';
import OpenCardMessage from '../views/login/OpenCardMessage.vue';
import OpenPay from '../views/login/pay.vue';
import WxQuery from '../views/login/wxquery.vue';

// 公众号免密登录
import DxWithoutCodeLogin from '../views/login/DxWithoutCodeLogin.vue'; //短信套餐
import YyWithoutCodeLogin from '../views/login/YyWithoutCodeLogin.vue'; //云语
import ZnWithoutCodeLogin from '../views/login/ZnWithoutCodeLogin.vue'; //昆山早鸟
import ZyWithoutCodeLogin from '../views/login/ZyWithoutCodeLogin.vue'; //直营门店
import SwWithoutCodeLogin from '../views/login/SwWithoutCodeLogin.vue'; //郑州思维
import phWithoutCodeLogin from '../views/login/phWithoutCodeLogin.vue'; //普号
import YhWithoutCodeLogin from '../views/login/YhWithoutCodeLogin.vue'; //副号D


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Hall',
    component: Hall,
    meta: {
        keepAlive: true, //此组件需要被缓存
    },
    redirect: 'Recharge',
    children: [{
        path: 'Recharge',
        name: 'Recharge',
        component: () =>
            import('../views/HallChildPage/Recharge.vue')

    },
    {
        path: 'BusinessQuery',
        name: 'BusinessQuery',
        component: () =>
            import('../views/HallChildPage/BusinessQuery.vue')
    },
    {
        path: 'BusinessHandle',
        name: 'BusinessHandle',
        component: () =>
            import('../views/HallChildPage/BusinessHandle.vue')
    },
    // {
    //     path: 'AccountBind',
    //     name: 'AccountBind',
    //     component: () =>
    //         import ('../views/HallChildPage/AccountBind.vue')
    // },

    {
        path: 'PersonalInformation',
        name: 'PersonalInformation',
        component: () =>
            import('../views/HallChildPage/PersonalInformation.vue')
    }
    ]
},

{
    path: '/login',
    name: 'login',
    component: login
},

{
    path: '/OpenCard',
    name: 'OpenCard',
    component: OpenCard
},
// {
//     path: '/AuthenticationSucces',
//     name: 'AuthenticationSucces',
//     component: AuthenticationSucces
// },

// {
//     path: '/AuthenticationFailure',
//     name: 'AuthenticationFailure',
//     component: AuthenticationFailure
// },

{
    path: '/OpenCardMessage',
    name: 'OpenCardMessage',
    component: OpenCardMessage
},
{
    path: '/OpenPay',
    name: 'OpenPay',
    component: OpenPay
},
{
    path: '/WxQuery',
    name: 'WxQuery',
    component: WxQuery
},

{
    path: '/DxWithoutCodeLogin' + "24af2192e12d490cbdb899729fcfc010",
    name: 'DxWithoutCodeLogin',
    component: DxWithoutCodeLogin,
    // meta: { title: "DxWithoutCodeLogin24af2192e12d490cbdb899729fcfc010" }
},

{
    path: '/YyWithoutCodeLogin' + "1dd955d4f93d4743a972ee639f94f843",
    name: 'YyWithoutCodeLogin',
    component: YyWithoutCodeLogin,
    // meta: { title: "YyWithoutCodeLogin1dd955d4f93d4743a972ee639f94f843" }
},

{
    path: '/ZnWithoutCodeLogin' + "284e92113c5e4391a689f4807cd9b0b6",
    name: 'ZnWithoutCodeLogin',
    component: ZnWithoutCodeLogin,
    // meta: { title: "ZnWithoutCodeLogin284e92113c5e4391a689f4807cd9b0b6" }
},

{
    path: '/ZyWithoutCodeLogin' + "a7f66fd4a61a4f18b76ead9c8a82177a",
    name: 'ZyWithoutCodeLogin',
    component: ZyWithoutCodeLogin,
    // meta: { title: "ZyWithoutCodeLogina7f66fd4a61a4f18b76ead9c8a82177a" }
},

{
    path: '/SwWithoutCodeLogin' + "3e86ae1e87084e5ca4028a42c6191b71",
    name: 'SwWithoutCodeLogin',
    component: SwWithoutCodeLogin,
    // meta: { title: "SwWithoutCodeLogin3e86ae1e87084e5ca4028a42c6191b71" }
},

{
    path: '/phWithoutCodeLogin' + "3ae42313a1324d9ea16e3daf081ff6e1",
    name: 'phWithoutCodeLogin',
    component: phWithoutCodeLogin,
    // meta: { title: "phWithoutCodeLogin3ae42313a1324d9ea16e3daf081ff6e1" }
},

{
    path: '/YhWithoutCodeLogin' + "7f00f1f345874528af99d77ed877748b",
    name: 'YhWithoutCodeLogin',
    component: YhWithoutCodeLogin,
    // meta: { title: "YhWithoutCodeLogin7f00f1f345874528af99d77ed877748b" }
},

// {
//     path: '/OpenCardPaySuccess',
//     name: 'OpenCardPaySuccess',
//     component: OpenCardPaySuccess
// },

{
    path: '/AccountBind',
    name: 'AccountBind',
    component: AccountBind
},
{
    path: '/mp',
    name: 'mp',
    component: mp
},

{
    path: '/packageOpenSuccess',
    name: 'packageOpenSuccess',
    component: PackageOpenSuccess
},
{
    path: '/PackageOpen',
    name: 'PackageOpen',
    component: PackageOpen
},
{
    path: '/transfer',
    name: 'transfer',
    component: Transfer
},

{
    path: '/queryCard',
    name: 'queryCard',
    component: queryCard

},
{
    path: '/card',
    name: 'card',
    component: card

},
{
    path: '/record',
    name: 'record',
    component: record
},
{
    path: '/orderbusine',
    name: 'orderbusine',
    component: orderbusine
},
{
    path: '/setMeal',
    name: 'setMeal',
    component: setMeal
},
{
    path: '/balance',
    name: 'balance',
    component: balance
},
{
    path: '/historyBill',
    name: 'historyBill',
    component: historyBill
},
{
    path: '/bill',
    name: 'bill',
    component: bill
},
{
    path: '/billDetails',
    name: 'billDetails',
    component: billDetails
},

{
    path: '/invoiceVerify',
    name: 'invoiceVerify',
    component: invoiceVerify
},
{
    path: '/invoiceList',
    name: 'invoiceList',
    component: invoiceList
},
{
    path: '/submitInvoice',
    name: 'submitInvoice',
    component: submitInvoice
},

{
    path: '/invoiceDetailPage',
    name: 'invoiceDetailPage',
    component: invoiceDetailPage
},

{
    path: '/addsite',
    name: 'addsite',
    component: addsite
},



{
    path: '/billing',
    name: 'billing',
    component: billing
},
{
    path: '/Invoice',
    name: 'Invoice',
    component: Invoice
},
{
    path: '/noCode',
    name: 'noCode',
    component: noCode
},
{
    path: '/success',
    name: 'success',
    component: success
},
{
    path: '/fail',
    name: 'fail',
    component: fail
},
{
    path: '/payFail',
    name: 'payFail',
    component: payFail
},
{
    path: '/paySuccess',
    name: 'paySuccess',
    component: paySuccess
},
{
    path: '/flowPayFali',
    name: 'flowPayFali',
    component: flowPayFali
},
{
    path: '/realName',
    name: 'realName',
    component: realName
},
{
    path: '/realResult',
    name: 'realResult',
    component: realResult
},
// {
//   path: '/caller',
//   name: 'caller',
//   component: caller
// },
// {
//   path: '/openCaller',
//   name: 'openCaller',
//   component: openCaller
// },
// {
//   path: '/openSuccess',
//   name: 'openSuccess',
//   component: openSuccess
// },
// {
//   path: '/remind',
//   name: 'remind',
//   component: remind
// },
{
    path: '/openRemindSuccess',
    name: 'openRemindSuccess',
    component: openRemindSuccess
},

{
    path: '/quitRemindSuccess',
    name: 'quitRemindSuccess',
    component: quitRemindSuccess
},
// {
//   path: '/openRemind',
//   name: 'openRemind',
//   component: openRemind
// },
{
    path: '/cancel',
    name: 'cancel',
    component: cancel
},
{
    path: '/auditCabcel',
    name: 'auditCabcel',
    component: auditCabcel
},
{
    path: '/auditFail',
    name: 'auditFail',
    component: auditFail
},
{
    path: '/cancelForm',
    name: 'cancelForm',
    component: cancelForm
},

//更改套餐相关路由信息
{
    path: '/verification',
    name: 'verification',
    component: verification
},

{
    path: '/Choosepackage',
    name: 'Choosepackage',
    component: Choosepackage
},

{
    path: '/PackageDetails',
    name: 'PackageDetails',
    component: PackageDetails
},

{
    path: '/ChangeSucces',
    name: 'ChangeSucces',
    component: ChangeSucces
},

{
    path: '/WillRegister',
    name: 'WillRegister',
    component: WillRegister
},

{
    path: '/NoteRegister',
    name: 'NoteRegister',
    component: NoteRegister
},

{
    path: '/SelectFunction',
    name: 'SelectFunction',
    component: SelectFunction
},

{
    path: '/RejectionBusiness',
    name: 'RejectionBusiness',
    component: RejectionBusiness
},

{
    path: '/RejectNumber',
    name: 'RejectNumber',
    component: RejectNumber
},

{
    path: '/CustomerService',
    name: 'CustomerService',
    component: CustomerService
},

{
    path: '/UserAgreement',
    name: 'UserAgreement',
    component: UserAgreement
},

// // 订购呼转的路由
// {
//     path: '/callDivert',
//     name: 'callDivert',
//     component: callDivert
// },
// {
//     path: '/calldivertOrder',
//     name: 'calldivertOrder',
//     component: calldivertOrder
// },

// // 订购权益的路由
// {
//     path: '/Appreciation',
//     name: 'Appreciation',
//     component: Appreciation
// },
// {
//     path: '/AppreciationOrder',
//     name: 'AppreciationOrder',
//     component: AppreciationOrder
// },
// {
//     path: '/AppreciationDetailPage',
//     name: 'AppreciationDetailPage',
//     component: AppreciationDetailPage
// },

// 增加补卡相关路由信息
{
    path: '/changeCard',
    name: 'changeCard',
    component: changeCard
},
{
    path: '/changeCardauditCabcel',
    name: 'changeCardauditCabcel',
    component: changeCardauditCabcel
},
{
    path: '/changeCardauditFail',
    name: 'changeCardauditFail',
    component: changeCardauditFail
},
{
    path: '/changeCardForm',
    name: 'changeCardForm',
    component: changeCardForm
},
// 增加过户发起相关路由信息
{
    path: '/transAcctS',
    name: 'transAcctS',
    component: transAcctS
},
{
    path: '/transAcctSauditCabcel',
    name: 'transAcctSauditCabcel',
    component: transAcctSauditCabcel
},
{
    path: '/transAcctSauditFail',
    name: 'transAcctSauditFail',
    component: transAcctSauditFail
},
{
    path: '/transAcctSForm',
    name: 'transAcctSForm',
    component: transAcctSForm
},
// 增加过户发起相关路由信息
{
    path: '/transAcctT',
    name: 'transAcctT',
    component: transAcctT
},
{
    path: '/transAcctTauditCabcel',
    name: 'transAcctTauditCabcel',
    component: transAcctTauditCabcel
},
{
    path: '/transAcctTauditFail',
    name: 'transAcctTauditFail',
    component: transAcctTauditFail
},
{
    path: '/transAcctTForm',
    name: 'transAcctTForm',
    component: transAcctTForm
},

{
    path: '/realNameLink',
    name: 'realNameLink',
    component: realNameLink
},

{
    path: '/realNameLinkSuccess',
    name: 'realNameLinkSuccess',
    component: realNameLinkSuccess
},

{
    path: '/realNameLinkError',
    name: 'realNameLinkError',
    component: realNameLinkError
},

{
    path: '/realNameLinkForm',
    name: 'realNameLinkForm',
    component: realNameLinkForm
},


{
    path: '/InvoiceSuccess',
    name: 'InvoiceSuccess',
    component: InvoiceSuccess
},
{
    path: '/state',
    name: 'state',
    component: state
},
{
    path: '/other',
    name: 'other',
    component: other
},
{
    path: '/cooperate',
    name: 'cooperate',
    component: cooperate
},
{
    path: '/complain',
    name: 'complain',
    component: complain
},
{
    path: '/complainSuccess',
    name: 'complainSuccess',
    component: complainSuccess
},
{
    path: '/store',
    name: 'store',
    component: store
},
{
    path: '/personal',
    name: 'personal',
    component: personal
},
{
    path: '/relieve',
    name: 'relieve',
    component: relieve
},
{
    path: '/relieveNot',
    name: 'relieveNot',
    component: relieveNot
},
{
    path: '/mealDetails',
    name: 'mealDetails',
    component: mealDetails
},
{
    path: '/setPassword',
    name: 'setPassword',
    component: setPassword
},
{
    path: '/setSuccess',
    name: 'setSuccess',
    component: setSuccess
},
{
    path: '/setFail',
    name: 'setFail',
    component: setFail
},
{
    path: '/notPassword',
    name: 'notPassword',
    component: notPassword
},
{
    path: '/newPassword',
    name: 'newPassword',
    component: newPassword
},
{
    path: '/orderQuery',
    name: 'orderQuery',
    component: orderQuery
},
{
    path: '/orderDetail',
    name: 'orderDetail',
    component: orderDetail
},
{
    path: '/integral',
    name: 'integral',
    component: integral,
    meta: {
        keepAlive: true, //此组件需要被缓存
    },
    redirect: '/integral/pointsExchange',
    children: [{
        path: 'pointsExchange',
        name: 'pointsExchange',
        component: () =>
            import('../views/memberIntegral/IntegralChildPage/pointsExchange.vue')

    },
    {
        path: 'myPoints',
        name: 'myPoints',
        component: () =>
            import('../views/memberIntegral/IntegralChildPage/myPoints.vue')
    },

    ]
},
{
    path: '/exchangeFail',
    name: 'exchangeFail',
    component: exchangeFail
},
{
    path: '/exchangeSuccess',
    name: 'exchangeSuccess',
    component: exchangeSuccess
},
{
    path: '/activity',
    name: 'activity',
    component: activity
},
{
    path: '/activityDetails',
    name: 'activityDetails',
    component: activityDetails
},
{
    path: '/myOrder',
    name: 'myOrder',
    component: myOrder
},
{
    path: '/integralDetails',
    name: 'integralDetails',
    component: integralDetails
},
{
    path: '/proposal',
    name: 'proposal',
    component: proposal
},
{
    path: '/message',
    name: 'message',
    component: message
},
{
    path: '/proposalDetail',
    name: 'proposalDetail',
    component: proposalDetail
}
]

const router = new VueRouter({
    mode: 'history',
    routes
})
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
})
export default router