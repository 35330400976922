<template>
    <div class="RejectionBusiness">
        <!-- 导航栏 -->
        <topHead>
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">行业营销类别防护</h3>
        </topHead>

        <div class="main">

            <div class="hint">
                <span>提示:选择后，所选行业类别发送的端口类商业性短信将会被拦截</span>
            </div>

            <div class="industry_table" v-if="enRoute != ''">
                <h3>拒收进度</h3>
                <table>
                    <tr>
                        <th>操作类型</th>
                        <th>拒收类别</th>
                        <th>进度</th>
                        <th>详情</th>
                    </tr>
                    <tr v-for="(item, index) in enRoute" :key="index">
                        <td v-if="item.pickType == 'add'">登记</td>
                        <td v-if="item.pickType == 'delete'">取消登记</td>
                        <td>行业</td>
                        <td v-if="item.value == 'sj'">手机号</td>
                        <td style="color: red;">处理中</td>
                        <td style="color: blue;" @click="detail(item, index)">详情</td>
                    </tr>
                </table>

                <van-overlay class="overlay_table" :show="tableShow" @click="tableShow = false">
                    <div class="phone_det">
                        <p>拒收行业详情 <span v-if="mark == 'add'">(登记)</span><span v-if="mark == 'delete'">(取消登记)</span></p>
                        <ul style="list-style: none;">
                            <li v-for="item, index in detailData" :key="index">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </van-overlay>
            </div>

            <div class="con">
                <span class="title">请选择需要拦截的行业营销类别</span>
                <ul class="business_list">
                    <li v-for="(item, index) in data_list" :key="index">
                        <div class="img" @click="select(item)">
                            <img v-if="item.icon_type" :src="item.icon_optfor" alt="">
                            <img v-else :src="item.icon_unselected" alt="">
                            <p class="check_btn" :class="{ btnImg: item.icon_type }"></p>
                        </div>
                        <p class="list_tit">{{ item.name }}</p>
                    </li>
                </ul>

                <div class="submit_btn">
                    <van-button round type="info" @click="submitHint()">确认</van-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import topHead from "../../../components/topHead.vue"
import { Toast, Dialog } from 'vant'
import API from "../../../common/api/api"
export default {
    components: {
        topHead
    },
    data() {
        return {
            data_list: [],
            localhost_list: [
                {
                    icon_unselected: require("@/assets/images/harassDefend/business/jrtx1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/jrtx21.png"),
                    switch_type: true,
                    icon_type: false,
                }, {
                    icon_unselected: require("@/assets/images/harassDefend/business/lxy1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/lxy2.png"),
                    switch_type: true,
                    icon_type: false,
                }, {
                    icon_unselected: require("@/assets/images/harassDefend/business/dxyw1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/dxyw2.png"),
                    switch_type: true,
                    icon_type: false,
                }, {
                    icon_unselected: require("@/assets/images/harassDefend/business/wlyx1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/wlyx2.png"),
                    switch_type: true,
                    icon_type: false,
                }, {
                    icon_unselected: require("@/assets/images/harassDefend/business/jypx1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/jypx2.png"),
                    switch_type: true,
                    icon_type: false,
                },
                {
                    icon_unselected: require("@/assets/images/harassDefend/business/fczj1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/fczj2.png"),
                    switch_type: true,
                    icon_type: false,
                },
                {
                    icon_unselected: require("@/assets/images/harassDefend/business/gpzq1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/gpzq2.png"),
                    switch_type: true,
                    icon_type: false,
                }, {
                    icon_unselected: require("@/assets/images/harassDefend/business/ylws1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/ylws2.png"),
                    switch_type: true,
                    icon_type: false,
                }, {
                    icon_unselected: require("@/assets/images/harassDefend/business/ltzp1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/ltzp2.png"),
                    switch_type: true,
                    icon_type: false,
                }, {
                    icon_unselected: require("@/assets/images/harassDefend/business/lytg1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/lytg2.png"),
                    switch_type: true,
                    icon_type: false,
                }, {
                    icon_unselected: require("@/assets/images/harassDefend/business/qtyx1.png"),
                    icon_optfor: require("@/assets/images/harassDefend/business/qtyx2.png"),
                    switch_type: true,
                    icon_type: false,
                }
            ],
            serviceKind: "", //移动联通
            enRoute: [],    // 进行中数据区分
            tableShow: false,   // 详情模态框展示隐藏
            mark: "",
            detailData: [], //拒收进度详情
            addType: [],
            delType: [],
        }
    },

    created() {
        this.query()
    },
    // 组件方法
    methods: {
        // 返回
        backBefor() {
            this.$router.push("/SelectFunction")
        },

        // 详情
        detail(item) {
            this.mark = item.pickType
            this.tableShow = true
            if (item.value == "sj") {
                this.detailData = item.registerNums.split(",")
            } else {
                this.detailData = item.RegisterType.split(",")
            }
        },

        // 查询行业类别及用户登记信息
        query() {
            // 刚进页面调用接口获取已拒收的行业进行渲染
            this.phone = localStorage.getItem("phone")
            API.getJson("QUERY_SMSREGISTER", { serviceid: this.phone }).then(res => {
                if (res.data) {
                    if (res.data.code == -100) {
                        this.$router.push("/WillRegister")
                        Toast({
                            message: res.data.msg,
                            position: "bottom",
                            duration: 1000,
                        })
                    }
                } else {
                    this.serviceKind = res.serviceKind  //获取移动联通区分
                    // 将本地图标(选中 未选中)、行业拒收状态及开关状态添加至列表中
                    this.data_list = res.list.map((item, index) => Object.assign({},
                        item, {
                        icon_unselected: this.localhost_list[index].icon_unselected,
                        icon_optfor: this.localhost_list[index].icon_optfor,
                        switch_type: this.localhost_list[index].switch_type,
                        icon_type: this.localhost_list[index].icon_type
                    }))
                    res.TypeProcessing.forEach(item => {
                        this.enRoute.push(item)
                    })
                    // 获取已登记的行业
                    let val = res.success.registerType.split(",").map(item => item.trim())
                    this.judge(this.data_list, val) //进行渲染
                }
            })
        },

        // 拒收行业已选中数据渲染
        judge(arr1, arr2) {
            // 循环所有返回的数据，进行判断将已拒收行业渲染到页面
            arr1.forEach(function (item) {
                if (arr2.some(function (element) {
                    return element == item.name;
                })) {
                    item.icon_type = true;
                }
                // 再次判断将后端返回的已选中行业增加标识
                if (item.icon_type) {
                    item.sign = "default"//给每个默认选中的值添加默认选中的标记"default"
                } else {
                    item.sign = ""//给每个值添加选中未选中标记
                }
            });
        },


        // 选择拒收行业
        select(item) {
            item.icon_type = !item.icon_type    // 取反 用户点击的拒收行业高亮或取消
            // 判断默认选中的标识，
            if (item.sign == "default") {
                // 默认选中的，如果现在状态是取消状态
                if (item.icon_type == false) {
                    // 那就是取消拒收
                    item.check = "cancel"
                } else {
                    // 否则就是没变化
                    item.check = "nochange"
                }
            } else {
                // 不是默认选中的话
                if (item.icon_type) {
                    // 那就是选中状态
                    item.check = "checked"
                } else {
                    // 否则没有变化
                    item.check = "nochange"
                }
            }
        },

        // 提交提示
        submitHint() {
            let add = []
            let del = []
            this.data_list.forEach(item => {
                if (item.check == "checked") {
                    add.push(item.code)
                } else if (item.check == "cancel") {
                    del.push(item.code)
                }
            })
            this.addType = add.join()
            this.delType = del.join()

            if (this.addType != "" && this.delType == "") {
                Dialog.confirm({
                    title: "提示",
                    message: "选择后，所选行业发送的端口类商业性短信将会被拦截",
                    confirmButtonText: "确认",
                })
                    .then(() => {
                        this.submit()
                    })
            } else if (this.delType != "" && this.addType == "") {
                Dialog.confirm({
                    title: "提示",
                    message: "取消选择后，取消行业的端口类商业性短信将不会被拦截",
                    confirmButtonText: "确认",
                })
                    .then(() => {
                        this.submit()
                    }).catch(() => {

                    })
            } else {
                Dialog.confirm({
                    title: "提示",
                    message: "选择后，所选行业发送的端口类商业性短信将会被拦截；取消的将不会被拦截！！",
                    confirmButtonText: "确认",
                })
                    .then(() => {
                        this.submit()
                    }).catch(() => {

                    })
            }
        },

        // 提交
        submit() {
            API.getJson('CHANGESMS', {
                addType: this.addType,
                delType: this.delType,
                serviceid: this.phone,
            }).then(res => {
                if (res.code == "1") {
                    localStorage.removeItem("phone")
                    this.$router.push("/BusinessHandle")
                    Toast({
                        message: "提交成功，请稍后进行查询！",
                        position: "bottom",
                        duration: 1000,
                    })
                }
            })
        }
    },

    computed: {},

    watch: {},

    mounted() { },
}
</script>

<style scoped lang='scss'>
.RejectionBusiness {
    width: 100%;
    height: 100%;
    background: #f8f8f8;

    .topHead {
        position: sticky;
        /* 新属性sticky */
        top: 0;
        background: #fff;
    }

    .main {
        width: 100%;
        height: 94%;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-items: center;
        border-radius: px2rem(20);


        .hint {
            width: 100%;
            height: 5%;
            font-size: px2rem(26);
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fddce0;
            color: #a7515c;
        }

        .industry_table {
            width: 89%;
            height: 18% !important;
            display: flex;
            flex-direction: column;
            height: px2rem(320);
            font-size: px2rem(28);
            padding: 0 px2rem(20);
            background: #fff;
            border-radius: px2rem(20);
            margin-top: px2rem(20);


            h3 {
                margin: px2rem(20) 0;
            }



            table {
                width: 100%;
                border: px2rem(1) solid;
                border: 0;
                border-collapse: collapse;
                border-style: solid;



                tr:hover {
                    background-color: #F0FFFF;
                }

                th,
                td {
                    width: 25%;
                    height: px2rem(50);
                    text-align: center;
                    line-height: normal;
                    border: px2rem(1) solid;
                    border-style: solid;


                    p {
                        width: px2rem(200);
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .overlay_table {
                display: flex;
                justify-content: center;
                align-items: center;


                .phone_det {
                    width: 80%;
                    background: #ffffff;
                    padding: px2rem(20);
                    border-radius: px2rem(20);

                    p {
                        width: 100%;
                        height: px2rem(40);
                        display: flex;
                        align-items: center;
                        font-weight: 600;

                        span {
                            color: red;
                        }
                    }

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;

                        li {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: px2rem(20) px2rem(20);
                            margin-top: px2rem(20);
                            margin-left: px2rem(40);
                            text-align: center;
                            border: px2rem(1) solid #aaa;
                            border-radius: px2rem(20);
                        }
                    }
                }
            }
        }

        .con {
            width: 89%;
            height: 70%;
            padding: px2rem(20);
            background: #fff;
            border-radius: px2rem(20);
            margin-top: px2rem(20);

            .title {
                font-size: px2rem(30);
            }

            .business_list {
                width: 100%;
                margin-top: px2rem(20);
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: center;

                li {
                    width: 24%;
                    height: px2rem(220);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: px2rem(26);

                    .img {
                        width: 60%;
                        height: px2rem(90);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #f4f4f4;
                        border-radius: 50%;
                        position: relative;
                        margin: px2rem(10);

                        img {
                            width: 60%;
                            height: 60%;
                        }

                        p {
                            position: absolute;
                            bottom: px2rem(-12);
                            right: px2rem(0);
                            width: px2rem(32);
                            height: px2rem(32);
                            border-radius: 50%;
                            background: #d0d0d0;
                            border: px2rem(1) solid #9badc6;
                        }

                        .btnImg {
                            background: url(../../../assets/images/xz.png) no-repeat center center;
                            background-size: cover;
                        }
                    }

                    .list_tit {
                        width: 70%;
                        margin: px2rem(10);
                    }
                }
            }

            .submit_btn {
                width: 100%;
                height: px2rem(120);
                margin: px2rem(30) 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .van-button {
                    display: flex;
                    justify-content: center;
                    width: px2rem(550);
                    height: px2rem(80);
                    color: #fff;
                    font-weight: bold;
                    font-size: px2rem(28);
                    background-color: #336289;
                }
            }
        }
    }
}
</style>