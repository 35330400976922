<!-- 选择功能页面 -->
<template>
    <div class="SelectFunction">
        <!-- 导航栏 -->
        <topHead>
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">功能办理</h3>
        </topHead>
        <ul>
            <li v-for="(item, index) in functionData" :key="index" @click="select(index)">
                <div class="con">
                    <div class="icon">
                        <img :src="item.url" alt="">
                    </div>
                    <div class="title">
                        <p>{{ item.title }}</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import topHead from "../../../components/topHead.vue"
import API from "../../../common/api/api"
export default {
    components: {
        topHead,
    },
    data() {
        return {
            functionData: [
                {
                    "title": "行业营销类别防护",
                    "url": require("../../../assets/images/harassDefend/a1.png")
                },
                {
                    "title": "拒收号码防护",
                    "url": require("../../../assets/images/harassDefend/a2.png")
                },
                {
                    "title": "客户服务",
                    "url": require("../../../assets/images/harassDefend/a1.png")
                },
                {
                    "title": "拦截申诉",
                    "url": require("../../../assets/images/harassDefend/a4.png")
                },
            ],
            mobilePhone: "",
            serviceKind: "",
        }
    },

    created() {
    },
    // 组件方法
    methods: {
        select(index) {
            if (index == 0) {
                this.$router.push({ name: "RejectionBusiness", })
            } else if (index == 1) {
                this.$router.push({ name: "RejectNumber" })
            } else if (index == 2) {
                this.$router.push({ name: "CustomerService" })
            } else if (index == 3) {
                // 跳转客服
                var token = localStorage.getItem("token")
                API.getJson("wx_url", {
                    token: token,
                }).then(res => {
                    this.nickType = res.list[0].nickType
                    this.clientId = res.list[0].nickId
                    this.otherParams = `{"nickName":"${res.list[0].nickName}"}`
                    let sit = `https://webchat.7moor.com/wapchat.html?accessId=b8e25cb0-5ca3-11ec-99c8-3739e01ad41c&fromUrl=&urlTitle="${this.nickType}"&language=ZHCN&clientId=${this.clientId}&otherParams=${this.otherParams}`
                    window.location.href = sit
                })
            }
        },
        backBefor() {
            localStorage.removeItem("phone")
            this.$router.push("/")
        },
    },

    computed: {},

    watch: {},

    mounted() { },
}
</script>

<style scoped lang='scss'>
.SelectFunction {
    width: 100%;
    height: 100%;
    background: #f8f8f8;

    .topHead {
        position: sticky;
        /* 新属性sticky */
        top: 0;
        background: #fff;
    }

    ul {
        width: 100%;
        height: 90%;

        li {
            width: 100%;
            height: 16%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0 px2rem(30);

            .con {
                width: 100%;
                height: 60%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: px2rem(90);
                padding: 0 px2rem(40);
                background: #fff;

                .icon {
                    width: px2rem(250);
                    height: px2rem(140);
                    text-align: center;
                    margin-top: px2rem(-36);

                    img {
                        width: 56%;
                        height: 100%;
                    }
                }

                .title {
                    width: 60%;
                    font-size: px2rem(30);
                    display: flex;
                    justify-content: flex-start;


                    p{
                        width: 80%;
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>