<template>
    <div class="realName">
        <!-- 导航栏 -->
        <topHead>
            <img
                slot="head"
                src="../../../assets/images/fh.png"
                alt=""
                @click="backBefor()"
            />
            <h3 slot="header">实名认证开机</h3>
        </topHead>
        <!-- 内容 -->
        <div class="main">
            <div class="ipt">
                <p>手机号</p>
                <input
                    type="text"
                    maxlength="11"
                    v-model="phone"
                    placeholder="请输入手机号"
                />
            </div>
            <div class="ipt">
                <p>服务密码</p>
                <input
                    type="password"
                    maxlength="10"
                    v-model="password"
                    placeholder="请输入服务密码"
                />
            </div>
            <van-button round type="info" @click="realClick">去认证</van-button>
        </div>
    </div>
</template>

<script>
import API from "../../../common/api/api"
import topHead from "../../../components/topHead.vue"
import { Toast, Dialog } from "vant"
import FaceAuth from "../../../common/FaceAuth"
import Lanmao from "../../../components/lanmao/index"
import CodeModal from "../../../components/codeModal/index.js" //二维码模态框
export default {
    name: "realName",
    data() {
        return {
            phone: "",
            password: "",
            show: false,
            ewmImg: "",
            load: "",
        }
    },
    created() {
        FaceAuth.check("REAL_NAME_AUTH")
            .then(res => {
                console.log("123456", res)
                if (res.code == 1) {
                    Lanmao.alert({
                        title: "提示",
                        message: "实名认证已完成，请继续视频认证",
                        confirm: () => {
                            let token = localStorage.getItem("token")
                            API.getJson("CHECKVIDEO", {
                                token: token,
                                phone: res.phone,
                            }).then(e => {
                                Lanmao.loading.show("加载中...")
                                //上传视频认证调取二维码
                                if (e.data.code == -100) {
                                    Lanmao.loading.hide()
                                    Lanmao.loading.show("加载中...")
                                    API.getJson("CPDEIMG", {
                                        token: token,
                                        sn: e.data.data,
                                    }).then(e => {
                                       Lanmao.loading.hide();
                                        CodeModal.alert({
                                            title: "提示",
                                            message:
                                                "请长按图片识别小程序完成视频认证上传！",
                                            ewmImg: e,
                                            confirm: () => {},
                                        })
                                    })
                                }
                            })
                        },
                    })
                } else if (res.code == -100) {
                    Toast({
                        message: res.msg,
                        position: "bottom",
                    })
                }
            })
            .catch(() => {
                Lanmao.alert({
                    title: "提示",
                    message: "您的身份信息校验失败，请重试",
                    confirm: () => {},
                })
            })
    },
    methods: {
        confirms() {
            this.show = false
        },
        realClick() {
            let options = {
                type: "REAL_NAME_AUTH",
                mobilePhone: this.phone,
                phonePassword: this.password,
            }
            let token = localStorage.getItem("token")
            FaceAuth.start(options)
            //校验是否已上传实名认证  1=未上传
            // API.getJson("CHECKVIDEO", {
            // 		token: token,
            // 		phone: this.phone
            // 	})
            // 	.then(res => {
            // 		if (res == 1) {
            // 		} else {
            // 			//旷世活体检测通过获取小程序二维码二次上传视频认证
            // 			API.getJson("CPDEIMG", {
            // 					token: token,
            // 					sn: res.data.data
            // 				})
            // 				.then(res => {
            // 					this.ewmImg = res
            // 					this.show = true
            // 				})
            // 		}
            // 	})
        }, //返回按钮
        backBefor() {
            Dialog.confirm({
                message: "您将退出实名认证开机受理页面",
            })
                .then(() => {
                    // on confirm
                    this.$router.replace("/")
                })
                .catch(() => {
                    // on cancel
                })
            // self.location = self.location.origin
        },
        // 返回弹窗
        backChange() {
            const that = this
            console.log("监听到了")
            history.pushState(null, null, document.URL)
            setTimeout(() => {
                Dialog.confirm({
                    message: "您将退出实名认证开机受理页面",
                })
                    .then(() => {
                        // on confirm
                        this.$router.replace("/")
                    })
                    .catch(() => {
                        // on cancel
                    })
            }, 100)
        },
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.backChange, false) //false阻止默认事件
    },
    mounted() {
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL)
            window.addEventListener("popstate", this.backChange, false) //false阻止默认事件
        }
    },
    components: {
        topHead,
    },
}
</script>
<style lang="scss" scoped>

.main {
    padding: 0 px2rem(30);
}

.ipt {
    position: relative;
    width: 100%;
    height: px2rem(100);
    background-color: #f6f6f6;
    border-radius: px2rem(18);
    margin-top: px2rem(30);
    font-size: px2rem(26);
    padding: 0 px2rem(30);
    box-sizing: border-box;
}

.ipt input {
    width: 100%;
    height: px2rem(100);
    text-align: right;
}

.ipt p {
    position: absolute;
    left: px2rem(30);
    top: 0;
    height: px2rem(100);
    line-height: px2rem(100);
}

.van-button {
    display: flex;
    justify-content: center;
    text-align: center;
    width: px2rem(550);
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin: px2rem(90) auto 0;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
}
</style>
