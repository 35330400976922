/*
*在数据适配器中循环重组数据
*/
import { dateFormat } from "../utils/time"
export default {
	RECHARGE_PRODUCT: (data) => {
		//转化接口字段为前端字段
		let callTmp = [];
		data.call.list.forEach((item, index) => {
			callTmp.push({

				cost: item.name,
				rechargeNum: item.rechargeNum
			})
		});
		data.call.list = callTmp;

		let dayFlowTmp = [];
		data.dayFlow.list.forEach((item, index) => {
			dayFlowTmp.push({
				id: item.productId,
				flow: item.name,
				money: item.amount
			})
		});

		data.dayFlow.list = dayFlowTmp;
		let monthFlowTmp = [];
		data.monthFlow.list.forEach((item, index) => {
			monthFlowTmp.push({
				id: item.productId,
				flow: item.name,
				money: item.amount,
				kind: item.serviceKind
			})
		});
		data.monthFlow.list = monthFlowTmp;

		return data;
	},
	/*
	//查询已订购业务 
	ORDER_BUSINESS: (data) => {
		//通过计算订单失效时间和有效时间判断是否过期
		// 有效： 生效时间<当前时间<失效时间
		// 无效： 失效时间<当前时间
		// 预约： 当前时间<生效时间
		let list = data.list
		list.forEach((item, index) => {
			let effectTime = new Date(item.effectTime).getTime();
			let orderTime = new Date(item.orderTime).getTime();
			let overTime = new Date(item.overTime).getTime();
			let time = new Date().getTime();
			let status;
			//无效
			if (time >= overTime) {
				status = -1;

			}
			//预约
			if (time < effectTime) {
				status = 0;

			}
			//有效
			if (time >= effectTime && time < overTime) {
				status = 1;

			}
			data.list[index].status=status;
			data.list[index].effectTime=dateFormat(effectTime,'Y年m月d日')
			data.list[index].orderTime=dateFormat(orderTime,'Y年m月d日')
			data.list[index].overTime=dateFormat(overTime,'Y年m月d日')

		})
		return data;
	} 
	*/
}