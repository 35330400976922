import API from '../../common/api/api'


const state = () => ({
    bannerList: [],
    productList: [],
    isSign: false,
    pointsOrdersList: [],
    pointsOrdersStore: [],
    pointsDetailList: [],
    pointsDetailStore: [],
    pointsRule: '',
})

// getters
const getters = {

}

// actions
const actions = {
    //积分兑换-获取banner图列表
    getBanner({ commit, state }) {
        if (state.bannerList.length) {
            return;
        }
        API.getJson('BANNER', {}).then((res) => {
            commit('setBannerList', res.list);
        })
    },
    //获取积分兑换产品列表
    getExchangeProductList({ commit, state }) {
        if (state.productList.length) {
            return;
        }
        API.getJson('POINTS_EXCHANGE_LIST', {}).then((res) => {
            commit('setProductList', res.list);
        })
    },
    //兑换
    exchange({ commit, state }, params) {
        return new Promise((resolve, reject) => {

            API.getJson('POINTS_EXCHANGE', { productId: params.productId }).then((res) => {
                commit('updatePointsBalance', params.points, { root: true });
                resolve()
            })
        })

    },
    //签到
    sign({ commit, dispatch, state }) {
        return new Promise((reslove, reject) => {
            API.getJson('SIGN', {}).then(() => {
                commit("setIsSign", true)
                commit('updateMember', 1, { root: true })
                reslove();
            })
        })
    },
    //签到状态查询
    getSignStatus({ commit, state }) {
        API.getJson('SIGN_STATUS', {}).then((res) => {
            commit("setIsSign", res.is_sign)
        })
    },
    //获取积分兑换订单
    getPointsOrders({ commit, state }, interval = 0) {
        if (state.pointsOrdersStore[interval] && state.pointsOrdersStore[interval].length) {
            commit('setPointsOrdersList', { list: state.pointsOrdersStore[interval], key: interval });
            return;
        }
        API.getJson('POINTS_ORDERS', { interval: interval }).then((res) => {

            commit('setPointsOrdersList', { list: res.list, key: interval });

        })
    },
    //积分明细
    getPointsDetail({ commit, state }, type = 0) {

        if (state.pointsDetailStore[type] && state.pointsDetailStore[type].length) {
            commit('setPointsDetailList', { list: state.pointsDetailStore[type], type: type });
            return;
        }
        API.getJson('POINTS_DETAIL', { type: type }).then((res) => {
            commit('setPointsDetailList', { list: res.list, key: type });
        })
    },

    getPointsRule({ commit, state }) {
        if (state.pointsRule) {
            return;
        }
        API.getJson('POINTS_RULE', {}).then((res) => {
            commit('setPointsRule', res.content)
        })
    }
}

// mutations
const mutations = {
    setBannerList(state, list) {
        state.bannerList = list
    },
    setProductList(state, list) {
        state.productList = list
    },
    setIsSign(state, flag) {
        state.isSign = flag
    },
    setPointsOrdersList(state, obj) {
        if (state.pointsOrdersList == null) {
            state.pointsOrdersList = []
        }
        state.pointsOrdersList = state.pointsOrdersStore[obj.key] = obj.list

    },
    setPointsDetailList(state, obj) {
        //state.pointDetailStore[obj.type] = [];

        state.pointsDetailList = state.pointsDetailStore[obj.key] = obj.list
    },
    setPointsRule(state, content) {
        state.pointsRule = content;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}