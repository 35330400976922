<template>
    <div class="openCaller">
        <!-- 导航栏 -->
        <topHead>
            <h3 slot="header">{{ navigationBarTitle }}</h3>
        </topHead>
        <!-- 内容 -->
        <div class="main">
            <div class="title">
                <h3>{{ packageInfo.title }}</h3>
                <p>{{ packageInfo.detail }}</p>
            </div>


            <div class="Cidshow" v-if="type == 2">
                <van-button round type="info" @click="Openbtn">立即开通</van-button>
            </div>

            <div class="Cidremind" v-if="type == 1">
                <van-button round type="info" @click="Openbtn">立即开通</van-button>
                <van-button round type="info" @click="quitbtn">退订</van-button>
            </div>
        </div>

        <!-- 退订弹框 -->
        <Popup :show="flag" @showClick="quitcloseClick(flag)">
            <div class="con" slot="conter">
                <p>
                    您即将退订<span>{{ packageInfo.title }}</span>功能
                </p>
            </div>
            <div class="btn" slot="btn">
                <van-button round type="info" class="back" @click="flag = false">取消</van-button>
                <van-button round type="info" class="pay" @click="goPay">确定</van-button>
            </div>
        </Popup>
        <!-- 订购弹框 -->
        <Popup :show="show" @showClick="closeClick(show)">
            <div class="conter" slot="conter">
                <p class="up">您即将开通{{ navigationBarTitle }}业务</p>
                <p class="low">每月资费￥{{ packageInfo.postage }}</p>
            </div>
            <div class="bottom" slot="bottom">
                业务资费将在您的当前余额中扣除
            </div>
            <div class="btn" slot="btn">
                <van-button round type="info" class="back" @click="show = false">取消</van-button>
                <van-button round type="info" class="pay" @click="goPay">确定</van-button>
            </div>
        </Popup>
    </div>
</template>

<script>
import Lanmao from "../../../components/lanmao/index"
import topHead from "../../../components/topHead.vue"
import Popup from "../../../components/Popup.vue"
import { Popup as VantPopup, Toast } from "vant"
import API from "../../../common/api/api"
export default {
    name: "openCaller",
    data() {
        return {
            show: false,
            flag: false,
            navigationBarTitle: "",
            typeList: ["来电提醒", "来电显示"],
            type: "",
            packageInfo: "",
            isDisabled: false,//按钮禁用
        }
    },
    created() {
        this.$nextTick(() => {
            this.type = this.$route.query.type
            //获取导航条标题
            this.navigationBarTitle = this.typeList[this.type - 1]
            // //获取套餐列表
            this.getPackageList()
        })
    },
    mounted() { },
    components: {
        topHead,
        Popup,
        "van-popup": VantPopup,
    },
    methods: {
        quitcloseClick(flag) {
            this.flag = !flag
        },
        closeClick(show) {
            this.show = !show
        },
        // 立即开通
        Openbtn() {
            this.show = true
            this.operType = 1 //0退订 1订购
            this.effectType = 0 // 0立即 1 预约
        },
        //退订
        quitbtn() {
            this.flag = true
            this.operType = 0 //0退订 1订购
            this.effectType = 1 // 0立即 1 预约
        },

        //订购 退订
        goPay() {
            if (this.type == 1) {
                // 来电提醒
                let token = localStorage.getItem("token")
                API.getJson("ORDER_BUSINESS", {
                    token: token,
                    type: 1,
                    page: 1,
                    pageSize: 6,
                }).then(res => {
                    if (res.list.find(item => item.packageName == "无条件呼叫转移(联通)")) {
                        Toast({
                            message: "已有无条件呼叫转移，无法订购漏电！",
                            position: "bottom",
                        })
                        this.show = false
                    } else {
                        this.Pay()
                    }

                })
            } else {
                // 来电显示
                this.Pay()
            }
        },
        
        Pay() {
            Lanmao.loading.show("处理中")
            API.getJson("PACKAGE_ORDER", {
                packageId: this.packageInfo.packageId,
                operType: this.operType,
                effectType: this.effectType,
            }).then(res => {
                Lanmao.loading.hide()
                if (res.code == 1) {
                    // 判断是来电提醒还是来电显示
                    if (this.type == 1) {
                        // 1是提醒 2是显示
                        if (this.operType == 0) {
                            this.flag = false
                            this.$router.replace({ path: "quitRemindSuccess" })
                        } else {
                            // 来电提醒订购成功
                            this.show = false
                            this.$router.replace({ path: "openRemindSuccess" })
                        }
                    } else {
                        this.show = false
                        this.$router.replace({ path: "packageOpenSuccess" })
                    }
                } else if (res.data.code == -100) {
                    if (this.type == 1) {
                        if (this.operType == 0) {
                            this.flag = false
                        } else {
                            this.show = false
                        }
                    } else {
                        this.show = false
                    }
                    Toast({
                        message: res.data.msg,
                        position: "bottom",
                    })
                } else {
                    Toast({
                        message: "提交数据异常",
                        position: "bottom",
                    })
                }
            })
        },

        //获取套餐列表
        getPackageList() {
            API.getJson("PACKAGE_LIST", { type: this.type }).then(res => {
                res.list.forEach(item => {
                    this.packageInfo = item
                });
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    height: 100%;
    padding: 0 px2rem(30);

    .title {
        width: 100%;
        line-height: px2rem(75);
        font-size: px2rem(30);
        color: #336289;

        p {
            width: 100%;
            font-size: px2rem(26);
            color: #000000;
            line-height: px2rem(48);
            margin-bottom: px2rem(15);
            margin-left: px2rem(60);
        }
    }

    .Cidremind {
        width: 100%;
        height: px2rem(300);
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        position: fixed;
        left: 0;
        bottom: px2rem(120);
    }

    .Cidshow {
        width: 100%;
        height: px2rem(300);
        position: fixed;
        left: 0;
        bottom: px2rem(120);
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.van-button {
    display: flex;
    justify-content: center;
    text-align: center;
    width: px2rem(550);
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
}

.con {
    width: 100%;
    height: px2rem(160);
    font-size: px2rem(30);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: px2rem(40);

    p {
        span {
            color: #ff7505;
            font-size: px2rem(32);
            font-weight: 600;
        }
    }
}

.conter {
    font-size: px2rem(30);
    font-weight: 700;
    text-align: center;
}

.conter .up {
    color: #333;
}

.conter .low {
    color: #ff7505;
    margin-top: px2rem(10);
}

.bottom {
    // border-top: px2rem(1) solid #909198;
    font-size: px2rem(24);
    color: #909198;
    line-height: px2rem(80);
    text-align: center;
    margin-top: px2rem(40);
}

.btn {
    display: flex;
    justify-content: space-around;
}

.btn .van-button {
    width: px2rem(210);
    margin: 0;
}

.btn .back {
    background-color: #bdcbd0;
    color: #336289;
}
</style>