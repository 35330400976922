<template>
    <div class="card">
        <!-- 导航栏 -->
        <topHead>
            <img
                slot="head"
                src="../../assets/images/fh.png"
                alt=""
                @click="backBefor()"
            />
            <h3 slot="header">查名下卡号</h3>
        </topHead>
        <!-- 内容 -->
        <div class="main" v-if="list && info">
            <div class="card_top">
                <div class="name">姓名：{{ info.realName }}</div>
                <div class="id">身份证号：{{ info.idCardNumber }}</div>
            </div>
            <ul class="card_list" v-for="(item, index) in list" :key="index">
                <li>
                    <div class="infor">
                        <div class="infor_phone">{{ item.mobileNumber }}</div>
                        <div class="infor_meal">{{ item.packageName }}</div>
                        <div class="infor_time">入网时间：{{ item.time }}</div>
                        <div class="infor_balance">
                            账户余额：{{ item.balance }}元
                        </div>
                    </div>
                    <div class="state">
                        <p>号码状态</p>
                        <h3 class="often" v-if="item.status == '正常服务'">
                            {{ item.status }}
                        </h3>
                        <h3 class="owe" v-else>{{ item.status }}</h3>
                        <!-- <h3 class="owe">欠费</h3> -->
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import topHead from "../../components/topHead.vue"
import FaceAuth from "../../common/FaceAuth"
import { Toast, Dialog } from "vant"
import Lanmao from "../../components/lanmao/index"
import API from "../../common/api/api"
export default {
    name: "card",
    components: {
        topHead,
    },
    data() {
        return {
            list: null,
            info: null,
            options: {},
        }
    },
    created() {
        // this.$nextTick(() => {
        //     this.options = this.$route.query
        //     this.getCardNumber()
        // })
    },
    methods: {
        getCardNumber() {
            API.getJson("QUERY_CARD_NUMBER", this.options).then(res => {
                this.list = res.list
                this.info = res.memberInfo
            })
        },
        backBefor() {
            Dialog.confirm({
                message: "您将退出查询名下卡号",
            })
                .then(() => {
                    // on confirm
                    this.$router.replace("/")
                })
                .catch(() => {
                    // on cancel
                })
            // self.location = self.location.origin
        },
        backChange() {
            const that = this
            console.log("监听到了")
            history.pushState(null, null, document.URL)
            setTimeout(() => {
                Dialog.confirm({
                    message: "您将退出查询名下卡号",
                })
                    .then(() => {
                        // on confirm
                        this.$router.replace("/")
                    })
                    .catch(() => {
                        // on cancel
                    })
            }, 100)
        },
    },
    destroyed() {
        window.removeEventListener("popstate", this.backChange, false) //false阻止默认事件
    },
    mounted() {
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL)
            window.addEventListener("popstate", this.backChange, false) //false阻止默认事件
        }
    },
    beforeRouteEnter(to, from, next) {
        FaceAuth.check("CARD_NUMBER")
            .then(res => {
                if (res.data) {
                    if (res.data.code == -998) {
                        Toast({
                            message: res.data.msg,
                            position: "bottom",
                        })
                        this.$router.push("/queryCard")
                    } else if (res.data.code == -100) {
                        Toast({
                            message: res.data.msg,
                            position: "bottom",
                        })
                        this.$router.push("/queryCard")
                    }
                } else {
                    if (res.biz_no != "") {
                        Toast({
                            message: "身份核验成功",
                            forbidClick: true,
                            position: "bottom",
                        })
                        next(vm => {
                            //  vm.$router.push({path:vm.$router.path,query:{biz_no:res.authInfo.BIZ_NO}})
                            vm.list = res.list
                            vm.info = res.memberInfo
                        })
                    }
                }
            })
            .catch(() => {
                Lanmao.alert({
                    title: "提示",
                    message: "您的身份信息校验失败，请重试",
                    confirm: () => {
                        next({ path: "/queryCard", replace: true })
                    },
                })
            })
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
}
.card {
    height: 100%;
}
.card_top {
    width: 100%;
    height: px2rem(150);
    background: url(../../assets/images/cardbj.png) no-repeat center center;
    background-size: cover;
    border-radius: px2rem(22);
    font-size: px2rem(28);
    color: #fff;
    padding: px2rem(30) 0 0 px2rem(40);
    box-sizing: border-box;
    box-shadow: 0 0 px2rem(20) rgba(0, 0, 0, 0.3);
    margin-top: px2rem(18);
    margin-bottom: px2rem(56);
}
.card_top .name {
    margin-bottom: px2rem(30);
}
.card_list {
    border-top: 1px solid #f5f5f5;
    width: 100%;
}
.card_list li {
    display: flex;
    width: 100%;
    height: px2rem(232);
    margin-bottom: px2rem(20);
}
.card_list .infor {
    width: 50%;
    height: 100%;
    background-color: #f5f5f5;
    border-radius: px2rem(20) px2rem(10) px2rem(10) px2rem(20);
    padding: px2rem(30) 0 0 px2rem(40);
    box-sizing: border-box;
}
.card_list .infor_phone {
    font-weight: bold;
    color: #336289;
    margin-bottom: px2rem(10);
    font-size: px2rem(36);
}
.card_list .infor_meal,
.card_list .infor_time,
.card_list .infor_balance {
    font-size: px2rem(24);
    color: #5d5f64;
}
.card_list .infor_time {
    margin: px2rem(20) 0;
}
.card_list .state {
    width:50%;
    height: 100%;
    background-color: #f5f5f5;
    border-radius: px2rem(10) px2rem(20) px2rem(20) px2rem(10);
    text-align: center;
    padding-top: px2rem(75);
    box-sizing: border-box;
}
.card_list .state p {
    font-size: px2rem(22);
    color: #5d5f64;
    margin-bottom: px2rem(13);
}
.card_list .state h3 {
    font-size: px2rem(32);
    font-weight: bold;
}
.card_list .state .often {
    color: #336289;
}
.card_list .state .owe {
    color: #d04040;
}
</style>