import { render, staticRenderFns } from "./complainSuccess.vue?vue&type=template&id=7c714bbe&scoped=true"
import script from "./complainSuccess.vue?vue&type=script&lang=js"
export * from "./complainSuccess.vue?vue&type=script&lang=js"
import style0 from "./complainSuccess.vue?vue&type=style&index=0&id=7c714bbe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c714bbe",
  null
  
)

export default component.exports