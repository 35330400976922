<template>
    <div class="invoiceList">
        <!-- 导航栏 -->
        <topHead>
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">发票列表</h3>
        </topHead>

        <div class="content" v-if="allData != ''">
            <van-tabs v-model="active">
                <van-tab title="未开票">
                    <div class="notInvoiced" v-if="noAccomplishList != ''">
                        <ul>
                            <li v-for="(item, index) in noAccomplishList" :key="index" @click="select(item.id, index)">
                                <div class="left">
                                    <div class="check_btn" :class="{ btnImg: selectList.indexOf(item.id) != -1 }"></div>
                                </div>
                                <div class="right">
                                    <div class="right_title">
                                        <p>{{ item.feeMonth }}</p>
                                        <p>月结发票</p>
                                    </div>
                                    <div class="right_price">
                                        ￥{{ item.feeAmount }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- 底部全选及开发票 -->
                    <div class="bottom" v-if="noAccomplishList != ''">
                        <div class="selctAll dis_bet">
                            <div class="selectAll dis_bet" @click="checkedAlls">
                                <div class="check_btn">
                                    <div class="check_btn"
                                        :class="{ select_btn_img: selectList.length == noAccomplishList.length }"></div>
                                </div>
                                <p>全选</p>
                            </div>
                            <van-button type="default" @click="invoice">开发票</van-button>
                        </div>
                    </div>

                    <div class="nodata" v-if="noAccomplishList == ''">
                        <ul>
                            <li>
                                <img src="../../../assets/images/sb.png" alt="">
                                <p> 暂无开票数据</p>
                            </li>
                        </ul>
                    </div>
                </van-tab>
                <van-tab title="已开票">
                    <div class="already" v-if="accomplishList != ''">
                        <div class="already_con" v-for="item, index in accomplishList" :key="index">
                            <div class="already_top">
                                <div class="Time">
                                    <p>{{ item.feeMonth }}</p>
                                </div>
                                <div class="price dis_bet">
                                    <p v-if="item.type == '026'">电子发票</p>
                                    <p v-if="item.type == '81'">增值税专用发票(数电票)</p>
                                    <p v-if="item.type == '82'">普通发票(数电票)</p>
                                    <p>{{ item.feeAmount }} 元</p>
                                </div>
                            </div>
                            <div class="already_bottom dis_bet">
                                <div class="tag">
                                    <span class="tag_warning" v-if="item.status == '1'">开票中</span>
                                    <span class="tag_success" v-if="item.status == '2'">已开票</span>
                                    <p class="tag_danger" v-if="item.status == '3'">开票失败 <span v-if="item.errMsg">原因:{{
                                        item.errMsg }}</span>
                                    </p>
                                </div>

                                <div class="anew_btn" v-if="item.status == '2' || item.status == '3'">
                                    <button @click="anew(item)">重发发票</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="nodata" v-else>
                        <ul>
                            <li>
                                <img src="../../../assets/images/sb.png" alt="">
                                <p> 暂无开票数据</p>
                            </li>
                        </ul>

                    </div>
                </van-tab>
            </van-tabs>
        </div>

        <div class="nodata" v-else>
            <ul>
                <li>
                    <img src="../../../assets/images/sb.png" alt="">
                    <p> 暂无开票数据</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import topHead from "../../../components/topHead.vue"
import { Dialog, Toast } from "vant"
import API from "../../../common/api/api"
export default {
    components: {
        topHead,
    },
    data() {
        return {
            allData: this.$route.params.arrs,//所有列表数据
            selectList: [], //选中的数据
            active: 0,
            checkedAll: false,
            accomplishList: [],//已开票
            noAccomplishList: [],//未开票
        }
    },
    created() {
        this.getList()

    },
    // 组件方法
    methods: {
        // 获取发票列表信息
        getList() {
            API.getJson("NEW_INVOICE_LIST", {
                service_id: localStorage.getItem("Phone")
            }).then(res => {
                this.allData = res

                this.allData.forEach(v => {
                    if (v.status == "0") {
                        // 未完成
                        this.noAccomplishList.push(v)
                    } else if (v.status == "1" || v.status == "2" || v.status == "3") {
                        // 已完成及进行中的
                        this.accomplishList.push(v)
                    }
                })
            })
        },

        // 单选
        select(id) {
            let select_id = this.selectList.indexOf(id)
            select_id == -1 ? this.selectList.push(id) : this.selectList.splice(select_id, 1)
            this.selectList.length == this.noAccomplishList.length ? this.checkedAll = true : this.checkedAll = false
        },

        // 全选
        checkedAlls() {
            if (this.selectList.length == this.noAccomplishList.length) {
                this.selectList = []
            } else {
                this.noAccomplishList.forEach(item => {
                    item.checked = !this.checkedAll
                    if (!this.selectList.includes(item.id)) {
                        this.selectList.push(item.id)
                    }
                })
            }
        },

        // 开发票
        invoice() {
            var money = 0
            let time = []
            var obj = []
            this.selectList.map(item => {
                this.noAccomplishList.map(items => {
                    if (items.id == item) {
                        obj.push(items)
                    }
                })
            })
            if (obj.length != "") {
                obj.forEach(item => {
                    money += Number(item.feeAmount)
                    time.push(item.feeMonth.substr(-2))

                })
                let price = Math.round(money * 100) / 100
                time.sort()
                Dialog.confirm({
                    width: "13rem",
                    message: `已选<span style='color:red;font-weight: 600;'> ${time} </span>月份的发票，<br/>共<span style='color:red;font-weight: 600;'> ${price} 元</span>`,
                    confirmButtonColor: "#ee0a24",
                })
                    .then(() => {
                        localStorage.setItem("userId", obj[0].userId)
                        localStorage.setItem("price", price)
                        localStorage.setItem("checkedList", JSON.stringify(obj))
                        this.$router.push("/submitInvoice")
                    })
                    .catch(() => {
                    })

            } else {
                Toast({
                    message: "请选择开票月份",
                    position: "bottom",
                    duration: 800,
                })
            }
        },

        // 重发发票
        anew(item) {
            let arr = []
            arr.push(item)
            // 已开票
            if (arr[0].status == "2") {
                API.getJson("NEW_MONTHS_INFO", {
                    id: item.id
                }).then(res => {
                    let total = res.saleAmount //总价
                    let month = []
                    res.months.forEach(item => {
                        month.push(item.feeMonth.substr(-2))

                    })
                    Dialog.confirm({
                        width: "13rem",
                        message: `重发<span style='color:red;font-weight: 600;'> ${month} </span>月份的发票，<br/>共<span style='color:red;font-weight: 600;'> ${total} 元</span>`,
                        confirmButtonColor: "#ee0a24",
                    }).then(() => {
                        this.$router.push({
                            "name": "invoiceDetailPage",
                            params: { arr: arr },
                        })
                    }).catch(() => {
                    })
                })

            } else if (arr[0].status == "3") {
                // 开票失败
                localStorage.setItem("userId", arr[0].userId)
                localStorage.setItem("price", arr[0].feeAmount)
                localStorage.setItem("checkedList", JSON.stringify(arr))
                this.$router.push("/submitInvoice")
            }
        },

        backBefor() {
            Dialog.confirm({
                message: "您将退出发票列表页面",
            }).then(() => {
                localStorage.removeItem("Phone")
                this.$router.push("/invoiceVerify");
            }).catch(() => {
            })
        },
    },

    watch: {},

    computed: {
    },

    mounted() { },
}
</script>

<style scoped lang='scss'>
* {
    box-sizing: border-box;
}

.invoiceList {
    height: 100vh;
    position: relative;

    .topHead {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
        background-color: #fff;
    }

    .nodata {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: px2rem(30);
        background-color: #ffffff;

        ul {
            width: 100%;
            height: 100%;

            li {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    width: px2rem(300);
                    height: px2rem(260);
                }

                p {
                    font-size: px2rem(40);
                    margin-top: px2rem(30);
                }
            }
        }
    }

    .content {
        width: 100%;
        height: 100%;
        position: fixed;
        overflow: auto;
        background-color: #f0f0f0;

        // 所有复选框样式
        .check_btn {
            width: px2rem(38);
            height: px2rem(38);
            border-radius: 50%;
            background-color: #d0d0d0;
        }

        .dis_bet {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .van-tabs {
            height: 100%;
            margin-top: px2rem(80);

            // 未开票
            .notInvoiced {
                width: 100%;
                height: 100%;

                ul {
                    li {
                        height: px2rem(120);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: px2rem(16);
                        border-radius: px2rem(12);
                        background-color: #fff;
                        padding: 0 px2rem(20);

                        .left {
                            flex: 2;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .btnImg {
                                background: url(../../../assets/images/xz.png) no-repeat center center;
                                background-size: cover;
                            }
                        }

                        .right {
                            height: 60%;
                            flex: 8;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            font-size: px2rem(24);
                            padding: 0 px2rem(24);

                            .right_title {
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;
                            }
                        }
                    }
                }
            }

            // 已开票等
            .already {
                width: 100%;
                height: 100%;


                .already_con {
                    font-size: px2rem(24);
                    border: px2rem(1) solid #ebebeb;
                    margin-top: px2rem(20);
                    border-radius: 10%;



                    .already_top {
                        height: 16%;
                        background-color: #3377f6;
                        padding: px2rem(30);
                        color: #fcfef9;
                        border-top-left-radius: px2rem(16);
                        border-top-right-radius: px2rem(16);



                        .price {
                            margin-top: px2rem(20);

                            :nth-child(2) {
                                font-size: px2rem(30);
                                // color: red;
                            }
                        }
                    }

                    .already_bottom {
                        padding: px2rem(20);
                        background-color: #ffffff;
                        border-bottom: px2rem(1) solid #d0d0d0;
                        border-bottom-left-radius: px2rem(16);
                        border-bottom-right-radius: px2rem(16);

                        .tag {

                            .tag_success {
                                color: #07c160;
                            }

                            .tag_warning {
                                color: #ff976a;
                            }

                            .tag_danger {
                                color: #ee0a24;
                                line-height: px2rem(40);
                            }
                        }

                        .anew_btn {
                            button {
                                width: px2rem(150);
                                height: px2rem(50);
                                font-size: px2rem(24);
                                background-color: #ffffff;
                                border: px2rem(1) solid #0b0b0b;
                                border-radius: px2rem(10);
                            }
                        }
                    }

                    .errmsg {
                        color: red;
                        font-size: px2rem(24);
                        background: #fff;
                        padding: px2rem(10) px2rem(30);

                        span {
                            line-height: px2rem(40);
                        }
                    }
                }
            }
        }

        .bottom {
            width: 100%;
            height: px2rem(100);
            position: fixed;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #336289;
            box-sizing: border-box;
            font-size: px2rem(24);
            color: #fff;

            .selctAll {
                height: 100%;
                padding: 0 px2rem(30);

                .selectAll {
                    width: 14%;



                    .select_btn_img {
                        background: url(../../../assets/images/qx.png) no-repeat center center;
                        background-size: cover;
                    }
                }

                .select {
                    width: 16%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .van-button {
                    width: px2rem(165);
                    height: px2rem(60);
                    line-height: px2rem(60);
                    font-size: px2rem(24);
                    font-weight: bold;
                    color: #336289;
                    border-radius: px2rem(10);
                }
            }
        }
    }
}
</style>
<style>
.van-checkbox__icon {
    font-size: 0.8rem !important;
}

.van-tab__pane {
    height: 100%;
}

.van-tabs__content {
    height: 80% !important;
}
</style>