<template>
    <div class="realNameLink">
        <div class="header">
            <h3>实名补录</h3>
        </div>
        <!-- 内容 -->
        <div class="main">
            <div class="ipt" v-if="flag == 'yes'">
                <p>实名手机号</p>
                <!-- 需要获取订单号的输入框 -->
                <input type="text" maxlength="11" placeholder="请输入手机号" readonly="readonly" v-model="phone" />
            </div>

            <div class="ipt" v-if="flag == 'no'">
                <p>实名手机号</p>
                <!-- 不需要获取订单号的输入框 -->
                <input type="text" maxlength="11" placeholder="请输入手机号" oninput="value=value.replace(/[^\d]/g,'')"
                    v-model="phone" />
            </div>
            <van-button round type="info" @click="cancelHeadler">下一步</van-button>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant'
import { NewTransAcctT, GetPhoneById } from "../../common/newapi/ghsm_api"
export default {
    components: {
    },
    data() {
        return {
            phone: "",
            recordNumber: "", //url获取的订单号
            flag: null
        }
    },

    created() {
        this.WhetheNeed()
    },
    // 组件方法
    methods: {
        // 判断是否获取订单号
        WhetheNeed() {
            if (this.$route.fullPath == "/realNameLink") {
                this.recordNumber = "noid"
                this.flag = 'no'
            } else {
                let routerdata = this.$route.fullPath.split('?')
                this.recordNumber = routerdata[1]
                this.flag = 'yes'
                this.GetPhone()
            }
        },

        // 获取实名手机号
        async GetPhone() {
            // 获取实名手机号接口
            let phonedata = await GetPhoneById({
                id: this.recordNumber
            })
            if (phonedata.code == 1) {
                this.phone = phonedata.data.phone
            } else {
                Toast({
                    message: phonedata.msg,
                    position: "bottom",
                })
            }
        },

        // 下一步
        async cancelHeadler() {
            let cancelData = await NewTransAcctT({
                phone: this.phone,
                url: "/realNameLinkForm"
            })
            if (cancelData.code != 1) {
                Toast({
                    message: cancelData.msg,
                    position: "bottom",
                })
            } else {
                sessionStorage.setItem("biz_no", cancelData.data.biz_no)
                sessionStorage.setItem("recordNumber", this.recordNumber)
                window.location.href = cancelData.data.url
            }
        },
    },

    computed: {},

    watch: {},

    mounted() { },
}
</script>

<style scoped lang='scss'>
.realNameLink {

    .header {
        height: px2rem(60);
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            font-size: px2rem(38);
        }
    }

    .main {
        padding: 0 px2rem(30);

        .ipt {
            position: relative;
            width: 100%;
            height: px2rem(100);
            background-color: #f6f6f6;
            border-radius: px2rem(18);
            margin-top: px2rem(60);
            font-size: px2rem(26);
            padding: 0 px2rem(30);
            box-sizing: border-box;

            input {
                width: 100%;
                height: px2rem(100);
                text-align: right;
            }

            p {
                position: absolute;
                left: px2rem(30);
                top: 0;
                height: px2rem(100);
                line-height: px2rem(100);
            }
        }

        .van-button {
            display: flex;
            justify-content: center;
            text-align: center;
            width: px2rem(550);
            height: px2rem(80);
            line-height: px2rem(80);
            color: #fff;
            font-weight: bold;
            font-size: px2rem(28);
            background-color: #336289;
            margin: px2rem(240) auto 0;
            box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
        }
    }

}
</style>