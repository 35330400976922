import { render, staticRenderFns } from "./SwWithoutCodeLogin.vue?vue&type=template&id=49128496&scoped=true"
import script from "./SwWithoutCodeLogin.vue?vue&type=script&lang=js"
export * from "./SwWithoutCodeLogin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49128496",
  null
  
)

export default component.exports