<template>
    <div class="Hall">
        <!-- 导航栏 -->
        <topHead class="headTop">
            <span slot="head"></span>
            <!--<img slot="head" src="../assets/images/fh.png" alt="" />-->
            <h3 slot="header">营业厅</h3>
        </topHead>

        <!-- <van-notice-bar left-icon="volume-o" :scrollable="false">
            <van-swipe
                background="#ecf9ff"
                vertical
                class="notice-swipe"
                :autoplay="4000"
                :show-indicators="false"
            >
                <van-swipe-item
                    >人工服务时间: <span> 8:00--21:00</span>
                </van-swipe-item>
                <van-swipe-item
                    >蓝猫客服热线: <span>400-180-6789</span>
                </van-swipe-item>
            </van-swipe>
        </van-notice-bar> -->

        <div class="marpuees">
            <div class="marpuees_tu">
                <img src="~@/assets/images/marpuees_tu.png" alt="" />
                <span>公告:</span>
            </div>
            <marquee behavior="scroll" scrollamount="4" direction="left">
                <span class="marquee_one"
                    >蓝猫客服热线: <em>10020 / 400-180-6789</em></span
                >
                <span>人工服务时间: <em> 8:00--21:00 </em></span>
            </marquee>
        </div>

        <!-- 内容区 -->
        <div class="main">
            <!-- 左侧tab -->
            <van-sidebar v-model="activeKey" class="left_tab">
                <router-link to="/Recharge">
                    <van-sidebar-item title="号码充值" />
                </router-link>

                <router-link to="/BusinessQuery">
                    <van-sidebar-item title="业务查询" />
                </router-link>

                <router-link to="/BusinessHandle">
                    <van-sidebar-item title="业务办理" />
                </router-link>

                <!-- <router-link to="/AccountBind">
          <van-sidebar-item title="账号绑定" />
        </router-link> -->

                <router-link to="/PersonalInformation">
                    <van-sidebar-item title="个人信息" />
                </router-link>
            </van-sidebar>
            <!-- 右侧内容 -->
            <div class="content">
                <!-- 充值 start -->
                <keep-alive>
                    <router-view></router-view>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
import topHead from "../components/topHead.vue"
import API from "../common/api/api"
import { Dialog, Toast } from "vant"
export default {
    name: "Hall",
    data() {
        return {
            activeKey: 0,
        }
    },
    watch: {},
    created() {
        //获取用户信息
        this.changeSideTab(this.$route)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch("getMember")
        })
    },
    beforeRouteUpdate(to, from, next) {
        if (to.name == "BusinessQuery") {
            this.$store.commit("resetMember")
            this.$store.dispatch("getMember")
        }
        this.changeSideTab(to)
        next()
    },
    mounted() {
        // var browser = {
        // versions: function () {
        //     var u = navigator.userAgent, app = navigator.appVersion;
        //     return {         //移动终端浏览器版本信息
        //         trident: u.indexOf('Trident') > -1, //IE内核
        //         presto: u.indexOf('Presto') > -1, //opera内核
        //         webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        //         gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
        //         mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        //         ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        //         android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
        //         iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        //         iPad: u.indexOf('iPad') > -1, //是否iPad
        //         webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
        //     };
        // }(),
        // language: (navigator.browserLanguage || navigator.language).toLowerCase()
        // }
        // if( window.history.length > 2 ){
        //   // 在手机上位了返回微信页面
        //   // alert(that.historyText.length)
        //   var ua = navigator.userAgent.toLowerCase();
        //   if ( browser.versions.mobile) {
        //     //判断是否是移动设备打开。browser代码在下面
        //     //最开始进入页面的那一条记录
        //     let textNum = parseInt(window.history.length) - 1;
        //     // alert(textNum)
        //     history.go(-textNum);
        //   }
        // };
    },
    components: {
        topHead,
    },
    methods: {
        //切换侧边选项卡
        changeSideTab(routeObj) {
            let map = {
                Recharge: "0",
                BusinessQuery: "1",
                BusinessHandle: "2",
                PersonalInformation: "3",
            }
            let routeName = routeObj.name
            if (map[routeName]) {
                this.activeKey = map[routeName]
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.headTop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
}

// .van-notice-bar {
//     width: 100%;
//     height: px2rem(60);
//     margin-top: px2rem(90);
//     background: #edeef6;
//     position: fixed;

//     .notice-swipe {
//         height: px2rem(40);
//         line-height: px2rem(36);
//         color: #336189;
//         font-size: px2rem(28);
//         span {
//             color: #ff8a54;
//         }
//     }
// }

.marpuees {
    position: fixed;
    margin-top: px2rem(90);
    width: 100%;
    height: px2rem(60);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: px2rem(32);
    background: #edeef6;

    .marpuees_tu {
        width: 18%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: px2rem(32);
        color: #ff8a54;

        span{
            font-size: px2rem(28);
        }


        img {
            width: 24%;
        }
    }

    marquee {
        color: #336285;

        .marquee_one {
            margin-right: px2rem(40);
        }

        em {
            color: #ff8a54;
        }
    }
}

.main {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    margin-top: px2rem(150);
}
// 左侧菜单
.left_tab {
    width: px2rem(150);
    height: 100vh;
    background-color: #edeef6;
}
.left_tab .van-sidebar-item {
    width: px2rem(151);
    height: px2rem(100);
    line-height: px2rem(100);
    font-size: px2rem(26);
    background-color: #edeef6;
    padding: 0 0 0 px2rem(15);
}
.left_tab .van-sidebar-item--select {
    background-color: #fff;
    color: #336289;
}
.van-sidebar-item--select::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: px2rem(6);
    height: px2rem(36);
    background-color: #336189;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "";
}
.content {
    width: 100%;
}
</style>