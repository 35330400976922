<template>
    <div class="YhWithoutCodeLogin">
        <WithoutCodeLogin :UserNames="UserName" :PassWords="PassWord"></WithoutCodeLogin>
    </div>
</template>

<script>
import WithoutCodeLogin from "../../components/openCard/WithoutCodeLoginPublic.vue"
export default {
    components: { WithoutCodeLogin },
    data() {
        return {
            // 昆山早鸟
            UserName: "AHTZYHZYMD",
            PassWord: "Kq123456..@",
        }
    },

    created() {},
    // 组件方法
    methods: {},

    computed: {},

    mounted() {},
}
</script>

<style scoped lang='scss'></style>