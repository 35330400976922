<template>
    <div class="cancelForm">
        <!-- 导航栏 -->
        <topHead>
            <img
                slot="head"
                src="../../../assets/images/fh.png"
                alt=""
                @click="backBefor()"
            />
            <h3 slot="header">销户申请</h3>
        </topHead>
        <!-- 内容 -->
        <div class="main">
            <div class="wrap">
                <div class="title">
                    <h4>拆机原因</h4>
                </div>
                <textarea
                    placeholder="请填写拆机原因"
                    cols="30"
                    rows="10"
                    v-model="reason"
                ></textarea>
            </div>
            <!-- <div class="wrap">
                <div class="title"><h4>上传照片</h4></div>
                <div class="upImg">
                    <van-uploader v-model="fileList.imgPath1" :max-count="1">
                        <img src="../../../assets/images/sfz.png" alt="" />
                    </van-uploader>
                    <van-uploader v-model="fileList.imgPath2" :max-count="1">
                        <img src="../../../assets/images/fmsfz.png" alt="" />
                    </van-uploader>
                    <van-uploader v-model="fileList.imgPath3" :max-count="1">
                        <img src="../../../assets/images/scsfz.png" alt="" />
                    </van-uploader>
                </div>
            </div> -->
            <div class="wrap">
                <div class="title">
                    <h4>其他信息</h4>
                </div>
                <div class="ipt">
                    <div class="text">拆机号码</div>
                    <input
                        type="text"
                        maxlength="11"
                        placeholder="请输入拆机号码"
                        v-model="mobilePhone"
                    />
                </div>
                <div class="ipt">
                    <div class="text">服务密码</div>
                    <input
                        type="password"
                        placeholder="请输入服务密码"
                        v-model="phonePassword"
                    />
                </div>
                <!-- <div class="ipt" style="margin-top: 30px">
                    <div class="text">
                        <p>主叫通话记录</p>
                        <span>3-5条主叫通话记录</span>
                    </div>
                    <input
                        type="text"
                        maxlength="11"
                        placeholder="请输入第一条"
                        v-model="callRecord.record1"
                    />
                </div>
                <div class="ipt" style="width: 50%; margin-left: auto">
                    <input
                        type="text"
                        maxlength="11"
                        placeholder="请输入第二条"
                        v-model="callRecord.record2"
                    />
                </div>
                <div class="ipt" style="width: 50%; margin-left: auto">
                    <input
                        type="text"
                        maxlength="11"
                        placeholder="请输入第三条"
                        v-model="callRecord.record3"
                    />
                </div>
                <div class="ipt" style="width: 50%; margin-left: auto">
                    <input
                        type="text"
                        maxlength="11"
                        placeholder="请输入第四条"
                        v-model="callRecord.record4"
                    />
                </div>
                <div class="ipt">
                    <input
                        type="text"
                        maxlength="11"
                        placeholder="请输入第五条"
                        v-model="callRecord.record5"
                    />
                </div> -->
            </div>
            <div
                class="wrap"
                style="position: relative; border-bottom: 1px solid #dfe0e7"
            >
                <div class="text">是否退费</div>
                <van-radio-group v-model="radio">
                    <van-radio name="1">否</van-radio>
                    <van-radio name="2">是</van-radio>
                </van-radio-group>
            </div>
            <div class="wrap" v-if="radio == 2">
                <div class="ipt" style="margin-top: 30px">
                    <div class="text">
                        <p>账户名称</p>
                        <span>账户名称必须与机主一致</span>
                    </div>
                    <!-- <van-dropdown-menu active-color="#1989fa">
            <van-dropdown-item v-model="accountName" :options="option" />
          </van-dropdown-menu> -->
                    <input
                        type="text"
                        placeholder="请输入账户名称"
                        v-model="accountName"
                    />
                </div>
                <div class="ipt">
                    <div class="text">开户行</div>
                    <input
                        type="text"
                        maxlength="40"
                        placeholder="请输入开户行"
                        v-model="accountBank"
                    />
                    <!-- <input type="text" placeholder="请输入开户行" v-model="accountBank" /> -->
                </div>
                <div class="ipt">
                    <div class="text">账号</div>
                    <input
                        type="number"
                        placeholder="请输入账号"
                        v-model="bankCard"
                    />
                </div>
            </div>
            <div class="wrap">
                <div class="title">
                    <h4>备用联系方式</h4>
                </div>
                <div class="ipt">
                    <div class="text">其它手机号码</div>
                    <input
                        type="text"
                        maxlength="11"
                        placeholder="请输入手机号码"
                        v-model="sparePhone"
                    />
                </div>
            </div>
        </div>
        <!-- 底部按钮 -->
        <div class="bottom_btn">
            <van-button @click="btnHeadler">提交</van-button>
        </div>
    </div>
</template>

<script>
import { Toast, Dialog } from "vant"
import API from "../../../common/api/api"
import topHead from "../../../components/topHead.vue"
import FaceAuth from "../../../common/FaceAuth"
import Lanmao from "../../../components/lanmao/index"
export default {
    name: "cancelForm",
    data() {
        return {
            radio: "0",
            reason: "",
            mobilePhone: "",
            phonePassword: "",
            accountName: "",
            accountBank: "",
            bankCard: "",
            sparePhone: "",
            option: [],
            biz_no: "",
        }
    },
    components: {
        topHead,
    },
    mounted() {
        //this.headlerBank();
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL)
            window.addEventListener("popstate", this.backChange, false) //false阻止默认事件
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            FaceAuth.check("UNSET_ACCONT")
                .then(res => {
                    if (res.data) {
                        if (res.data.code == -100) {
                            Toast({
                                message: res.data.msg,
                                position: "bottom",
                            })
                            this.$router.push("/cancel")
                        }
                    } else {
                        if (res.biz_no != "") {
                            Toast({
                                message: "身份核验成功",
                                forbidClick: true,
                                position: "bottom",
                            })
                            vm.biz_no = res.biz_no
                            next()
                        }
                    }
                })
                .catch(() => {
                    Lanmao.alert({
                        title: "提示",
                        message: "身份核验失败，请重试",
                        confirm: () => {
                            next({ path: "/cancel", replace: true })
                        },
                    })
                })
        })
    },
    destroyed() {
        window.removeEventListener("popstate", this.backChange, false) //false阻止默认事件
    },
    methods: {
        //点击切换银行
        onConfirm() {
            this.$refs.item.toggle()
        },
        /*
        //获取银行列表
        headlerBank() {
            let token = localStorage.getItem("token")
            API.getJson("ACCOUNT_BANK", {
                token: token,
            }).then(res => {
                this.option = res.option
            })
        },
        */

        //提交信息
        btnHeadler() {
            let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (this.reason == "") {
                Toast({
                    message: "请填写拆机原因",
                    position: "bottom",
                })
                return
            }
            if (this.mobilePhone == "") {
                Toast({
                    message: "请填写拆机号码",
                    position: "bottom",
                })
                return
            } else if (!phone.test(this.mobilePhone)) {
                Toast({
                    message: "请输入正确的手机号",
                    position: "bottom",
                })
                return
            }
            if (this.phonePassword == "") {
                Toast({
                    message: "请填写服务密码",
                    position: "bottom",
                })
                return
            }
            if (!phone.test(this.sparePhone)) {
                Toast({
                    message: "请输入正确的备用手机号",
                    position: "bottom",
                })
                return
            }
            if (this.radio == 0) {
                Toast({
                    message: "请选择是否退费",
                    position: "bottom",
                })
                return
            }
            if (this.radio == 2) {
                if (this.accountName == 0) {
                    Toast({
                        message: "请选择银行",
                        position: "bottom",
                    })
                    return
                } else if (this.accountBank == "") {
                    Toast({
                        message: "请填写开户行",
                        position: "bottom",
                    })
                    return
                } else if (this.bankCard == "") {
                    Toast({
                        message: "请输入账号",
                        position: "bottom",
                    })
                    return
                }
            }
            API.getJson("ACCOUNT_FORM", {
                biz_no: this.biz_no,
                reason: this.reason,
                mobilePhone: this.mobilePhone,
                phonePassword: this.phonePassword,
                accountName: this.accountName,
                accountBank: this.accountBank,
                bankCard: this.bankCard,
                sparePhone: this.sparePhone,
            }).then(res => {
                if (res.code == 1) {
                    this.$router.push({ path: "/auditCabcel" })
                } else if (res.data.code == -100) {
                    Toast({
                        message: res.data.msg,
                        position: "bottom",
                    })
                } else {
                    Toast({
                        message: "提交数据异常",
                        position: "bottom",
                    })
                }
            })
        },
        backBefor() {
            Dialog.confirm({
                message: "您确定退出销户申请吗",
            })
                .then(() => {
                    // on confirm
                    this.$router.replace("/")
                })
                .catch(() => {
                    // on cancel
                })
            // self.location = self.location.origin
        },
        // BBBB.vue
        backChange() {
            const that = this
            console.log("监听到了")
            history.pushState(null, null, document.URL)
            setTimeout(() => {
                Dialog.confirm({
                    message: "您确定退出销户申请吗",
                })
                    .then(() => {
                        // on confirm
                        this.$router.replace("/")
                    })
                    .catch(() => {
                        // on cancel
                    })
            }, 100)
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30) px2rem(150);
}
.wrap:nth-child(4) {
    position: relative;
}
.wrap:nth-child(1) .title h4,
.wrap:nth-child(2) .title h4 {
    margin-bottom: px2rem(20);
}
.wrap .title h4 {
    font-size: px2rem(30);
    color: #333333;
    margin-top: px2rem(35);
}
.wrap textarea {
    width: 100%;
    height: px2rem(210);
    background-color: #f6f6f6;
    border-radius: px2rem(8);
    font-size: px2rem(28);
    padding: px2rem(40) px2rem(30);
    box-sizing: border-box;
}
.wrap .upImg {
    display: flex;
    justify-content: space-between;
}
.wrap .van-uploader,
.wrap .van-uploader img {
    width: px2rem(220);
    height: px2rem(160);
    border-radius: px2rem(8);
    overflow: hidden;
}
.wrap .ipt {
    width: 100%;
    position: relative;
    height: px2rem(97);
    font-size: px2rem(28);
    border-bottom: 1px solid #dfe0e7;
}
.wrap .text {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    top: 0;
    height: px2rem(97);
    font-size: px2rem(28);
}
.wrap .ipt .text span {
    flex: 0 0 100%;
    font-size: px2rem(28);
    color: #999999;
    margin-top: px2rem(-20);
}
.wrap .ipt input {
    width: 100%;
    height: px2rem(97);
    text-align: right;
}
// 底部按钮.
.bottom_btn {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}
.bottom_btn .van-button {
    width: 100%;
    height: px2rem(98);
    line-height: px2rem(98);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin: px2rem(50) auto 0;
    box-shadow: 0 px2rem(-10) px2rem(20) rgba($color: #000000, $alpha: 0.2);
}
// 单选框
.van-radio {
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
.van-radio-group {
    display: flex;
    justify-content: flex-end;
    height: px2rem(97);
}
</style>