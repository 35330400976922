<template>
    <div id="app">
        <div class="bg" style="background: #ececec">
            <!--  <img src="imgs/home-default17.jpg" width="100%" height="100%;"> -->
        </div>
        <div data-role="widget" data-widget="nav4" class="nav4">
            <nav>
                <div id="nav4_ul" class="nav_4">
                    <ul class="box">
                        <li v-for="(item, index) in MENU_CONFIG" :key="index">
                            <a href="javascript:;" class="on"
                                ><span>{{ item.title }}</span></a
                            >
                            <dl>
                                <dd
                                    v-for="(v, k) in item.list"
                                    :key="k"
                                    @click="TOkf(k), token(k)"
                                >
                                    <router-link :to="v.url" v-if="v.url">
                                        <a href="javascript:void(0);">
                                            <span>{{ v.title }}</span>
                                        </a>
                                    </router-link>
                                    <a href="javascript:void(0);" v-if="!v.url"
                                        ><span>{{ v.title }}</span></a
                                    >
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
            </nav>
            <div id="nav4_masklayer" class="masklayer_div on"></div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import API from "../../common/api/api"
import CONFIG from "../../common/config.js"
import router from "../../router"
export default {
    name: "transfer",
    data() {
        return {
            HOST: "http://tianfuzangbao.dev.com/index.php?",
            MENU_CONFIG: [
                {
                    title: "营业厅",
                    list: [
                        {
                            title: "号码充值",
                            url: "/Recharge",
                        },
                        {
                            title: "业务查询",
                            url: "/BusinessQuery",
                        },
                        {
                            title: "业务办理",
                            url: "/BusinessHandle",
                        },
                        {
                            title: "账号绑定",
                            url: "/AccountBind",
                        },
                    ],
                },
                {
                    title: "活动商城",
                    list: [
                        { title: "选购号码", url: "" },
                        { title: "特惠商城", url: "/activity" },
                    ],
                },
                {
                    title: "会员中心",
                    list: [
                        {
                            title: "个人信息",
                            url: "/personal",
                        },
                        {
                            title: "我的权益",
                            url: "",
                        },
                        { title: "积分兑换", url: "/integral" },
                        { title: "其他服务", url: "/other" },
                        { title: "在线客服", url: "" },
                        { title: "清除token", url: "" },
                    ],
                },
            ],

            site: "",
            nickType: "",
            clientId: "",
            otherParams: "",
        }
    },
    watch: {},
    created() {},

    components: {},
    beforeRouteEnter: (to, from, next) => {
        next(vm => {
            console.log("路由守卫", to)
        })
    },
    methods: {
        TOkf(index) {
            if (index == 4) {
                let appId = CONFIG.APPID
                let pageUrl = window.location.href
                let wechatAuth2URL =
                    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                    appId +
                    "&redirect_uri=" +
                    encodeURIComponent(pageUrl) +
                    "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
                window.location.href = wechatAuth2URL
                var token = localStorage.getItem("token")
                console.log("获取的token", token)
                if (token) {
                    API.getJson("wx_url", {
                        token: token,
                    }).then(res => {
                        console.log("101")
                        try {
                            if (res.list[0].nickName != null) {
                                console.log("103")
                                //获取三个参数拼接的新链接
                                this.nickType = res.list[0].nickType
                                this.clientId = res.list[0].nickId
                                this.otherParams = `{"nickName":"${res.list[0].nickName}"}`
                                let sit = `https://webchat.7moor.com/wapchat.html?accessId=b8e25cb0-5ca3-11ec-99c8-3739e01ad41c&fromUrl=&urlTitle="${this.nickType}"&language=ZHCN&clientId=${this.clientId}&otherParams=${this.otherParams}`
                                window.location.href = sit
                            }
                        } catch (err) {
                            if (res.data.code == -100) {
                                console.log("102")
                                console.log("还未登录还未登录")

                                clearTimeout(this.timer)
                                this.timer = setTimeout(() => {
                                    console.log("ok")
                                }, 3000)

                                API.getJson("wx_url", {
                                    token: token,
                                }).then(res => {
                                    console.log(res)
                                    this.nickType = res.list[0].nickType
                                    this.clientId = res.list[0].nickId
                                    this.otherParams = `{"nickName":"${res.list[0].nickName}"}`
                                    let sit = `https://webchat.7moor.com/wapchat.html?accessId=b8e25cb0-5ca3-11ec-99c8-3739e01ad41c&fromUrl=&urlTitle="${this.nickType}"&language=ZHCN&clientId=${this.clientId}&otherParams=${this.otherParams}`
                                    window.location.href = sit
                                })
                            }
                        }
                    })
                } else {
                    //用户未登录进行登录
                }
            }
        },
        token(v) {
            if (v == 5) {
                localStorage.removeItem("token")
            }
        },
    },
}
</script>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
}

.bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
}

ul,
ol,
li,
dl {
    list-style-type: none;
}

.box {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.box > * {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
}

a:link,
a:visited {
    color: #575757;
    text-decoration: none;
}

a {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.35);
}

a:link,
a:visited {
    color: #575757;
    text-decoration: none;
}

a {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.35);
}

.nav4 {
    height: px2rem(103.5);
}

.nav4 ul {
    position: fixed;
    z-index: 200;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
}

.nav4 li {
    flex: 1;
    border: px2rem(2.3) solid rgba(190, 190, 190, 1);
    border-right: 0;
    border-left: 0;
    height: px2rem(103.5);
    border-bottom: 0;
    border-right: 0;
    position: relative;
    -webkit-box-shadow: inset 0 0 px2rem(6.9) #fff;
}

.nav4 li:nth-of-type(1) {
    border-left: 0;
}

.nav4 li > a {
    font-size: px2rem(32.2);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /*border:1px solid #f9f8f9;*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-bottom: 0;
    display: block;
    line-height: px2rem(103.5);
    text-align: center;
    background: #f7f7f7;
}

.nav4 li > a:only-child span {
    background: none;
    padding-left: 0;
}

.nav4 li > a.on + dl {
    display: block;
}

.nav4 li > a span {
    color: #4f4d4f;
    display: inline-block;
    /*padding-left: 15px;*/
    /*background: url(imgs/1.svg#2) no-repeat 4px 18px;  */
    -webkit-background-size: px2rem(20.7) auto;
    text-shadow: 0px px2rem(2.3) 0px #ffffff;
}

/***********************/
.nav4 dl {
    display: none;
    position: absolute;
    z-index: 220;
    bottom: px2rem(105.8);
    left: 50%;
    width: px2rem(230);
    margin-left: px2rem(-115);
    background: red;
    /*min-height:100px;*/
    background: #e4e3e2;
    /*border:1px solid #afaeaf;*/
    /*   border-radius:5px; */
    -webkit-box-shadow: 0 0 px2rem(6.9) #ececec;
    background-color: #fbfffe;
    -webkit-background-size: 100%;
    background-size: 100%;
}

/*, .nav4 dl:after*/
.nav4 dl:before {
    content: "";
    display: inline-block;
    position: absolute;
    z-index: 240;
    bottom: 0;
    left: 50%;
    /*width:0; 
    height:0; 
    border:8px solid red; 
    border-color:#afaeaf transparent transparent transparent; 
    margin-left:-8px; 
    margin-bottom:-16px;*/
    width: px2rem(23);
    height: px2rem(18.4);
    /* background: url(imgs/1.svg#2) no-repeat center -55px; */
    -webkit-background-size: px2rem(23) auto;
    bottom: px2rem(-16.1);
    margin-left: px2rem(-11.5);
}

/*.nav4 dl:after{ 
    z-index:241; 
    border-color:#e4e3e2 transparent transparent transparent; 
    margin-bottom:-15px; 
}*/
.nav4 dl dd {
    line-height: px2rem(103.5);
    text-align: center;
    background: -webkit-gradient(
        linear,
        0 0,
        100% 0,
        from(rgba(194, 194, 194, 0.3)),
        to(rgba(194, 194, 194, 0.3)),
        color-stop(50%, rgba(194, 194, 194, 0.3))
    );
    background-size: 100% px2rem(2.3);
    background-repeat: no-repeat;
    background-position: center bottom;
    /*background: url(imgs/3.svg#4) no-repeat center bottom; 
    -webkit-background-size:100px 1px;*/
}

.nav4 dl dd:last-of-type {
    background: none;
}

.nav4 dl dd a {
    font-size: px2rem(27.6);
    display: block;
    color: #4f4d4f;
    text-shadow: 0px px2rem(2.3) 0px #ffffff;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nav4 .masklayer_div {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 180;
    background: #eeeeee;
}

.nav4 .masklayer_div.on {
    display: block;
}
</style>