<template>
    <div class="realNameLinkSuccess">
        <!-- 导航栏 -->
        <div class="header">
            <h3>实名补录</h3>
        </div>
        <!-- 内容 -->
        <div class="main">
            <div class="hit">
                <img src="../../assets/images/cg.png" alt="">
                <p>订单已提交，请退出此页面</p>
                <!-- <em>请在已订购业务菜单中查询是否办理成功</em> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
        }
    },

    created() { },
    // 组件方法
    methods: {},


    computed: {},

    watch: {},

    mounted() { },
}
</script>

<style scoped lang='scss'>
.realNameLinkSuccess {
    height: 100%;

    .header {
        height: px2rem(60);
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            font-size: px2rem(38);
        }
    }

    .main {
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;

        .hit {
            height: 40%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            img {
                width: px2rem(147);
                height: px2rem(118);
                margin: px2rem(50) 0 px2rem(40);
            }

            p {
                font-size: px2rem(32);
                font-weight: 700;
            }
        }
    }
}
</style>