import Vue from 'vue'
import Vuex from 'vuex'
import API from "../common/api/api"
import pointsExchange from "./modules/pointsExchange" //积分兑换
import bill from "./modules/bill" //账单查询
import opencard from "./modules/opencard" //开卡

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        member: [],
        verifyCode: '获取验证码',
        isNone: false,
        load: false,
        time: '',
        list: [],
        content: [],
        data: [],
        userdata: [], //工号登录信息
        CheckPhoneData: [], //号卡有效性验证
        ChanelCheckData: [], //渠道校验的数据
        cardNum: {}, //实名认证信息
        simple: {}, //实名认证信息
        QuerySigndata: {} //提交加密
    },
    mutations: {
        // 添加工号登录信息
        Userdata: (state, data) => {
            state.userdata = data
        },
        //实名认证信息
        CardNum: (state, data) => {
            console.log("", data);
            state.cardNum = data
        },
        Simple: (state, data) => {
            console.log("", data);
            state.simple = data
        },
        //提交加密信息
        QuerySigns: (state, data) => {
            console.log("提交加密", data);
            state.QuerySigndata = data
        },
        // 获取个人信息
        getData(state) {
            API.getJson("PERSONAL_DATA", {}).then(res => {
                state.member = res
                state.time = 0
            })
        },
        // 解除绑定
        relieveHeadler(state) {
            state.member = [];
            state.time = 0
        },
        //发送验证码
        async obtainCode(state, options) {
            var index = null
            state.time = 60
            state.load = true
            try {
                let result = await API.getJson("SEND_SMS_CODE", options)
                setTimeout(() => {
                    state.load = false
                }, 3000)
            } catch (err) {
                state.load = false
                console.log(err, "err err err")
                return
            }
            index = setInterval(function() {
                state.verifyCode = state.time--
                    if (state.time == -1) {
                        clearTimeout(index)
                        state.time = 60
                        state.verifyCode = "获取验证码"
                        state.isNone = false
                    } else {
                        state.isNone = true
                    }
            }, 1000)
        },
        //漏电提醒 来电显示 套餐列表
        handle(state, step) {
            API.getJson("HANDLE_REMIND_LIST", {
                type: step
            }).then(res => {
                state.list = res.list;
            })
        },
        //漏电提醒 来电显示 开通办理页
        openHandler(state, step) {
            API.getJson("PACKAGE_CONTENT", {
                packageId: step
            }).then(res => {
                state.content = res.list;
            })
        },
        //查账单
        queryBiil(state, step) {
            let token = localStorage.getItem("token");
            console.log(token);
            API.getJson("QUERY_BILL_DETAILS", {
                date: step,
                token: token
            }).then(res => {
                state.data = res
            })
        },
        //减去扣除的积分
        updatePointsBalance(state, points) {
            state.member.mobilePhoneInfo.pointInfo.currentPoints -= points
        },
        //设置会员信息变量
        setMember(state, info) {
            state.member = info
        },
        //更新会员积分信息
        updateMember(state, number) {
            state.member.mobilePhoneInfo.pointInfo.currentPoints += number;
            state.member.mobilePhoneInfo.pointInfo.grandTotalPoints += number;
        },
        //重置会员信息变量
        resetMember(state) {
            state.member = []
        },

    },
    actions: {
        //获取会员信息
        getMember({ commit, state }) {
            if (state.member.memberInfo) {
                return;
            }
            API.getJson("PERSONAL_DATA", {}).then(res => {
                commit('setMember', res)
            })
        }


    },
    modules: {
        pointsExchange,
        bill,
        opencard
    }
})