<template>
  <van-overlay :show="show">
  	<div class="wrapper">
  		<div class="block">
  			<div class="conter">
  				<p>{{title}}</p>
  			</div>
  			<div class="bottom">
  				<img :src="ewmImg" style="width:100%">
  				<div style="margin-top: 20px;">{{message}}</div>
  			</div>
  			<div class="btn">
  				<!-- <p class="back" @click="cancel">取消</p> -->
  				<p class="pay" @click="cancel">取消</p>
  			</div>
  		</div>
  	</div>
  </van-overlay>
</template>

<script>
import { Overlay } from "vant"
export default {
    name: "Confirm",
    data() {
        return {
            title: "",
            message: "",
			ewmImg:'',
            show: false,
            // cancelText: "取消",
            // confirmText: "确定",
        }
    },
    components: { "van-overlay": Overlay },
    methods: {
        confirm() {
            // this.$emit("confirm")
        },
        cancel() {
            // this.$emit("cancel")
        },
    },
}
</script>

<style lang='scss' scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.block {
    width: px2rem(600);
    padding: px2rem(50) 0 px2rem(40);
    box-sizing: border-box;
    background-color: #fff;
    border-radius: px2rem(10);
}
.conter {
    text-align: center;
    color: #333333;
    font-size: px2rem(30);
    font-weight: bold;
    // line-height: px2rem(58);
    // border-bottom: 1px solid #dfe0e7;
}
.bottom {
    line-height: px2rem(40);
    font-size: px2rem(32);
    color: #555;
    text-align: center;
    padding: px2rem(32);
}
.btn {
    display: flex;
    justify-content: center;
    padding: 0 px2rem(60);
}
.pay {
    color: #fff;
    background-color: #336289;
}
.back {
    color: #336289;
    background-color: #bdcbd0;
}

.btn p {
    width: px2rem(210);
    height: px2rem(70);
    line-height: px2rem(70);
    font-size: px2rem(28);
    font-weight: bold;
    color: #336289;
    box-shadow: 0 px2rem(5) px2rem(10) rgba($color: #000000, $alpha: 0.2);
    border-radius: px2rem(30);
    text-align: center;
    background-color: #bdcbd0;
}
.btn .pay {
    background-color: #336289;
    color: #fff;
}
</style>