import Axios from 'axios'
import env from './env'
import { Toast, Dialog } from 'vant';
import Lanmao from "../../components/lanmao/index"


// 二次封装axios
const service = Axios.create({
    // 设置接口基地址
    baseURL: env.prod.baseUrl,
    timeout: 30000,


})

// 添加请求拦截器
service.interceptors.request.use(function (config) {

    // console.log('请求拦截器', config)
    //如果url不等login即登录模块，则要获取本地token，配置header头
    const token = localStorage.getItem('tokens');
    const workNo = localStorage.getItem('workNo');
    if (token) {
        config.headers['thirdSession'] = token
        config.headers['workNo'] = workNo
        headers: {
            // 'Content-Type': 'application/json;charset=utf-8',
        }
    }


    // 在发送请求之前做些什么
    return config
}, function (error) {
    // 对请求错误做些什么
    Toast({
        message: '系统更新中，请稍后重试...',
        position: 'bottom',
    });
    return Promise.resolve(error)
})

// 添加响应拦截器
service.interceptors.response.use(function (res) {
    console.log('返回数据:', res)
    if (res.status == 200) {
        console.log('接口状态码：', '【' + res.data.code + '】');
        if (res.data.code == 0) {

            return Promise.resolve(res.data)
        } else if (res.data.code == 1) {

            return Promise.resolve(res.data)
        } else if (res.data.code == -100) {
            // Toast({
            //     message: res.data.msg,
            //     position: 'bottom',
            // });
            return Promise.resolve(res.data)
            // 60001：登录过期 60003：账号在另一部手机登录
        } else if (res.data.code == 60001 || res.data.code == 60002 || res.data.code == 60003) {
            Toast({
                message: "请关闭当前链接，重新进入进行开卡。",
                position: 'bottom',
            });
            if (Lanmao.loading.hide) {
                Lanmao.loading.hide()
            }
        } else if (res.config.url.slice(0, 21) == "/api/gzh/captchaImage") {
            return Promise.resolve(res.data)
        } else {
            //未知错误
            Toast({
                message: res.data.msg,
                position: 'bottom',
            });
            return Promise.resolve(res.data)
        }

    }
}, function (error) {
    if (Lanmao.loading.hide) {
        Lanmao.loading.hide()
    }
    Toast({
        message: '系统更新中，请稍后重试...',
        position: 'bottom',
    });

    // 对响应错误做点什么
    return Promise.reject(error)
})

export default service