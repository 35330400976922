<template>
    <div class="realNameLinkForm">
        <!-- 内容 -->
        <div class="main">
            <div class="wrap">
                <div class="title">
                    <h4>联系方式</h4>
                </div>
                <div class="ipt">
                    <div class="text">手机号码</div>
                    <input type="text" maxlength="11" placeholder="请输入手机号码" v-model="sparePhone" />
                </div>
            </div>
        </div>
        <!-- 底部按钮 -->
        <div class="bottom_btn">
            <van-button @click="btnHeadler">提交</van-button>
        </div>
    </div>
</template>

<script>
import { NewGetImgPath, NewTransAcctTForm } from "../../common/newapi/ghsm_api"
import Lanmao from "../../components/lanmao/index"
import { Toast } from 'vant'
export default {
    components: {},
    data() {
        return {
            sparePhone: "", // 用户联系方式
            biz_no: "",
            id: ""
        }
    },

    created() { },
    // 组件方法
    methods: {
        //提交信息
        async btnHeadler() {
            let phone = /^[1][3,4,5,6,7,8][0-9]{9}$/
            if (this.sparePhone == "") {
                Toast({
                    message: "请填写联系号码",
                    position: "bottom",
                })
                return
            } else if (!phone.test(this.sparePhone)) {
                Toast({
                    message: "请输入正确的联系手机号",
                    position: "bottom",
                })
                return
            }
            if (sessionStorage.getItem("recordNumber") == "noid") {
                this.id = ""
            } else {
                this.id = sessionStorage.getItem("recordNumber")
            }
            let FormData = await NewTransAcctTForm({
                biz_no: this.biz_no,
                sparePhone: this.sparePhone,
                id: this.id
            })
            if (FormData.code != 1) {
                Toast({
                    message: FormData.msg,
                    position: "bottom",
                })
            } else {
                Toast({
                    message: "资料提交成功!!",
                    position: "bottom",
                })
                sessionStorage.removeItem("recordNumber")
                this.$router.push("/realNameLinkSuccess")
            }
        },
    },

    computed: {},

    watch: {},

    mounted() { },
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            Lanmao.loading.show("身份校验中")
            let getimgpath = await NewGetImgPath({
                biz_no: sessionStorage.getItem("biz_no")
            })
            if (getimgpath.code != 1) {
                Toast({
                    message: getimgpath.msg,
                    position: "bottom",
                })
                Lanmao.loading.hide()
                next({ path: "/realNameLinkError" })
            } else {
                Toast({
                    message: "身份核验成功",
                    forbidClick: true,
                    position: "bottom",
                })
                Lanmao.loading.hide()
                sessionStorage.removeItem("biz_no")
                vm.biz_no = getimgpath.biz_no
                next()
            }
        })
    },
}
</script>

<style scoped lang='scss'>
.realNameLinkForm {
    .main {
        padding: 0 px2rem(30) px2rem(150);

        .wrap {
            .title {
                font-size: px2rem(30);
                color: #333333;
                margin-top: px2rem(35);
            }

            .ipt {
                width: 100%;
                position: relative;
                height: px2rem(97);
                font-size: px2rem(28);
                border-bottom: 1px solid #dfe0e7;

                .text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: px2rem(97);
                    font-size: px2rem(28);
                }

                input {
                    width: 100%;
                    height: px2rem(97);
                    text-align: right;
                }
            }
        }

    }

    // 底部按钮.
    .bottom_btn {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;

        .van-button {
            width: 100%;
            height: px2rem(98);
            line-height: px2rem(98);
            color: #fff;
            font-weight: bold;
            font-size: px2rem(28);
            background-color: #336289;
            margin: px2rem(50) auto 0;
            box-shadow: 0 px2rem(-10) px2rem(20) rgba($color: #000000, $alpha: 0.2);
        }
    }
}
</style>