<template>
    <div class="balance">
        <!-- 导航栏 -->
        <topHead><h3 slot="header">余额详情</h3></topHead>
        <!-- 内容 -->
        <div class="main">
            <div class="balance_top">
                <p>您好，截至目前为止，您的账户余额为:</p>
                <p>{{ amount }}</p>
            </div>

            <Echarts
                v-if="data.length > 0"
                :color="color"
                :title="title"
                :data="data"
                :dataVal="dataVal"
            ></Echarts>

            <div class="explain">
                <div class="explain_title">说明</div>
                <p v-html="explain"></p>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../common/api/api"
import topHead from "../../components/topHead.vue"
import Echarts from "../../components/echarts.vue"
export default {
    name: "balance",
    data() {
        return {
            explain:'',
            amount: "",
            color: [],
            title: [],
            data: [],
            dataVal: []
        }
    },
    created() {
        this.echartsData()
    },
    methods: {
        echartsData() {
            let token = localStorage.getItem("token")
            API.getJson("QUERY_BALANCE", {
                token: token
            }).then(res => {
                    this.amount = res.amount,
                    this.color = res.color,
                    this.title = res.title,
                    this.data = res.data,
                    this.dataVal = res.dataVal,
                    this.explain = res.explain
            })
        }
    },
    components: {
        topHead,
        Echarts
    }
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
}
.balance_top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: px2rem(90);
    line-height: px2rem(90);
    background-color: #336189;
    border-radius: px2rem(18);
    color: #fff;
    font-size: px2rem(26);
    padding: 0 px2rem(20);
    box-sizing: border-box;
    margin-top: px2rem(20);
    box-shadow: 0 0 px2rem(20) rgba($color: #000000, $alpha: 0.2);
    font-weight: bold;
}
.balance_top p:nth-child(2) {
    font-weight: bold;
    font-size: px2rem(36);
}

.explain {
    width: 100%;
    border-radius: px2rem(18);
    background-color: #e5eaed;
    padding: px2rem(28);
    box-sizing: border-box;
}
.explain_title {
    font-weight: bold;
    margin-bottom: px2rem(15);
    font-size: px2rem(26);
    color: #336289;
}
.explain p {
    font-size: px2rem(22);
    color: #333;
    line-height: px2rem(44);
}
</style>
