<template>
    <div class="detail">
        <!-- 导航栏 -->
        <topHead style="background: #fff; position: fixed; left: 0; top: 0;z-index:99999;"
            ><h3 slot="header">评论详情</h3></topHead
        >
        <!-- 内容 -->
        <div class="main">
            <div class="content">
                <img
                    :src="cont.face"
                    class="commentFace"
                    alt=""
                />
                <div class="commentBody">
                    <div class="commentBox">
                        <div class="commentInfo">
                            <p class="name">{{cont.name}}</p>
                            <p class="time">{{cont.time}}</p>
                        </div>
                        <div class="commentGood" @click.stop @click="myLike(cont.id,cont.myLike)">
                            <img v-if="cont.myLike == 0" src="../../assets/images/aimg/wei_d@2x.png"  alt=""/>
                            <img v-else src="../../assets/images/aimg/yi_d@2x.png"  alt=""/>
                            <p :class="cont.myLike == 0?'none':'yes'">{{cont.total_good == 0 ? '点赞' : cont.total_good }}</p>
                        </div>
                        <div class="commentCont">
                            {{cont.text}}
                        </div>
                        <div class="commentReplybtn" @click="replyBtn()">
                            <img
                                src="../../assets/images/aimg/hui_f@2x.png"
                                alt=""
                            />回复
                        </div>
                    </div>
                </div>
            </div>
            <van-divider>全部评论</van-divider>
            <div class="reply" v-for="(item,index) in reply" :key="index">
                <img :src="item.face" class="commentFace" alt=""/>
                <div class="commentBody">
                    <div class="commentBox">
                        <div class="commentInfo">
                            <p class="name replyName">{{item.name}}</p>
                            <p class="is_official" v-if="item.is_official == true">官方回复</p>
                        </div>
                        <div class="commentCont">
                          <span class="myReply">{{item.content}}</span>
                          <span class="replied" v-if="item.reply_name != ''">//@{{item.reply_name}}:</span>
                          <span class="repliedCont" v-if="item.reply_name != ''">{{item.reply_content}}</span>
                        </div>
                        <div class="commentReplybtn" @click="replyBtn(item.reply_id)">
                            <img src="../../assets/images/aimg/hui_f@2x.png" alt=""/>回复
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- 回复框 -->
        <van-overlay :show="input" @click="replayIpt"/>
        <div class="ipt">
            <input class="replyIpt" type="text" ref="input" v-model="text" />
            <button class="button"  @click="inpReplyBtn">回复</button>
        </div>

    </div>
</template>

<script>
import API from '../../common/api/api'
import { Toast, Dialog } from "vant"
import topHead from "../../components/topHead.vue"
export default {
    name: "detail",
    data() {
        return {
            text: "",
            reply:[],
            cont:'',
            id:'',
            page:0,
            total_page:'',
            input:false,
            reply_id:''
        }
    },
    created() {
        this.id = this.$route.query.id
        this.get_list();
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll, true);
    },
    components: {
        topHead,
    },
    methods: {
        handleScroll(e) {
            let doc_scrollTop =
                Math.ceil(e.target.documentElement.scrollTop) ||
                Math.ceil(e.target.body.scrollTop)
            let doc_scrollHeight = e.target.documentElement.scrollHeight
            let scrollHeight = e.target.body.offsetHeight

            if (doc_scrollTop + scrollHeight >= doc_scrollHeight) {
                if(this.total_page > this.page){
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true,
                        duration:1500
                    });
                    this.get_list();
                }
            }
        },
        //获取列表
        get_list(){
             API.getJson("replyDetails", {
                 id:this.id,
                 page:this.page+1,
                 page_size:15
             }).then(res => {
                 this.cont = res;
                 let arr = this.reply.concat(res.reply)
                 this.reply = arr;
                 this.page = res.page,
                 this.total_page = res.total_page
                 Toast.clear();
            })
        },
        //点赞
        myLike(id,state,index){
            API.getJson("myLike", {
                id:id,
                state,state
            }).then(res => {
                if(state == 0){
                    this.cont.myLike = 1
                    this.cont.total_good += 1
                }else{
                    this.cont.myLike = 0
                    this.cont.total_good -= 1
                }
            })
        },
        //单机蒙版
        replayIpt(){
            this.input = false;
        },
        //回复按钮
        inpReplyBtn(){
            API.getJson("sub_comment", {
                text:this.text,
                article_id:this.id,
                others_id:this.reply_id
            }).then(res => {
                Toast({
                    message:'提交成功',
                    position:'bottom'
                })
                this.input = false;
                this.text = '';
                this.reply_id = '';
                this.page = 0;
                this.total_page = '';
                this.get_list();
            })
        },
        //回复
        replyBtn(id){
            if(id != undefined){
                this.reply_id = id;
            }else{;
                this.reply_id = '';
            }
            this.input = true;
            this.$refs.input.focus();
        },
    },
}
</script>

<style lang="scss" scoped>
.van-divider{
    font-size: px2rem(24);
}
.main {
    padding: px2rem(90) px2rem(30) px2rem(80);
    background: #ffffff;
    box-sizing: border-box;
}
//内容
.content {
    display: flex;
    width: 100%;
    background: #ffffff;
    padding: px2rem(40);
    padding-bottom: 0;
    box-sizing: border-box;
}
.reply{
    display: flex;
    width: 100%;
    background: #ffffff;
    margin-bottom: px2rem(20);
    padding: 0 px2rem(40) 0;
    box-sizing: border-box;
}
.comment {
    display: flex;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: px2rem(43);
    padding-bottom: px2rem(20);
}
//头像
.commentFace,
.commentFace img {
    width: px2rem(60);
    height: px2rem(60);
    border-radius: 50%;
}
.commentBody {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: px2rem(555);
    box-sizing: border-box;
    margin-left: auto;
}
.commentBox {
    display: flex;
    flex-wrap: wrap;
}
//名字
.commentInfo {
    text-align: left;
    color: #999999;
    margin-left: px2rem(18);
}
.commentInfo .name {
    max-width: px2rem(400);
    font-size: px2rem(28);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    color: #333;
}
.commentInfo .replyName{
  font-weight: normal;
  margin-top: px2rem(15);
}
.commentInfo .time {
    font-size: px2rem(22);
}

.reply .commentInfo{
 display: flex; 
 justify-content: start;
 align-items: center;
}
.commentInfo .is_official{
    width: px2rem(120);
    height: px2rem(36);
    line-height: px2rem(36);
    text-align: center;
    font-size: px2rem(24);
    color: #FFF;
    background-color: #ffdd00;
    margin-top: px2rem(15);
    margin-left: px2rem(10);
    border-radius: px2rem(6);
}
//点赞
.commentGood {
    display: flex;
    margin-top: px2rem(7);
    margin-left: auto;
}
.commentGood img {
    width: px2rem(32);
    height: px2rem(32);
    vertical-align: middle;
    margin-right: px2rem(10);
}
.commentGood .none {
    font-size: px2rem(24);
    color: #999999;
    margin-top: px2rem(6);
}
.commentGood .yes {
    font-size: px2rem(24);
    color: #e83b3b;
    margin-top: px2rem(6);
}
//已采纳
.adopt {
    position: absolute;
    left: 50%;
    top: px2rem(25);
    width: px2rem(108);
    height: px2rem(108);
    margin-left: px2rem(-54);
}
//内容
.commentCont {
    width: px2rem(530);
    font-size: px2rem(24);
    color: #333333;
    line-height: px2rem(36);
    margin-left: auto;
    margin-top: px2rem(10);
}
.commentCont .replied{
  color: #999;
  margin-right: px2rem(10);
}
//回复
.commentReplybtn {
    font-size: px2rem(24);
    color: #999999;
    vertical-align: bottom;
    margin: px2rem(25) 0 px2rem(35);
}
.commentReplybtn img {
    width: px2rem(32);
    height: px2rem(32);
    vertical-align: top;
    margin: px2rem(-2) px2rem(10) 0;
}
.more {
    display: flex;
    align-items: center;
    font-size: px2rem(24);
    color: #999999;
    margin-left: px2rem(45);
}
.more img {
    width: px2rem(16);
    height: px2rem(10);
    margin-left: px2rem(5);
}
.iptNone {
    opacity: 0;
}

//回复框
.ipt {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: px2rem(100);
    background: #e4e2e2;
}
.replyIpt {
    width: 70%;
    height: px2rem(60);
    border-radius: px2rem(10);
    background: #ffffff;
    padding: 0 px2rem(15);
    box-sizing: border-box;
    font-size: px2rem(28);
    color: #999;
}
.button {
    width: px2rem(120);
    height: px2rem(60);
    line-height: px2rem(60);
    color: #fff;
    background: #ffdd00;
    font-size: px2rem(26);
    text-align: center;
    border-radius: px2rem(10);
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.block {
    width: px2rem(600);
    height: px2rem(350);
    background-color: #fff;
    border-radius: px2rem(16);
    text-align: center;
}
.block img {
    width: px2rem(150);
    height: px2rem(150);
    margin-top: px2rem(50);
}
.block p {
    font-size: px2rem(28);
    color: #333;
    margin-top: px2rem(50);
}

.paging {
    width: 100%;
    text-align: center;
    font-size: px2rem(28);
    color: #999;
}
</style>