import router from '../../router';
import { Toast } from "vant"
export const toLink = (name, query = {}) => {
    // if (name == "changeCard") {
    //     Toast({
    //         position: "bottom",
    //         message: "系统升级中，暂时无法换补卡",
    //         duration: 2000, //单位ms
    //     })
    // } else {
    let path = name == '/' ? '/' : '/' + name
    router.push({ path: path, query: query }).catch(err => { console.log(err) });
    // }

};