<template>
    <div class="historyBill">
        <!-- 导航栏 -->
        <topHead><h3 slot="header">历史账单</h3></topHead>
        <!-- 内容 -->
        <div class="main">
            <ul class="history_list">
                <li
                    v-for="(itme, index) in history"
                    :key="index"
                    @click="historyClick(index)"
                >
                    <p>{{ itme.date }}账单</p>
                    <span>￥{{ itme.amount }}</span
                    ><img src="../../assets/images/yjt.png" alt="" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import API from "../../common/api/api"
import topHead from "../../components/topHead.vue"
export default {
    name: "historyBill",
    data() {
        return {
            history: [],
        }
    },
    mounted() {
        this.queryHistoryBill()
    },
    methods: {
        queryHistoryBill() {
            let token = localStorage.getItem("token")
            API.getJson("QUERY_HISTORY_BILL", {
                token: token,
            }).then(res => {
                this.history = res.list
            })
        },
        historyClick(index) {
            this.$router.push({
                path: "/billDetails",
                query: { date: this.history[index].date },
            })
        },
    },
    components: {
        topHead,
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
}
.history_list li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: px2rem(88);
    line-height: px2rem(88);
    border-bottom: 1px solid #dfe0e7;
}
.history_list li p {
    font-size: px2rem(26);
    color: #333;
}
.history_list li span {
    font-size: px2rem(24);
    color: #ff7505;
    margin-left: auto;
}
.history_list li img {
    height: px2rem(32);
}
</style>