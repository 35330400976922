import API from '../../common/api/api'


const state = () => ({
    bill: null,
    historyBillList:[]
    
})

// getters
const getters = {

}

// actions
const actions = {
    //查询账单
    queryBill({ commit, state }, step = '') {
        if (state.bill && !step) {
            return;
        }
        let option = {};
        if (step) {
            option.date = step
        }
        API.getJson("QUERY_BILL_DETAILS", option).then(res => {
            commit("setBill", res);
        })
    },
     //查询账单 /mock/13/historybill
     historyBill({ commit, state }, step = '') {
        if (state.historyBillList.length) {
            return;
        }
       
        API.getJson("QUERY_HISTORY_BILL", {}).then(res => {
            commit("setHistoryBillList", res.list);
        })
    },


}

// mutations
const mutations = {
    resetBill(state){
        state.bill=null
    },
    setBill(state, data) {
        data.title = '您好截至目前为止，您本月的消费账单为：\n总费用：' + data.amount + '元';
        data.detailTitle= '总费用：' + data.amount + '元';
        state.bill = data
    },
    setHistoryBillList(state, list) {
        state.historyBillList = list
    },


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}