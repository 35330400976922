<template>
  <div class="integral">
    <!-- 导航栏 -->
    <topHead><h3 slot="header">我的积分</h3></topHead>

    <!-- 内容 -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>

    <!-- 底部 -->
    <div class="foot">
      <p
        :class="{ foot_active: currentBottomBarIndex == 0 }"
        @click="switchBottomBar('/integral/pointsExchange')"
      >
        积分兑换
      </p>

      <p
        :class="{ foot_active: currentBottomBarIndex == 1 }"
        @click="switchBottomBar('/integral/myPoints')"
      >
        我的积分
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import topHead from "../../components/topHead.vue";

export default {
  name: "integral",
  data() {
    return {
      currentBottomBarIndex: 0,
    };
  },
  created() {
    this.$nextTick(() => {
      this.changeBottomBar(this.$route.path);
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.changeBottomBar(to.path);
    next();
  },
  computed: {
    ...mapState("pointsExchange", ["bannerList", "productList"]),
  },
  components: {
    topHead,
  },
  methods: {
    //切换底部栏选中状态
    changeBottomBar(path) {
      let pathMap = { "/integral/pointsExchange": 0, "/integral/myPoints": 1 };
      this.currentBottomBarIndex = pathMap[path];
    },
    //点击底部栏跳转到指定页面
    switchBottomBar(path) {
      if (this.$route.path != path) {
       this.$router.replace({ path: path ,replace:true});
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.foot {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: px2rem(98);
  line-height: px2rem(98);
  font-size: px2rem(28);
  color: #333;
  background-color: #d3d4d4;
}
.foot p {
  width: 50%;
  text-align: center;
}
.foot_active {
  color: #fff;
  background-color: #336289;
}
</style>