<template>
  <div class="success">
    <!-- 导航栏 -->
    <topHead><h3 slot="header">吐槽专区</h3></topHead>
    <!-- 内容 -->
    <div class="main">
      <div class="img" @click="toMeassage">
        <img src="../../assets/images/aimg/index.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import topHead from "../../components/topHead.vue";
export default {
  name: "success",
  components: {
    topHead,
  },
  methods:{
    toMeassage(){
      this.$router.push({ path: 'message' })
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  padding: 0 px2rem(30);
  text-align: center;
}
.img{
  width: px2rem(690);
  height: px2rem(290);
  background: #FFDD00;
  border-radius: px2rem(20);
  margin-top: px2rem(20);
}
.img img{
  width: 100%;
  height: 100%;
}
</style>