<template>
    <div class="state">
        <!-- 导航栏 -->
        <topHead><h3 slot="header">开发票</h3></topHead>
        <!-- 内容 -->
        <div class="main">
            <van-tabs v-model="active">
                <van-tab title="未开票">
                    <div class="box">
                        <div class="list">
                            <div
                                class="wrap"
                                :class="{ btnImg: arr.indexOf(v.id) != -1 }"
                                v-for="(v, index) in notOpen"
                                :key="index"
                                @click="selectClick(v.id, index)"
                            >
                                <div class="wrap_btn fl"></div>
                                <div class="wrap_left fl">
                                    <h3>￥{{ v.amount }}</h3>
                                    <p>月结发票</p>
                                </div>
                                <div class="wrap_right fr">
                                    <p>{{ v.time }}</p>
                                </div>
                            </div>
                        </div>
                        <p class="bottom">最多展示1年内的记录</p>
                        <div class="btn">
                            <div class="select" @click="selectAll">
                                <div
                                    class="select_btn"
                                    :class="{
                                        select_btn_img:
                                            arr.length == notOpen.length,
                                    }"
                                ></div>
                                <p>全选</p>
                            </div>
                            <van-button type="default" @click="invoice"
                                >开发票</van-button
                            >
                        </div>
                    </div>
                </van-tab>
                <van-tab title="已开票">
                    <div class="already">
                        <div
                            class="wrap"
                            v-for="(item, index) in openedInvoice"
                            :key="index"
                            @click="goInvoiceSuccess(item.id)"
                        >
                            <div class="wrap_top">
                                <h3>￥{{ item.amount }}</h3>
                                <p>{{ item.time }}</p>
                            </div>
                            <div class="wrap_bottom">
                                <p>月结发票</p>
                                <p>
                                    查看<img
                                        src="../../../assets/images/yjt.png"
                                        alt=""
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import API from "../../../common/api/api"
import topHead from "../../../components/topHead.vue"
import { Dialog, Toast } from "vant"
export default {
    name: "state",
    data() {
        return {
            arr: [],
            active: 0,
            notOpen: [],
            openedInvoice: [],
            indexs: [],
        }
    },
    mounted() {
        this.getInvoiceList()
    },
    methods: {
        //获取发票列表
        getInvoiceList() {
            let token = localStorage.getItem("token")
            
            API.getJson("INVOICE_LIST", {
                token: token,
            }).then(res => {
                this.notOpen = res.notInvoice
                this.openedInvoice = res.openedInvoice
            })
        },
        //发票详情
        goInvoiceSuccess(id) {
            this.$router.push({ path: "InvoiceSuccess", query: { id: id } })
        },
        //开发票
        invoice() {
            let money = null
            let time = []
            if (this.arr.length != 0) {
                for (let index in this.indexs) {
                    money += this.notOpen[this.indexs[index]].amount
                    time.push(this.notOpen[this.indexs[index]].time.substr(5))
                }
                time.sort(function (a, b) {
                    return a - b
                })
                this.$router.push({
                    path: "/Invoice",
                    query: { money: money, time: time },
                })
            } else {
                Toast({
                    message: "请选择开票月份",
                    position: "bottom",
                })
            }
        },
        //全选单选
        selectClick(id, index) {
            // 下标
            var time = this.indexs.indexOf(index)
            if (time == -1) {
                this.indexs.push(index)
            } else {
                this.indexs.splice(time, 1)
            }
            // id
            var seek = this.arr.indexOf(id)
            if (seek == -1) {
                this.arr.push(id)
            } else {
                this.arr.splice(seek, 1)
            }
        },
        selectAll() {
            // 判断是否全选
            if (this.indexs.length == this.notOpen.length) {
                // 如果全选就清空
                this.indexs.splice(0, this.indexs.length)
            } else {
                // 否则先清空
                this.indexs.splice(0, this.indexs.length)
                // 再循环数据添加
                for (let index = 0; index < this.notOpen.length; index++) {
                    var all = index
                    this.indexs.push(all)
                }
            }

            // 判断是否全选
            if (this.arr.length == this.notOpen.length) {
                // 如果全选就清空
                this.arr.splice(0, this.arr.length)
            } else {
                // 否则先清空
                this.arr.splice(0, this.arr.length)
                // 再循环数据添加
                for (let index = 0; index < this.notOpen.length; index++) {
                    var all = this.notOpen[index].id
                    this.arr.push(all)
                }
            }
        },
    },
    components: {
        topHead,
    },
}
</script>

<style lang="scss" scoped>
.topHead {
    position: fixed !important;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: #fff;
}
.state {
    position: relative;
    height: 100vh;
}
.main {
    height: 100vh;
    overflow-x: auto;
    background-color: #f5f5f5;
}
.wrap {
    width: px2rem(690);
    height: px2rem(124);
    border-radius: px2rem(20);
    background-color: #fff;
    margin-top: px2rem(30);
    padding: px2rem(20) px2rem(37);
    box-sizing: border-box;
    font-size: px2rem(24);
    color: #5d5f64;
}
.wrap_top,
.wrap_bottom {
    display: flex;
    justify-content: space-between;
}
.wrap_top {
    margin-bottom: px2rem(18);
}
.wrap_top h3,
.wrap_left h3 {
    font-size: px2rem(30);
    color: #336289;
}
.wrap_bottom p img {
    width: px2rem(24);
    vertical-align: middle;
    margin-top: px2rem(-5);
}
.btnImg .wrap_btn {
    background: url(../../../assets/images/xz.png) no-repeat center center;
    background-size: cover;
}
.wrap_btn,
.select_btn {
    width: px2rem(38);
    height: px2rem(38);
    border-radius: 50%;
    background-color: #d0d0d0;
    margin-right: px2rem(22);
    margin-top: px2rem(23);
}
.wrap_left h3 {
    margin-bottom: px2rem(16);
}
.wrap_right {
    line-height: px2rem(84);
}
.box {
    width: 100%;
    height: 100%;
}
.list,
.already {
    margin: px2rem(220) 0 px2rem(200);
}
.bottom {
    position: fixed;
    left: 0;
    bottom: px2rem(98);
    width: 100%;
    height: px2rem(81);
    line-height: px2rem(81);
    font-size: px2rem(24);
    color: #999999;
    text-align: center;
    background-color: #f5f5f5;
}
.btn {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: px2rem(98);
    line-height: px2rem(98);
    background-color: #336289;
    padding: 0 px2rem(30) 0 px2rem(56);
    box-sizing: border-box;
}
.btn .van-button {
    width: px2rem(165);
    height: px2rem(60);
    line-height: px2rem(60);
    font-size: px2rem(24);
    font-weight: bold;
    color: #336289;
    border-radius: px2rem(10);
}
.select {
    display: flex;
    color: #fff;
    font-size: px2rem(24);
}
.select_btn {
    margin: px2rem(30) px2rem(15) 0 0;
}
.select_btn_img {
    background: url(../../../assets/images/qx.png) no-repeat center center;
    background-size: cover;
}
</style>