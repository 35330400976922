<template>
    <div class="orderQuery">
        <topHead class="topHead">
            <h3 slot="header">订单查询</h3>
        </topHead>
        <!-- 导航栏 -->
        <div class="main" v-if="detail == false">
            <div class="ipt">
                <p>手机号</p>
                <input type="text" maxlength="11" placeholder="请输入11位蓝猫手机号码" v-model="mobilePhone"
                    onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" />
            </div>
            <div class="ipt">
                <p>验证码</p>
                <input type="text" maxlength="8" placeholder="请输入验证码" v-model="code" />
                <button class="tab4_btn" :disabled="isNone" @click="codeHeadler">
                    {{ verifyCode }}
                </button>
            </div>
            <van-button round type="info" @click="orderQuery()">查询</van-button>
            <!-- 加载中 -->
            <load :load="load">
                <p slot="text" class="loadText">短信发送中...</p>
            </load>
        </div>
        <div class="main" v-if="detail == true">
            <div class="form_table">
                <div class="form_title">
                    <p>申请日期</p>
                    <p>办理业务名称</p>
                    <p>审核情况</p>
                    <p>详情</p>
                </div>
                <div class="form_cont" v-for="(item, index) in list" :key="index">
                    <p>{{ item.date }}</p>
                    <p>{{ item.name }}</p>
                    <p>{{ item.check }}</p>
                    <p class="form-cont-detail" @click="getDetail(item.oid, item.detail)">订单详情</p>
                </div>
            </div>
        </div>
        <!-- 弹出层 -->
        <van-overlay :show="isDetail" @click="overlayHide">
            <div class="wrapper" onclick="javascript:event.stopPropagation()">
                <div class="block">
                    <div class="top">
                        <img @click="isDetail = false" src="../../../assets/images/fh.png" alt="" />
                        <h5>{{ detailTitle }}</h5>
                    </div>
                    <div class="content">
                        <div v-for="(item, index) in content.detail" :key="index">
                            <span>{{ item.title }}:</span>
                            <em>{{ item.content }}</em>
                        </div>
                    </div>
                    <div class="tips">{{ content.tips }}</div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>
<script>
import { mapState } from "vuex"
import API from "../../../common/api/api"
import load from "../../../components/load.vue"
import topHead from "../../../components/topHead.vue"
import { Dialog, Toast } from "vant"
export default {
    name: "orderQuery",
    data() {
        return {
            mobilePhone: "",
            code: "",
            list: [],
            isDetail: false,
            detail: false,
            detailTitle: "",
            content: "",
        }
    },
    watch: {},
    created() { },
    components: {
        load,
        topHead,
    },
    computed: {
        ...mapState(["member", "verifyCode", "isNone", "load"]),
    },
    mounted() { },
    methods: {
        overlayHide() {
            this.isDetail = false
        },
        //获取验证码
        codeHeadler() {
            let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (this.mobilePhone == "") {
                Toast({
                    message: "请输入手机号",
                    position: "bottom",
                })
            } else if (!phone.test(this.mobilePhone)) {
                Toast({
                    message: "请输入正确的手机号",
                    position: "bottom",
                })
            } else {
                this.$store.commit("obtainCode", {
                    mobilePhone: this.mobilePhone,
                    type: "06",
                })
            }
        },
        //查询
        orderQuery() {
            let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (this.mobilePhone == "") {
                Toast({
                    message: "请输入手机号",
                    icon: "none",
                })
            } else if (this.code == "") {
                Toast({
                    message: "请输入验证码",
                    icon: "none",
                })
            } else if (!phone.test(this.mobilePhone)) {
                Toast({
                    message: "请输入正确的手机号",
                    icon: "none",
                })
            } else {
                API.getJson("ORDER_QUERY", {
                    mobilePhone: this.mobilePhone,
                    code: this.code,
                }).then(res => {
                    this.detail = true
                    this.list = res.list
                })
            }
        },
        //详情
        getDetail(id, title) {
            API.getJson("ORDER_DETAIL", {
                oid: id,
            }).then(res => {
                console.log(res)
                this.isDetail = true
                this.detailTitle = title
                this.content = res
            })
        },
    },
}
</script>
<style scoped lang='scss' >
.wrapper {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;


}

.block {
    position: relative;
    width: px2rem(690);
    max-height: 80vh;
    background-color: #fff;
    border-radius: px2rem(20);
    padding: 0 px2rem(30);
    box-sizing: border-box;
}

.block .top {
    position: relative;
    width: 100%;
    height: px2rem(98);
    line-height: px2rem(98);
    text-align: center;
}

.block .top h5 {
    font-size: px2rem(34);
}

.block .top img {
    position: absolute;
    left: 0;
    top: 50%;
    width: px2rem(32);
    height: px2rem(27);
    margin-top: px2rem(-27/2);
}

.block .content {
    font-size: px2rem(32);
    color: #333;
    margin-bottom: px2rem(80);
}

.block .content div {
    line-height: px2rem(80);
}

.block .tips {
    font-size: px2rem(32);
    color: rgb(224, 49, 49);
    padding-bottom: px2rem(80);
}

.main {
    padding: px2rem(100) px2rem(30) 0;
    box-sizing: border-box;

    .form_table {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #2f5fd5;

        p {
            width: 24.8%;
            text-align: center;
            border: px2rem(1) solid #2f5fd5;
            padding: px2rem(16);
        }


        .form_title {
            display: flex;
            justify-content: space-around;
            color: #fff;
            border: px2rem(1) solid #2f5fd5;
            font-size: px2rem(22);

            p {
                width: 24.8%;
                text-align: center;
                background: #2f5fd5;
            }
        }

        .form_cont {
            width: 100%;
            height: px2rem(60);
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: px2rem(26);

            p{
                width: 24.8%;
                height: 100%;
                border: px2rem(1) solid #2f5fd5;
            }

            :nth-child(4) {
                color: red;
                text-decoration: underline;
            }
        }
    }
}

.topHead {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    background: #fff;
    z-index: 99;
}


.ipt {
    position: relative;
    width: 100%;
    height: px2rem(100);
    background-color: #f6f6f6;
    border-radius: px2rem(18);
    margin-bottom: px2rem(30);
    font-size: px2rem(26);
    padding: 0 px2rem(30);
    box-sizing: border-box;
}

.ipt:nth-child(1) input {
    text-align: right;
}

.ipt input {
    width: 100%;
    height: px2rem(100);
    box-sizing: border-box;
}

.ipt:nth-child(2) input {
    padding: 0 px2rem(120);
}

.ipt p {
    position: absolute;
    left: px2rem(30);
    top: 0;
    height: px2rem(100);
    line-height: px2rem(100);
}

.tab4_btn {
    position: absolute;
    right: px2rem(30);
    top: px2rem(28);
    padding: 0 px2rem(20);
    height: px2rem(44);
    line-height: px2rem(44);
    color: #fff;
    font-size: px2rem(24);
    background-color: #336289;
    text-align: center;
    border-radius: px2rem(20);
}

.van-button {
    display: flex;
    justify-content: center;
    width: px2rem(550);
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin: px2rem(80) auto 0;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
}

.form-cont .form-cont-detail {
    text-decoration: underline;
    color: rgb(238, 32, 32);
}
</style>
