<template>
    <div class="echarts_list">
        <div id="myCharts" ref="myCharts"></div>
    </div>
</template>

<script>
export default {
    name: "echarts",
    props: ["color", "title", "data", "dataVal"],
    mounted() {
        const myCharts = this.$echarts.init(this.$refs.myCharts)
        let options = {
            // 全局调色盘。
            color: this.color,
            title: {
                text: this.title,
                bottom: 150,
                left: 20,
                textStyle: {
                    lineHeight: 100,
                    fontSize: 30,
                    color: "#333",
                    fontWeight: "normal",
                },
            },
            legend: {
                //位置至于底部
                bottom: 0,
                // left:0,
                width: "100%",
                //按钮宽高
                itemWidth: 60,
                itemHeight: 34,
                orient: "vertical",
                textStyle: {
                    fontSize: 24,
                },
                //间距
                itemGap: 18,
                align: "left",
                data: this.data,
            },
            series: {
                bottom: 200,
                name: "余额详情",
                type: "pie",
                //直径占整个div高度的多少
                radius: ["50%", "73%"],
                data: this.dataVal,
                label: {
                    fontSize: 22,
                },
            },
            formatter: function (name) {
                var data = options.series.data
                var total = 0
                var tarValue
                for (var i = 0; i < data.length; i++) {
                    total += data[i].value
                    if (data[i].name == name) {
                        tarValue = data[i].value
                    }
                }
                var v = tarValue
                var p = Math.round((tarValue / total) * 100)
                return `${name}：${v}元`
            },
            lineStyle: {
                color: "#999",
            },
            tooltip: {
                show: true,
                trigger: "item",
                formatter: "{b} : {c} ({d}%)",
                textStyle: {
                    fontSize: 24,
                },
            },
        }
        myCharts.setOption(options)
    },
}
</script>

<style lang="scss" scoped>
.echarts_list {
    width: 100%;
    display: flex;
    justify-content: center;

    #myCharts {
        width: px2rem(690);
        height: px2rem(900);
        margin-bottom: px2rem(40);
    }
}
</style>