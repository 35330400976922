<template>
    <div class="cancel">
        <!-- 导航栏 -->
        <topHead>
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">销户申请</h3>
        </topHead>
        <!-- 内容 -->
        <div class="main">
            <div class="ipt">
                <p>手机号</p>
                <input type="text" maxlength="11" v-model="mobilePhone" oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder="请输入手机号" />
            </div>
            <div class="ipt">
                <p>身份证号</p>
                <input type="text" maxlength="18" v-model="idCard" placeholder="请输入身份证号" />
            </div>
            <div class="ipt">
                <p>姓名</p>
                <input type="text" maxlength="10" v-model="name" placeholder="请输入您的姓名" />
            </div>
            <van-button round type="info" @click="cancelHeadler">下一步</van-button>
        </div>
        <!-- 加载中 -->
        <load :load="load">
            <p slot="text" class="loadText">结果查询中...</p>
        </load>
        <!-- 失败 -->
        <fail :fail="fail" @headler="failHeadler()"></fail>

        <Popup :show="show">
            <div class="conter" slot="conter">
                <img src="../../../assets/images/sb.png" alt="" />
            </div>
            <div class="bottom" slot="bottom">请先绑定手机号</div>
            <div class="btn" slot="btn">
                <van-button round type="info" class="pay" @click="back">确定</van-button>
            </div>
        </Popup>
    </div>
</template>

<script>
import { mapState } from "vuex"
import topHead from "../../../components/topHead.vue"
import load from "../../../components/load.vue"
import fail from "../../../components/fail.vue"
import { Toast } from "vant"
import FaceAuth from "../../../common/FaceAuth"
import Popup from "../../../components/Popup.vue"
export default {
    name: "cancel",
    data() {
        return {
            //上传内容
            idCard: "", //142729200005241218
            name: "", //李世峰
            mobilePhone: "", //16555649985
            //类型
            type: "FACE_AUTH",
            //加载中
            load: false,
            //失败
            fail: false,
            paths: "",
            usertime: "",
            show: false,
        }
    },

    computed: {
        ...mapState(["member"]),
    },
    created() {
        this.$store.dispatch("getMember")
    },
    methods: {
        // 查询校验结果
        //上传信息
        cancelHeadler() {
            //获取用户入网时间
            let usertime = this.$store.state.member.mobilePhoneInfo.time
            if (usertime == undefined) {
                // 去绑定手机号
                this.show = true
            } else {
                let options = {
                    type: "UNSET_ACCONT",
                    idCard: this.idCard,
                    name: this.name,
                    mobilePhone: this.mobilePhone,
                }
                FaceAuth.start(options)
                // let usertimes = usertime.split("-")
                // let NetIn = `${usertimes[0]}${usertimes[1]}`

                // // 当前年月日
                // var date = new Date()
                // let Year = date.getFullYear()
                // let Month =
                //     date.getMonth() < 9
                //         ? "0" + (date.getMonth() + 1)
                //         : date.getMonth() + 1

                // let AtPresent = `${Year}${Month}`

                // if (AtPresent == NetIn) {
                //     Toast({
                //         message: "开户首月不能销户",
                //         position: "bottom",
                //     })
                // } else {
                //     let options = {
                //         type: "UNSET_ACCONT",
                //         idCard: this.idCard,
                //         name: this.name,
                //         mobilePhone: this.mobilePhone,
                //     }
                //     FaceAuth.start(options)
                // }
            }
        },
        //子传父
        failHeadler() {
            this.fail = false
        },
        backBefor() {
            this.$router.push("/BusinessHandle")
        },

        back() {
            this.$router.push("/AccountBind")
        },
    },
    components: {
        topHead,
        load,
        fail,
        Popup,
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
}

.ipt {
    position: relative;
    width: 100%;
    height: px2rem(100);
    background-color: #f6f6f6;
    border-radius: px2rem(18);
    margin-top: px2rem(30);
    font-size: px2rem(26);
    padding: 0 px2rem(30);
    box-sizing: border-box;
}

.ipt input {
    width: 100%;
    height: px2rem(100);
    text-align: right;
}

.ipt p {
    position: absolute;
    left: px2rem(30);
    top: 0;
    height: px2rem(100);
    line-height: px2rem(100);
}

.van-button {
    display: flex;
    justify-content: center;
    text-align: center;
    width: px2rem(550);
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin: px2rem(90) auto 0;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
}

.conter {
    display: flex;
    justify-content: center;
}

.conter img {
    width: px2rem(147);
    height: px2rem(118);
}

.bottom {
    font-size: px2rem(30);
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-top: px2rem(50);
}

.pay {
    width: px2rem(210);
    height: px2rem(70);
    line-height: px2rem(70);
}
</style>