<template>
    <div class="verify">
        <topHead class="topHead">
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">开发票</h3>
        </topHead>
        <!-- 导航栏 -->
        <div class="main">
            <div class="ipt">
                <p>手机号</p>
                <input type="text" maxlength="11" placeholder="请输入11位蓝猫手机号码" v-model="mobilePhone"
                    onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" />
            </div>
            <div class="ipt">
                <p>验证码</p>
                <input type="text" maxlength="8" placeholder="请输入验证码" v-model="code" />
                <button class="tab4_btn" :disabled="isNone" @click="codeHeadler">
                    {{ verifyCode }}
                </button>
            </div>
            <van-button round type="info" @click="orderQuery()">确定</van-button>
            <!-- 加载中 -->
            <load :load="load">
                <p slot="text" class="loadText">短信发送中...</p>
            </load>
        </div>
    </div>
</template>

<script>
import API from "../../../common/api/api"
import { mapState } from "vuex"
import load from "../../../components/load.vue"
import topHead from "../../../components/topHead.vue"
import Popup from "../../../components/Popup.vue"
import { Toast } from "vant"
export default {
    components: {
        Popup,
        load,
        topHead,
    },
    data() {
        return {
            mobilePhone: "",
            code: "",
        }
    },

    created() { },
    // 组件方法
    methods: {
        //获取验证码
        codeHeadler() {
            let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (this.mobilePhone == "") {
                Toast({
                    message: "请输入手机号",
                    position: "bottom",
                })
            } else if (!phone.test(this.mobilePhone)) {
                Toast({
                    message: "请输入正确的手机号",
                    position: "bottom",
                })
            } else {
                this.$store.commit("obtainCode", {
                    mobilePhone: this.mobilePhone,
                    type: "08",
                })
            }
        },

        orderQuery() {
            let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (this.mobilePhone == "") {
                Toast({
                    message: "请输入手机号",
                    icon: "none",
                    duration: 800,
                })
            } else if (this.code == "") {
                Toast({
                    message: "请输入验证码",
                    icon: "none",
                    duration: 800,
                })
            } else if (!phone.test(this.mobilePhone)) {
                Toast({
                    message: "请输入正确的手机号",
                    icon: "none",
                    duration: 800,
                })
            } else {
                API.getJson("NEW_CHECKCODE", {
                    service_id: this.mobilePhone,
                    code: this.code
                }).then(res => {
                    if (res.code != 1) {
                        Toast({
                            message: res.data.msg,
                            icon: "none",
                            duration: 800,
                        })
                    } else {
                        localStorage.setItem("Phone", this.mobilePhone)
                        this.$router.push("/invoiceList")
                    }
                })
            }
        },

        backBefor() {
            localStorage.removeItem("Phone")
            this.$router.push("/Recharge");
        }
    },
    computed: {
        ...mapState(["verifyCode", "isNone", "load"]),
    },
    mounted() { },
}
</script> 

<style scoped lang='scss' >
.rechar_active {
    color: #fff !important;
    background-color: #336289 !important;
    box-shadow: 0 px2rem(5) px2rem(10) rgba(0, 0, 0, 0.3);
    border: none !important;
}

.topHead {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    background: #fff;
    z-index: 99;
}

.main {
    padding: px2rem(100) px2rem(30) 0;
    box-sizing: border-box;
}

.ipt {
    position: relative;
    width: 100%;
    height: px2rem(100);
    background-color: #f6f6f6;
    border-radius: px2rem(18);
    margin-bottom: px2rem(30);
    font-size: px2rem(26);
    padding: 0 px2rem(30);
    box-sizing: border-box;
}

.ipt:nth-child(1) input {
    text-align: right;
}

.ipt input {
    width: 100%;
    height: px2rem(100);
    box-sizing: border-box;
}

.ipt:nth-child(2) input {
    padding: 0 px2rem(120);
}

.ipt p {
    position: absolute;
    left: px2rem(30);
    top: 0;
    height: px2rem(100);
    line-height: px2rem(100);
}

.tab4_btn {
    position: absolute;
    right: px2rem(30);
    top: px2rem(28);
    padding: 0 px2rem(20);
    height: px2rem(44);
    line-height: px2rem(44);
    color: #fff;
    font-size: px2rem(24);
    background-color: #336289;
    text-align: center;
    border-radius: px2rem(20);
}

.van-button {
    display: flex;
    justify-content: center;
    width: px2rem(550);
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    // margin: px2rem(10) auto 0;
    margin: auto;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
}

.bottom {
    width: 100%;
    height: px2rem(80);
    line-height: px2rem(80);
    font-size: px2rem(24);
    color: #909198;
    border-top: 1px solid #909198;
    text-align: center;
}
</style>
