<template>
    <div class="tab4" style="height: 100vh">
        <topHead>
            <img
                slot="head"
                src="../../assets/images/fh.png"
                alt=""
                @click="backBefor()"
            />
            <h3 slot="header">号码信息</h3>
        </topHead>

        <!-- 骨架屏 -->
        <div
            class="skeleton-accountbind-box skeleton--animate"
            v-if="member.length == 0"
        >
            <ul class="skeleton-content-box">
                <li>
                    <div class="skeleton-row"></div>
                    <div
                        class="skeleton-row-long"
                        style="margin-right: 0"
                    ></div>
                </li>
                <li>
                    <div class="skeleton-row"></div>
                    <div class="skeleton-row" style="margin-right: 0"></div>
                </li>
                <li>
                    <div class="skeleton-row"></div>
                    <div
                        class="skeleton-row-right-3"
                        style="margin-right: 0"
                    ></div>
                </li>
                <li>
                    <div class="skeleton-row"></div>
                    <div class="skeleton-row" style="margin-right: 0"></div>
                </li>
                <li>
                    <div class="skeleton-row-half"></div>
                    <div
                        class="skeleton-row-half"
                        style="margin-right: 0"
                    ></div>
                </li>
                <li>
                    <div class="skeleton-row"></div>
                    <div
                        class="skeleton-row-half"
                        style="margin-right: 0"
                    ></div>
                </li>
                <li>
                    <div class="skeleton-row"></div>
                    <div class="skeleton-row" style="margin-right: 0"></div>
                </li>
                <li>
                    <div class="skeleton-row-long"></div>
                    <div
                        class="skeleton-row-half"
                        style="margin-right: 0"
                    ></div>
                </li>
            </ul>
        </div>
        <!-- 绑定。 -->
        <div
            class="binding"
            v-else-if="
                !member.mobilePhoneInfo || !member.mobilePhoneInfo.mobilePhone
            "
        >
            <div class="ipt">
                <p>手机号</p>
                <input
                    type="text"
                    maxlength="11"
                    placeholder="请填写您的手机号"
                    v-model="mobilePhone"
                />
            </div>
            <div class="ipt">
                <p>验证码</p>
                <input
                    type="text"
                    maxlength="8"
                    placeholder="请输入验证码"
                    v-model="code"
                />
                <button
                    class="tab4_btn"
                    :disabled="isNone"
                    @click="codeHeadler"
                >
                    {{ verifyCode }}
                </button>
            </div>
            <div class="unableCode" @click="goNoCode">无法收到验证码</div>
            <van-button round type="info" @click="bindPhone()">绑定</van-button>
            <!-- tab4 弹窗 -->
            <van-overlay :show="isShow">
                <div class="wrapper">
                    <div class="block" @click.stop>
                        <img src="../../assets/images/cg.png" alt="" />
                        <p>绑定成功</p>
                        <van-button
                            round
                            type="info"
                            class="back"
                            @click="confirmBinding()"
                            >确定</van-button
                        >
                    </div>
                </div>
            </van-overlay>
            <!-- 加载中 -->
            <load :load="load">
                <p slot="text" class="loadText">短信发送中...</p>
            </load>
        </div>
        <!-- 解绑 -->
        <div
            class="relieve"
            v-else-if="
                member.mobilePhoneInfo && member.mobilePhoneInfo.mobilePhone
            "
        >
            <ul class="bind_conter">
                <li>
                    <p>绑定号码</p>
                    <em>{{ member.mobilePhoneInfo.mobilePhone }}</em>
                </li>
                <li>
                    <p>号码状态</p>
                    <em>{{ member.mobilePhoneInfo.mobileStatus }}</em>
                </li>
                <li>
                    <p>入网时间</p>
                    <em>{{ member.mobilePhoneInfo.time }}</em>
                </li>
                <li>
                    <p>当前套餐</p>
                    <em>{{ member.mobilePhoneInfo.packageName }}</em>
                </li>
                <li>
                    <p>网龄</p>
                    <em>{{ member.mobilePhoneInfo.internetAge }}月</em>
                </li>
                <li>
                    <p>当前积分</p>
                    <em>{{
                        member.mobilePhoneInfo.pointInfo.currentPoints
                    }}</em>
                </li>
                <li>
                    <span></span>
                    <p>绑定状态</p>
                    <em>{{ member.memberInfo.bindStatus }}</em>
                </li>
                <li>
                    <span></span>
                    <p>号码归属地</p>
                    <em>{{ member.mobilePhoneInfo.attribution }}</em>
                </li>
            </ul>
            <van-button round type="info" @click="relieveBinding"
                >解除绑定</van-button
            >
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex"
import topHead from "../../components/topHead.vue"
import API from "../../common/api/api"
import load from "../../components/load.vue"
import { Dialog, Toast } from "vant"
export default {
    name: "AccountBind",
    data() {
        return {
            // 绑定成功
            isShow: false,
            mobilePhone: "",
            code: "",
            mobilePhoneInfo: [],
        }
    },
    watch: {},
    created() {
        //获取用户信息
        // this.$store.dispatch("getMember")
    },

    components: {
        load,
        topHead,
    },
    computed: {
        ...mapState(["member", "verifyCode", "isNone", "load"]),
    },
    mounted() {
        console.log(this.member.length, "member member member")
    },
    methods: {
        // 确认绑定
        confirmBinding() {
            this.isShow = false
            let that = this
            this.$store.commit("getData")
            this.$router.push("/personal")
        },
        // 解除绑定
        relieveBinding() {
            this.$router.push({ name: "relieve" })
        },
        // 解绑成功
        relieveSuccess() {
            this.relieve = false
            this.mobilePhone = ""
            this.code = ""
            setTimeout(() => {
                this.$store.commit("relieveHeadler")
            }, 1000)
        },
        //获取验证码
        codeHeadler() {
            let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (this.mobilePhone == "") {
                Toast({
                    message: "请输入手机号",
                    position: "bottom",
                })
            } else if (!phone.test(this.mobilePhone)) {
                Toast({
                    message: "请输入正确的手机号",
                    position: "bottom",
                })
            } else {
                this.$store.commit("obtainCode", {
                    mobilePhone: this.mobilePhone,
                    type: "01",
                })
            }
        },
        dialog() {
            this.isBind = false
            this.isShow = true
        },
        //没有验证码
        goNoCode() {
            this.$router.push({ path: "/noCode" })
        },
        //绑定手机号
        bindPhone() {
            API.getJson("BIND_PHONE", {
                code: this.code,
                phone: this.mobilePhone,
            }).then(res => {
                if (res.code == 1) {
                    this.isShow = true
                } else if (res.data.code == -100) {
                    Toast({
                        message: res.data.msg,
                        position: "bottom",
                    })
                }
            })
        },
        backBefor() {
            Dialog.confirm({
                message: "您确定退出号码信息吗",
            })
                .then(() => {
                    // on confirm
                    this.$router.replace("/PersonalInformation")
                })
                .catch(() => {
                    // on cancel
                })
            // self.location = self.location.origin
        },
    },
}
</script>
<style lang="scss" scoped>
//骨架屏
// .skeleton-accountbind-box{

// }
.skeleton-content-box {
    width: 100%;
    background-color: #fff;
    border-radius: px2rem(20);
    margin-top: px2rem(70);
    padding: px2rem(20) px2rem(40);
    background-color: #f6f6f6;
    box-sizing: border-box;
}
.skeleton-content-box li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: px2rem(21);
    padding-bottom: px2rem(21);
    line-height: px2rem(66);
}
.skeleton-content-box li p {
    margin-right: auto;
    font-size: px2rem(28);
    color: #333;
}
.skeleton-row {
    width: px2rem(120);
    height: px2rem(24);

    background-color: #eeeeee;
}
.skeleton-row-half {
    width: px2rem(60);
    height: px2rem(24);

    background-color: #eeeeee;
}
.skeleton-row-long {
    width: px2rem(144);
    height: px2rem(24);

    background-color: #eeeeee;
}
.skeleton-row-right-3 {
    width: px2rem(132);
    height: px2rem(24);

    background-color: #eeeeee;
}
.skeleton--animate {
    -webkit-animation: skeleton-blink 1.2s ease-in-out infinite;
    animation: skeleton-blink 1.2s ease-in-out infinite;
}
@-webkit-keyframes skeleton-blink {
    50% {
        opacity: 0.6;
    }
}
@keyframes skeleton-blink {
    50% {
        opacity: 0.6;
    }
}

.tab4 {
    padding: 0 px2rem(30);
    box-sizing: border-box;
}
.ipt {
    position: relative;
    width: 100%;
    height: px2rem(100);
    background-color: #f6f6f6;
    border-radius: px2rem(18);
    margin-bottom: px2rem(30);
    font-size: px2rem(26);
    padding: 0 px2rem(30);
    box-sizing: border-box;
}
.ipt:nth-child(1) input {
    text-align: right;
}

.ipt input {
    width: 100%;
    height: px2rem(100);
    box-sizing: border-box;
}

.ipt:nth-child(2) input {
    padding: 0 px2rem(120);
}

.ipt p {
    position: absolute;
    left: px2rem(30);
    top: 0;
    height: px2rem(100);
    line-height: px2rem(100);
}

.unableCode {
    font-size: px2rem(26);
    color: #999;
    text-align: right;
}

.tab4_btn {
    position: absolute;
    right: px2rem(30);
    top: px2rem(28);
    padding: 0 px2rem(20);
    height: px2rem(44);
    line-height: px2rem(44);
    color: #fff;
    font-size: px2rem(24);
    background-color: #336289;
    text-align: center;
    border-radius: px2rem(20);
}

.van-button {
    width: 100%;
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin: px2rem(90) auto 0;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
}

// 弹窗
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.block {
    width: px2rem(600);
    padding: px2rem(50) 0 px2rem(40);
    box-sizing: border-box;
    background-color: #fff;
    border-radius: px2rem(10);
    text-align: center;
}

.block img {
    width: px2rem(147);
    height: px2rem(118);
    margin-bottom: px2rem(40);
}

.block p {
    font-size: px2rem(30);
    color: #333;
    margin-bottom: px2rem(60);
}

.wrapper .van-button {
    width: px2rem(210);
    height: px2rem(70);
    line-height: px2rem(70);
    text-align: center;
    font-size: px2rem(30);
    font-weight: bold;
    border: none;
    margin: px2rem(0);
}

.jb_btn {
    display: flex;
    justify-content: space-around;
}

.qx {
    color: #336289;
    background-color: #bdcbd0;
}

.back {
    color: #ffffff;
    background-color: #336289;
}

//已绑定
.bind_conter {
    width: 100%;
    background-color: #fff;
    border-radius: px2rem(20);
    margin-top: px2rem(70);
    padding: px2rem(20) px2rem(40);
    background-color: #f6f6f6;
    box-sizing: border-box;
}

.bind_conter li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: px2rem(66);
}

.bind_conter li p {
    margin-right: auto;
    font-size: px2rem(28);
    color: #333;
}

.bind_conter li em {
    font-size: px2rem(26);
    color: #999999;
}

.bind_conter li:nth-child(4) em {
    color: #4182ef;
}

//对话框
.dialog {
    width: px2rem(500);
    height: px2rem(300);
    text-align: center;
    font-size: px2rem(30);
    padding-top: px2rem(20);
}
</style>
