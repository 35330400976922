<template>
    <div class="PackageDetails">
        <div class="top">
            <img
                src="../../../assets/images/fh.png"
                alt=""
                @click="$router.go(-1)"
            />
            <topHead class="topHead"><h3 slot="header">套餐详情</h3></topHead>
        </div>

        <!-- 套餐简介 -->
        <div class="Introducee">
            <div class="IntroduceeTit">
                <h4>{{ details.name }}</h4>
            </div>
        </div>
        <div class="hint">
            <p>本月办理，次月生效</p>
        </div>

        <!-- 套餐说明 -->
        <div class="explain">
            <p>套餐说明</p>
            <ul class="explainAll">
                <li>
                    <span>月套餐费</span
                    ><span>{{ details.monthfee }} 元/月</span>
                </li>
                <li>
                    <span>免费通话</span><span>{{ details.voicein }}</span>
                </li>
                <li>
                    <span>全国流量</span><span> {{ details.datain }}</span>
                </li>
            </ul>
        </div>
        <!-- 资费标准 -->
        <div class="TariffStandard">
            <p>资费标准</p>
            <ul class="TariffStandardAll">
                <li>
                    <span>拨打电话</span><span>{{ details.voiceout }}</span>
                </li>
                <li><span>接听电话</span><span>全国免费</span></li>
                <li><span>来电显示</span><span>{{details.vsopin}}元/月</span></li>
                <li><span>长途漫游</span><span>无</span></li>
                <li>
                    <span>短信</span><span>{{ details.smsout }}</span>
                </li>
            </ul>
        </div>

        <div class="gui">
            <span>套餐规则</span>
            <p v-html="details.description"></p>
        </div>
    </div>
</template>

<script>
import topHead from "../../../components/topHead.vue"
import API from "../../../common/api/api"
export default {
    name: "PackageDetails",
    data() {
        return {
            id: "",
            details: {},
        }
    },
    components: {
        topHead,
    },
    created() {
        this.PackageDetails()
    },

    // 组件方法
    methods: {
        PackageDetails() {
            this.id = this.$route.query.id
            let token = localStorage.getItem("token")
            console.log("token", token, this.id)
            API.getJson("PACKAGE_DETAILS", {
                token: token,
                productId: this.id,
            }).then(res => {
                this.details = res
            })
        },
    },
}
</script> 

<style scoped lang='scss' >
.PackageDetails {
    width: 100%;
    background: #f7f7f7;
    font-size: px2rem(24);
    .top {
        position: relative;
        width: px2rem(750);
        height: px2rem(88);
        line-height: px2rem(88);
        font-size: px2rem(30);
        color: #fff;
        text-align: center;
        img {
            position: absolute;
            left: 5%;
            top: 50%;
            width: px2rem(32);
            height: px2rem(27);
            margin-top: px2rem(-27/2);
        }

        .topHead {
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            background: #fff;
            z-index: 99;
        }
    }

    ul {
        width: 100%;
        background: #fff;
        font-size: px2rem(29);
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
            width: 88%;
            height: px2rem(90);
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: px2rem(1) solid #f7f7f7;
            font-weight: 600;
        }
    }

    .Introducee {
        width: 100%;
        height: px2rem(90);
        background: #fff;
        font-size: px2rem(28);

        .IntroduceeTit {
            width: px2rem(750);
            background: #fff;
            padding-left: px2rem(30);

            h4 {
                font-size: px2rem(30);
            }
        }
    }
    .hint {
        height: px2rem(80);
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: px2rem(28);
        margin-top: px2rem(4);
        background: #fff;
        padding-left: px2rem(46);
        color: #7c7c7c;

        p {
            height: 100%;
            line-height: px2rem(80);
        }
    }

    .TariffStandard {
        margin-top: px2rem(20);
        background: #fff;

        p {
            color: #8e8e8e;
            height: px2rem(90);
            font-size: px2rem(28);
            padding-left: px2rem(46);
            line-height: px2rem(90);
            border-bottom: px2rem(1) solid #f7f7f7;
        }
    }

    .explain {
        margin-top: px2rem(20);
        background: #fff;

        p {
            color: #8e8e8e;
            height: px2rem(90);
            font-size: px2rem(28);
            padding-left: px2rem(46);
            border-bottom: px2rem(1) solid #f7f7f7;
            line-height: px2rem(90);
        }
    }

    .gui {
        width: 100%;
        background: #fff;
        margin-top: px2rem(10);
        span {
            display: flex;
            color: #8e8e8e;
            height: px2rem(90);
            font-size: px2rem(28);
            padding-left: px2rem(46);
            border-bottom: px2rem(1) solid #f7f7f7;
            line-height: px2rem(90);
        }

        p {
            width: 100%;
            box-sizing: border-box;
            padding: px2rem(20) px2rem(40);
            line-height: px2rem(40);
            ul {
                margin-top: px2rem(30);

                li {
                    border: 0;
                    height: px2rem(60);
                    color: #aeaeae;
                    font-size: px2rem(24);
                    line-height: px2rem(30);
                }
            }
        }
    }
}
</style>