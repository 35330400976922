<template>
    <!-- 重发邮件 -->
    <div class="invoiceDetailPage">
        <topHead>
            <h3 slot="header">重发发票</h3>
        </topHead>

        <ul>
            <li>
                <div class="inp">
                    <span>电子邮箱:</span><input type="text" v-model="mailBox" @click="hint = true" @blur="hint = false" />
                </div>
                <div class="hint" v-if="hint">
                    <span>最多可发送3次，请仔细核对邮箱名!</span>
                </div>
            </li>
        </ul>
        <div class="bottom">
            <button @click="submit">提交</button>
        </div>
    </div>
</template>

<script>
import topHead from "../../../components/topHead.vue"
import API from "../../../common/api/api"
import { Toast } from "vant"
import Lanmao from "../../../components/lanmao/index"
export default {
    components: {
        topHead
    },
    data() {
        return {
            id: this.$route.params.arr[0].id,
            mailBox: "",//邮箱
            hint: false,//邮箱提示
        }
    },

    created() {},
    // 组件方法
    methods: {
        // 提交
        submit() {
            let emailVerify = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

            if (this.mailBox == '') {
                Toast({
                    message: "邮箱号不能为空",
                    position: "bottom",
                    duration: 800,
                })
            } else if (!emailVerify.test(this.mailBox)) {
                Toast({
                    message: "请输入正确的邮箱号",
                    position: "bottom",
                    duration: 800,
                })
            } else {
                Lanmao.loading.show("提交中")
                API.getJson("NEW_RELOAD_EMAIL", {
                    id: this.id,
                    email: this.mailBox
                }).then(res => {
                    Lanmao.loading.hide()
                    if (res.code == 1) {
                        Toast({
                            message: "发送成功",
                            position: "bottom",
                            duration: 800,
                        })
                        this.$router.push("/invoiceList")
                    }
                }).catch(err => {
                    Lanmao.loading.hide()
                })
            }
        },

        // 返回
        backBefor() {
            localStorage.removeItem("Phone")
            this.$router.push("/invoiceVerify");
        },
    },
    computed: {},

    watch: {},

    mounted() {},
}
</script> 

<style scoped lang='scss' >
.invoiceDetailPage {
    background-color: #f5f5f5;
    height: 100%;
    position: relative;

    .topHead {
        background-color: #ffffff;
    }

    ul {
        li {
            height: px2rem(160);
            background: #fff;
            font-size: px2rem(26);
            display: flex;
            flex-direction: column;
            justify-content: space-around;


            .inp {
                height: px2rem(60);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 px2rem(20);
                border-bottom: px2rem(1) solid #f5f5f5;

                input {
                    width: 80%;
                    height: 10%;
                    margin-left: px2rem(30);
                    height: px2rem(26);
                }
            }

            .hint {
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    color: red;
                    margin-right: px2rem(10);
                }
            }
        }
    }

    .bottom {
        width: 100%;
        height: px2rem(120);
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;

        button {
            width: 86%;
            height: 80%;
            border-radius: px2rem(10);
            font-size: px2rem(30);
            background-color: #336289;
            color: #ffffff;
        }
    }
}
</style>