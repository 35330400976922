<template>
    <div class="OpenCardMessage">
        <div class="OpenCardMessage_header">
            <h3>首页</h3>
            <div class="OpenCardMessage_header_con">
                <img src="@/assets/images/opencardHome/home3.png" alt="" />
                <ul>
                    <li>{{ userListData.workName }}</li>
                    <li>{{ userListData.workNo }}</li>
                </ul>
            </div>
        </div>
        <ul class="OpenCardMessageData">
            <li>
                <div class="opencard" @click="$router.push('/OpenCard')">
                    <div class="img">
                        <img
                            src="~@/assets/images/opencardHome/home1.png"
                            alt=""
                        />
                    </div>
                    <span>开卡</span>
                </div>
            </li>
            <li>
                <div class="userdata" @click="show = true">
                    <div class="img">
                        <img
                            src="~@/assets/images/opencardHome/home2.png"
                            alt=""
                        />
                    </div>
                    <span>个人信息</span>
                </div>
                <van-popup
                    v-model="show"
                    :style="{ width: '90%', height: '30%' }"
                >
                    <div class="block_tit">
                        <span class="SetMealDetail">个人信息</span
                        ><span class="close" @click="show = false">X</span>
                    </div>
                    <div class="wrapper">
                        <div @click.stop class="block">
                            <div class="userdatas">
                                <div class="UserItemData">
                                    <span>登录姓名:</span>
                                    <p>{{ userListData.workName }}</p>
                                </div>

                                <div class="UserItemData">
                                    <span>登录工号:</span>
                                    <p>{{ userListData.workNo }}</p>
                                </div>

                                <div class="UserItemData">
                                    <span>登录手机号:</span>
                                    <p>{{ userListData.mobile }}</p>
                                </div>

                                <div class="UserItemData">
                                    <span>渠道信息:</span>
                                    <p>{{ userListData.dealerName }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-popup>
            </li>
        </ul>

        <div class="logout" @click="LogOut">
            <img src="@/assets/images/opencardHome/home4.png" alt="" />
            <span>退出登录</span>
        </div>
    </div>
</template>

<script>
import preventBack from "vue-prevent-browser-back" 
import topHead from "../../components/topHead.vue"
import { Toast, Dialog } from "vant"
import { Checkworknoinfo } from "../../common/newapi/api.js"
export default {
    name: "OpenCardMessage",
    components: {
        topHead,
    },
    mixins: [preventBack], //注入  阻止返回上一页
    data() {
        return {
            show: false,
            userListData: {},
            systemId: "", //来源
        }
    },

    created() {
        this.UserList()
    },

    // 组件方法
    methods: {
        async UserList() {
            // 工号登录信息查询接口
            let CheckworknoinfoData = await Checkworknoinfo({
                workNo: localStorage.getItem("workNo"),
                type: "1", //1:工号; 2:手机号
            })

            if (CheckworknoinfoData.code == -100) {
                Toast({
                    message: CheckworknoinfoData.msg,
                    position: "bottom",
                })
            } else {
                localStorage.setItem("Userdata", JSON.stringify(CheckworknoinfoData.data))
                this.userListData = CheckworknoinfoData.data
                if (CheckworknoinfoData.data.defaultSaleKind == 3) {
                    // 业务类型改为4
                    this.systemId = 4
                } else if (
                    CheckworknoinfoData.data.defaultSaleKind == 1 ||
                    CheckworknoinfoData.data.defaultSaleKind == 4
                ) {
                    // 业务类型改为7
                    this.systemId = 7
                }
                // 将业务类型数据保存到systemId字段中放在本地储存
                localStorage.setItem("systemId", this.systemId)
            }
        },
        
        LogOut() {
            localStorage.removeItem("tokens")
            localStorage.removeItem("workNo")
            localStorage.removeItem("systemId")
            localStorage.removeItem("Userdata")
            this.$router.push("/login")
        },
    },

    computed: {},

    mounted() {},
}
</script> 

<style scoped lang='scss' >
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.OpenCardMessage {
    width: 100%;
    height: 100%;
    background: #f3f3f9;

    .OpenCardMessage_header {
        width: 100%;
        height: px2rem(520);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-size: px2rem(32);
        background: #33608a;
        color: #ffffff;

        .OpenCardMessage_header_con {
            width: 100%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            img {
                width: 30%;
                height: 56%;
            }

            ul {
                height: px2rem(80);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    .OpenCardMessageData {
        width: 100%;
        height: px2rem(220);
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: px2rem(30);
        padding: px2rem(14);

        li {
            width: 46%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            background: #ffffff;

            .van-popup {
                width: 100%;
                height: 100%;

                .block_tit {
                    width: 100%;
                    height: 20%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    background: #ffffff;
                    color: #636564;
                    padding: px2rem(30);
                    border-bottom: px2rem(1) solid #636564;

                    .SetMealDetail {
                        margin-right: px2rem(240);
                        color: #000000;
                    }

                    .close {
                        font-size: px2rem(36);
                    }
                }

                .wrapper {
                    width: 100%;
                    height: 80%;
                    font-size: px2rem(32);
                    background: #f8f8f8;

                    .userdatas {
                        width: 100%;
                        height: 100%;
                        padding: px2rem(0) px2rem(20);

                        .UserItemData {
                            width: 100%;
                            height: px2rem(60);
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: px2rem(10);
                            border-bottom: px2rem(1) solid #f8f8f8f8;

                            span {
                                color: #000000;
                                font-weight: 600;
                            }

                            p {
                                width: 70%;
                            }
                        }
                    }
                }
            }

            .userdata {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
            }
            .opencard {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
            }

            .img {
                width: 26%;
                height: 40%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            span {
                font-size: px2rem(32);
            }
        }
    }

    .logout {
        width: 100%;
        height: px2rem(120);
        background: #ffffff;
        margin-top: px2rem(30);
        font-size: px2rem(40);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 px2rem(20);

        img {
            width: px2rem(80);
            height: px2rem(80);
            margin-left: px2rem(30);
        }

        span {
            margin-left: px2rem(30);
        }
    }
}
</style>