<template>
    <div class="message">
        <!-- 导航栏 -->
        <topHead class="headTop">
            <div class="back" slot="head" @click="back()">
                <img src="../../assets/images/aimg/heis_jt@2x.png" alt="" />
            </div>
        </topHead>
        <!-- 轮播图 -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="jumpUrl(item.tpurl)">
                <img :src="item.url" alt="" />
            </van-swipe-item>
        </van-swipe>
        <!-- 内容 -->
        <div class="main">
            <!-- 输入框 -->
            <div class="textIpt">
                <textarea
                    cols="30"
                    rows="10"
                    placeholder="请输入您要吐槽的内容，建议您留下联系方式"
                    v-model="content"
                ></textarea>
                <button @click="subContent">提交</button>
            </div>
            <!-- 选项卡 -->
            <div class="tab">
                <div :class="active == 0 ? 'tab-title-active tab-title' : 'tab-title'" @click="handlerActive(0)">全部吐槽<span></span></div>
                <div :class="active == 1 ? 'tab-title-active tab-title' : 'tab-title'" @click="handlerActive(1)">精品吐槽<span></span></div>
                <div :class="active == 2 ? 'tab-title-active tab-title' : 'tab-title'" @click="handlerActive(2)">我的吐槽<span></span></div>
            </div>
            <!-- 选项卡内容 -->
            <div class="content">
                <div class="content-item">
                    <div class="empty" v-if="this.total_num == 0">
                        <img src="../../assets/images/aimg/zw.png" alt="" />
                        <p>暂无内容</p>
                    </div>
                    <div  class="comment" v-for="(item, index) in list" :key="index">
                        <img :src="item.face" class="commentFace" alt="" />
                        <div class="commentBody">
                            <div class="commentBox" @click="toDetails(item.id)">
                                <div class="commentInfo">
                                    <p class="name">{{ item.name }}</p>
                                    <p class="time">{{ item.time }}</p>
                                </div>
                                <div class="commentGood" @click.stop @click="myLike(item.id,item.myLike,index)">
                                    <img v-if="item.myLike == 0" src="../../assets/images/aimg/wei_d@2x.png"  alt=""/>
                                    <img v-else src="../../assets/images/aimg/yi_d@2x.png"  alt=""/>
                                    <p :class="item.myLike == 0?'none':'yes'">{{item.total_good == 0 ? '点赞' : item.total_good }}</p>
                                </div>
                                <img v-if="item.is_adopt == 1" class="adopt" src="../../assets/images/aimg/yi_caina@2x.png" alt="">
                                <div class="commentCont">{{item.text}}</div>
                                <div class="commentReplybtn" @click.stop @click="replyBtn(item.id)"><img src="../../assets/images/aimg/hui_f@2x.png" alt="">回复</div>
                            </div>
                            <div class="commentreply" v-if="item.reply != ''" @click="item.reply.length < 3 ? toDetails(item.id):''">
                              <div class="reply" v-for="(v, index2) in item.reply" :key="index2" >
                                <!-- <img :src="v.face" class="replyFace" alt="" /> -->
                                <div class="replyBody" v-if="index2 < 3">
                                  <div class="replyContent">
                                      <span class="is_official" v-if="v.is_official">官方</span>
                                      <span class="replyName"  v-if="v.reply_name != ''">{{ v.name }}</span>
                                      <span class="replyName"  v-else>{{ v.name }}:</span>
                                      <span class="isReply-text" v-if="v.reply_name != ''">回复</span>
                                      <span class="isReply" v-if="v.reply_name != ''">{{ v.reply_name }}:</span>
                                      <span>{{ v.content}}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="view-details" v-if="item.reply.length >= 3" @click="toDetails(item.id)">查看全部{{item.reply.length}}评论 ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="paging" v-if="total_page == page && this.list != ''" >到底咯...</p>
            </div>
        </div>
        
        <!-- 回复框 -->
        <van-overlay :show="input" @click="replayIpt" />
        <div v-if="input" class="ipt">
            <input class="replyIpt" type="text" ref="input" v-model="teplyText">
            <button class="button"  @click="inpReplyBtn">回复</button>
        </div>

        <!-- 对话框 -->
        <van-overlay :show="show">
            <div class="wrapper" @click.stop>
                <div class="block">
                    <img src="../../assets/images/aimg/cg.png" alt="">
                    <p>提交成功，{{times}}秒后关闭弹窗</p>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import API from '../../common/api/api'
import { Toast, Dialog } from "vant"
import topHead from "../../components/topHead.vue"
export default {
    name: "message",
    components: {
        topHead
    },
    data() {
        return {
            bannerList: [],
            active: 0,
            list:[],
            times:'',
            input:false, //遮罩层显示
            button:true, //禁用按钮
            show:false, // 对话框显示
            content:'', //吐槽评论
            teplyText:'',//回复评论
            
            total_num:999,

            page:0,
            total_page:'',

            id:'',
            reply_id:'',
        }
    },
    mounted(){
        window.addEventListener("scroll", this.handleScroll, true);
        this.getBanner();
        this.getCommentList();
    },
    methods: {
        //页面事件
        //触底事件
        handleScroll(e) {
            let doc_scrollTop =
                Math.ceil(e.target.documentElement.scrollTop) ||
                Math.ceil(e.target.body.scrollTop)
            let doc_scrollHeight = e.target.documentElement.scrollHeight
            let scrollHeight = e.target.body.offsetHeight

            if (doc_scrollTop + scrollHeight >= doc_scrollHeight) {
                if(this.total_page > this.page){
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true
                    });
                    this.getCommentList();
                }
            }
        },
        //轮播图跳转
        jumpUrl(url){
            console.log(url)
            // this.$router.push({ path: url })
            location.href = url
        },
        //返回上一页
        back() {
            this.$router.back()
        },
        //选项卡切换
        handlerActive(index) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration:1500
            });
            this.active = index
            this.page = 0;
            this.total_page = '';
            this.list = [];
            this.total_num = 999;
            this.getCommentList();
        },
        //单机蒙版
        replayIpt(){
            this.input = false;
            this.button = true;
        },
        //跳转详情页
        toDetails(id){
            this.$router.push({ path: 'proposalDetail',query:{id:id} })
        },

        //API
        //获取轮播图
        getBanner(){
            API.getJson("get_banner", {}).then(res => {
                this.bannerList = res.list;
                console.log(this.bannerList)
            })
        },
        //提交吐槽
        subContent(){
            if(this.content == ''){
                Toast({
                    message:'请输入内容',
                    position:'bottom'
                })
            }else{
                API.getJson("sub_content", {
                    content:this.content
                }).then(res => {
                    let index = null;
                    let time = 5;
                    this.times = time;
                    this.show  = true;
                    index = setInterval(()=>{
                        this.times = time--;
                        if(time == -1){
                            time = 5;
                            this.times = time
                            clearTimeout(index);
                            this.show = false;
                        }
                    },1000)
                    setTimeout(()=>{
                        this.page = 0;
                        this.total_page = '';
                        this.list = []
                        this.getCommentList();
                    },5000)
                })
                
            }
        },
        //获取吐槽列表
        getCommentList(){
            API.getJson("comment_list", {
                tab: this.active,
                page: this.page + 1,
                page_size: 15
            }).then(res => {
                let arr = this.list.concat(res.list)
                this.list = arr
                this.page = res.page
                this.total_page = res.total_page
                this.total_num = res.total
                Toast.clear();
            })
        },
        //点赞
        myLike(id,state,index){
            API.getJson("myLike", {
                id:id,
                state,state
            }).then(res => {
                if(state == 0){
                    this.list[index].myLike = 1
                    this.list[index].total_good += 1
                }else{
                    this.list[index].myLike = 0
                    this.list[index].total_good -= 1
                }
            })
        },
        //回复
        replyBtn(id,reply_id){
            if(reply_id != undefined){
                this.id = id;
                this.reply_id = reply_id;
            }else{
                this.id = id;
                this.reply_id = '';
            }
            this.input = true;
            this.button = false;
            // this.$refs.input.focus();
        },
        //回复按钮
        inpReplyBtn(){
            API.getJson("sub_comment", {
                text:this.teplyText,
                article_id:this.id,
                others_id:this.reply_id
            }).then(res => {
                Toast({
                    message:'提交成功',
                    position:'bottom'
                })
                this.input = false;
                this.button = true;
                this.teplyText = '';
                this.page = 0;
                this.total_page = '';
                this.list = []
                this.getCommentList();
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.main {
    width: 100vw;
    position: absolute;
    left: 0;
    top: px2rem(412);
    padding: 0 px2rem(30);
    box-sizing: border-box;
}
.headTop {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9699;
}
.back,
.back img {
    width: px2rem(32);
    height: px2rem(27);
}
.my-swipe {
    width: 100vw;
    height: px2rem(500);
}
.van-swipe-item,
.van-swipe-item img {
    width: 100%;
    height: px2rem(500);
}
.textIpt {
    position: relative;
    width: px2rem(690);
    height: px2rem(290);
    box-shadow: 0px px2rem(6) px2rem(20) 0px rgba(0, 0, 0, 0.15);
    border-radius: px2rem(20);
    background: #ffffff;
}
.textIpt textarea {
    width: 100%;
    height: 100%;
    font-size: px2rem(28);
    color: #999999;
    line-height: px2rem(40);
    overflow-y: hidden;
    padding: px2rem(30);
    box-sizing: border-box;
}
.textIpt button {
    position: absolute;
    bottom: px2rem(-100);
    right: px2rem(22);
    width: px2rem(140);
    height: px2rem(72);
    line-height: px2rem(72);
    text-align: center;
    background: #ffdd00;
    border-radius: px2rem(20);
    font-size: px2rem(32);
    color: #3c3c3b;
}
.tab {
    display: flex;
    justify-content: space-around;
    padding: 0 px2rem(60);
    box-sizing: border-box;
    margin-top: px2rem(140);
}
.tab .tab-title {
    position: relative;
    font-size: px2rem(32);
    color: #999999;
    font-weight: 400;
}
.tab .tab-title-active {
    font-size: px2rem(32);
    font-weight: bold;
    color: #3c3c3b;
}
.tab .tab-title-active span {
    position: absolute;
    left: 50%;
    bottom: px2rem(-18);
    transform: translateX(-50%);
    display: block;
    width: px2rem(40);
    height: px2rem(6);
    background: #ffdd00;
    border-radius: px2rem(3);
}
//内容
.content {
    width: 100%;
    overflow: hidden;
    min-height: px2rem(400);
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: px2rem(20);
    margin-top: px2rem(40);
    margin-bottom: px2rem(70);
    padding: px2rem(40);
    box-sizing: border-box;
}
.content-item .empty,
.content-item .empty img {
    width: 100%;
    overflow: hidden;
    margin-top: px2rem(30);
}
.content-item .empty p {
    font-size: px2rem(24);
    color: #999;
    text-align: center;
}
.comment{
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: px2rem(43);
  padding-bottom:px2rem(20) ;
}
//头像
.commentFace,
.commentFace img {
    width: px2rem(60);
    height: px2rem(60);
    border-radius: 50%;
}
.commentBody{    
    position: relative;
  display: flex;
  flex-wrap: wrap;
  width:px2rem(555);
  box-sizing: border-box;
  margin-left: auto;
}
.commentBox{
  display: flex;
  flex-wrap: wrap;
}
//名字
.commentInfo {
    text-align: left;
    color: #999999;
    margin-left: px2rem(18);
}
.commentInfo .name {
    width: px2rem(400);
    font-size: px2rem(28);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.commentInfo .time {
    font-size: px2rem(22);
}
//点赞
.commentGood {
    display: flex;
    margin-top: px2rem(7);
    margin-left: auto;
}
.commentGood img {
    width: px2rem(32);
    height: px2rem(32);
    vertical-align: middle;
    margin-right: px2rem(10);
}
.commentGood .none {
    font-size: px2rem(24);
    color: #999999;
    margin-top: px2rem(6);
}
.commentGood .yes {
    font-size: px2rem(24);
    color: #E83B3B;
    margin-top: px2rem(6);
}
//已采纳
.adopt{
    position: absolute;
    left: 50%;top: px2rem(25);
    width: px2rem(108);
    height: px2rem(108);
    margin-left: px2rem(-54);
}
//内容
.commentCont {
    width: px2rem(530);
    font-size: px2rem(24);
    color: #333333;
    line-height: px2rem(36);
    margin-left: auto;
    margin-top: px2rem(10);
}
//回复
.commentReplybtn {
    font-size: px2rem(24);
    color: #999999;
    vertical-align: bottom;
    margin: px2rem(25) 0 px2rem(35);
}
.commentReplybtn img {
    width: px2rem(32);
    height: px2rem(32);
    vertical-align: top;
    margin: px2rem(-2) px2rem(10) 0;
}
//评论
.commentreply{
  width: 100%;
  background: #f5f5f5;
  border-radius: px2rem(20);
  padding: px2rem(30) px2rem(30) 0;
  box-sizing: border-box;
}
.view-details{
    display: inline-block;
    color: rgb(69, 150, 243);
    font-size: px2rem(26);
    margin-bottom: px2rem(30);
}
.reply{
    display: flex;
    flex-wrap: wrap;
}
.replyFace{
    width: px2rem(42);
    height: px2rem(42);
    border-radius: 50%;
}
.replyBody{
    display: flex;
    width: px2rem(500);
    margin-bottom: px2rem(30);
}
.replyName{
    // display: flex;
    font-size: px2rem(24);
    color: #333;
    font-weight: 600;
}
// .replyName p{
//     max-width: px2rem(210);
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
.isReply-text{
    color: #999;
    margin: 0 px2rem(5);
}
.is_official{
    background: #336189;
    color: #FFF;
    font-size:px2rem(24);
    padding: px2rem(3);
    margin: 0 px2rem(5);
}
// .replyName .isReply img{
//     width: px2rem(24);
//     height: px2rem(23);
//     margin: px2rem(2) px2rem(15) 0;
// }
.isReply {
    max-width: px2rem(260);
    color: #999;
    font-weight: normal;
}
.replyContent{
    font-size: px2rem(28);
    line-height: px2rem(35);
    color: #333333;
}
.replyImgBtn{
    display: inline-block;
    font-size: px2rem(24);
    color: #999999;
    vertical-align: bottom;
}
.replyImgBtn img{
    width: px2rem(32);
    height: px2rem(32);
    vertical-align: bottom;
    margin-right: px2rem(8);
}
.more{    
    display: flex;
    align-items: center;
    font-size: px2rem(24);
    color: #999999;
    margin-left: px2rem(45);
}
.more img{
    width: px2rem(16);
    height: px2rem(10);
    margin-left:px2rem(5) ;
}

.iptNone{
    opacity: 0;
}
.ipt{
    position: fixed;
    left: 0;bottom: 0;
    z-index: 999;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: px2rem(100);
    background: #e4e2e2;
}
.replyIpt{
    width: 70%;
    height:px2rem(60);
    border-radius: px2rem(10);
    background: #ffffff;
    padding: 0 px2rem(15);
    box-sizing: border-box;
    font-size: px2rem(28);
    color: #999;
}
.button{
    width: px2rem(120);
    height: px2rem(60);
    line-height: px2rem(60);
    color: #FFF;
    background:#ffdd00 ;
    font-size: px2rem(26);
    text-align: center;
    border-radius: px2rem(10);
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.block {
    width: px2rem(600);
    height: px2rem(350);
    background-color: #fff;
    border-radius: px2rem(16);
    text-align: center;
}
.block img{
    width: px2rem(150);
    height: px2rem(150);
    margin-top: px2rem(50);
}
.block p{
    font-size: px2rem(28);
    color: #333;
    margin-top: px2rem(50);
}

.paging{
    width: 100%;
    text-align: center;
    font-size: px2rem(28);
    color: #999;
}
</style>
