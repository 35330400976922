<template>
    <div class="balance">
        <!-- 导航栏 -->
        <topHead><h3 slot="header">账单详情</h3></topHead>
        <!-- 内容 -->
        <div class="main"  v-if="bill">
            <div class="balance_top" @click="goHall">
                <p>交话费冲流量，请点这里</p>
                <img src="../../assets/images/jin.png" alt="" />
            </div>
            <div class="balance_top balance_bottom" @click="goHistoryBill">
                <p>查询近 6 个月账单，请点这里</p>
                <img src="../../assets/images/jin.png" alt="" />
            </div>

            <Echarts
                :color="bill.color"
                :title="bill.title"
                :data="bill.legend"
                :dataVal="bill.chart"
            ></Echarts>

            <div class="explain" v-html="bill.desc">
                
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../common/api/api"
import topHead from "../../components/topHead.vue"
import Echarts from "../../components/echarts.vue"
import {mapState}  from "vuex"
export default {
    name: "balance",
    data() {
        return {
           
        }
    },
    created(){
        this.$store.dispatch('bill/queryBill');
    },
    computed:{
        ...mapState('bill',['bill'])

    },
    mounted() {},
    methods: {
        goHistoryBill() {
            this.$router.push({ path: "/historyBill" })
        },
        goHall() {
            this.$router.replace({ path: "/" })
        }
    },
    components: {
        topHead,
        Echarts
    }
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
}
.main .balance_bottom {
    background-color: #c9976d;
}
.balance_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: px2rem(90);
    line-height: px2rem(90);
    background-color: #336189;
    border-radius: px2rem(18);
    color: #fff;
    font-size: px2rem(26);
    padding: 0 px2rem(20);
    box-sizing: border-box;
    margin-top: px2rem(20);
    box-shadow: 0 0 px2rem(20) rgba($color: #000000, $alpha: 0.2);
    font-weight: bold;
}
.balance_top p:nth-child(2) {
    font-weight: bold;
    font-size: px2rem(36);
}
.balance_top img {
    height: px2rem(38);
    vertical-align: middle;
}
.explain {
    width: 100%;
    border-radius: px2rem(18);
    background-color: #e5eaed;
    padding: px2rem(28);
    box-sizing: border-box;
}
.explain_title {
    font-weight: bold;
    margin-bottom: px2rem(15);
    font-size: px2rem(26);
    color: #336289;
}
.explain ::v-deep p {
    font-size: px2rem(22);
    color: #333;
    line-height: px2rem(44);
}
</style>
