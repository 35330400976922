import API from '../../common/api/api'


const state = () => ({
        userdata: [], //工号登录信息

    })
    //mutations
const mutations = {
    // 添加工号登录信息
    Userdata: (state, data) => {
        state.userdata = data
    },
}

// actions
const actions = {

}

// getters
const getters = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}