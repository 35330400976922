<template>
  <div style="width:100%;height:100%;">
    <div style="position:absolute;text-align:center;font-size:0.96rem;color:#888;width:100%;height:10rem;top:50%;margin-top:-5rem;">
      <p>人脸验证模拟页面</p>
      <p style="font-size:.8rem;color:red;line-height:1.6rem;">{{time}}秒后重定向</p>
    </div>
  </div>
</template>

<script>
import FaceAuth from "../../common/FaceAuth"
export default {
    name: "transfer",
    data() {
        return {
            timer: null,
            time: 3,
        }
    },
    watch: {},
    created() {
        this.$nextTick(() => {
            this.timer = setInterval(() => {
                this.time--
                if (this.time == 0) {
                    clearInterval(this.timer)
                    FaceAuth.test()
                }
            }, 1000)
        })
    },

    components: {},
    methods: {},
}
</script>

<style lang="scss" scoped>
</style>