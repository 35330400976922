<template>
    <div class="activityDetails">
        <!-- 导航栏 -->
        <topHead><h3 slot="header">特惠活动</h3></topHead>
        <!-- 内容 -->
        <div class="main">
            <div class="title">{{detail.name}}</div>
            <div class="time">{{detail.author}} {{detail.date}}</div>
            <div class="derail" v-html="detail.detail">

            </div>
        </div>
    </div>
</template>

<script>
import topHead from "../../components/topHead.vue"
import API from "../../common/api/api.js"
export default {
    name: "activityDetails",
    data() {
        return {
            activityId: "",
            detail:''
        }
    },
    components: {
        topHead,
    },
    mounted() {
        this.activityId = this.$route.query.id;
        this.getDetail();
    },
    methods: {
        getDetail() {
          let that = this;
            API.getJson("GET_ACTIVITY_DETAIL", {
              activityId : this.activityId
            }).then(res => {
                that.detail  = res
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
}
.title {
    font-size: px2rem(30);
    font-weight: bold;
    color: #333;
    line-height: px2rem(70);
}
.time {
    font-size: px2rem(24);
    color: #999999;
    margin-bottom: px2rem(20);
}
.derail{
    font-size: px2rem(26) !important;
    color: #333 !important;
    line-height: px2rem(50) !important;
}
.derail img {
    width: 100% !important;
    height: px2rem(290) !important;
    margin: px2rem(20) 0 !important;
}
</style>