<template>
    <div class="orderbusine">
        <!-- 导航栏 -->
        <topHead><h3 slot="header">已订购业务</h3></topHead>
        <!-- 内容 -->
        <div class="main">
            <van-tabs
                v-model="active"
                @click="tabActive"
                v-if="NoData == 'succeed'"
            >
                <van-tab title="基础套餐">
                    <div
                        v-for="(item, index) in list"
                        :key="index"
                        :class="item.status == -1 ? 'invalid wrap' : 'wrap'"
                    >
                        <div class="wrap_title">{{ item.packageName }}</div>

                        <img
                            class="sx"
                            v-if="item.status == -1"
                            src="../../assets/images/sx.png"
                            alt=""
                        />
                        <img
                            class="yx"
                            v-if="item.status == 1"
                            src="../../assets/images/yx.png"
                            alt=""
                        />
                        <img
                            class="yy"
                            v-if="item.status == 0"
                            src="../../assets/images/yy.png"
                            alt=""
                        />
                        <div class="wrap_time">
                            <div class="start">
                                <p>生效时间</p>
                                <p>{{ item.effectTime }}</p>
                            </div>
                            <div class="end">
                                <p>失效时间</p>
                                <p>{{ item.overTime }}</p>
                            </div>
                        </div>
                        <div class="start_time">
                            订购时间：{{ item.orderTime }}
                        </div>
                    </div>
                    <div class="notData" v-if="NoDatas == 1">
                        <van-empty description="暂无数据" />
                    </div>
                </van-tab>
                <van-tab title="流量包套餐">
                    <div
                        v-for="(item, index) in list"
                        :key="index"
                        :class="item.status == -1 ? 'invalid wrap' : 'wrap'"
                    >
                        <div class="wrap_title">{{ item.packageName }}</div>

                        <img
                            class="sx"
                            v-if="item.status == -1"
                            src="../../assets/images/sx.png"
                            alt=""
                        />
                        <img
                            class="yx"
                            v-if="item.status == 1"
                            src="../../assets/images/yx.png"
                            alt=""
                        />
                        <img
                            class="yy"
                            v-if="item.status == 0"
                            src="../../assets/images/yy.png"
                            alt=""
                        />
                        <div class="wrap_time">
                            <div class="start">
                                <p>生效时间</p>
                                <p>{{ item.effectTime }}</p>
                            </div>
                            <div class="end">
                                <p>失效时间</p>
                                <p>{{ item.overTime }}</p>
                            </div>
                        </div>
                        <div class="start_time">
                            订购时间：{{ item.orderTime }}
                        </div>
                    </div>
                    <div class="notData" v-if="NoDatas == 2">
                        <van-empty description="暂无数据" />
                    </div>
                </van-tab>
                <van-tab title="增值业务">
                    <div
                        class="wrap"
                        v-for="(item, index) in list"
                        :key="index"
                        :class="item.status == -1 ? 'invalid wrap' : 'wrap'"
                    >
                        <div class="wrap_title">{{ item.packageName }}</div>
                        <img
                            class="sx"
                            v-if="item.status == -1"
                            src="../../assets/images/sx.png"
                            alt=""
                        />
                        <img
                            class="yx"
                            v-if="item.status == 1"
                            src="../../assets/images/yx.png"
                            alt=""
                        />
                        <img
                            class="yy"
                            v-if="item.status == 0"
                            src="../../assets/images/yy.png"
                            alt=""
                        />
                        <div class="wrap_time">
                            <div class="start">
                                <p>生效时间</p>
                                <p>{{ item.effectTime }}</p>
                            </div>
                            <div class="end">
                                <p>失效时间</p>
                                <p>{{ item.overTime }}</p>
                            </div>
                        </div>
                        <div class="start_time">
                            订购时间：{{ item.orderTime }}
                        </div>
                    </div>
                    <div class="notData" v-if="NoDatas == 3">
                        <van-empty description="暂无数据" />
                    </div>
                </van-tab>
            </van-tabs>

            <div class="notData" v-if="NoData == 'error'">
                <van-empty description="暂无数据" />
            </div>
            <!-- 加载中 -->
            <load :load="load">
                <p slot="text" class="loadText">短信发送中...</p>
            </load>
        </div>
    </div>
</template>

<script>
import API from "../../common/api/api"
import load from "../../components/load.vue"
import topHead from "../../components/topHead.vue"
export default {
    name: "orderbusine",
    data() {
        return {
            active: 0,
            list: [],
            load: false,
            NoData: "",
            NoDatas: "",
        }
    },
    mounted() {
        this.tabActive()
    },
    methods: {
        //获取
        tabActive() {
            let token = localStorage.getItem("token")
            API.getJson("ORDER_BUSINESS", {
                token: token,
                type: this.active,
                page: 1,
                pageSize: 6,
            }).then(res => {
                if (res.data) {
                    // 报错-100
                    this.NoData = "error"
                } else {
                    this.NoData = "succeed"
                    this.list = res.list
                    if (this.active == 0) {
                        if (this.list == "") {
                            this.NoDatas = 1
                        }
                    } else if (this.active == 1) {
                        if (this.list == "") {
                            this.NoDatas = 2
                        }
                    } else if (this.active == 2) {
                        if (this.list == "") {
                            this.NoDatas = 3
                        }
                    }
                }
            })
            .catch((err)=>{
                console.log("接口失败的报错",err)
            })
        },
    },
    components: {
        topHead,
        load,
    },
}
</script>

<style lang="scss" scoped>
.topHead {
    position: fixed !important;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: #fff;
}
.main {
    height: 100vh;
    overflow-x: auto;
    background-color: #f5f5f5;
    margin-top: px2rem(187);
}
.wrap {
    position: relative;
    width: 100%;
    height: px2rem(250);
    border-radius: px2rem(20);
    background-color: #fff;
    margin-bottom: px2rem(30);
    margin-top: px2rem(30);
    padding-top: px2rem(30);
    box-sizing: border-box;
}
.invalid {
    background-color: #cccccc;
    color: #545454 !important;
}
.invalid .wrap_title {
    color: #545454 !important;
}
.wrap_title {
    font-size: px2rem(30);
    color: #336289;
    font-weight: bold;
    margin-left: px2rem(40);
    margin-bottom: px2rem(28);
}
.wrap_time {
    display: flex;
    height: px2rem(58);
    margin-bottom: px2rem(30);
}
.start {
    border-right: 1px solid #d9d9d9;
}
.start p:nth-child(1),
.end p:nth-child(1) {
    margin-bottom: px2rem(15);
}
.start,
.end {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 px2rem(40);
    width: 50%;
    color: #5d5f64;
    font-size: px2rem(22);
}
.wrap .yx,
.wrap .yy {
    position: absolute;
    right: px2rem(30);
    top: px2rem(30);
    width: px2rem(80);
    height: px2rem(30);
}
.wrap .sx {
    position: absolute;
    right: px2rem(30);
    top: px2rem(30);
    width: px2rem(80);
    height: px2rem(68);
}
.start_time {
    height: px2rem(74);
    line-height: px2rem(74);
    border-top: 1px solid #d9d9d9;
    background: url(../../assets/images/sj.png) no-repeat px2rem(40) center;
    font-size: px2rem(22);
    color: #999;
    padding-left: px2rem(90);
    box-sizing: border-box;
}
</style>