<template>
    <div class="ZyWithoutCodeLogin">
        <WithoutCodeLogin
            :UserNames="UserName"
            :PassWords="PassWord"
        ></WithoutCodeLogin>
    </div>
</template>

<script>
import WithoutCodeLogin from "../../components/openCard/WithoutCodeLoginPublic.vue"
export default {
    components: { WithoutCodeLogin },
    data() {
        return {
            // 直营门店
            UserName: "LMSIP_X", //测试工号 SHSHZY003  正式：JSKSZNZY001
            PassWord: "123456", //测试密码  744810   正式密码：Kszn1234567@@123
        }
    },

    created() {},
    // 组件方法
    methods: {},

    computed: {},

    mounted() {},
}
</script> 

<style scoped lang='scss' >
</style>