<template>
    <div class="CustomerService">
        <!-- 导航栏 -->
        <topHead>
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">客户服务</h3>
        </topHead>
        <div class="main">
            <div class="con">
                <div class="feedback_type">
                    <div class="type_tit">
                        <h4>请选择您要反馈的问题类型</h4>
                        <p>单选</p>
                    </div>
                    <ul>
                        <li v-for="item, index in title_list" :key="index" :class="{ selsect_li: index == indexs }"
                            @click="select(item, index)">{{
                                item.title }}</li>
                    </ul>
                </div>

                <div class="feedback">
                    <h4>您的意见</h4>
                    <div class="feedback_box">
                        <textarea @click.stop name="" id="" cols="30" rows="1" style="resize: none;" v-model="textdata"
                            onkeyup="this.value=this.value.replace(/[ ]/g,'')" maxlength="100"
                            placeholder="请尽量准确描述问题，以便我们提供更好的帮助。"></textarea>
                        <p>{{ text_number > 0 ? text_number : 0 }}/100</p>
                    </div>
                </div>
            </div>
            <div class="btn">
                <van-button round type="info" @click="submit">提交意见</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import topHead from "../../../components/topHead.vue"
import API from "../../../common/api/api"
import { Toast } from 'vant'
export default {
    components: { topHead },
    data() {
        return {
            indexs: null,
            textdata: "",
            text_number: "",
            appeal_type: "",
            title_list: [
                { "title": "账号" },
                { "title": "功能" },
                { "title": "注册" },
                { "title": "服务建议" },
                { "title": "界面显示异常" },
                { "title": "其他" },
            ]
        }
    },

    created() { },
    // 组件方法
    methods: {
        select(item, index) {
            this.indexs = index
            this.appeal_type = item.title
        },

        // 提交意见
        submit() {
            let phone = localStorage.getItem("phone")
            let token = localStorage.getItem("token")
            API.getJson("INSERTUSERAPPEAL", {
                service_id: phone,
                token: token,
                appeal_type: this.appeal_type,
                appeal_note: this.textdata
            }).then(res => {
                if (res == true) {
                    Toast({
                        message: "意见提交成功",
                        position: "bottom",
                        duration: 1000,
                    })
                    this.$router.push("/SelectFunction")
                } else {
                    if (res.data.code == "-100") {
                        this.$router.push("/WillRegister")
                    }
                }
            })
        },

        // 返回
        backBefor() {
            this.$router.push("/SelectFunction")
        },
    },

    computed: {},

    watch: {
        textdata: {
            deep: true,
            handler(newVal, oldVal) {
                this.text_number = newVal.length
            }
        },
    },

    mounted() { },
}
</script>

<style scoped lang='scss'>
.CustomerService {
    width: 100%;
    height: 100%;
    background: #f8f8f8;

    .topHead {
        position: sticky;
        /* 新属性sticky */
        top: 0;
        background: #fff;
    }

    .main {
        width: 100%;

        .con {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .feedback_type {
                width: 89%;
                padding: px2rem(20);
                margin-top: px2rem(20);
                border-radius: px2rem(20);
                background: #fff;


                .type_tit {
                    display: flex;
                    justify-content: flex-start;
                    font-size: px2rem(28);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;


                    p {
                        padding: px2rem(12);
                        font-size: px2rem(24);
                        border-radius: px2rem(22);
                        background: #f8f8f8;
                        margin-left: px2rem(10);
                        color: #6a9bf2;
                    }
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    margin-top: px2rem(20);

                    .selsect_li {
                        background: #2881f8;
                        color: #fff;
                    }

                    li {
                        font-size: px2rem(26);
                        background: #f8f8f8;
                        padding: px2rem(18) px2rem(26);
                        margin: px2rem(16);
                        border-radius: px2rem(26);
                    }
                }
            }

            .feedback {
                width: 89%;
                font-size: px2rem(28);
                margin-top: px2rem(50);
                padding: px2rem(20);
                background: #fff;


                .feedback_box {
                    position: relative;


                    textarea {
                        width: 100%;
                        height: px2rem(200);
                        background: #f8f8f8;
                        padding: px2rem(20);
                        margin-top: px2rem(20);
                    }

                    p {
                        position: absolute;
                        bottom: px2rem(12);
                        right: px2rem(12);
                    }
                }



            }
        }

        .btn {
            width: 100%;
            height: px2rem(120);
            margin: px2rem(30) 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .van-button {
                display: flex;
                justify-content: center;
                width: px2rem(550);
                height: px2rem(80);
                color: #fff;
                font-weight: bold;
                font-size: px2rem(28);
                background-color: #336289;
            }
        }
    }
}
</style>