//import DialogComponent from './dialog.vue'
import Vue from 'vue'
import LoadComponent from './lanmao-load.vue'
import AlertComponent from './lanmao-alert.vue'


const Lanmao = {};
Lanmao.loading = {
  LoadInstance: null,
  show(message = '加载中...') { // 显示方法
    let LoadTpl = Vue.extend(LoadComponent);
    this.LoadInstance = new LoadTpl();
    this.LoadInstance.show = true
    this.LoadInstance.message = message
    document.body.appendChild(this.LoadInstance.$mount().$el);
    //  document.body.appendChild(instance.$el)
  },
  hide() { 
    // 隐藏方法
    this.LoadInstance.show = false
  }
};



let AlertTpl = Vue.extend(AlertComponent);
let AlertInstance = new AlertTpl();
Lanmao.alert = function (options) {
  AlertInstance.title = options.title
  AlertInstance.message = options.message
  document.body.appendChild(AlertInstance.$mount().$el);
  AlertInstance.show = true;
  AlertInstance.confirm = function () {
    options && options.confirm && options.confirm()
    AlertInstance.show = false;


  }



}
export default Lanmao;


