export default {
    // 正式
    // "API_HOST": "http://192.168.12.82:80",
    "API_HOST": "https://wechat.lanmaomobile.com", //"http://192.168.12.83:18080", 正式：http://wechat.lanmaomobile.com  测试：http://localhost:18080

    // 测试
    // "API_HOST": "http://172.16.30.243:18080",
    // "API_HOST": "https://apptest.lanmaomobile.com",
    // "API_HOST": "http://47ruwq.natappfree.cc",
    // "API_HOST": "http://172.16.31.49:18080", //"wxltest.lanmaomobile.com", 正式：http://wechat.lanmaomobile.com  测试：http://localhost:18080
    "APPID": "wx5b3ad181c94ea4d5",
    //显示loading框的接口列表
    "SHOW_LOADING_REQUEST_LIST": ['ADD_ORDERS', 'PAY', 'OPEN_FLOW', 'RESULT_QUERY', 'QUERY_PHONE', 'FACE_AUTH_ACCOUNT_BIND', "GetSimpleOrCardNum", "AddOpenCardLocation"]
}