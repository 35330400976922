<template>
    <div class="PhWithoutCodeLogin">
        <WithoutCodeLogin
            :UserNames="UserName"
            :PassWords="PassWord"
        ></WithoutCodeLogin>
    </div>
</template>

<script>
import WithoutCodeLogin from "../../components/openCard/WithoutCodeLoginPublic.vue"
export default {
    components: { WithoutCodeLogin },
    data() {
        return {
            // 普号业务
            UserName: "SDYLFTZYMD",
            PassWord: "123456", 
        }
    },

    created() {},
    // 组件方法
    methods: {},

    computed: {},

    mounted() {},
}
</script> 

<style scoped lang='scss' >
</style>