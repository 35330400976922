import axios from 'axios';
import Apis from './url.js';
import CONFIG from '../config.js';
import { Toast, Dialog } from 'vant';
import { toLink } from '../utils/router.js'
import adapter from '../adapter/api.adapter'
import Lanmao from '../../components/lanmao/index';
const API = {};
let obj = {};
CONFIG.SHOW_LOADING_REQUEST_LIST.forEach((value, index) => {
    obj[value] = true;
})
API.ShowLoadingRequestList = obj;
API.ApiConfig = {
    'Apis': Apis
};

//获取接口完整地址
API.getUrl = function (alias) {
    return CONFIG.API_HOST + this.ApiConfig.Apis[alias];
}

//发送请求
API.getJson = function (alias, params, isAutoTips = true) {

    if (this.ShowLoadingRequestList[alias]) {
        Lanmao.loading.show('加载中...');
    }

    var url = this.getUrl(alias);
    var token = localStorage.getItem('token');
    if (token) {
        params.token = token
    }
    let _this = this
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: url,
            data: params,
            // headers: {
            //     'Content-Type': 'application/json;charset=utf-8'
            // }
        }).then(function (res) {

            if (_this.ShowLoadingRequestList[alias]) {
                Lanmao.loading.hide();
            }

            console.log('接口响应：', res)
            if (res.status == 200) {
                console.log('接口状态码：', '【' + res.data.code + '】');
                if (res.data.code == 1) {
                    //请求成功
                    // if (res.config.url == "http://wechat.lanmaomobile.com" + Apis.CHANGE_CARD_FORM) {
                    //     toLink('changeCardauditCabcel', {});
                    // }

                    let data = res.data.data ? res.data.data : res.data;

                    //--判断是否有对应的适配器方法  如果有进行调用
                    if (adapter[alias]) {
                        let func = adapter[alias];
                        data = func(data);
                    }
                    resolve(data);
                    return;
                } else if (res.data.code == -100) {
                    //通用错误消息
                    Toast({
                        message: res.data.msg,
                        position: 'bottom',
                    });
                    resolve(res);
                } else if (res.data.code == -101) {
                    localStorage.removeItem('token');
                    //token不合法或用户未登录
                    Dialog.confirm({
                        title: "提示",
                        message: res.data.msg,
                        confirmButtonText: "去登录",
                    }).then(() => {
                        toLink('AccountBind', {});
                    }).catch(() => {
                        // on cancel
                    });
                } else if (res.data.code == -102) {
                    res.data.msg = res.data.msg ? res.data.msg : '请先绑定手机号';
                    //用户未绑定手机号码 
                    toLink('AccountBind', {});
                }
                if (res.data.code == -104) {
                    //通用错误消息
                    resolve(res);

                } else if (res.data.code == -998) {
                    //通用错误消息
                    resolve(res);

                } else if (res.data.code == -100) {
                    //通用错误消息
                    Toast({
                        message: res.data.msg,
                        position: 'bottom',
                    });
                    // resolve(res);

                } else {
                    //未知错误
                    Toast({
                        message: '未知错误',
                        position: 'bottom',
                    });
                }
                reject && reject(res.data);
                if (isAutoTips) {
                    Toast({
                        message: res.data.msg,
                        position: 'bottom',
                    });
                }

                return;
            }
        }).catch((err) => {

            console.log(err, 'catch catch err');
            Toast({
                message: '系统更新中，请稍后重试...',
                position: 'bottom',
            });
            Lanmao.loading.hide();
        });
    })

}
export default API;