<template>
    <div class="OpenCard">
        <div class="OpenCard_header">
            <div class="OpenCard_header_title">
                <!-- <div class="goback">
                    <van-icon name="arrow-left" @click="GoBack" />
                </div> -->
                <p>开卡</p>
            </div>
            <div class="OpenCard_header_Steps">
                <div class="reset-steps">
                    <div class="reset_steps" v-for="(item, index) in step" :key="index">
                        <div class="steps_con">
                            <img v-if="active >= index" :src="item.imgActive" alt="" />
                            <img v-else :src="item.img" alt="" />
                            <div class="steps_tit">
                                <span :class="active >= index ? 'lineActive' : ''">{{ item.title }}</span>
                            </div>
                        </div>
                        <span v-if="item.span" :class="active >= index + 1 ? 'lineActive' : ''"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="one" v-if="active == 0">
            <div class="one_header">
                <div class="one_header_tit">
                    <div class="tu">
                        <img src="~@/assets/images/opencard/open1.png" alt="" />
                    </div>
                    <div>填写开卡信息</div>
                </div>
                <div class="stepNumber">1/4</div>
            </div>
            <div class="one_content">
                <div class="inp">
                    <div class="title">手机号码</div>
                    <input placeholder="请输入11位有效手机号码" maxlength="11"
                        onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" v-model="Iphone" />
                </div>
                <div class="inp inpno">
                    <div class="title">ICCID卡号</div>
                    <input placeholder="请输入ICCID卡号  后七位" v-model="wholetcard" />
                </div>
            </div>
        </div>

        <div class="two" v-if="active == 1">
            <div class="one_header">
                <div class="one_header_tit">
                    <div class="tu">
                        <img src="~@/assets/images/opencard/open3.png" alt="" />
                    </div>
                    <div>默认套餐</div>
                </div>
                <div class="stepNumber">2/4</div>
            </div>
            <div class="one_content">
                <div class="setMeas">
                    <div class="setMea">
                        <div class="setMea_title">
                            <div class="setMea_name">
                                {{ prodOfferName }}
                            </div>
                            <div class="setMea_price">￥{{ sumFee }}</div>
                        </div>
                        <!-- <div class="particulars">
                            <p v-html="ProdOfferData.prodofferdesc"></p>
                            <van-popup
                                v-model="show"
                                :style="{ width: '90%', height: '30%' }"
                            >
                                <div class="wrapper">
                                    <div @click.stop class="block">
                                        <div class="block_tit">
                                            <span class="SetMealDetail"
                                                >套餐详情</span
                                            ><span
                                                class="close"
                                                @click="show = false"
                                                >X</span
                                            >
                                        </div>
                                        <div
                                            class="block_con"
                                            v-html="ProdOfferData.prodofferdesc"
                                        ></div>
                                    </div>
                                </div>
                            </van-popup>
                            <span @click="show = true">查看详情</span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="three" v-if="active == 2">
            <!-- <Four @ScrollTop="ScrollTop"></Four> -->
            <Four></Four>
            <div class="consent">
                <div class="checkedbox">
                    <van-checkbox v-model="checked" shape="square"></van-checkbox>
                </div>
                <div class="checkedboxs">
                    <p>我同意<span>《蓝猫移动入网协议》</span></p>
                </div>
            </div>
            <div class="canvasbox">
                <div class="canvasbox_header_tit">
                    <div class="tu">
                        <img src="~@/assets/images/opencard/open4.png" alt="" />
                    </div>
                    <span>签名确认</span>
                </div>
                <!-- 签名的点击按钮 -->
                <div class="clacksignature" @click="openModal">
                    <div class="padding" v-if="!Imgs">请点击完善签名确认</div>
                    <img v-else style="height: 200rpx" :src="Imgs" alt="" />
                </div>

                <!-- 签名的弹出框 -->
                <van-popup v-model="showModal">
                    <div class="popup_header">
                        <span class="popup_header_text">签名表</span><span @click="showModal = false">X</span>
                    </div>
                    <div class="popup_content">
                        <div class="popup_content_con" style="border: 1px solid #e6e6e6">
                            <vue-esign ref="esign" :width="900" :height="400" :isCrop="isCrop" :lineWidth="lineWidth"
                                :lineColor="lineColor" :bgColor.sync="bgColor" />
                        </div>
                    </div>

                    <div class="btnBox">
                        <button @click="handleReset">重签</button>
                        <button @click="handleGenerate">确定</button>
                    </div>
                </van-popup>
            </div>
        </div>

        <!-- 拍照开始 -->
        <div class="five" v-if="active == 3">
            <div class="one_header">
                <div class="one_header_tit">
                    <div class="tu">
                        <img src="~@/assets/images/opencard/open2.png" alt="" />
                    </div>
                    <div>证件合拍</div>
                </div>
            </div>
            <div class="ImgFor" v-if="TogetherImgsFlag == 'wu'">
                <img class="ImgData" src="@/assets/images/opencard/open7.png" @click="imgClick" />
                <p>点击图片将卡板背面和身份证国徽面进行合拍</p>
            </div>
            <div class="ImgFor" v-if="TogetherImgsFlag == 'you'">
                <img class="ImgData" v-if="TogetherImgsFlag == 'you'" :src="TogetherImgsData" />
            </div>

            <input style="float: left; display: none" type="file" id="uploadFile" accept="image/*" capture="camera"
                @change="readLocalFile($event)" />
        </div>
        <!-- 拍照结束 -->

        <div class="four" v-if="active == 4">
            <div class="one_header">
                <div class="one_header_tit">
                    <div class="tu">
                        <img src="~@/assets/images/opencard/open2.png" alt="" />
                    </div>
                    <div>实名认证</div>
                </div>
                <div class="stepNumber">3/4</div>
            </div>
            <!-- v-if="ifCheckCard == '0'" -->
            <div class="three_goautonym" @click="goautonym">
                <img src="~@/assets/images/opencard/open5.png" alt="" />
            </div>
        </div>

        <div class="OpenCard_footer">
            <button @click="NextStep">
                {{ active == 4 ? "去实名" : "下一步" }}
            </button>
        </div>
    </div>
</template>

<script>
import preventBack from "vue-prevent-browser-back"
import { Toast, Dialog } from "vant"
import { mapState } from "vuex"
import Four from "../../components/openCard/four.vue"
import Lanmao from "../../components/lanmao/index"
import {
    SimOrServiceIdQuery,
    CheckIccid,
    QueryOpenCardLocation,
    CheckPhoneValid,
    ChanelCheck,
    AppCommonCheck,
    Checkoffername,
    AddOpenCardLocation,
    SignatureUpload,
    GetUrl,
    GetSimpleOrCardNum,
    DoCheckIdentityCodeByWorkNo,
    QuerySign,
    Dosubmits,
    UploadCard,
} from "../../common/newapi/api.js"
export default {
    components: {
        Four,
    },
    mixins: [preventBack], //注入  阻止返回上一页
    data() {
        return {
            // 测试环境
            Iphone: "", //用户输入的手机号
            wholetcard: "", //用户输入的的后七位iccid号码
            // 生产环境
            // Iphone: "1650444997", //用户输入的手机号
            // wholetcard: "1099983", //用户输入的的后七位iccid号码
            FullCard: "", //完整的卡号
            step: [
                {
                    title: "填写信息",
                    img: require("@/assets/images/opencard/open11.png"),
                    imgActive: require("@/assets/images/opencard/open1.png"),
                    span: true,
                },
                {
                    title: "套餐详情",
                    img: require("@/assets/images/opencard/open22.png"),
                    imgActive: require("@/assets/images/opencard/open2.png"),
                    span: true,
                },
                {
                    title: "入网协议",
                    img: require("@/assets/images/opencard/open44.png"),
                    imgActive: require("@/assets/images/opencard/open4.png"),
                    span: true,
                },
                {
                    title: "实名认证",
                    img: require("@/assets/images/opencard/open33.png"),
                    imgActive: require("@/assets/images/opencard/open3.png"),
                },
            ],
            prodOfferName: "", //套餐名称
            sumFee: "", //套餐价格
            go: "下一步",
            active: "0", //流程进度
            // show: false,//原套餐详情显示隐藏开关（已注释）

            judge: false, //实名认证完成返回页面的变量
            // 签名数据
            lineWidth: 6,
            lineColor: "#000000",
            bgColor: "",
            isCrop: false,
            Imgs: "", //页面展示的图片
            imgsData: "", //签名base64码
            showModal: false, //签名框是否打开开关
            //入网协议数据
            ScrollTopData: "", //入网协议是否查看完
            checked: false, //入网协议是否选中
            canvas: 1, //判断是否执行画布
            sign: "", //提交加密接口的加密串
            timestamp: "", //提交加密接口的时间戳
            payNum: false, //支付查询的开关 000开始查询
            // 是否增加小卡版数据
            TogetherImgsFlag: "wu", //用户合拍显示隐藏
            TogetherImgsData: "", //合拍base64码
            ifCheckCard: "", //实名认证背景图是否添加小卡版显示隐藏
            fileName: "", //拍照图片的名称
            fileData: "", //压缩后图片数据

            // 接口数据
            SimOrServiceIdQueryDatas: {}, //号卡查询接口的数据
            checkphonevaliddata: {}, //号卡有效性验证的数据
            ProdOfferData: {}, //获取的默认套餐页面数据
            QueryOne: {}, //第一次查询开卡记录的数据
            // 接口数据结束

            // 业务类型为4的小号、云语套餐
            // 1354022:蓝猫基础卡套餐、1354242: 蓝猫企信通套餐
            // 1354364:蓝猫云语套餐、1354455: 蓝猫云通信套餐(联通)
            // 1354602: 蓝猫云通信A套餐（联通）、
            systemIdFour: ["1354022", "1354242", "1354364", "1354455", "1354602"],
            // 业务类型为7的短信套餐
            // 1354122:蓝猫副号A套餐、1354262: 蓝猫副号C套餐、1354283: 蓝猫副号D套餐、
            systemIdSeven: ["1354122", "1354262", "1354283",],
        }
    },

    created() {
        this.authentication()
        this.wxPayQuery()
    },
    // 组件方法
    methods: {
        async NextStep() {
            // 第一页
            if (this.active == 0) {
                if (this.Iphone == "" && this.wholetcard == "") {
                    Toast({
                        position: "bottom",
                        message: "手机号 卡号不能为空",
                    })
                } else if (this.wholetcard.length != 7) {
                    Toast({
                        position: "bottom",
                        message: "卡号长度有误，请重新输入",
                    })
                } else {
                    // 号码查询
                    try {
                        var SimOrServiceIdQueryData = await SimOrServiceIdQuery({
                            iccidOrServiceId: this.Iphone,
                            type: 2,
                        })
                    } catch (err) {
                        Toast({
                            position: "button",
                            message: "号码查询失败",
                            duration: 800, //单位ms
                        })
                    }
                    //将号卡查询接口的数据保存
                    if (SimOrServiceIdQueryData.code != "0000") {
                        Toast({
                            position: "button",
                            message: SimOrServiceIdQueryData.msg,
                            duration: 800, //单位ms
                        })
                    } else {
                        this.SimOrServiceIdQueryDatas = SimOrServiceIdQueryData
                        localStorage.setItem("uimId", SimOrServiceIdQueryData.cardNo)   //将卡号添加至本地缓存
                        this.FullCard = SimOrServiceIdQueryData.cardNo  //获取完整卡号
                        //首次查询开卡记录(判断首次有没有开卡流程)
                        var QueryOpenCardData = await QueryOpenCardLocation({
                            uimId: this.FullCard,
                        })
                        this.QueryOne = QueryOpenCardData.data

                        if (QueryOpenCardData.code == 1) {
                            if (QueryOpenCardData.data.openCardLocation == 1) {
                                // 套餐名称及价格赋值渲染
                                this.prodOfferName = QueryOpenCardData.data.prodOfferName
                                this.sumFee = QueryOpenCardData.data.sumFee

                                // 通过判断rechargeAmount(充值金额)跟rechargeFlag[(支付标记)start为没有充值，finish为已充值成功]
                                // 充值金额大于0 与 支付标记为finish时为充值成功
                                if (
                                    QueryOpenCardData.data.sumFee > 0 &&
                                    QueryOpenCardData.data.rechargeFlag != "start"
                                ) {
                                    this.active = QueryOpenCardData.data.openCardLocation // 直接跳转到存储的页面中
                                }
                                // 充值金额大于0 与 支付标记为start时为有充值但未支付成功
                                else if (
                                    QueryOpenCardData.data.sumFee > 0 &&
                                    QueryOpenCardData.data.rechargeFlag == "start"
                                ) {
                                    // this.active = 1 // 直接跳转到套餐详情页面
                                    this.$router.push("/OpenPay")
                                } else if (QueryOpenCardData.data.sumFee == 0) {
                                    this.active = 2 // 直接跳转到存储的页面中
                                }
                            }
                            // 判断等于2
                            else if (QueryOpenCardData.data.openCardLocation == 2) {
                                // 通过判断 rechargeFlag、rechargeAmount、tradeNo、outTradeNo、来判断是否有充值
                                this.ifCheckCard = this.QueryOne.ifCheckCard //是否显示小卡版赋值
                                this.active = QueryOpenCardData.data.openCardLocation //跳转相应页面
                            }
                            // openCardLocation等于3，之前跳转签名页
                            else if (QueryOpenCardData.data.openCardLocation == 3) {
                                this.ifCheckCard = this.QueryOne.ifCheckCard //是否显示小卡版赋值
                                if (this.QueryOne.ifCheckCard == 1) {
                                    this.active = 3
                                } else {
                                    this.active = 4
                                }
                                this.active = QueryOpenCardData.data.openCardLocation // 直接跳转到存储的页面中
                            } else if (
                                QueryOpenCardData.data.openCardLocation == 4
                            ) {
                                this.ifCheckCard = this.QueryOne.ifCheckCard //是否显示小卡版赋值
                                this.active = QueryOpenCardData.data.openCardLocation // 直接跳转到存储的页面中
                            } else {
                                console.log("开卡流程缺失")
                            }
                            //
                        } else {
                            // 没有开过卡就正常流程
                            let Userdata = JSON.parse(
                                localStorage.getItem("Userdata")
                            )
                            // 卡号后七位校验
                            let CheckIccidData = await CheckIccid({
                                serviceId: this.Iphone,
                                iccid: this.wholetcard,
                            })

                            if (CheckIccidData.code != 1) {
                                Toast({
                                    position: "bottom",
                                    message: CheckIccidData.msg,
                                })
                            } else {
                                //号卡有效性验证接口
                                var CheckPhoneValidData = await CheckPhoneValid(
                                    {
                                        serviceId: this.Iphone, //用户输入的手机号
                                        uimId: this.FullCard, //用户输入的iccid
                                        workNo: Userdata.workNo,
                                        appType: Userdata.dealerName,
                                    }
                                )

                                if (CheckPhoneValidData.code != 0) {
                                    Toast({
                                        position: "bottom",
                                        message: CheckPhoneValidData.msg,
                                    })
                                }
                                // 号卡有效性验证否则(接口成功)
                                else {
                                    this.checkphonevaliddata = CheckPhoneValidData.data
                                    //渠道校验接口
                                    var ChanelCheckData = await ChanelCheck({
                                        serviceId: this.Iphone, //用户输入的手机号
                                        workNo: localStorage.getItem("workNo"),
                                    })

                                    if (ChanelCheckData.code != 0) {
                                        Toast({
                                            position: "bottom",
                                            message: ChanelCheckData.msg,
                                        })
                                    }
                                    //渠道校验否则(接口成功)
                                    else {
                                        //激活期和冻结接口
                                        var AppCommonCheckData = await AppCommonCheck({
                                            serviceId: this.Iphone, //用户输入的手机号
                                            workNo: localStorage.getItem("workNo"),
                                        })

                                        if (AppCommonCheckData.code != 0) {
                                            Toast({
                                                position: "bottom",
                                                message: AppCommonCheckData.msg,
                                            })
                                        }
                                        //激活期和冻结否则(接口成功)
                                        else {
                                            //未激活号码套餐展示
                                            var CheckoffernameData = await Checkoffername({
                                                WorkNo: localStorage.getItem("workNo"),
                                                serviceId: this.Iphone, //用户输入的手机号
                                                iccId: this.FullCard, //用户输入的iccid
                                                type: 2, //查询类型
                                                SystemId: 1, //来源类型
                                            })
                                            if (CheckoffernameData.code != 1) {
                                                Toast({
                                                    position: "bottom",
                                                    message: CheckoffernameData.msg,
                                                })
                                            }
                                            //未激活号码套餐展示否则
                                            else {
                                                // 将获取的套餐数据渲染至页面
                                                this.ProdOfferData = CheckoffernameData.data
                                                // 套餐名称及价格赋值渲染
                                                this.prodOfferName = CheckoffernameData.data.prodOfferName
                                                this.sumFee = CheckoffernameData.data.sumFee

                                                // 获取本地修改的业务类型
                                                let systemId = localStorage.getItem("systemId")

                                                // 短信套餐：default_sale_kind为4，system_id转换为7，符合开卡条件
                                                if (systemId == "7") {
                                                    // 套餐ID为短信套餐时,套餐ID为1354122,符合开卡条件
                                                    if (this.systemIdSeven.find(item => item == CheckoffernameData.data.prodOfferId)) {
                                                        this.active = 1
                                                    } else {
                                                        Toast({
                                                            message: "此号码套餐异常，请在蓝猫云商APP开卡激活",
                                                            position: "bottom",
                                                        })
                                                    }
                                                }
                                                //"小号业务"及 "云语套餐" default_sale_kind为3，system_id转换为4
                                                else if (systemId == "4") {
                                                    if (this.systemIdFour.find(item => item == CheckoffernameData.data.prodOfferId)) {
                                                        this.active = 1
                                                    } else {
                                                        Toast({
                                                            message: "此号码套餐异常，请在蓝猫云商APP开卡激活",
                                                            position: "bottom",
                                                        })
                                                    }
                                                } else {
                                                    Toast({
                                                        message: "此号码请在蓝猫云商APP开卡激活",
                                                        position: "bottom",
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } // 第二页
            else if (this.active == 1) {
                if (this.ProdOfferData.sumFee > 0) {
                    // 价格大于0跳转支付页面
                    // 1、支付前增加缓存记录
                    let Userdata = JSON.parse(
                        localStorage.getItem("Userdata")
                    )
                    let AddOpenCardOnePay = await AddOpenCardLocation({
                        serviceid: this.SimOrServiceIdQueryDatas.phoneNum, //手机号
                        applyEvent: "1", //业务类型
                        systemId: localStorage.getItem("systemId"), //套餐业务类型
                        acceptPerson: this.ProdOfferData.workNo, //工号
                        cityCode: "360", //受理人所在地市
                        resInstId: this.checkphonevaliddata.serviceInstId, //号码的实例ID
                        serviceKind: this.checkphonevaliddata.serviceKind, //运营商类型
                        uimId: this.checkphonevaliddata.uimId, //卡号
                        uimResInstId: this.checkphonevaliddata.uimInstId, //卡号的实例ID
                        belongCode: "360", //号码归属地
                        dealerId: Userdata.dealerId, //代理商编号
                        recordNumber: this.SimOrServiceIdQueryDatas.recordNumber, //订单号
                        openCardLocation: "1", //步骤条进度
                        prodOfferId: this.SimOrServiceIdQueryDatas.prodOfferId, //套餐ID
                        ifCheckCard: this.ProdOfferData.ifCheckCard, //是否添加小卡版
                        prodOfferName: this.ProdOfferData.prodOfferName, //套餐名称
                        sumFee: this.ProdOfferData.sumFee, //充值金额
                        rechargeFlag: "start", //充值标记 start进入支付 finish支付成功
                        dealerMainId: Userdata.dealerMainId, //顶级渠道
                    })
                    if (AddOpenCardOnePay.code != 1) {
                        Dialog.alert({
                            message: "缓存记录失败,请重新进行开卡",
                        }).then(() => {
                            this.active = 0
                        })
                    } else {
                        this.$router.push("/OpenPay")
                    }
                } else {
                    let Userdata = JSON.parse(
                        localStorage.getItem("Userdata")
                    )
                    // 3、不支付增加缓存记录 （套餐详情增加开卡记录）
                    let AddOpenCardOne = await AddOpenCardLocation({
                        serviceid: this.SimOrServiceIdQueryDatas.phoneNum, //手机号
                        applyEvent: "1", //业务类型
                        systemId: localStorage.getItem("systemId"), //套餐业务类型
                        acceptPerson: this.ProdOfferData.workNo, //工号
                        cityCode: "360", //受理人所在地市
                        resInstId: this.checkphonevaliddata.serviceInstId, //号码的实例ID
                        serviceKind: this.checkphonevaliddata.serviceKind, //运营商类型
                        uimId: this.checkphonevaliddata.uimId, //卡号
                        uimResInstId: this.checkphonevaliddata.uimInstId, //卡号的实例ID
                        belongCode: "360", //号码归属地
                        dealerId: Userdata.dealerId, //代理商编号
                        recordNumber: this.SimOrServiceIdQueryDatas.recordNumber, //订单号
                        openCardLocation: "2", //步骤条进度
                        prodOfferId: this.SimOrServiceIdQueryDatas.prodOfferId, //套餐ID
                        ifCheckCard: this.ProdOfferData.ifCheckCard, //是否添加小卡版
                        rechargeFlag: "start",
                        dealerMainId: Userdata.dealerMainId,//顶级渠道
                    })
                    if (AddOpenCardOne.code != 1) {
                        Dialog.alert({
                            message: "缓存记录失败,请重新进行开卡",
                        }).then(() => {
                            this.active = 0
                        })
                    } else {
                        this.active = 2
                    }
                }
            }
            // 第三页
            else if (this.active == 2) {
                // if (this.ScrollTopData < 28800) {
                //     Toast({
                //         message: "请完整看完《蓝猫移动入网协议》",
                //         position: "bottom",
                //         duration: 800,//单位ms
                //     })
                // } else
                if (this.checked == false) {
                    Toast({
                        position: "bottom",
                        message: "请打勾",
                    })
                } else if (this.imgsData == "") {
                    Toast({
                        message: "电子签名不能为空",
                        position: "bottom",
                    })
                } else {
                    //上传签名查询开卡记录
                    let QueryOpenCardLocationData = await QueryOpenCardLocation(
                        {
                            uimId: localStorage.getItem("uimId"),
                        }
                    )

                    if (QueryOpenCardLocationData.code != 1) {
                        Dialog.alert({
                            message: "开卡时间过长数据缺失,请重新输入手机号",
                        }).then(() => {
                            this.active = 0
                        })
                    } else {
                        // 上传签名接口
                        let SignatureUploadData = await UploadCard({
                            image: this.imgsData, //签名图片路径
                        })

                        if (SignatureUploadData.code != 1) {
                            Toast({
                                position: "bottom",
                                message: SignatureUploadData.msg,
                            })
                        }
                        //上传签名否则(接口成功)
                        else {
                            // 4、签名后增加缓存记录（入网协议增加缓存记录）
                            var CheckCard
                            if (
                                // 查询redis 是否添加小卡版为0时，直接跳转至第四个实名页面。否者跳转第三个合拍页面
                                QueryOpenCardLocationData.data.ifCheckCard == 0
                            ) {
                                CheckCard = 4
                            } else {
                                CheckCard = 3
                            }
                            if (QueryOpenCardLocationData.data.rechargeFlag != "finish") {
                                var AddOpenCardLocationTwo = await AddOpenCardLocation({
                                    serviceid: QueryOpenCardLocationData.data.serviceid, //手机号
                                    applyEvent: "1", //业务类型
                                    systemId: QueryOpenCardLocationData.data.systemId, //套餐业务类型
                                    acceptPerson: QueryOpenCardLocationData.data.acceptPerson, //工号
                                    cityCode: "360", //受理人所在地市
                                    resInstId: QueryOpenCardLocationData.data.resInstId, //号码的实例ID
                                    serviceKind: QueryOpenCardLocationData.data.serviceKind, //运营商类型
                                    uimId: QueryOpenCardLocationData.data.uimId, //卡号
                                    uimResInstId: QueryOpenCardLocationData.data.uimResInstId, //卡号的实例ID
                                    belongCode: "360", //号码归属地
                                    dealerId: QueryOpenCardLocationData.data.dealerId, //代理商编号
                                    recordNumber: QueryOpenCardLocationData.data.recordNumber, //订单号
                                    openCardLocation: CheckCard, //步骤条进度
                                    prodOfferId: QueryOpenCardLocationData.data.prodOfferId, //套餐ID
                                    ifCheckCard: QueryOpenCardLocationData.data.ifCheckCard, //是否添加小卡版
                                    rechargeFlag: QueryOpenCardLocationData.data.rechargeFlag, //充值标记 start进入支付 finish支付成功
                                    netSignUrl: SignatureUploadData.data, //签名图片地址
                                    dealerMainId: QueryOpenCardLocationData.data.dealerMainId, //顶级渠道
                                })
                            } else {
                                var AddOpenCardLocationTwo =
                                    await AddOpenCardLocation({
                                        serviceid: QueryOpenCardLocationData.data.serviceid, //手机号
                                        applyEvent: "1", //业务类型
                                        systemId: QueryOpenCardLocationData.data.systemId, //套餐业务类型
                                        acceptPerson: QueryOpenCardLocationData.data.acceptPerson, //工号
                                        cityCode: "360", //受理人所在地市
                                        resInstId: QueryOpenCardLocationData.data.resInstId, //号码的实例ID
                                        serviceKind: QueryOpenCardLocationData.data.serviceKind, //运营商类型
                                        uimId: QueryOpenCardLocationData.data.uimId, //卡号
                                        uimResInstId: QueryOpenCardLocationData.data.uimResInstId, //卡号的实例ID
                                        belongCode: "360", //号码归属地
                                        dealerId: QueryOpenCardLocationData.data.dealerId, //代理商编号
                                        recordNumber: QueryOpenCardLocationData.data.recordNumber, //订单号
                                        openCardLocation: CheckCard, //步骤条进度
                                        prodOfferId: QueryOpenCardLocationData.data.prodOfferId, //套餐ID
                                        ifCheckCard: QueryOpenCardLocationData.data.ifCheckCard, //是否添加小卡版
                                        netSignUrl: SignatureUploadData.data, //签名图片地址
                                        sumFee: QueryOpenCardLocationData.data.sumFee, //充值金额
                                        outTradeNo: QueryOpenCardLocationData.data.outTradeNo, //商户流水号
                                        // tradeNo: QueryOpenCardLocationData.data.tradeNo, //支付流水号
                                        rechargeFlag: QueryOpenCardLocationData.data.rechargeFlag, //充值标记 start进入支付 finish支付成功
                                        dealerMainId: QueryOpenCardLocationData.data.dealerMainId, //顶级渠道
                                    })
                            }

                            if (AddOpenCardLocationTwo.code != 1) {
                                Dialog.alert({
                                    message: "缓存记录失败,请重新进行开卡",
                                }).then(() => {
                                    this.active = 0
                                })
                            } else {
                                this.ifCheckCard = QueryOpenCardLocationData.data.ifCheckCard
                                if (
                                    QueryOpenCardLocationData.data.ifCheckCard != 1) {
                                    this.active = 4
                                } else {
                                    // 需要拍照
                                    this.active = 3
                                }
                            }
                        }
                    }
                }
            }
            // 第四页 合拍页
            else if (this.active == 3) {
                if (this.TogetherImgsData == "") {
                    Toast({
                        position: "bottom",
                        message: "请点击合拍图片进行拍照",
                    })
                } else {
                    let QueryOpenCardLocationData = await QueryOpenCardLocation(
                        {
                            uimId: localStorage.getItem("uimId"),
                        }
                    )
                    if (QueryOpenCardLocationData.code != 1) {
                        Dialog.alert({
                            message: "开卡时间过长数据缺失,请重新输入手机号",
                        }).then(() => {
                            this.active = 0
                        })
                    } else {
                        Lanmao.loading.show("上传照片中")
                        // 上传合拍接口增加工号入参
                        let SignatureUploadData = await SignatureUpload({
                            image: this.TogetherImgsData,
                            workNo: QueryOpenCardLocationData.data.acceptPerson, //工号
                        })
                        if (SignatureUploadData.code != 1) {
                            Lanmao.loading.hide()
                            Toast({
                                position: "bottom",
                                message: SignatureUploadData.msg,
                            })
                        } else {
                            Lanmao.loading.hide()
                            // 4、拍合照增加缓存记录（入网协议增加缓存记录）
                            if (QueryOpenCardLocationData.data.rechargeFlag != "finish") {
                                var AddOpenCardLocationTwo = await AddOpenCardLocation({
                                    serviceid: QueryOpenCardLocationData.data.serviceid, //手机号
                                    applyEvent: "1", //业务类型
                                    systemId: QueryOpenCardLocationData.data.systemId, //套餐业务类型
                                    acceptPerson: QueryOpenCardLocationData.data.acceptPerson, //工号
                                    cityCode: "360", //受理人所在地市
                                    resInstId: QueryOpenCardLocationData.data.resInstId, //号码的实例ID
                                    serviceKind: QueryOpenCardLocationData.data.serviceKind, //运营商类型
                                    uimId: QueryOpenCardLocationData.data.uimId, //卡号
                                    uimResInstId: QueryOpenCardLocationData.data.uimResInstId, //卡号的实例ID
                                    belongCode: "360", //号码归属地
                                    dealerId: QueryOpenCardLocationData.data.dealerId, //代理商编号
                                    recordNumber: QueryOpenCardLocationData.data.recordNumber, //订单号
                                    openCardLocation: "4", //步骤条进度
                                    prodOfferId: QueryOpenCardLocationData.data.prodOfferId, //套餐ID
                                    ifCheckCard: QueryOpenCardLocationData.data.ifCheckCard, //是否添加小卡版
                                    rechargeFlag: QueryOpenCardLocationData.data.rechargeFlag, //充值标记 start进入支付 finish支付成功
                                    netSignUrl: QueryOpenCardLocationData.data.netSignUrl, //签名图片地址
                                    TogetherUrl: SignatureUploadData.data, //合拍图片地址
                                    dealerMainId: QueryOpenCardLocationData.data.dealerMainId, //顶级渠道
                                })
                            } else {
                                var AddOpenCardLocationTwo =
                                    await AddOpenCardLocation({
                                        serviceid: QueryOpenCardLocationData.data.serviceid, //手机号
                                        applyEvent: "1", //业务类型
                                        systemId: QueryOpenCardLocationData.data.systemId, //套餐业务类型
                                        acceptPerson: QueryOpenCardLocationData.data.acceptPerson, //工号
                                        cityCode: "360", //受理人所在地市
                                        resInstId: QueryOpenCardLocationData.data.resInstId, //号码的实例ID
                                        serviceKind: QueryOpenCardLocationData.data.serviceKind, //运营商类型
                                        uimId: QueryOpenCardLocationData.data.uimId, //卡号
                                        uimResInstId: QueryOpenCardLocationData.data.uimResInstId, //卡号的实例ID
                                        belongCode: "360", //号码归属地
                                        dealerId: QueryOpenCardLocationData.data.dealerId, //代理商编号
                                        recordNumber: QueryOpenCardLocationData.data.recordNumber, //订单号
                                        openCardLocation: "4", //步骤条进度
                                        prodOfferId: QueryOpenCardLocationData.data.prodOfferId, //套餐ID
                                        ifCheckCard: QueryOpenCardLocationData.data.ifCheckCard, //是否添加小卡版
                                        netSignUrl: QueryOpenCardLocationData.data.netSignUrl, //签名图片地址
                                        sumFee: QueryOpenCardLocationData.data.sumFee, //充值金额
                                        outTradeNo: QueryOpenCardLocationData.data.outTradeNo, //商户流水号
                                        // tradeNo:QueryOpenCardLocationData.data.tradeNo, //支付流水号
                                        rechargeFlag: QueryOpenCardLocationData.data.rechargeFlag, //充值标记 start进入支付 finish支付成功
                                        TogetherUrl: SignatureUploadData.data, //合拍图片地址
                                        dealerMainId: QueryOpenCardLocationData.data.dealerMainId, //顶级渠道
                                    })
                            }

                            if (AddOpenCardLocationTwo.code != 1) {
                                Dialog.alert({
                                    message: "缓存记录失败,请重新进行开卡",
                                }).then(() => {
                                    this.active = 0
                                })
                            } else {
                                this.ifCheckCard =
                                    QueryOpenCardLocationData.data.ifCheckCard
                                this.active = 4
                            }
                        }
                    }
                }
            }
            // 第五页 实名页
            else if (this.active == 4) {
                // 获取实名认证地址
                this.goautonym()
            }
        },

        //微信查询返回跳转入网协议操作
        async wxPayQuery() {
            if (localStorage.getItem("payquery") == "1") {
                var QueryOpenCardLocationData = await QueryOpenCardLocation({
                    uimId: localStorage.getItem("uimId"),
                })
                if (QueryOpenCardLocationData.code != 1) {
                    localStorage.setItem("payquery", false)
                    Dialog.alert({
                        message: "开卡时间过长数据缺失,请重新输入手机号",
                    }).then(() => {
                        this.active = 0
                    })
                } else {
                    localStorage.removeItem("payquery")
                    this.ifCheckCard =
                        QueryOpenCardLocationData.data.ifCheckCard
                    this.active =
                        QueryOpenCardLocationData.data.openCardLocation //是否添加小卡版及步骤条赋值
                }
            } else {
                console.log("微信认证失败")
                localStorage.setItem("payquery", false)
            }
        },

        // 签名的打开框
        openModal() {
            this.showModal = true
        },

        // 重签
        handleReset() {
            this.$refs.esign.reset()
        },
        // 生成base64格式
        handleGenerate() {
            this.$refs.esign
                .generate()
                .then(res => {
                    this.Imgs = res
                    this.imgsData = res.replace(/^data:image\/\w+;base64,/, "") //把base64赋给img
                    Toast({
                        position: "bottom",
                        message: "签名已确认，继续进行提交",
                    })
                    this.showModal = false
                })
                .catch(err => {
                    this.error = err
                    Toast({
                        message: "电子签名不能为空",
                        position: "bottom",
                    }) // 画布没有签字时会执行这里
                })
        },

        // 获取实名认证地址
        async goautonym() {
            let GetUrlData = await GetUrl()
            if (GetUrlData.code != 1) {
                Dialog.alert({
                    message: GetUrlData.msg,
                }).then(() => {
                    this.active = 0
                })
            } else {
                localStorage.setItem("GetToken", GetUrlData.data.token)
                this.judge = "RealNameStart" //实名开卡=0需要查询
                localStorage.setItem("Judge", this.judge) //存储开关
                window.location.href = GetUrlData.data.url
            }
        },

        // 实名认证查询
        async authentication() {
            if (localStorage.getItem("Judge") == "RealNameStart") {
                console.log("认证成功开始调用")
                // 开始认证查询
                this.active = 4
                Lanmao.loading.show("正在查询验证")
                // 实名后查询开卡记录
                let QueryOpenCardData = await QueryOpenCardLocation({
                    uimId: localStorage.getItem("uimId"),
                })
                if (QueryOpenCardData.code != 1) {
                    localStorage.setItem("Judge", false) //实名查询禁止
                    Lanmao.loading.hide()
                    Dialog.alert({
                        message: "开卡时间过长数据缺失,请重新输入手机号",
                    }).then(() => {
                        localStorage.setItem("Judge", false)
                        this.active = 0
                    })
                } else {
                    this.ifCheckCard = QueryOpenCardData.data.ifCheckCard //实名认证图片展示赋值

                    // 获取实名认证验证信息接口
                    let GetSimpleOrCardNumData = await GetSimpleOrCardNum({
                        token: localStorage.getItem("GetToken"),
                        serviceId: QueryOpenCardData.data.serviceid,
                        workNo: QueryOpenCardData.data.acceptPerson,
                        recordNumber: QueryOpenCardData.data.recordNumber,
                    })

                    if (GetSimpleOrCardNumData.code != 1) {
                        localStorage.setItem("Judge", false) //实名查询禁止
                        Lanmao.loading.hide()
                        Toast({
                            position: "bottom",
                            message: GetSimpleOrCardNumData.msg,
                        })
                    }
                    // 获取实名认证验证信息接口的否则(接口成功)
                    else {
                        // localStorage.setItem("Judge", false) //实名查询禁止
                        //         Lanmao.loading.hide() //提交提示框关闭
                        if (GetSimpleOrCardNumData.data.cardNum.success == 0) {
                            if (
                                GetSimpleOrCardNumData.data.simple.success == 0
                            ) {
                                // 一证五户校验接口
                                let IdentityCardVerifyData = await DoCheckIdentityCodeByWorkNo({
                                    aceptPerson: QueryOpenCardData.data.acceptPerson,
                                    identityCode: GetSimpleOrCardNumData.data.cardNum.confirmIdcardNumber,
                                })

                                if (IdentityCardVerifyData.code != 0) {
                                    localStorage.setItem("Judge", false) //实名查询禁止
                                    Lanmao.loading.hide()
                                    Toast({
                                        position: "bottom",
                                        message: IdentityCardVerifyData.msg,
                                    })
                                } else {
                                    Lanmao.loading.hide()
                                    Lanmao.loading.show("正在提交")
                                    let QuerySignData = await QuerySign({
                                        serviceId:
                                            QueryOpenCardData.data.serviceid,
                                    })
                                    if (QuerySignData.code != 1) {
                                        Lanmao.loading.hide()
                                        localStorage.setItem("Judge", false) //实名查询禁止
                                        Toast({
                                            position: "bottom",
                                            message: QuerySignData.msg,
                                        })
                                    }
                                    // 提交加密接口否则(接口成功)
                                    else {
                                        // 判断是否充值定义变量rechargeFlag (充值标记为finish为已充值，start为)
                                        var PayOutTradeNo
                                        var PaySumFee
                                        var PayrechargeFlag
                                        var CardOutdexData
                                        if (
                                            QueryOpenCardData.data.rechargeFlag != "finish"
                                        ) {
                                            if (QueryOpenCardData.data.ifCheckCard != 1) {
                                                //等于0时 换为百度云身份证背面
                                                CardOutdexData = GetSimpleOrCardNumData.data.cardNum.back
                                            } else {
                                                //等于1时 换为身份证加卡板合拍照片
                                                CardOutdexData = QueryOpenCardData.data.TogetherUrl
                                            }
                                            // 没有充值
                                            PayOutTradeNo = ""
                                            PaySumFee = ""
                                            PayrechargeFlag = ""
                                        } else {
                                            if (QueryOpenCardData.data.ifCheckCard != 1) {
                                                //等于0时 换为百度云身份证背面
                                                CardOutdexData = GetSimpleOrCardNumData.data.cardNum.back
                                            } else {
                                                //等于1时 换为身份证加卡板合拍照片
                                                CardOutdexData = QueryOpenCardData.data.TogetherUrl
                                            }

                                            PayrechargeFlag = ""
                                            PayOutTradeNo = QueryOpenCardData.data.outTradeNo //商户流水号
                                            PaySumFee = QueryOpenCardData.data.sumFee //充值金额
                                        }

                                        // 提交
                                        let DosubmitData = await Dosubmits({
                                            serviceId: QueryOpenCardData.data.serviceid, //手机号
                                            applyEvent: "1", //业务类型 1开成卡2过户
                                            systemId: QueryOpenCardData.data.systemId,
                                            customerName: GetSimpleOrCardNumData.data.cardNum.confirmName, //姓名
                                            identityCode: GetSimpleOrCardNumData.data.cardNum.confirmIdcardNumber, //证件号码
                                            identityKind: "1", //证件类型
                                            custAddress: GetSimpleOrCardNumData.data.cardNum.address, //地址
                                            cardIndex: GetSimpleOrCardNumData.data.cardNum.front, //正面
                                            cardOutdex: CardOutdexData, //反面(这参数为身份证反面改为合拍的照片路径)
                                            custPhoto: GetSimpleOrCardNumData.data.simple.image, //人脸
                                            acceptPerson: QueryOpenCardData.data.acceptPerson, //工号
                                            cityCode: "360",
                                            resInstId: QueryOpenCardData.data.resInstId, //号码的实例ID
                                            serviceKind: QueryOpenCardData.data.serviceKind, //运营商类型
                                            uimId: QueryOpenCardData.data.uimId, //卡号
                                            uimResInstId: QueryOpenCardData.data.uimResInstId, //卡号的实例ID
                                            belongCode: "360",
                                            dealerId: QueryOpenCardData.data.dealerId, //代理商编号
                                            prodOfferId: QueryOpenCardData.data.prodOfferId, //套餐ID
                                            netSignUrl: QueryOpenCardData.data.netSignUrl,
                                            sign: QuerySignData.data.sign, //提交加密的加密串
                                            timestamp: QuerySignData.data.timestamp, //提交加密接口的时间戳
                                            ifCheckCard: QueryOpenCardData.data.ifCheckCard, //是否添加小卡板
                                            recordNumber: QueryOpenCardData.data.recordNumber, //订单号
                                            rechargeFlag: PayrechargeFlag, //充值标记
                                            rechargeAmount: PaySumFee, //充值金额
                                            outTradeNo: PayOutTradeNo, //商户流水号
                                            dealerMainId: QueryOpenCardData.data.dealerMainId, //顶级渠道
                                        })

                                        if (DosubmitData.code != 0) {
                                            Lanmao.loading.hide() //提交提示框关闭
                                            localStorage.setItem("Judge", false) //实名查询禁止
                                            Toast({
                                                message: DosubmitData.msg,
                                                position: "bottom",
                                            })
                                        } else {
                                            Lanmao.loading.hide() //提交提示框关闭
                                            // 实名认证查询结束
                                            Toast({
                                                message: DosubmitData.msg,
                                                position: "bottom",
                                            })
                                            this.active = 0
                                            localStorage.removeItem("paynum")
                                            localStorage.removeItem("uimId")
                                            localStorage.removeItem("GetToken")
                                            localStorage.removeItem("Judge")
                                        }
                                    }
                                }
                            } else {
                                Lanmao.loading.hide()
                                localStorage.setItem("Judge", false) //实名查询禁止
                                // 录制视频失败后success不等于0时的判断，并提示
                                if (GetSimpleOrCardNumData.data.simple.success != 0) {
                                    Toast({
                                        message:
                                            GetSimpleOrCardNumData.data.simple.message,
                                        position: "bottom",
                                    })
                                } else {
                                    Toast({
                                        position: "bottom",
                                        message:
                                            "人脸校验失败，请重新继续进行实名",
                                    })
                                }
                            }
                        } else {
                            Lanmao.loading.hide()
                            localStorage.setItem("Judge", false) //实名查询禁止
                            // 录入身份证失败后success不等于0时的判断，并提示
                            if (GetSimpleOrCardNumData.data.cardNum.success != 0) {
                                Toast({
                                    message: GetSimpleOrCardNumData.data.cardNum.message,
                                    position: "bottom",
                                })
                            } else {
                                Toast({
                                    position: "bottom",
                                    message: "身份校验失败，请重新继续进行实名",
                                })
                            }
                        }
                    }
                }
            } else {
                console.log("认证失败")
                localStorage.setItem("Judge", false)
            }
        },

        // 拍照
        imgClick() {
            document.getElementById("uploadFile").click()
        },
        // 获取拍的照片的数据
        async readLocalFile(e) {
            var localFile = document.getElementById("uploadFile").files[0]
            // 判断图片是否要压缩
            let isLt1M = localFile.size / 1024 / 1024 <= 1
            if (!isLt1M) {
                this.ImgCompress(e, localFile)
            } else {
                // 拍的照片进行展示
                var reader = new FileReader()
                var content
                var current = this
                reader.onload = async function (event) {
                    content = event.target.result
                    current.TogetherImgsData = content //获取图片base64代码
                    current.TogetherImgsFlag = "you"
                }
                reader.onerror = function (event) {
                    alert("error")
                }
                content = reader.readAsDataURL(localFile, "UTF-8")
                let sss = document.getElementById("uploadFile").value
            }
        },

        // 图片压缩
        ImgCompress(e, file) {
            var vm = this
            vm.fileName = file.name
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function (e) {
                let image = new Image() //新建一个img标签（还没嵌入DOM节点)
                image.src = e.target.result
                image.onload = function () {
                    let canvas = document.createElement("canvas"),
                        context = canvas.getContext("2d"),
                        imageWidth = image.width / 4, //压缩后图片的大小
                        imageHeight = image.height / 4,
                        data = ""

                    canvas.width = imageWidth
                    canvas.height = imageHeight

                    context.drawImage(image, 0, 0, imageWidth, imageHeight)
                    data = canvas.toDataURL("image/jpeg")

                    var arr = data.split(","),
                        mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]),
                        n = bstr.length,
                        u8arr = new Uint8Array(n)
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n)
                    }
                    let filePress = new File([u8arr], vm.fileName, {
                        type: mime,
                    })
                    vm.fileData = filePress

                    // 拍的照片进行展示
                    var reader = new FileReader()
                    var content
                    reader.onload = async function (event) {
                        content = event.target.result
                        vm.TogetherImgsData = content //获取图片base64代码
                        vm.TogetherImgsFlag = "you"
                    }
                    reader.onerror = function (event) {
                        alert("error")
                    }
                    content = reader.readAsDataURL(vm.fileData, "UTF-8")
                    let sss = document.getElementById("uploadFile").value
                }
            }
        },
    },

    watch: {},

    computed: {},

    mounted() { },
}
</script>

<style scoped lang='scss'>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.OpenCard {
    width: 100%;
    height: 100%;
    background: #f1f1f3;

    .OpenCard_header {
        width: 100%;
        height: px2rem(350);
        background: #4881c4;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: px2rem(40);

        .OpenCard_header_title {
            width: 100%;
            height: px2rem(120);
            color: #ffffff;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: px2rem(46);
            padding: px2rem(0) px2ren(32);

            p {
                width: 100%;
                height: px2rem(60);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .OpenCard_header_Steps {
            width: 100%;
            height: 66%;
            background: #ffffff;
            border-top-left-radius: px2rem(30);
            border-top-right-radius: px2rem(30);
            padding: px2rem(0) px2rem(20);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .reset-steps {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                :nth-child(4) {
                    width: 16% !important;
                }

                .reset_steps {
                    width: 28%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    .steps_con {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;

                        img {
                            width: px2rem(60);
                            height: px2rem(60);
                        }

                        .steps_tit {
                            span {
                                font-size: px2rem(24);
                                background: #ffffff !important;
                            }
                        }
                    }

                    span {
                        margin-top: px2rem(-38);
                        width: px2rem(84);
                        height: px2rem(4);
                        background-color: #c2cec8;
                    }
                }

                .lineActive {
                    background: #1e4b9f !important;
                    color: #1e4b9f;
                }
            }
        }
    }

    .one_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: px2rem(20) px2rem(20);

        .one_header_tit {
            width: 30%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: px2rem(34);
            color: #a9a9a9;

            .tu {
                width: px2rem(50);
                height: px2rem(50);

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            span {
                font-size: px2rem(34);
            }
        }

        .stepNumber {
            color: #666668;
            font-size: px2rem(34);
        }
    }

    .one {
        width: 100%;
        background: #ffffff;

        .one_header_tit {
            width: 38%;
        }

        .one_content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            margin-top: px2rem(20);
            padding: px2rem(0) px2rem(20);

            .inp {
                width: 80%;
                height: px2rem(80);
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: px2rem(34);
                margin-top: px2rem(40);
                color: #6d6d6f;
                border-bottom: px2rem(1) solid #f0f0f2;

                input {
                    width: 70%;
                    font-size: px2rem(32);
                }

                input::-webkit-input-placeholder {
                    color: #aab2bd;
                    font-size: px2rem(26);
                }
            }

            .inpno {
                border: none;
                margin-bottom: px2rem(8);
            }
        }
    }

    .two {
        width: 100%;
        background: #ffffff;

        .one_content {
            width: 100%;
            height: px2rem(200);

            .setMeas {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: px2rem(20);

                .setMea {
                    width: 90%;
                    height: px2rem(180);
                    background: #4a81c1;
                    margin-top: px2rem(20);
                    color: #ffffff;
                    padding: px2rem(10) px2rem(20);
                    border-radius: px2rem(20);

                    .setMea_title {
                        width: 100%;
                        height: px2rem(40);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: px2rem(32);
                        margin-top: px2rem(20);
                    }

                    .gopay {
                        width: 100%;
                        height: 50%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        button {
                            width: px2rem(160);
                            height: px2rem(60);
                            border-radius: px2rem(8);
                            background: #1e4b9f;
                            font-size: px2rem(32);
                            margin-top: px2rem(30);
                        }
                    }

                    // .particulars {
                    //     width: 100%;
                    //     height: px2rem(40);
                    //     font-size: px2rem(30);
                    //     display: flex;
                    //     justify-content: space-between;
                    //     align-items: center;
                    //     margin-top: px2rem(30);

                    //     p {
                    //         width: 53%;
                    //         height: px2rem(34);
                    //         overflow: hidden;
                    //         text-overflow: ellipsis;
                    //         white-space: nowrap;
                    //     }
                    // }
                }
            }
        }

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;

            .block {
                width: 100%;
                height: px2rem(340);
                background: #f8f8f8;
                font-size: px2rem(30);
                color: #000000;

                .block_tit {
                    width: 100%;
                    height: px2rem(70);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    background: #ffffff;
                    color: #636564;
                    padding: px2rem(30);

                    .SetMealDetail {
                        margin-right: px2rem(240);
                        color: #000000;
                    }

                    .close {
                        font-size: px2rem(36);
                    }
                }

                .block_con {
                    padding: px2rem(20) px2rem(20);
                    line-height: px2rem(46);
                }
            }
        }
    }

    .four {
        width: 100%;
        height: 45%;
        background: #ffffff;

        .one_header_tit {
            width: 30%;
        }

        .three_goautonym {
            width: 100%;
            height: 80%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;
            // margin-top: px2rem(80);

            p {
                width: 90%;
                height: px2rem(100);
                font-size: px2rem(30);
                background: #4981c5;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
            }

            img {
                width: 80%;
                height: 70%;
                background: #ffffff;
                color: #ffffff;
                font-size: px2rem(32);
            }
        }
    }

    .three {
        width: 100%;
        font-size: px2rem(32);
        // padding: 0 px2rem(20);

        .consent {
            width: 100%;
            height: px2rem(80);
            font-size: px2rem(28);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background: #ffffff;

            .checkedboxs {
                width: 50%;
                height: 80%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                p {
                    width: 100%;
                }
            }

            .checkedbox {
                width: 50%;
                height: 80%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .van-checkbox {
                    width: 12%;
                    height: 60%;
                }
            }

            span {
                color: #4981c5;
            }
        }

        .canvasbox {
            width: 100%;
            height: px2rem(320);
            margin-top: px2rem(20);
            background: #ffffff;
            padding: px2rem(10);

            .canvasbox_header_tit {
                width: 100%;
                height: px2rem(50);
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: px2rem(40);
                color: #a9a9a9;
                margin-bottom: px2rem(30);

                .tu {
                    width: px2rem(50);
                    height: px2rem(50);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                span {
                    font-size: px2rem(34);
                    margin-left: px2rem(20);
                }
            }

            .clacksignature {
                width: 100%;
                height: 50%;

                .padding {
                    width: 100%;
                    height: px2rem(90);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: px2rem(1) solid red;
                }

                img {
                    width: 100%;
                    height: 100%;
                    border: px2rem(1) solid red;
                }
            }

            .van-popup {
                width: 80%;
                height: 34%;

                .popup_header {
                    width: 100%;
                    height: px2rem(80);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding: 0 px2rem(30);
                    border-bottom: px2rem(1) solid #eee;

                    .popup_header_text {
                        margin-right: px2rem(200);
                    }
                }

                .popup_content {
                    width: 100%;
                    height: 60%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .popup_content_con {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .btnBox {
                    width: 100%;
                    height: 20%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    button {
                        border: none;
                        background: none;

                        &:last-child {
                            color: green;
                        }
                    }
                }
            }

        }
    }

    .five {
        width: 100%;
        height: 60%;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .ImgFor {
            width: 90%;
            height: 90%;
            border: 1px solid #ccc;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            p {
                width: 90%;
                height: px2rem(100);
                font-size: px2rem(30);
                display: flex;
                justify-content: center;
                align-items: center;
                color: red;
            }

            .ImgData {
                width: 100%;
                height: 80%;
            }
        }

        .Imgbtn {
            width: 80%;
            height: px2rem(60);
            background: #4981c5;
            color: #ffffff;
        }
    }

    .OpenCard_footer {
        width: 100%;
        height: px2rem(150);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: px2rem(34) !important;
        margin-bottom: px2rem(60);

        button {
            width: 80%;
            height: 55%;
            background: #4881c4;
            color: #ffffff;
            border-radius: px2rem(16);
        }
    }
}
</style>
<style>
.van-checkbox__icon {
    font-size: 30px;
}
</style>