<template>
    <div class="cancelForm">
        <!-- 导航栏 -->
        <topHead>
            <img
                slot="head"
                src="../../../assets/images/fh.png"
                alt=""
                @click="backBefor()"
            />
            <h3 slot="header">过户发起申请</h3>
        </topHead>
        <!-- 内容 -->
        <div class="main">
            <div class="wrap">
                <div class="title">
                    <h4>过户发起原因</h4>
                </div>
                <textarea
                    placeholder="请填写过户发起原因"
                    cols="30"
                    rows="10"
                    v-model="reason"
                ></textarea>
            </div>
            <div class="wrap">
                <div class="title">
                    <h4>其他信息</h4>
                </div>
                <div class="ipt">
                    <div class="text">过户号码</div>
                    <input
                        type="text"
                        maxlength="11"
                        placeholder="请输入过户号码"
                        v-model="mobilePhone"
                    />
                </div>
                <div class="ipt">
                    <div class="text">服务密码</div>
                    <input
                        type="password"
                        placeholder="请输入服务密码"
                        v-model="phonePassword"
                    />
                </div>
            </div>
            <!-- 底部按钮 -->
            <div class="bottom_btn">
                <van-button @click="btnHeadler">提交</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast, Dialog } from "vant"
import API from "../../../common/api/api"
import topHead from "../../../components/topHead.vue"
import FaceAuth from "../../../common/FaceAuth"
import Lanmao from "../../../components/lanmao/index"
export default {
    name: "transAcctSForm",
    data() {
        return {
            //radio: "1",
            reason: "",
            mobilePhone: "",
            phonePassword: "",
            //accountName: 0,
            //accountBank: "",
            //bankCard: "",
            //sparePhone: "",
            // receive_man: "",
            // receive_phone: "",
            // receive_addr: "",
            option: [],
        }
    },
    components: {
        topHead,
    },
    mounted() {
        // this.headlerBank();
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL)
            window.addEventListener("popstate", this.backChange, false) //false阻止默认事件
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            FaceAuth.check("TRANS_ACCT_S")
                .then(res => {
                    if (res.data) {
                        if (res.data.code == -100) {
                            Toast({
                                message: res.data.msg,
                                position: "bottom",
                            })
                            this.$router.push("/transAcctS")
                        }
                    } else {
                        if (res.biz_no != "") {
                            Toast({
                                message: "身份核验成功",
                                forbidClick: true,
                                position: "bottom",
                            })
                            vm.biz_no = res.biz_no
                            next()
                        }
                    }

                    Toast({
                        message: "身份核验成功",
                        forbidClick: true,
                        position: "bottom",
                    })
                    vm.biz_no = res.biz_no
                    next()
                })
                .catch(() => {
                    Lanmao.alert({
                        title: "提示",
                        message: "身份核验失败，请重试",
                        confirm: () => {
                            next({ path: "/transAcctS", replace: true })
                        },
                    })
                })
        })
    },
    destroyed() {
        window.removeEventListener("popstate", this.backChange, false) //false阻止默认事件
    },
    methods: {
        //点击切换银行
        onConfirm() {
            this.$refs.item.toggle()
        },
        //提交信息
        btnHeadler() {
            let phone = /^[1][3,4,5,6,7,8][0-9]{9}$/
            if (this.reason == "") {
                Toast({
                    message: "请填写过户原因",
                    position: "bottom",
                })
                return
            }
            if (this.mobilePhone == "") {
                Toast({
                    message: "请填写过户号码",
                    position: "bottom",
                })
                return
            } else if (!phone.test(this.mobilePhone)) {
                Toast({
                    message: "请输入正确的手机号",
                    position: "bottom",
                })
                return
            }
            if (this.phonePassword == "") {
                Toast({
                    message: "请填写服务密码",
                    position: "bottom",
                })
                return
            }

            API.getJson("TRANS_ACCT_S_FORM", {
                biz_no: this.biz_no,
                token: localStorage.getItem("token"),
                reason: this.reason,
                mobilePhone: this.mobilePhone,
                phonePassword: this.phonePassword,
            }).then(res => {
                if (res.code == 1) {
                   this.$router.push({ path: "/transAcctSauditCabcel" })
                } else if (res.data.code == -100) {
                    Toast({
                        message: res.data.msg,
                        position: "bottom",
                    })
                } else {
                    Toast({
                        message: "提交数据异常",
                        position: "bottom",
                    })
                }
            })
        },
        backBefor() {
            Dialog.confirm({
                message: "您确定退出过户发起申请吗",
            })
                .then(() => {
                    // on confirm
                    this.$router.replace("/")
                })
                .catch(() => {
                    // on cancel
                })
            // self.location = self.location.origin
        },
        backChange() {
            const that = this
            console.log("监听到了")
            history.pushState(null, null, document.URL)
            setTimeout(() => {
                Dialog.confirm({
                    message: "您确定退出过户发起申请吗",
                })
                    .then(() => {
                        // on confirm
                        this.$router.replace("/")
                    })
                    .catch(() => {
                        // on cancel
                    })
            }, 100)
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30) px2rem(150);
}
.wrap:nth-child(4) {
    position: relative;
}
.wrap:nth-child(1) .title h4,
.wrap:nth-child(2) .title h4 {
    margin-bottom: px2rem(20);
}
.wrap .title h4 {
    font-size: px2rem(30);
    color: #333333;
    margin-top: px2rem(35);
}
.wrap textarea {
    width: 100%;
    height: px2rem(210);
    background-color: #f6f6f6;
    border-radius: px2rem(8);
    font-size: px2rem(28);
    padding: px2rem(40) px2rem(30);
    box-sizing: border-box;
}
.wrap .upImg {
    display: flex;
    justify-content: space-between;
}
.wrap .van-uploader,
.wrap .van-uploader img {
    width: px2rem(220);
    height: px2rem(160);
    border-radius: px2rem(8);
    overflow: hidden;
}
.wrap .ipt {
    width: 100%;
    position: relative;
    height: px2rem(97);
    font-size: px2rem(28);
    border-bottom: 1px solid #dfe0e7;
}
.wrap .text {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    top: 0;
    height: px2rem(97);
    font-size: px2rem(28);
}
.wrap .ipt .text span {
    flex: 0 0 100%;
    font-size: px2rem(28);
    color: #999999;
    margin-top: px2rem(-20);
}
.wrap .ipt input {
    width: 100%;
    height: px2rem(97);
    text-align: right;
}
// 底部按钮.
.bottom_btn {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}
.bottom_btn .van-button {
    width: 100%;
    height: px2rem(98);
    line-height: px2rem(98);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin: px2rem(50) auto 0;
    box-shadow: 0 px2rem(-10) px2rem(20) rgba($color: #000000, $alpha: 0.2);
}
// 单选框
.van-radio {
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
.van-radio-group {
    display: flex;
    justify-content: flex-end;
    height: px2rem(97);
}
</style>