<template>
    <div class="Pay">
        <div class="Pay_header">
            <div class="OpenCard_header_title">
                <!-- <div class="goback">
                    <van-icon name="arrow-left" @click="GoBack" />
                </div> -->
                <p>支付</p>
            </div>
        </div>
        <div class="Pay_content">
            <div class="Pay_content_price">
                <div class="Pay_content_price_logo">
                    <img src="~@/assets/images/pay/logo.png" alt="" />
                </div>
                <p>
                    需充值: <span>{{ sumFee }}.00</span>元
                </p>
            </div>

            <div class="Pay_content_mode">
                <p class="title">支付方式</p>
                <section class="selectPay">
                    <ul class="payBox active" id="payBox">
                        <li class="payWay" v-if="payKind == 1">
                            <div class="payWay_left">
                                <img src="../../assets/images/pay/wx1.png" />
                                <span>微信支付</span>
                            </div>
                            <span class="active"></span>
                            <!-- <span class="chooseIcon" :class="item.payType == payKind ? 'active' : ''"></span> -->
                        </li>
                        <li class="payWay" v-if="payKind == 2">
                            <div class="payWay_left">
                                <img src="../../assets/images/pay/zjczf.png" />
                                <span>资金池支付</span>
                            </div>
                            <span class="active"></span>
                        </li>
                    </ul>
                </section>
            </div>

            <div class="btn">
                <button @click="openPay">缴费</button>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast, Dialog } from "vant"
import {
    QueryOpenCardLocation,
    AddOpenCardLocation,
    AliPay,
    WxPay,
    PayKind,
    FundPoolRecharge
} from "../../common/newapi/api.js"
import preventBack from "vue-prevent-browser-back"
export default {
    components: {},
    mixins: [preventBack], //注入  阻止返回上一页
    data() {
        return {
            // payList: [
            //     {
            //         text: "微信支付",
            //         tuimg: require("../../assets/images/pay/wx1.png"),
            //         payType: "1"
            //     },
            //     {
            //         text: "资金池支付",
            //         tuimg: require("../../assets/images/pay/zjczf.png"),
            //         payType: "2"
            //     },
            //     {
            //         text: "支付宝支付",
            //         tuimg: require("../../assets/images/pay/zfb1.png"),
            //     },
            // ],
            payIndex: "",
            AllformData: "",
            sumFee: "",
            payWay: "", //支付方式
            payFrontData: {}, //支付前缓存的数据
            Allouttradeno: "", //商户流水号
            payKind: "",//支付方式
        }
    },
    created() {
        this.paydata()
    },

    // 组件方法
    methods: {
        // 获取支付前的数据
        async paydata() {
            var QueryOpenCardData = await QueryOpenCardLocation({
                uimId: localStorage.getItem("uimId"),
            })
            if (QueryOpenCardData.code != 1) {
                Dialog.alert({
                    message: "开卡时间过长数据缺失,请重新输入手机号",
                }).then(() => {
                    this.$router.push("/OpenCard")
                })
            } else {
                this.payFrontData = QueryOpenCardData.data
                this.sumFee = QueryOpenCardData.data.sumFee
                // 获取支付方式
                var PayQuery = await PayKind({
                    phoneNumber: QueryOpenCardData.data.serviceid,
                })
                this.payKind = PayQuery.data.payKind
            }
        },

        async openPay() {
            // 常规支付：1及资金池支付：2
            if (this.payKind == 1) {
                // 常规支付
                // 获取微信支付链接接口
                var WxPayData = await WxPay({
                    phone: this.payFrontData.serviceid,
                    amount: `${this.payFrontData.sumFee}.00`,
                    recordNumber: this.payFrontData.recordNumber,
                })
                if (WxPayData.code != 1 && WxPayData.code != 2) {
                    Toast({
                        position: "bottom",
                        message: WxPayData.msg,
                    })
                } else if (WxPayData.code == 2) {
                    let AddOpenCardOne = await AddOpenCardLocation({
                        serviceid: this.payFrontData.serviceid, //手机号
                        applyEvent: "1", //业务类型
                        systemId: this.payFrontData.systemId, //套餐业务类型
                        acceptPerson: this.payFrontData.acceptPerson, //工号
                        cityCode: "360", //受理人所在地市
                        resInstId: this.payFrontData.resInstId, //号码的实例ID
                        serviceKind: this.payFrontData.serviceKind, //运营商类型
                        uimId: this.payFrontData.uimId, //卡号
                        uimResInstId: this.payFrontData.uimResInstId, //卡号的实例ID
                        belongCode: "360", //号码归属地
                        dealerId: this.payFrontData.dealerId, //代理商编号
                        recordNumber: this.payFrontData.recordNumber, //订单号
                        openCardLocation: "2", //步骤条进度
                        prodOfferId: this.payFrontData.prodOfferId, //套餐ID
                        ifCheckCard: this.payFrontData.ifCheckCard, //是否添加小卡版
                        sumFee: this.payFrontData.sumFee, //充值金额
                        outTradeNo: this.payFrontData.outtradeno, //商户流水号
                        rechargeFlag: "finish", //充值标记 start进入支付 finish支付成功
                        dealerMainId: this.payFrontData.dealerMainId, //顶级渠道
                        // payquery: "1", //支付查询标记
                    })
                    if (AddOpenCardOne.code != 1) {
                        Dialog.alert({
                            message: "开卡时间过长数据缺失,请重新输入手机号",
                        }).then(() => {
                            this.$router.push("/OpenCard")
                        })
                    } else {
                        this.$router.push("/OpenCard")
                    }
                } else {
                    this.Allouttradeno = WxPayData.outtradeno //拿到商户流水号
                    this.AllformData = WxPayData.form //拿到去支付页面的链接

                    this.AddRedis()
                }
            } else if (this.payKind == 2) {
                // 资金池支付
                var FundPoolRechargeData = await FundPoolRecharge({
                    service_id: this.payFrontData.serviceid,// 号码
                    dealer_id: this.payFrontData.dealerMainId,// 工号顶级渠道
                    work_no: this.payFrontData.acceptPerson,// 登录工号
                    pay_fee: this.payFrontData.sumFee,//充值金额
                    recordNumber: this.payFrontData.recordNumber,//订单号
                })
                if (FundPoolRechargeData.code == 0) {
                    // 资金池支付成功把之前的信息存redis
                    let AddOpenCardOne = await AddOpenCardLocation({
                        serviceid: this.payFrontData.serviceid, //手机号
                        applyEvent: "1", //业务类型
                        systemId: this.payFrontData.systemId, //套餐业务类型
                        acceptPerson: this.payFrontData.acceptPerson, //工号
                        cityCode: "360", //受理人所在地市
                        resInstId: this.payFrontData.resInstId, //号码的实例ID
                        serviceKind: this.payFrontData.serviceKind, //运营商类型
                        uimId: this.payFrontData.uimId, //卡号
                        uimResInstId: this.payFrontData.uimResInstId, //卡号的实例ID
                        belongCode: "360", //号码归属地
                        dealerId: this.payFrontData.dealerId, //代理商编号
                        recordNumber: this.payFrontData.recordNumber, //订单号
                        openCardLocation: "2", //步骤条进度
                        prodOfferId: this.payFrontData.prodOfferId, //套餐ID
                        ifCheckCard: this.payFrontData.ifCheckCard, //是否添加小卡版
                        sumFee: this.payFrontData.sumFee, //充值金额
                        outTradeNo: FundPoolRechargeData.data.outTradeNo, //商户流水号
                        rechargeFlag: "finish", //充值标记 start进入支付 finish支付成功
                        dealerMainId: this.payFrontData.dealerMainId, //顶级渠道
                    })
                    if (AddOpenCardOne.code != 1) {
                        Dialog.alert({
                            message: "开卡时间过长数据缺失,请重新输入手机号",
                        }).then(() => {
                            this.$router.push("/OpenCard")
                        })
                    } else {
                        localStorage.setItem("payquery", "1")
                        this.$router.push("/OpenCard")
                    }
                } else {
                    Toast({
                        position: "bottom",
                        message: FundPoolRechargeData.message,
                    })
                }
            }
            // 获取支付宝支付链接接口
            // else {
            //     // 获取支付宝支付链接接口
            //     let AliPayData = await AliPay({
            //         phone: this.payFrontData.serviceid,
            //         amount: `${this.payFrontData.sumFee}.00`,
            //         recordNumber: this.payFrontData.recordNumber,
            //     })
            //     if (AliPayData.code != 1) {
            //         Toast({
            //             position: "bottom",
            //             message: AliPayData.msg,
            //         })
            //     } else {
            //         this.Allouttradeno = AliPayData.outtradeno //拿到商户流水号
            //         this.AllformData = AliPayData.form //拿到去支付页面的链接
            //         this.AddRedis()
            //     }
            // }
        },

        // 拿到支付链接后增加缓存记录
        async AddRedis() {
            let AddOpenCardOnePay = await AddOpenCardLocation({
                serviceid: this.payFrontData.serviceid, //手机号
                applyEvent: "1", //业务类型
                systemId: localStorage.getItem("systemId"), //套餐业务类型
                acceptPerson: this.payFrontData.acceptPerson, //工号
                cityCode: "360", //受理人所在地市
                resInstId: this.payFrontData.resInstId, //号码的实例ID
                serviceKind: this.payFrontData.serviceKind, //运营商类型
                uimId: this.payFrontData.uimId, //卡号
                uimResInstId: this.payFrontData.uimResInstId, //卡号的实例ID
                belongCode: "360", //号码归属地
                dealerId: this.payFrontData.dealerId, //代理商编号
                recordNumber: this.payFrontData.recordNumber, //订单号
                openCardLocation: "1", //步骤条进度
                prodOfferId: this.payFrontData.prodOfferId, //套餐ID
                ifCheckCard: this.payFrontData.ifCheckCard, //是否添加小卡版
                prodOfferName: this.payFrontData.prodOfferName, //套餐名称
                sumFee: this.payFrontData.sumFee, //充值金额
                rechargeFlag: "start", //充值标记 start进入支付 finish支付成功
                outtradeno: this.Allouttradeno, //商户流水号
                payMethod: this.payWay, //0微信，1是支付宝
                dealerMainId: this.payFrontData.dealerMainId, //顶级渠道
                // tradeNo: "", //支付流水号
                // payquery: "0", //支付查询标记
            })
            if (AddOpenCardOnePay.code != 1) {
                Dialog.alert({
                    message: "缓存记录失败,请重新进行开卡",
                }).then(() => {
                    this.$router.push("/OpenCard")
                })
            } else {
                if (this.payWay == 0) {
                    // 拼接支付后的返回地址
                    let redirect_url = "https://wechat.lanmaomobile.com/WxQuery"
                    // let redirect_url =
                    //     "https://apptest.lanmaomobile.com/WxQuery"
                    let wxUrl =
                        this.AllformData +
                        "&redirect_url=" +
                        encodeURIComponent(redirect_url)
                    window.location.href = wxUrl
                }
                // 支付宝跳转支付页面
                // else {
                //     const div = document.createElement("div")
                //     div.innerHTML = this.AllformData
                //     document.body.appendChild(div)
                //     document.forms[0].submit()
                // }
            }
        },

        // 选择支付方式
        // addImg(index, item) {
        //     this.payWay = index
        //     let _this = this
        //     _this.isBlanced = !_this.isBlanced
        //     if (_this.payIndex == index) {
        //         if (!_this.isBlanced) {
        //             _this.payIndex = -1
        //         } else {
        //             _this.payIndex = index
        //         }
        //     } else {
        //         _this.payIndex = index
        //     }
        // },
    },

    computed: {},

    mounted() { },
}
</script>

<style scoped lang='scss'>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.Pay {
    width: 100%;
    height: 100%;
    background: #f1f1f1;

    .Pay_header {
        width: 100%;
        height: px2rem(110);

        .OpenCard_header_title {
            width: 100%;
            height: 100%;
            color: #ffffff;
            background: #4881c4;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: px2rem(46);
            padding: px2rem(0) px2ren(40);

            p {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .Pay_content {
        width: 100%;

        .Pay_content_price {
            width: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            padding: px2rem(26);

            background: #ffffff;

            .Pay_content_price_logo {
                width: 20%;
                height: 20%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            p {
                margin-top: px2rem(60);
                margin-left: px2rem(26);
                font-size: px2rem(36);
                font-weight: 600;
                color: #4881c4;

                span {
                    color: red;
                    font-weight: 700;
                    font-size: px2rem(38);
                }
            }
        }

        .Pay_content_mode {
            width: 100%;
            background: #ffffff;
            margin-top: px2rem(30);
            padding: 0 px2rem(26);
            font-size: px2rem(28);

            .title {
                width: 100%;
                height: px2rem(60);
                font-size: px2rem(30);
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: px2rem(1) solid #f1f1f1;
            }

            .selectPay {
                width: 100%;

                #payBox {
                    width: 100%;

                    .payWay {
                        height: px2rem(80);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: px2rem(18);
                        border-bottom: px2rem(1) solid #f1f1f1;

                        .payWay_left {
                            width: 30%;
                            display: flex;
                            justify-content: start;
                            align-items: center;

                            img {
                                width: px2rem(46);
                                height: p2rem(46);
                                margin-right: px2rem(10);
                            }
                        }

                        .chooseIcon {
                            width: px2rem(30);
                            height: px2rem(30);
                            display: flex;
                            border: px2rem(1) solid #4881c4;
                            border-radius: 50%;
                            // background: red;
                        }

                        .active {
                            width: px2rem(30);
                            height: px2rem(30);
                            display: flex;
                            background: url(../../assets/images/xz.png) no-repeat;
                            background-size: 100%;
                        }
                    }
                }
            }
        }

        .btn {
            width: 100%;
            height: px2rem(80);
            margin-top: px2rem(30);
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                width: 86%;
                height: 80%;
                background: #4881c4;
                color: #ffffff;
                font-size: px2rem(32);
                border-radius: px2rem(10);
            }
        }
    }
}
</style>