<template>
  <div class="realSuccess">
    <!-- 导航栏 -->
    <topHead>
      <h3 slot="header">实名认证开机</h3>
    </topHead>
    <!-- 内容 -->
    <div class="main" v-if="result == true">
      <img src="../../../assets/images/cg.png" alt="" />
      <p>认证成功</p>
      <van-button round type="info" @click="realSuccess">确定</van-button>
    </div>
    <div class="main" v-if="result == false">
      <img src="../../../assets/images/sb.png" alt="" />
      <p>认证失败，请重新认证</p>
      <van-button round type="info" @click="goRealName">重新认证</van-button>
    </div>
    <!-- 加载中 -->
    <!-- <load :load="load">
            <p slot="text" class="loadText">结果查询中...</p>
        </load> -->
  </div>
</template>

<script>
import API from "../../../common/api/api"
import load from "../../../components/load.vue"
import topHead from "../../../components/topHead.vue"
export default {
    name: "realSuccess",
    components: {
        topHead,
        // load,
    },
    data() {
        return {
            result: null,
            load: true,
            type: "REAL_NAME_AUTH",
        }
    },
    created() {
        this.resultQuery()
    },
    mounted() {},
    methods: {
        // 查询校验结果
        resultQuery() {
            let token = localStorage.getItem("token")
            let biz_no = localStorage.getItem("biz_no")
            API.getJson("RESULT_QUERY", { token: token, biz_no: biz_no })
                .then(res => {
                    this.result = true
                })
                .catch(res => {
                    this.result = false
                })
        },
        // 失败
        goRealName() {
            // 直接跳转第三方
            // 返回开始页
            this.$router.push("/realName")
        },
        // 成功
        realSuccess() {
            self.location = self.location.origin
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    padding: 0 px2rem(30);
    text-align: center;
}
img {
    width: px2rem(147);
    height: px2rem(118);
    margin: px2rem(50) 0 px2rem(40);
}
p {
    font-size: px2rem(32);
    font-weight: 700;
}
.van-button {
    display: flex;
    justify-content: center;
    text-align: center;
    width: px2rem(550);
    height: px2rem(80);
    line-height: px2rem(80);
    color: #fff;
    font-weight: bold;
    font-size: px2rem(28);
    background-color: #336289;
    margin: px2rem(145) auto 0;
    box-shadow: 0 0 px2rem(10) rgba($color: #000000, $alpha: 0.2);
}
</style>