import Vue from 'vue'
import API from './api/api'
import Lanmao from "../components/lanmao/index"
import CodeModal from '../components/codeModal/index.js' //二维码模态框
import {
    Toast
} from 'vant'
export default {
    API_ALIAS_MAP: {
        //账号绑定
        'ACCOUNT_BIND': {
            "GET": "FACE_AUTH_ACCOUNT_BIND",
            "CHECK": "RESULT_QUERY",
            "ROUTE": "/noCode",
            'VERIFY': ['idCardNumber', 'name', 'mobilePhone']
        },
        //查名下卡号
        'CARD_NUMBER': {
            "GET": "QUERY_PHONE",
            "CHECK": "QUERY_CARD_NUMBER",
            "ROUTE": "/card", //queryCard
            'VERIFY': ['idCardNumber', 'name']
        },
        //销户申请
        'UNSET_ACCONT': {
            "GET": "CLOSE_ACCONT",
            "CHECK": "RESULT_QUERY",
            "ROUTE": "/cancelForm",
            'VERIFY': ['idCard', 'name', 'mobilePhone']
        },
        //补卡申请
        'CHANGE_CARD': {
            "GET": "CHANGE_CARD",
            "CHECK": "RESULT_QUERY",
            "ROUTE": "/changeCardForm",
            'VERIFY': ['idCard', 'name']
        },
        //实名认证
        'REAL_NAME_AUTH': {
            "GET": "REAL_NAME",
            "CHECK": "RESULT_QUERY",
            "ROUTE": "/realName",
            'VERIFY': ['mobilePhone', 'phonePassword']
        },

        // 过户发起人
        'TRANS_ACCT_S': {
            "GET": "TRANS_ACCT_S",
            "CHECK": "RESULT_QUERY",
            "ROUTE": "/transAcctSForm",
            'VERIFY': ['idCard', 'name']
        },
        // 过户确认人
        'TRANS_ACCT_T': {
            "GET": "TRANS_ACCT_T",
            "CHECK": "RESULT_QUERY",
            "ROUTE": "/transAcctTForm",
            'VERIFY': ['idCard', 'name']
        },


        //更改服务密码验证
        'SET_PASSWORD': {
            "GET": "servicePasswordEditTest",
            "CHECK": "RESULT_QUERY",
            "ROUTE": "/newPassword",
            'VERIFY': ['idCard', 'name', 'mobilePhone']
        },




    },
    //人脸验证
    start: function(options) {
        let mapList = this.API_ALIAS_MAP;
        let type = options.type;
        let alias = mapList[type].GET;
        let route = mapList[type].ROUTE;
        let field = mapList[type].VERIFY
        if (!this.verify(options, field)) {
            return;
        }
        console.log(type)

        delete options.type;
        options.returnUrl = window.location.protocol + '//' + window.location.host + route
            //检测参数
        API.getJson(alias, options).then(res => {
            //操作页面模态框的显隐
            //延迟挂载
            let token = localStorage.getItem("token")
            if (res.data) {
                //-104 代表已经有待审核的记录或者已经上传   -100 手机号不满足受理条件 （审核通过、或者手机号密码错误）调用二维码显示接口
                //必须为实名认证才会走CHECKVIDEO
                if ((res.data.code == -104 || res.data.code == -100) && type == 'REAL_NAME_AUTH') {
                    console.log('aaaaaaaa')
                    if (res.data.code == -100) {
                        Toast({
                            message: res.data.msg,
                            position: 'bottom',
                        });
                    } else {
                        API.getJson("CHECKVIDEO", {
                                token: token,
                                phone: res.data.data ? res.data.data.split(",")[1] : options.mobilePhone
                            })
                            .then(e => {
                                //上传视频认证调取二维码 
                                if (e.data) {
                                    if (e.data.code == -100) {
                                        Lanmao.loading.show('加载中...');
                                        API.getJson("CPDEIMG", {
                                            token: token,
                                            sn: e.data.data
                                        }).then(e => {
                                            Lanmao.loading.hide();
                                            CodeModal.alert({
                                                title: "提示",
                                                message: "请长按图片识别小程序完成视频认证上传！",
                                                ewmImg: e,
                                                confirm: () => {

                                                },
                                            })
                                        })
                                    }
                                }
                            })
                    }

                }
            } else {
                //旷视已拒审、未提交、不存在待审核记录 跳旷视认证
                sessionStorage.setItem('AUTH_TYPE', type);
                sessionStorage.setItem('FACE_AUTH_' + type, res.biz_no);
                if (res.url) {
                    window.location.href = res.url
                }
            }
        })
    },
    test: function() {
        let authType = sessionStorage.getItem('AUTH_TYPE') ? sessionStorage.getItem('AUTH_TYPE') : '';

        let router = authType ? this.API_ALIAS_MAP[authType].ROUTE : ''

        window.location.href = location.protocol + "//" + location.host + router
    },
    //校验参数
    verify: function(options, field = []) {
        let verifyMap = {
            'idCardNumber': [{
                'type': 'Require',
                'tips': '请输入身份证号后六位'
            }],
            'name': [{
                'type': 'Require',
                'tips': '请输入您的姓名'
            }],
            'idCard': [{
                    'type': 'Require',
                    'tips': '请输入您证件信息'

                }
                /* 由于过户接收 使用证件+姓名的方式 实现过户单号+验证码 因此去掉身份证号格式校验
                ,
                {
                    'type': 'RegEx',
                    'tips': '身份证号格式错误',
                    'regex': /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
                }*/
            ],
            'mobilePhone': [{
                    'type': 'Require',
                    'tips': '请输入手机号'
                },
                {
                    'type': 'RegEx',
                    'tips': '手机号格式错误',
                    'regex': /^1[3456789]\d{9}$/
                }
            ],
            'phonePassword': [{
                    'type': 'Require',
                    'tips': '请输入服务密码'
                }

            ]
        }
        try {
            field.forEach(key => {

                let map = verifyMap[key]
                map.forEach((item) => {
                    if (item.type === 'Require') {
                        if (!options[key]) throw (item.tips)

                    }
                    if (item.type === 'RegEx') {
                        if (!item.regex.test(options[key])) throw (item.tips)
                    }
                })
            });
        } catch (err) {
            Toast({
                message: err,
                position: 'bottom'
            })
            return false;
        }

        //销户申请字段 身份证号 姓名     idCard、name

        //实名认证字段 手机号 服务密码   mobilePhone、phonePassword

        //账号绑定字段 身份证后六位 姓名

        //查名下卡号字段 身份证后六位 姓名
        return true;
    },
    //检测结果
    check: function(type) {
        return new Promise((resolve, reject) => {
            let BIZ_NO = sessionStorage.getItem('FACE_AUTH_' + type) ? sessionStorage.getItem('FACE_AUTH_' +
                type) : '';
            sessionStorage.removeItem('FACE_AUTH_' + type);
            if (!BIZ_NO) {

                return;
            }
            let mapList = this.API_ALIAS_MAP;
            let alias = mapList[type].CHECK;
            API.getJson(alias, {
                biz_no: BIZ_NO
            }, false).then((res) => {
                setTimeout(() => {
                    res.biz_no = BIZ_NO;
                    resolve(res);
                }, 500)

            }).catch((err) => {
                let authInfo = {
                    biz_no: BIZ_NO
                };
                reject(authInfo);
            })
        })
    }
}