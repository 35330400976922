<template>
    <div class="WithoutCodeLogin">
        <div class="logins" v-if="Judge == 'android'"></div>

        <div class="login_tu" v-if="Judge == 'micromessenger'">
            <p>点击右上角在浏览器中打开</p>
        </div>

        <div class="login_tus" v-if="Judge == 'unknown'">
            <p>该系统暂不支持开卡</p>
        </div>
    </div>
</template>

<script>
import { GetSeesion, Login, Checkworknoinfo } from "../../common/newapi/api.js"
import { Toast } from "vant"
export default {
    components: {},
    props: ["UserNames", "PassWords"],
    data() {
        return {
            UserName: "", //测试工号 SHSHZY003  正式：LMQXTZYMD
            PassWord: "", //测试密码  744810   正式密码：Hlsd2021!
            type: "0", //0是不需要图形验证码，1是需要图形验证码
            imgCode: "1234", //图形验证码
            randomStrs: "",
            token: "",
            Judge: "",
        }
    },

    created() {
        this.JobNumber()
        // this.getCode()//免登录测试（线上注释）
    },
    // 组件方法
    methods: {
        JobNumber() {
            this.UserName = this.UserNames
            this.PassWord = this.PassWords
            this.judge()//免登录测试（线上放开）
        },
        // 获取code
        getCode() {
            let times = Date.now()
            this.randomStr = Math.ceil(Math.random() * 10)
            this.randomStrs = `${this.randomStr}${times}`
            this.Gologin()
        },

        judge() {
            let ua = window.navigator.userAgent.toLowerCase()

            // 判断用户手机是否为安卓手机
            if (
                ua.match(/android/i) == "android" ||
                ua.match(/iphone os/i) == "iphone os"
            ) {
                // 用户手机为安卓手机在判断是否在微信浏览器中打开
                if (ua.match(/MicroMessenger/i) == "micromessenger") {
                    // 微信浏览器中打开
                    this.Judge = "micromessenger"
                } else {
                    this.Judge = "android"
                    this.getCode()
                }
            } else {
                alert("该系统暂不支持开卡")
                this.Judge = "unknown"
            }
        },

        // 获取token
        async Gologin() {
            // 获取token
            let TokenData = await GetSeesion({
                workNo: this.UserName,
                Type: this.type,
            })

            if (TokenData.code != 1) {
                Toast({
                    position: "bottom",
                    message: TokenData.msg,
                })
            } else {
                // 将 工号 跟 tokens 存储在本地
                localStorage.setItem("tokens", TokenData.data.thirdSession)
                localStorage.setItem("workNo", TokenData.data.workNo)

                // 开成开登录
                let LoginData = await Login({
                    workNo: this.UserName,
                    passWord: this.PassWord,
                    code: this.imgCode,
                    randomStr: this.randomStrs,
                    type: this.type,
                })

                if (LoginData.code == 1) {
                    if (LoginData.data.flag == "0000") {

                        // 工号登录信息查询接口
                        let CheckworknoinfoData = await Checkworknoinfo({
                            workNo: localStorage.getItem("workNo"),
                            type: "1", //1:工号; 2:手机号
                        })

                        if (CheckworknoinfoData.code != 1) {
                            Toast({
                                message: CheckworknoinfoData.msg,
                                position: "bottom",
                            })
                        } else {
                            localStorage.setItem(
                                "Userdata",
                                JSON.stringify(CheckworknoinfoData.data)
                            )
                            this.userListData = CheckworknoinfoData.data
                            if (CheckworknoinfoData.data.defaultSaleKind == 3) {
                                // 业务类型改为4
                                this.systemId = 4
                            } else if (
                                CheckworknoinfoData.data.defaultSaleKind == 1 ||
                                CheckworknoinfoData.data.defaultSaleKind == 4
                            ) {
                                // 业务类型改为7
                                this.systemId = 7
                            }
                            // 将业务类型数据保存到systemId字段中放在本地储存
                            localStorage.setItem("systemId", this.systemId)
                            this.$router.push("/OpenCard")
                        }
                    } else {
                        Toast({
                            position: "bottom",
                            message: LoginData.data.message,
                        })
                    }
                } else if (LoginData.code == -100) {
                    Toast({
                        message: LoginData.msg,
                        position: "bottom",
                    })
                    this.LoginCode = ""
                } else {
                    Toast({
                        message: LoginData.msg,
                        position: "bottom",
                    })
                }
            }

           
        },

        // 退出登录
        LogOut() {
            localStorage.removeItem("thirdSession")
            localStorage.removeItem("tokens")
            localStorage.removeItem("Userdata")
            localStorage.removeItem("workNo")
            this.$router.push("/login")
        },
    },

    computed: {},

    mounted() {},
}
</script> 

<style scoped lang='scss' >
.WithoutCodeLogin {
    width: 100%;
    height: px2rem(1200);
    .logins {
        width: 100%;
        height: 100%;

        .login_header {
            width: 100%;
            height: px2rem(340);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 60%;
                height: 40%;
            }
        }
    }

    .login_tu {
        width: 100%;
        height: 100%;
        background: url(../../assets/images/login/login5.png) no-repeat;
        background-size: 100% 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        p {
            margin-bottom: px2rem(300);
            color: #1497ef;
        }
    }
    .login_tus {
        width: 100%;
        height: 100%;
        background: url(../../assets/images/login/login6.png) no-repeat;
        background-size: 100% 70%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        p {
            margin-bottom: px2rem(300);
            color: #1497ef;
        }
    }
}
</style>