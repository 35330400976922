<template>
    <div class="submitInvoice">
        <topHead>
            <img slot="head" src="../../../assets/images/fh.png" alt="" @click="backBefor()" />
            <h3 slot="header">开票详情</h3>
        </topHead>

        <div class="min">
            <div class="details">
                <h4>发票详情</h4>
                <ul class="min_list">
                    <li>
                        <div class="li_bet">
                            <div class="left_title">发票抬头</div>
                            <div class="radio_end">
                                <van-radio-group v-model="flexEnd_radio">
                                    <van-radio checked-color="#1d6184" name="0">个人</van-radio>
                                    <van-radio checked-color="#1d6184" name="1">单位</van-radio>
                                </van-radio-group>
                            </div>
                        </div>
                    </li>

                    <li v-if="flexEnd_radio == '1'">
                        <div class="addsite" @click="$router.push('/addsite')">
                            <p>选择公司信息</p>
                            <span>></span>
                        </div>
                    </li>

                    <li v-if="flexEnd_radio == '1'">
                        <div class="li_bet">
                            <p>发票类型</p>
                            <div class="pull_type">
                                <van-popover v-model="showPopover" trigger="click" :actions="actions"
                                    @select="onSelect">
                                    <template #reference>
                                        <div class="select_com">
                                            <input type="text" :value="select_text" placeholder="请选择发票类型" readonly>
                                            <div class="img">
                                                <img v-if="showPopover" src="../../../assets/images/sjt.png" alt="">
                                                <img v-else src="../../../assets/images/xjt.png" alt="">
                                            </div>
                                        </div>
                                    </template>
                                </van-popover>
                            </div>
                        </div>
                    </li>

                    <li v-if="companyMessage != '' && flexEnd_radio == '1'">
                        <div class="li_bet">
                            <div class="left_title">公司名称:</div>
                            <div class="right_con">
                                <input type="text" v-model="selectData.firmName" readonly="ture"
                                    placeholder="请输入公司名称 (必填)" />
                            </div>
                        </div>
                        <div class="li_bet">

                            <div class="left_title">纳税人识别号:</div>
                            <div class="right_con">
                                <input type="text" v-model="selectData.taxNumber" readonly="ture"
                                    placeholder="请输入纳税人识别号 (必填)" />
                            </div>
                        </div>
                        <div class="li_bet">

                            <div class="left_title">地址:</div>
                            <div class="right_con">
                                <input type="text" v-model="selectData.address" readonly="ture" placeholder="(选填)" />
                            </div>
                        </div>
                        <div class="li_bet">

                            <div class="left_title">手机号:</div>
                            <div class="right_con">
                                <input type="text" v-model="selectData.tel" readonly="ture" placeholder="(选填)" />
                            </div>
                        </div>
                        <div class="li_bet">
                            <div class="left_title">开户行:</div>
                            <div class="right_con">
                                <input type="text" v-model="selectData.bankName" readonly="ture" placeholder="(选填)" />
                            </div>
                        </div>
                        <div class="li_bet">

                            <div class="left_title">账号:</div>
                            <div class="right_con">
                                <input type="text" v-model="selectData.bankAccount" readonly="ture"
                                    placeholder="(选填)" />
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="li_bet">
                            <div class="left_title">发票金额:</div>
                            <div class="right_con">
                                <input class="money_inp" type="text" :value="total" readonly="ture"
                                    style="color: #ccc;" />
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="li_bet" v-if="flexEnd_radio == '0'">
                            <div class="left_title">发票类型:</div>
                            <div class="right_con">
                                <input class="money_type" type="text" value="普通发票(数电票)" readonly="ture"
                                    style="color: #ccc;" />
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="li_bet">
                            <div class="left_title">电子邮箱:</div>
                            <div class="right_con">
                                <input type="text" v-model="mailBox" @click="hint = true" @blur="hint = false">
                            </div>
                        </div>

                        <div class="hint" v-if="hint">
                            <span>最多可发送3次，请仔细核对邮箱名!</span>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="comment">
                <h4>全部账单</h4>
                <div class="comment_con" v-for="item, index in pitchOnlist" :key="index">
                    <p>{{ item.feeMonth }}</p>
                    <p>{{ item.feeAmount }}.00元</p>
                </div>

                <div class="total">
                    <p>合计：￥{{ total }}.00</p>
                </div>
            </div>

            <div class="btn">
                <button @click="backBefor">返回</button><button @click="drawerSubmit">提交开票</button>
            </div>

        </div>
    </div>
</template>

<script>
import topHead from "../../../components/topHead.vue"
import { Toast } from "vant"
import API from "../../../common/api/api"
import Lanmao from "../../../components/lanmao/index"
export default {
    components: {
        topHead,
    },
    data() {
        return {
            flexEnd_radio: "0",//抬头单选的值
            selectData: {
                firmName: "",//公司名称
                taxNumber: "",//识别号
                address: "",//发票地址
                tel: "",//发票手机号
                bankName: "",//开户行
                bankAccount: "",//账号
            },//选择的公司信息
            mailBox: "",//邮箱数据
            pitchOnlist: [],//列表页面选择的数据
            total: "",//列表页面选择发票的总价
            hint: false,//邮箱提示
            arr: [],
            companyMessage: [],//选择的公司信息
            showPopover: false,
            select_text: "", //选择的类型
            select_id: "", //选择的id
            // 通过 actions 属性来定义菜单选项
            actions: [
                { id: "81", text: '增值税专用发票(数电票)' },
                { id: "82", text: '普通发票(数电票)' }
            ],
        }
    },

    created() {
        this.checked()
    },
    // 组件方法
    methods: {
        checked() {
            this.receptionData()
        },
        onSelect(action) {
            this.select_text = action.text
            this.select_id = action.id
        },

        // 接收选择的信息
        receptionData() {
            this.pitchOnlist = JSON.parse(
                localStorage.getItem("checkedList")
            )
            this.total = localStorage.getItem("price")
            // 如果selectFlag等于query时，则为单位选择完公司信息
            if (localStorage.getItem("selectFlag") == "query") {
                this.companyMessage = this.$route.params.Message
                this.selectData.address = this.companyMessage.address
                this.selectData.firmName = this.companyMessage.name
                this.selectData.tel = this.companyMessage.tel
                this.selectData.taxNumber = this.companyMessage.tin
                this.selectData.bankName = this.companyMessage.bankName
                this.selectData.bankAccount = this.companyMessage.bankAccount
                this.flexEnd_radio = "1"
                localStorage.removeItem("selectFlag")
            } else {
                localStorage.removeItem("selectFlag")
            }
        },

        // 开票提交校验
        drawerSubmit() {
            var emailVerify = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
            if (this.flexEnd_radio == '0') {
                if (this.mailBox == "") {
                    Toast({
                        message: "邮箱号不能为空",
                        position: "bottom",
                        duration: 800,
                    })
                } else if (!emailVerify.test(this.mailBox)) {
                    Toast({
                        message: "请输入正确的邮箱号",
                        position: "bottom",
                        duration: 800,
                    })
                } else {
                    this.postSubmit()
                }
            } else {
                if (this.selectData.firmName == "" && this.selectData.taxNumber == "") {
                    Toast({
                        message: "请先选择公司信息",
                        position: "bottom",
                        duration: 900,
                    })
                } else if (this.select_id == "") {
                    Toast({
                        message: "请选择发票类型",
                        position: "bottom",
                        duration: 800,
                    })
                } else if (this.selectData.firmName == "") {
                    Toast({
                        message: "公司名称不能为空，选择公司信息时可进行编辑",
                        position: "bottom",
                        duration: 900,
                    })
                }
                else if (this.selectData.taxNumber == "") {
                    Toast({
                        message: "识别号不能为空，选择公司信息时可进行编辑",
                        position: "bottom",
                        duration: 900,
                    })
                } else if (this.mailBox == "") {
                    Toast({
                        message: "邮箱号不能为空",
                        position: "bottom",
                        duration: 800,
                    })
                } else if (!emailVerify.test(this.mailBox)) {
                    Toast({
                        message: "请输入正确的邮箱号",
                        position: "bottom",
                        duration: 800,
                    })
                } else {
                    this.postSubmit()
                }
            }
        },
        // 开票提交
        postSubmit() {
            Lanmao.loading.show("提交中")
            this.pitchOnlist.forEach(element => {
                this.arr.indexOf(element.id) == -1 ? this.arr.push(element.id) : "";
            });
            let tinId
            if (this.flexEnd_radio == '0') {
                tinId = ""
                this.sameData(tinId)
            } else {
                // 单位提交
                tinId = this.companyMessage.id
                API.getJson("NEW_SEND_INVOICE", {
                    invoiceType: this.select_id,     // 发票类型
                    type: this.flexEnd_radio,    // 个人/单位类型
                    ids: `${this.arr}`,          // 每个发票id
                    tinId: tinId,                   // 用户发票管理的id
                    email: this.mailBox,             // 用户邮箱
                    name: this.selectData.firmName == '' ? null : this.selectData.firmName,  // 公司名称
                    address: this.selectData.address == '' ? null : this.selectData.address, // 公司地址
                    tel: this.selectData.tel == '' ? null : this.selectData.tel,             // 公司电话
                    tin: this.selectData.taxNumber == '' ? null : this.selectData.taxNumber, // 公司纳税人识别号
                    bankName: this.selectData.bankName == '' ? null : this.selectData.bankName,  // 银行
                    bankAccount: this.selectData.bankAccount == '' ? null : this.selectData.bankAccount, // 银行卡号
                }).then(res => {
                    Lanmao.loading.hide()
                    if (res.code == 1) {
                        Toast({
                            message: res.msg,
                            position: "bottom",
                            duration: 800,
                        })
                        this.delData()
                        this.$router.push("/billing")
                    }
                }).catch(err => {
                    Lanmao.loading.hide()
                })
            }
        },
        // 个人提交
        sameData(tinId) {
            API.getJson("NEW_SEND_INVOICE", {
                ids: `${this.arr}`,
                tinId: tinId,
                type: this.flexEnd_radio,//0
                email: this.mailBox,
                invoiceType: "82",   // 个人只能开82 电子发票（普通发票）
            }).then(res => {
                Lanmao.loading.hide()
                if (res.code == 1) {
                    Toast({
                        message: res.msg,
                        position: "bottom",
                        duration: 800,
                    })
                    this.delData()
                    this.$router.push("/billing")
                }

            }).catch(err => {
                Lanmao.loading.hide()
            })
        },

        // 删除本地数据
        delData() {
            localStorage.removeItem("checkedList")
            localStorage.removeItem("price")
            localStorage.removeItem("userId")
        },

        // 头部返回/返回按钮返回
        backBefor() {
            this.$router.push("/invoiceList")
            this.delData()
        }
    },

    computed: {},

    watch: {},

    mounted() { },
}
</script>

<style scoped lang='scss'>
.submitInvoice {
    width: 100%;
    background-color: #f5f5f5;
    height: 100%;
    position: relative;

    .topHead {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
        background-color: #fff;
    }

    .min {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: auto;
        margin-top: px2rem(90);


        h4 {
            font-size: px2rem(28);
        }

        .details {
            width: 94%;
            padding: px2rem(30);
            margin-top: px2rem(12);
            border-radius: px2rem(16);
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            background-color: #fff;

            .min_list {
                line-height: px2rem(80);


                li {
                    font-size: px2rem(24);


                    .addsite {
                        height: px2rem(80);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span {
                            font-size: px2rem(28);
                        }

                        p {
                            color: red;
                        }
                    }

                    .li_bet {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .radio_end {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            flex: 6;

                            .van-radio-group {
                                width: 60%;
                                display: flex;
                                justify-content: space-around;
                                align-items: center;
                            }
                        }

                        .pull_type {
                            width: 80%;
                            height: 80%;

                            .van-popover__wrapper {
                                width: 100%;
                                padding: 0 px2rem(10);
                                border: px2rem(1) solid #333;
                                border-radius: px2rem(10);


                                .select_com {
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    input {
                                        height: px2rem(50);
                                        padding-left: px2rem(10);

                                    }

                                    .img {
                                        width: px2rem(30);
                                        height: px2rem(30);

                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }

                            }
                        }

                        .left_title {
                            flex: 3;
                        }
                    }

                    .right_con {
                        flex: 6;

                        .money_inp {
                            width: 50%;
                        }

                        .money_type {
                            width: 100%;
                        }

                        input {
                            width: 100%;
                            height: px2rem(46);
                            border-radius: px2rem(8);
                            border: px2rem(1) solid #e8e8e8;
                            padding-left: px2rem(20);
                        }
                    }

                    .hint {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        span {
                            color: red;
                            margin-right: px2rem(10);
                        }
                    }
                }
            }
        }

        .comment {
            width: 94%;
            padding: px2rem(30);
            margin-top: px2rem(12);
            border-radius: px2rem(16);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            line-height: px2rem(80);
            background-color: #fff;

            .comment_con {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .total {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                p {
                    font-size: px2rem(26);
                    font-weight: 600;
                }
            }

            p {
                font-size: px2rem(24);
            }
        }

        .btn {
            width: 94%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: px2rem(24);
            margin-bottom: px2rem(30);


            button {
                width: 46%;
                height: px2rem(80);
                font-size: px2rem(24);
                border-radius: px2rem(30);
                background: #fff;
                color: #fff;
            }


            :nth-child(1) {
                color: #1d6184;
            }

            :nth-child(2) {
                background-color: #1d6184;
            }
        }
    }
}
</style>
<style>
.van-icon-success::before {
    font-size: 0.5rem;
}

.van-popover__content {
    width: 8rem;
}

.van-popover__action {
    width: 100%;
    box-sizing: border-box;
    margin: 0.5rem 0;
    font-size: 0.5rem;
}
</style>